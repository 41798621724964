//Banner

.how-it-works {
  .banner-home {
    background-image: url('../../../img/svg/HowItWorks/banner-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;

    .text-slider {
      width: 100%;

      .texts {
        h1 {
          font-weight: 600;
          font-size: 54px;
          line-height: 60px;
          color: $white-color;

          span {
            color: $starstack-blue-main;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: rgba(248, 248, 248, 0.72);
        }

        .cta {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          align-content: center;

          .play-btn {
            cursor: pointer;
            font-weight: 300;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 1rem;
            color: $white-color;

            img {
              margin: 0px 1rem;
            }
          }
        }
      }

      @media screen AND (max-width: 992px) {
        width: 100%;
      }
    }

    @media screen AND (max-width: 992px) {
      .left-right-img-text {
        padding: 0;
      }
    }
  }

  .whats-dif-sec {
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: 991px) {
      padding-top: 0px;
    }
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    // @media screen AND (max-width: 450px) {
    //   flex-direction: column;
    // }
  }

  .st-cards {
    width: 45%;
    min-height: 340px;
    background: rgba(60, 59, 74, 0.24);
    backdrop-filter: blur(54px);
    border-radius: 20px;
    padding: 19px 31px;
    margin-right: 24px;

    @media screen AND (max-width: 992px) {
      margin-bottom: 24px;
      width: 100%;
      margin-right: 0;
    }

    .title {
      p {
        margin-bottom: 10px;
        color: $starstack-blue-main;
      }

      img {
        padding-bottom: 16px;
      }
    }

    .content {
      p {
        color: $white-color;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.005em;
      }
    }
  }
}

.st-name {
  color: $starstack-blue-main;
}

.home .abt-st {
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 43px;
    line-height: 52px;
    margin-bottom: 18px;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: $white-color;
    margin-bottom: 24px;
  }
}

.banner-home {
  .stars-img {
    max-width: 650px;
    right: 118px;
    bottom: 0;
    max-height: 640px;
    width: 50%;
  }

  @media screen AND (max-width: 992px) {
    .stars-img {
      display: none;
    }
  }
}

.bg-gradient {
  background-image: url($aws-base-url + '/bg-blur-lines.png') !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.ecosystem {
  .left-right-img-text {
    .ss-diff {
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;
    }
  }
}

.how-starstake-works {
  h2 {
    color: white;
  }

  ul {
    li {
      color: white;

      span {
        background-color: black !important;
        border: 2px solid $white-color;
        color: white !important;
      }
    }
  }

  .process-list {
    ul {
      &::after {
        background-color: rgb(253 253 253 / 12%);
      }

      li {
        text-align: left !important;

        h6 {
          color: $white-color;
        }

        span {
          border: 2px solid $white-color;
        }

        p {
          text-align: left !important;

          color: #787878;
        }

        .list-text {
          &:hover {
            background: rgba(60, 59, 74, 0.24);
            padding: 5px 21px 0;
          }
        }
      }
    }
  }

  .bg-black {
    background-color: black !important;
  }

  .banner-home {
    .text-slider {
      height: 100vh;
    }
  }
}

//Nft Bucks Banner
.Essentials-banner {
  background-image: url($aws-base-url + '/howItWorks/banner-bg.webp') !important;
  .main-content-wrapper {
    .banner-container {
      margin-top: 20rem;
      .banner-texts {
        .banner-heading {
          font-weight: 500;
          font-size: 38px;
          line-height: 60px;
          color: $white-color;
          @media screen and (max-width: 991px) {
            font-size: 28px;
            line-height: 35px;
          }
        }

        h1 {
          font-weight: 600;
          font-size: 96.33px;
          color: #ffffff;
          @media screen and (max-width: 991px) {
            font-size: 60px;
          }
        }

        .banner-desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #ded9d9;
          @media screen and (max-width: 991px) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

// How starstake creator works section css
.whos-on-creator {
  background: $black-color;

  span {
    color: $starstack-blue-main;
  }

  .bg-black {
    background-color: black;

    // margin-left: -2rem;
    // margin-right: -2rem;
    // width: 100%;
    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 43px;
      color: $white-color;
    }

    p {
      font-weight: normal;
      text-align: center;
      font-size: 16px;
      color: $white-color;
    }

    ul {
      li {
        list-style: none;
        font-weight: normal;
        text-align: center;
        font-size: 16px;
        color: $white-color;
      }
    }
  }

  .sec-1-typo {
    p {
      font-weight: 300;
    }
    @media screen AND (min-width: 992px) {
      margin: 0 auto;
    }
  }
}

// }

/* I am fan/creator section */
.iamfan-section {
  color: $white-color;
  font-weight: 300;
  text-align: left;
  display: flex;
  max-width: none !important;

  @media screen AND (max-width: 992px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-size: 1.75rem;
    margin: 0 0 0.75rem 0;
  }

  .i-am-fan-part {
    background: rgba(60, 59, 74, 0.24);
    flex: 1;
    padding: 5rem;

    @media screen AND (max-width: 994.27px) {
      padding: 1rem;
    }
  }

  .i-am-creator-part {
    background: rgba(60, 59, 74, 0.24);
    font-weight: 300;
    backdrop-filter: blur(54px);
    flex: 1;
    padding: 1rem;

    @media screen AND (min-width: 992px) {
      padding-left: 5rem;
    }
  }

  .toggle-bg {
    background: linear-gradient(
      126.44deg,
      $starstack-blue-main 3.16%,
      #8367ee 77.34%
    ) !important;
  }
}

// howitworks table section

.starstake-platform {
  padding: 60px 0;
}

.starstake-table-container {
  // border: 2px solid gray;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $cinder-black-color;
  border-radius: 24px;
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 110px;

  .table-body {
    background-image: url($aws-base-url + '/common/blur-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.7;
  }

  .perf-icon {
    position: absolute;
    right: -60px;
    bottom: -150px;
    width: 220px;
    height: 220px;
  }

  .table-heading {
    padding: 0.5rem;
    font-weight: 400;
    font-size: 21px;
    width: 100%;
    line-height: 50px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    background: rgba(28, 26, 65, 0.24);
    backdrop-filter: blur(54px);
    border-radius: 30px 30px 0px 0px;
    svg {
      width: 300px;
      height: 30px;
      @media screen and (max-width: 991px) {
        width: 150px;
        height: 20px;
      }
    }
    h2 {
      color: $white-color;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      line-height: 52px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }
  }

  .table-body {
    display: flex;
    width: 100%;
    flex: 1;
  }

  .col1 {
    width: 50%;
    border-right: 2px solid;
    border-image: rgba(86, 78, 206, 0.08);
    padding: 10px;
    .small-cell {
      padding: 20px 47px;

      @media screen AND (max-width: 992px) {
        padding: 20px 0;
      }
    }

    h6 {
      font-size: 21px;
      color: $starstack-blue-main;
      @media screen and (max-width: 991px) {
        font-size: 15px;
      }
    }

    p {
      font-size: 16px;
      color: $white-color;
      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }
  }

  .col2 {
    width: 50%;
    padding: 10px;

    .small-cell {
      padding: 20px 47px;

      @media screen AND (max-width: 992px) {
        padding: 20px 0 20px 20px;
      }
    }

    h6 {
      font-size: 21px;
      color: $starstack-blue-main;
      @media screen and (max-width: 991px) {
        font-size: 15px;
      }
    }

    p {
      font-size: 16px;
      color: $white-color;
      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }
  }
}

//Royalty based nft section

.royalty-based-nft {
  background-image: url($aws-base-url + '/light-blur.png'),
    url($aws-base-url + '/polygon.png');
  background-size: 90%, 75% 125%;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: bottom;
  background-position-x: right;

  .left-right-img-text {
    .left-right-img {
      padding-top: 10rem;
      width: 40%;
      @media screen and (max-width: 991px) {
        padding-top: 0rem;
        width: 100%;
      }
    }

    .text {
      width: 60%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.015em;
        color: $white-color;
      }
    }
  }

  span {
    color: $starstack-blue-main;
  }

  .royalty-table-container {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    flex-wrap: nowrap;

    .table-body {
      display: flex;
      width: 100%;
      flex: 1;
    }

    .col1 {
      width: 50%;
      border-right: 2px solid;
      border-image: rgba(86, 78, 206, 0.08);

      .small-cell {
        padding: 20px 35px 20px 0;

        @media screen AND (max-width: 992px) {
          padding: 20px 0;
        }
      }

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 110%;
        color: $starstack-blue-main;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.015em;
        color: #ffffff;
      }
    }

    .col2 {
      width: 50%;

      .small-cell {
        padding: 20px 0 20px 35px;

        @media screen AND (max-width: 992px) {
          padding: 20px 0 20px 20px;
        }
      }

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 110%;
        color: $starstack-blue-main;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.015em;
        color: #ffffff;
      }
    }
  }
}

// World of possibilities section

.world-of-possibilities-section-hiw {
  background-image: url($aws-base-url + '/common/blur-bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 0;
  margin-top: 4%;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 52px;
  }

  .bottom-img {
    display: flex;
    justify-content: center;

    img {
      max-width: 95%;
    }
  }

  .color-purple {
    background-color: $starstack-blue-main;
  }
}

@media screen AND (max-width: 992px) {
  .gradientcircle {
    display: none;
  }
  .image-mob {
    width: 100%;
  }
  .banner-mobile-img {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
