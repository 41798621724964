.stage-of-starstake-section {
  background: url($aws-base-url + '/aboutus/Stages0fStarstakeBG.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .row-wrapper {
    width: 90%;
    padding: 50px;
    .roadmap-title {
      padding-bottom: 5rem;
      .h1 {
        font-size: 43px;
        line-height: 52px;
        font-weight: 600;
        color: #ffffff;
        font-style: normal;
      }
      h5 {
        font-weight: 600;
      }
    }
    .stageswrapper {
      .stages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .stages-details {
          position: relative;
          max-width: 271px;
          p {
            color: rgba(255, 255, 255, 0.66);
            font-size: 14px;
            line-height: 20.5px;
          }
          &::before {
            content: '';
            display: block;
            width: 2px;
            height: 243px;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.67) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            left: -0.4em;
            top: 0.5em;
          }
        }
      }

      .row-first {
        margin-right: 8rem;
        padding-bottom: 2em;
        background-image: linear-gradient(
          to right,
          rgb(86, 78, 206) 30%,
          rgba(0, 0, 0, 0) 0%
        );
        background-position: bottom;
        background-size: 236px 3px;
        background-repeat: repeat-x;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      .row-secound {
        margin-left: 8rem;
        padding-top: 2rem;
      }
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .stage-of-starstake-section {
    .row-wrapper {
      width: 100%;
      padding: 30px;
      .roadmap-title {
        padding-bottom: 5rem;
        .h1 {
          font-size: 43px;
          line-height: 52px;
          font-weight: 600;
        }
        h5 {
          font-weight: 600;
        }
      }
      .stageswrapper {
        display: flex;
        justify-content: center;
        .stages {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          .stages-details {
            max-width: 100%;
            padding: 20px;
          }
        }

        .row-first {
          margin-right: 2rem;
          padding-bottom: 2em;
        }

        .row-secound {
          margin-left: 2rem;
          padding-top: 2rem;
        }
      }
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .stage-of-starstake-section {
    .row-wrapper {
      width: 100%;
      padding: 30px;
      .roadmap-title {
        padding-bottom: 5rem;
        .h1 {
          font-size: 43px;
          line-height: 52px;
          font-weight: 600;
        }
        h5 {
          font-weight: 600;
        }
      }
      .stageswrapper {
        display: none;
        .stages {
          .stages-details {
            max-width: 100%;
            padding: 20px;
          }
        }

        .row-first {
          margin-right: 2rem;
          padding-bottom: 2em;
        }

        .row-secound {
          margin-left: 2rem;
          padding-top: 2rem;
        }
      }
    }
  }
}

// resposnive

.ressponsive-details {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .ressponsive-details {
    display: flex;
    .quarters {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .quarters-details {
        position: relative;
        max-width: 100%;
        padding: 10px;
        p {
          color: rgba(255, 255, 255, 0.66);
          font-size: 14px;
          line-height: 20.5px;
        }
        &::before {
          content: '';
          display: block;
          width: 2px;
          height: 243px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.67) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          position: absolute;
          left: -0.4em;
          top: 0.5em;
        }
      }
    }

    .row-one {
      background-image: linear-gradient(
        to right,
        rgb(86, 78, 206) 30%,
        rgba(0, 0, 0, 0) 0%
      );
      background-position: bottom;
      background-size: 236px 3px;
      background-repeat: repeat-x;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}
