@media screen and (min-width: $sm) and (max-width: $md) {
  .invitation-modal {
    width: 550px !important;
  }

  .confirmation-container {
    width: 550px !important;
    padding: 1rem !important;
  }

  .form-modal-box {
    width: 550px !important;
  }
}

@media screen and (max-width: $md) {
  //Home Page banner
  .banner-bg {
    height: auto;
    max-height: unset;
  }

  // .home .banner-home .banner-slider
  .banner-home {
    padding: 1rem;
    overflow: auto;
    height: auto;
    max-height: unset;

    .banner-slider {
      .owl-theme {
        .owl-item {
          // width: 250px;
          .img {
            width: auto !important;
          }
        }
      }
    }

    .text-slider {
      display: flex;
      flex-direction: column;
      margin-top: 70px;
      height: auto;

      .text {
        flex: 1;
        width: 100%;

        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 30px !important;
          line-height: 35px !important;
          color: $white-color;

          span {
            color: $starstack-blue-main;
          }
        }

        p {
          font-weight: 300;
          font-size: 15px !important;
          line-height: normal !important;
          color: rgba(248, 248, 248, 0.72);
        }

        .cta {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          align-content: center;

          .play-btn {
            cursor: pointer;
            font-weight: 300;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $white-color;

            img {
              margin: 0px 1rem;
            }
          }
        }

        .powered-by {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: $white-color;

          .badges {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            align-content: center;

            span {
              margin-right: 2rem;
            }
          }
        }
      }

      .slider {
        margin: 5rem 0 0 0;
        height: auto;
        flex: auto;
        width: 100%;
      }
    }
  }

  .collections-list .nav-pills .nav-item .nav-link {
    font-size: 12px;
  }

  .feature-collection {
    margin: 0 auto;
  }

  // all the banner css
  .Essentials-banner {
    .position-absolute {
      position: unset !important;
      background: $black-color;
    }
  }

  .blurr-bg {
    &::after {
      content: '';
      background-image: url($aws-base-url + '/common/blur-bg.webp');
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      height: 100%;
    }
  }

  .banner-collection {
    border-radius: 32px;

    .total-blance {
      border: none;
    }
  }

  .profile-section {
    .text-end {
      text-align: left !important;
    }
  }

  .profile-text .form-control {
    width: 100%;
  }

  // Shipping Address ------------------------------
  .shipping-section {
    .box-address {
      h3 {
        font-size: 14px;
      }

      h4 {
        font-size: 15px;
      }

      p {
        font-size: 13px;
      }

      .btn {
        width: 130px;
        height: 40px;
      }
    }

    .add-address .btn {
      width: 254px;
      height: 59px;
    }
  }

  .add-address-modal .modal-content {
    .form-control {
      height: 50px;
      padding: 0px 15px;
      font-size: 12px;
    }

    .form-label {
      font-size: 12px;
    }

    .modal-header span {
      cursor: pointer;
      padding-left: 20px;
    }

    .btn {
      width: 100%;
      height: 50px;
    }
  }

  // starter bundle
  .sb-wrapper {
    margin: 1rem;

    .starter-bundle {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      .text {
        background: linear-gradient(
          102.14deg,
          $starstack-blue-main 29.17%,
          #2b00cf 84.6%
        );
        border-radius: 20px;
        color: $white-color;
        position: relative;
        margin-right: 2rem;
        padding: 4em 0;

        .text-text {
          margin: 1.15rem 2rem;

          h1 {
            font-size: 54px;
            font-weight: 600;
          }

          p {
            width: 44%;
            font-weight: 400;
            line-height: 28px;
            font-style: normal;
          }

          .view-bundle-btn {
            .btn-white {
              width: 250px;
              height: 53px;
              padding: 0;
              font-size: 16px;
              font-weight: 400;
              background: $white-color;
              color: $starstack-blue-main;

              &:hover {
                background: $gray9-color;
                color: $white-color;
              }
            }
          }
        }
      }

      .image {
        display: none;
      }

      > div {
        flex: 1;
      }
    }
  }
}
