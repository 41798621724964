.nftb-selector {
  .sub-header-p {
    color: $white-color;
    font-size: 16px;
    line-height: 21px;
    width: 70%;
    margin-left: 55px;
    margin-top: 20px;

    @media screen and (max-width: 767px) {
      margin-left: 10px;
      width: 100%;
    }
  }

  .sub-hheader-links {
    .btn.btn-md {
      padding: 14px 24px;
      font-size: 14px;
      color: $starstack-blue-main !important;
    }

    .btn.btn-outline-primary {
      padding: 14px 24px;
      font-size: 14px;
      border: 1.5px solid $starstack-blue-light;
      color: $starstack-blue-main !important;
      background-color: transparent !important;

      &:hover {
        color: white !important;
        background-color: $starstack-blue-light !important;
      }
    }
  }

  .nftb-cards {
    // display: flex;
    // justify-content: space-between;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // overflow: auto;
    .slick-slider {
      width: 100%;
    }

    .collection-list-box {
      min-width: 240px;
      max-width: 240px;
      flex: 1;
      border: 1px solid rgba(171, 171, 171, 0.38);
      box-sizing: border-box;
      border-radius: 25px;
      margin: 0px 20px;
      padding: 1.25rem;
      cursor: pointer;

      .collections-img {
        img {
          width: 100%;
          height: 387px;
          border-radius: 8px;
        }
      }

      .collection-text {
        font-size: 16px;
        line-height: 37px;
        font-weight: 500;
        color: $starstack-blue-light;

        .icon-menusx {
          display: flex;
          align-items: center;
          font-size: 15px;
        }
        svg {
          height: 18px;
          margin-right: 0.2rem !important;

          path {
            fill: $starstack-blue-main;
          }
        }

        .check-mark {
          width: 22px;
          height: 22px;
          margin-right: 0.5rem;
          border-radius: 22px;
          background: linear-gradient(
            134.22deg,
            rgba(86, 78, 206, 0.15) 9.27%,
            rgba(0, 148, 255, 0.15) 142.83%
          );
        }
      }

      &.selected {
        background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
        box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
        border-radius: 25px;
        border: 1px solid #393394;

        .collection-text {
          color: $white-color;

          svg {
            path {
              fill: $white-color;
            }
          }

          .check-mark {
            width: 22px;
            height: 22px;
            margin-right: 0.5rem;
            border-radius: 22px;
            background: $white-color;
            background-image: url('../../..//img/svg/CheckMark/CheckMark.svg');
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .custom-arrow-slider {
    position: relative;

    .arrow-slider-main {
      width: calc(100% - 100px);
      margin: auto;
      position: relative;
    }

    .owl-carousel {
      .owl-nav {
        position: absolute;
        top: 36%;
        left: 0;
        width: 100%;

        button {
          &:hover {
            background: transparent;
            transition: all ease 0.4s;

            span {
              margin-left: 2px;
              transition: all ease 0.4s;
            }
          }

          &:disabled {
            &.owl-prev {
              display: none;

              span {
                display: none;
              }
            }
          }

          &.owl-next {
            position: absolute;
            right: 0;

            &:disabled {
              display: none;
            }

            span {
              background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
                no-repeat;
              width: 27px;
              height: 29px;
              background-position: center center;
              background-size: contain;
              color: transparent;
              display: inline-block;
              margin-right: -130px;
            }
          }

          &.owl-prev {
            position: absolute;
            left: 0;

            span {
              background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
                no-repeat;
              width: 27px;
              height: 29px;
              background-position: center center;
              background-size: contain;
              color: transparent;
              display: inline-block;
              transform: rotate(180deg);
              margin-left: -130px;
            }

            display: unset !important;
          }
        }
      }
    }
  }
}

.bundle-summary {
  .box {
    overflow: hidden;

    // background: #33314B;
    .heading {
      background: rgba(86, 78, 206, 0.06);
      box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      color: $white-color;
      margin: 0px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.5px;
      // line-height: 22px;
      color: rgba(255, 255, 255, 0.66);
      white-space: pre-wrap;
    }

    .bundle-img {
      width: 70%;
      margin-left: 25px;

      img {
        width: 100%;
      }
    }

    .summary {
      background: rgba(86, 78, 206, 0.06);
    }

    .in-bundle {
      background: rgba(86, 78, 206, 0.11);
      border-radius: 18px;
      margin-bottom: 1rem;

      .buck-img {
        width: 51px;

        img {
          width: 100%;
        }
      }

      .rairty {
        white-space: nowrap;
        font-weight: 500;
        min-width: fit-content;
        font-size: 10.8036px;
        background: $starstack-blue-main;
        border-radius: 18.4562px;
        padding: 0.5rem 1rem;
        color: $white-color;
      }

      .react-select-container {
        min-height: auto;
        margin: 0px 0.5rem;
        .react-select__control {
          padding: 0px 5px 0px 0px;
        }
      }

      .react-select__single-value {
        color: hsl(0, 0%, 100%);
      }
    }
  }

  .instructions {
    .heading {
      background: rgba(86, 78, 206, 0.06);
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      /* or 169% */
      color: rgba(255, 255, 255, 0.66);
    }
  }
}

@media screen and (max-width: 600px) {
  .nftb-selector {
    .nftb-cards {
      flex-direction: column;

      .collection-list-box {
        margin: 10px 10px;
      }
    }
  }

  .bundle-summary {
    .box {
      overflow: hidden;

      // background: #33314B;
      .heading {
        background: rgba(86, 78, 206, 0.06);
        box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: $white-color;
        margin: 0px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.5px;
        // line-height: 22px;
        color: rgba(255, 255, 255, 0.66);
      }

      .bundle-img {
        width: 70%;
        margin-left: 25px;

        img {
          width: 100%;
        }
      }

      .in-bundle {
        .buck-img {
          margin-bottom: 1rem;
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.input-wrapper-market {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.input-for-price {
  input {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
  }
}

.add-remove-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 60px 10px;
}
