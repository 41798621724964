.img-banner {
  position: relative;

  &.categoryBanner {
    background: $dark-color;
    height: auto;

    .icon-image-tem {
      width: 532px;
      height: 159px;
      background: rgba(0, 0, 0, 0.39);
      backdrop-filter: blur(23px);
      border-radius: 29px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .icon-box-tm {
        text-align: center;

        a {
          color: $white-color;
          font-weight: 500;
          font-size: 18px;
          line-height: 40px;
          text-decoration: none;
        }
      }
    }

    .video-text-left-image {
      height: max-content;
      width: 561px;
      height: 522px;
      background: rgba(0, 0, 0, 0.42);
      backdrop-filter: blur(53px);
      border-radius: 12px;
      text-align: center;
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .bg-light-box {
        background: rgb(239 233 255 / 7%);
      }
    }

    .image-banner-boxs-inft {
      width: 670px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  &::after {
    content: '';
    background: url($aws-base-url + '/shadow-img-im.png');
    position: absolute;
    width: 849.27px;
    height: 740.88px;
    width: 994.27px;
    height: 1027.88px;
    top: 0;
    left: 0;
  }

  .video-thm-detail {
    background: rgba(28, 28, 28, 0.79);
    backdrop-filter: blur(8px);
    border-radius: 0px 0px 12px 12px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-weight: 500;
      font-size: 25px;
      margin: 0;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: rgba(239, 233, 255, 0.6);
    }
  }
}

.image-banner-tex {
  min-width: 561px;
  max-width: 48%;
  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
  a {
    font-weight: 500;
    font-size: 20px;
    color: $starstack-blue-main;
    text-decoration: none;
  }
}

.snft-profile-verify-icon {
  position: absolute;
  bottom: -4px;
  right: 0;
  background-color: #000;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 10px;
    width: 6.8px;
  }
}
