.ci-s1 {
  background-image: url('../../../../img/CreatorhubInvitationImg/Frame.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .ci-s1-b1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8%;

    .ci-s1-b3 {
      display: flex;
      flex-direction: row;

      .ci-s1-b2 {
        padding: 10px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .h1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          margin-right: 20px;
          line-height: 25px;
          color: #ffffff;
          width: 70%;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
