.ms-box1 {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  background: #0b0d19;
  padding: 20px;
  border-radius: 32px;

  .ms-h3 {
    font-family: Poppins;
    font-style: 'normal';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #efe9ff;
  }

  .ms-h3a {
    font-family: Poppins;
    font-style: 'normal';
    font-weight: 400;
    color: #564ece;
  }

  .ms-h3w {
    font-size: 24px;
    font-family: Poppins;
    font-style: 'normal';
    font-weight: 400;
    color: #ffffff;
  }

  .ms-font1 {
    font-family: Poppins;
    font-style: 'normal';
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #ffffff;
    margin: 0 10px;
    padding: 0 10px;
  }

  .ms-main {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;

    .ms-comp2 {
      display: flex;
      justify-content: space-between;
    }

    .ms-bg1 {
      width: 50%;
      padding: 10px;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      .ms-bga {
        display: flex;
        .ms-bga-img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }

      .ms-bg {
        background: #121420;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 30px;
        flex-wrap: wrap;
        .ms-img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .ms-bga {
          display: flex;
        }
      }
    }
  }
}
