.nft-details-page-settings-accordian-wrapper {
  background: #0f0f11;
  border-radius: 17px;
  overflow: hidden;
  .accordian-title-toggle-container {
    background: rgba(86, 78, 206, 0.42);
    padding: 20px 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .accordian-title-icon {
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 42px;
        color: white;

        @media screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 30px;
        }

        @media screen and (max-width: 600px) {
          font-size: 15px;
          line-height: auto;
        }
      }
    }

    .accordian-toggle-icon {
      cursor: pointer;
      svg {
        height: 50px;
        width: 50px;
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);

        @media screen and (max-width: 767px) {
          height: 40px;
          width: 40px;
        }
        &.collapsed-icon {
          transform: rotate(0deg);
        }
      }
    }
  }

  .accordian-body-container {
    position: relative;
    min-height: 400px;
    background: url('../../../img/CreatorHub/nft-settings-body-bg.webp')
      no-repeat;
    background-position: center;
    background-size: cover;

    .accordian-cards-wrapper {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      padding: 60px 40px;
      padding-bottom: 120px;

      @media screen and (max-width: 600px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .accordian-individual-card {
        padding: 20px;
        display: flex;
        gap: 20px;
        width: 375px;
        height: 180px;
        background: url('../../../img/CreatorHub/nft-settings-card-bg.webp')
          no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 13px;
        overflow: hidden;
        .icon {
        }
        .text-content {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 500;
            font-size: 18px;
            color: white;
            margin-bottom: 10px;
          }
          .tag {
            font-size: 12px;
            line-height: 129%;
            color: rgba(255, 255, 255, 0.42);
          }
          .action-div {
            margin-top: auto;

            .action-text {
              color: white;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }
      }
    }

    .accordian-footer {
      min-height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      padding: 10px 40px;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(16.5px);

      .share-link {
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(16.5px);
        .link {
          padding: 10px;
          color: white;
          font-size: 16px;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
        .copy-icon {
          cursor: pointer;
          background: #1c1c21;
          padding: 10px;
        }
      }
      .share-text {
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        font-size: 16px;
      }
    }
  }

  // switch css
  .custom-checkbox-switch {
    display: flex;
    position: relative;
    width: 100%;
    height: 25px;

    .custom-checkbox-switch-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;

      &:checked ~ .custom-checkbox-switch-label::before {
        color: white;
        background: linear-gradient(
          89.93deg,
          #ffb89a 12.96%,
          #ff8585 54.64%,
          #8745c9 99.91%,
          #a94fb0 99.92%
        );
      }
    }

    .custom-checkbox-switch-label {
      position: relative;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 25px;
        content: '';
        background-color: rgba(217, 217, 217, 0.2);
        border-radius: 21.5px;
      }

      &::after {
        position: absolute;
        top: 3px;
        left: 5px;
        width: 19px;
        height: 19px;
        content: '';
        background: no-repeat 50% / 50% 50%;
        background-color: white;
        border-radius: 100%;
        transition: transform 0.15s ease-in-out,
          background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }

    .switch-text {
      position: absolute;
      left: 60px;
    }
  }

  .custom-checkbox-switch-input:checked ~ .custom-checkbox-switch-label::after {
    transform: translateX(1.4rem);
  }
}
