.my-agent-manager {
  .my-manager-cards-wrapper {
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  .starCard {
    background: #0b0c11;
    backdrop-filter: blur(93px);
    border-radius: 20px;
    padding: 1.2rem;
    max-width: 524px;
    width: max-content;
    .no-data-title {
      font-weight: 400;
      font-size: 21px;
      color: #ffffff;
      line-height: 34px;
    }
    .starType {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.66);
      margin-top: 1rem;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 10px;
      }
    }
    .edit-button {
      text-decoration: none;
      color: #564ece;
      div {
        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 10px;
        }
      }
    }

    .starName {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.05em;
      color: #ffffff;
      margin-top: 2px;
      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .starInfo {
      padding: 1rem;
      background: rgba(239, 233, 255, 0.03);
      backdrop-filter: blur(93px);
      border-radius: 12px;
      display: flex;
      gap: 10px;
      margin-top: 2rem;
      .starImage {
        border-radius: 50%;
        width: 99px;
        height: 99px;
      }
      .inftImage {
        width: 16px;
        height: 15.31px;
        left: 0px;
        top: -0.14px;
      }
      .starEarning {
        display: flex;
        flex-direction: column;
        font-family: $starstack-font-Montserrat;
        font-weight: 800;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: -0.01em;
        color: #ffffff;
        @media screen and (max-width: 600px) {
          margin-top: 10px;
          font-size: 25px;
          line-height: 35px;
        }
      }
      .starInfts {
        display: flex;
        flex-direction: column;
        font-family: $starstack-font-Montserrat;
        font-weight: 800;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: -0.01em;
        color: #ffffff;
        @media screen and (max-width: 600px) {
          margin-top: 10px;
          font-size: 25px;
          line-height: 35px;
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2rem;
      .profileButton {
        width: 202px;
        height: 53px;
        background: #564ece;
        border-radius: 9px !important;
        font-family: $starstack-font-main !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 28px !important;
        text-align: center !important;
        color: #ffffff !important;
        padding: 0px !important;
        &:hover {
          background: #0b0c11 !important;
        }
      }
      .salesButton {
        width: 202px;
        height: 53px;
        background: #0b0c11 !important;
        border: 1px solid #564ece !important;
        border-radius: 9px !important;
        font-family: $starstack-font-main !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 28px !important;
        text-align: center !important;
        color: #ffffff !important;
        padding: 0px !important;
        &:hover {
          background: #564ece !important;
        }
      }
    }
  }
}
.star-details {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  gap: 5px;
  align-items: center;
  p {
    font-family: $starstack-font-Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(239, 233, 255, 0.6);
  }
}
.my-managers-overview {
  .top-section {
    .top-section-list {
      button {
        padding: 10px 20px;
        margin-bottom: 2rem;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          padding: 10px;
        }
      }
      .watch-tut {
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
