//MODAL
.modal-backdrop {
    &.show {
        opacity: 0.8;
    }
}

.text-w {
    color: $white-color;
}

.detail-img {
    position: relative;
    top: -25px;
    // svg {
    //     width: 100%;
    //     height: 100%;
    // }
    img {
        width: 350px;
        // height: 100%;
    }
    @media screen and (max-width: 991px) {
        position: relative;
        top: 0px;
        // svg {
        //     width: 100%;
        //     height: 100%;
        // }
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 768px) {
        position: relative;
        top: 0px;
        // svg {
        //     width: 100%;
        //     height: 100%;
        // }
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 400px) {}
}

.modal {
    .modal-dialog {
        .modal-content {
            background: $cinder-black-color;
            box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
            border-radius: 31px;
            border: 1px solid $bluelight-color;
            .modal-header {
                border-bottom: 0px solid #dee2e6;
                .modal-title {
                    width: 100%;
                    font-size: 14px;
                    color: $white-color;
                    button {
                        display: inline-block;
                    }
                    h4 {
                        margin: 0px;
                    }
                }
            }
            .modal-body {
                p {
                    &.text-w {
                        color: $white-color;
                    }
                    color: $carbon-gray-color;
                    a {
                        color: $starstack-blue-light;
                    }
                }
                a {
                    color: $white-color;
                    text-decoration: none;
                }
                .address-box {
                    border-radius: 31px;
                    border: 2px solid $starstack-blue-light;
                }
            }
            .modal-footer {}
        }
    }
}

.tran {
    border-radius: 1rem;
    overflow: hidden;
    // border: 1px solid $starstack-blue-light;
    .address {
        background-color: $gray9-color;
        // border-bottom: 1px solid $starstack-blue-light;
        p {
            color: $white-color !important;
        }
    }
    .asset {
        background-color: $ship-gray-color;
        p {
            color: $white-color !important;
        }
    }
}