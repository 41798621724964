.form-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.8);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-modal-box {
  animation: fadeIn 2s;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 576px;
  max-width: 95%;
  height: auto;
  background: rgba(11, 13, 25, 1);
  backdrop-filter: blur(45.59px);
  border-radius: 25px;
  overflow: hidden;
  .header {
    font-weight: 500;
    font-size: 22px;
    line-height: 42px;
    color: rgba(86, 78, 206, 1);
    background-color: rgba(86, 78, 206, 0.08);
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      padding: 12px 18px;
      font-size: 17px;
    }
    svg {
      cursor: pointer;
      path {
        fill: rgba(255, 255, 255, 0.4);
      }
    }

    span {
      color: white;
      font-weight: 800;
      font-size: 15px;
      padding: 5px 10px;
      border-radius: 4px;
      background-image: linear-gradient(
        to left,
        rgba(86, 78, 206, 1),
        rgba(199, 173, 255, 1)
      );
      @media screen and (max-width: 767px) {
        padding: 4px 8px;
        font-size: 12px;
      }
    }
  }

  .form-body {
    padding: 15px 30px;
    @media screen and (max-width: 767px) {
      padding: 0 18px;
    }
    h3 {
      color: white;
      font-size: 24px;
      line-height: 29px;

      span {
        width: max-content;
        background: linear-gradient(
          80.03deg,
          #ffb89a 0.98%,
          #ff8585 45.52%,
          #a94fb0 93.44%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
    .part-1 {
      color: rgba(255, 255, 255, 0.67);
    }
  }

  .button-container {
    padding: 10px 20px 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;

    p {
      color: rgba(117, 118, 124, 1);
      font-size: 12px;
      margin-bottom: 0;
    }

    button {
      background: #564ece;
      border: 1px solid #564ece;
      border-radius: 9px;
      width: 271px;
      height: 54px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 44px;
      text-decoration: none;
      display: flex;
      text-align: center;
      color: #ffffff;
      &:hover {
        background: transparent;
      }
    }

    .agent-button {
      width: 142px;
      height: 44px;
      font-weight: 500;
      font-size: 18px;
      line-height: 44px;
      /* identical to box height, or 244% */
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .form-footer {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;

    span {
      color: #564ece;
    }
  }
}

@keyframes slide-in-out-1 {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-out-2 {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-from-right {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: slide-in-out-2;
  transform: translateX(-100vw);
}

.slide-from-left {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: slide-in-out-1;
  transform: translateX(-100vw);
}

.invitation-form-container {
  position: relative;
  background-image: url($aws-base-url + '/CreatorhubInvitationImg/form-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  margin-top: 65px;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &::after {
    min-height: 100vh;
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(86, 78, 206, 0.17);
  }

  .form-error {
    display: block;
    color: #ff3f56;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
  }

  .confirmation-container {
    animation-duration: 2s;
    position: relative;
    animation-name: slide-in-out-1;
    animation-fill-mode: forwards;
    position: absolute;
    transform: translateX(-100vw);
    color: #ffffff;
    max-width: 730px;
    padding: 40px;
    background: rgba(7, 7, 11, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 33px;
    text-align: center;
    margin: 5rem auto;
    max-height: 100%;

    @media only screen and (max-width: 991px) {
      padding: 25px;
      max-width: 600px;
    }
    .tick-icon {
      text-align: left;
      svg {
        @media screen and (max-width: 776px) {
          width: 90px;
          height: 90px;
        }
      }
    }
    .svg-confirm-browse-nft {
      path {
        stroke: #fc1885;
        fill: #fc1885;
      }
    }
    .pink-text {
      color: #fc1885;
    }

    .confirmation-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      color: #ffffff;
      text-align: left;

      @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 25px;
      }

      span {
        color: #564ece;
      }
    }

    .confirmation-desc {
      line-height: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.57);

      span {
        color: #fff;
        text-decoration-line: underline;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .confirmation-footer {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      text-align: left;
      .access-steps-container {
        padding: 16px 28px;
        border: 1px dashed #564ece;
        background: linear-gradient(
          261deg,
          rgba(86, 78, 206, 0.1) 19%,
          rgba(199, 173, 255, 0.1) 79%
        );
      }
      .yellow-text {
        background: linear-gradient(
          102deg,
          #ffca80 15%,
          #ffe0ce 54%,
          #f3bc68 95%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        span {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .invitation-button-container {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      align-items: center;

      button {
        background: #564ece;
        border: 1px solid #564ece;
        border-radius: 9px;
        width: max-content;
        font-weight: 500;
        font-size: 18px;
        line-height: 44px;
        color: #ffffff;
        padding: 5px 30px;
        &:hover {
          border: 1px solid #564ece;
          background: transparent;
        }

        @media screen and (max-width: 991px) {
          font-size: 14px;
          line-height: 20px;
          max-width: 230px;
          padding: 7px 20px;
        }
      }

      .join-starstake-club-link {
        color: #564ece;
        font-size: 16.4px;
        font-weight: 500;
        line-height: 100%;
        text-decoration: none;

        span {
          background: linear-gradient(
            102deg,
            #ffca80 14%,
            #ffe0ce 54%,
            #f3bc68 95%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-decoration-line: underline;
          color: #ffca80;
        }

        &:hover span {
          opacity: 0.7;
        }

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
  }

  .invitation-modal {
    margin: 10rem 1rem 5rem;
    width: 608px;
    background: #ffffff;
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    border-radius: 27px;

    &.creator-agreement-modal {
      width: 991px;
    }

    .invitation-header {
      height: 117px;
      background: #111113;
      border-radius: 20px 20px 0px 0px;

      .title {
        font-weight: 400;
        font-size: 21px;
        line-height: 42px;
        letter-spacing: -0.05em;
        color: #ffffff;
        span {
          color: #564ece;
        }
      }

      .form-heading {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #ffffff;
      }
    }
    .drop-down-main-wrapper {
      display: flex;
      justify-content: space-between;
      .dropdown-wrapper {
        position: relative;
        width: 60%;
        aspect-ratio: 1.33;
        margin-left: 20px;
        margin-top: 30px;
        border-radius: 20px;
        .dropArea {
          position: relative;
          width: 100%;
          height: 100%;
          background: #f2f1ff;
          border: 1px dashed #564ece;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          flex-direction: column;
          svg {
            width: 50px;
            height: 50px;
          }
          .upload-img-text {
            font-weight: 500;
            font-size: 16.4076px;
            line-height: 15px;
            text-align: center;
            color: #564ece;
            padding: 20px;
          }
        }
        .preview-image {
          position: absolute;
          top: 1px;
          bottom: 1px;
          left: 1px;
          right: 1px;
          border-radius: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 20px;
            background-color: #ddd;
          }
        }
      }
      .delete-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 164px;
        margin-left: 20px;
        margin-top: 30px;
      }
    }

    .invitation-body {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }
    .invitation-body-subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }
    .invitation-body-subtext {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.42);
    }
    .invitation-title {
      font-weight: 500;
      font-size: 16.4076px;
      line-height: 15px;
      color: #000000;
    }

    .invitation-creator-options {
      height: auto;
      color: #564ece;
      background: #e3e1ff;
      cursor: pointer;
    }

    .inivitaion-input-line {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #000000;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;

        &:focus {
          -webkit-box-shadow: none;
          box-shadow: none;
          border-bottom: 1px solid #564ece;
        }
      }

      .control-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        /* or 109% */

        color: #000000;
      }
    }

    .invitation-creator-options-selected {
      height: auto;
      color: #ffffff;
      cursor: pointer;
      background: linear-gradient(
        90.2deg,
        #564ece 22.33%,
        #3a32ad 100.76%,
        #3d34c9 100.76%
      );
      z-index: 2;
    }

    .creator-agreement {
      max-height: 500px;
      overflow-y: auto;
    }

    .checkbox-and-button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px -10px 33px 0px rgba(0, 0, 0, 0.25);

      .form-check-input {
        border: 1.4px solid #564ece;
        border-radius: 0;
        &:checked {
          background-color: #564ece;
        }
      }

      .form-check-label {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        padding-left: 6px;
      }
      button {
        background: #564ece;
        border-radius: 9px;
        width: max-content;
        height: 54px;
        font-weight: 500;
        font-size: 18px;
        line-height: 44px;
        border: 1px solid #564ece;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        outline: none;
        padding: 2px 32px;
        &:hover {
          background: transparent;
          color: #564ece;
        }
      }
    }

    .invitation-confirmation {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      /* or 109% */

      color: #000000;

      span {
        color: #564ece;
        text-decoration: underline;
      }
    }

    .invitation-input {
      form {
        .form-control {
          padding: 10px;
          height: 40px;

          font-weight: 400;
          font-size: 13.673px;
          line-height: 15px;
          color: #656565;
        }
      }
      .text-area {
        form {
          .form-control {
            padding: 10px;
            height: auto;

            font-weight: 400;
            font-size: 13.673px;
            line-height: 15px;
            color: #656565;
            background: #ededee !important;
            border-radius: 8.8px !important;
          }
        }
      }
      .PhoneInput {
        input {
          background: #ededee;
          color: black;
        }
      }

      .input-text {
        background: #ededee;
        box-shadow: 1.82306px 7.29226px 72.4px -15.4961px rgba(0, 0, 0, 0.17);
        border-radius: 27.8017px;
      }

      .input-title {
        font-size: 16px;
        line-height: 15px;
        color: #000000;
      }
      .form-check {
        .form-check-input[type='radio'] {
          border: 1px solid #564ece;
          border-radius: 50%;
        }

        .form-check-input[type='radio']:checked {
          background: #564ece;
        }
        label.form-check-label,
        input.form-check-input {
          cursor: pointer;
        }
      }
    }

    .bg {
      background: rgba(55, 54, 68, 0.5);
    }

    .invitation-options {
      width: 116px;
      height: 129px;
      cursor: pointer;
      border: 1px solid #564ece;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: transparent;
      position: relative;

      svg {
        width: 100px;
      }
      .selected-div {
        width: 116px;
        height: 129px;
        cursor: pointer;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        z-index: 5;
        &:hover {
          background: rgba(55, 54, 68, 0.5);
        }
      }
    }

    .invitation-button-container {
      display: flex;

      button {
        background: #564ece;
        border: 1px solid #564ece;
        border-radius: 9px;
        width: 271px;
        height: 54px;
        justify-content: center;
        align-items: center;

        font-weight: 500;
        font-size: 18px;
        line-height: 44px;
        border: none;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        &:hover {
          background: transparent;
          color: #564ece;
          border: 1px solid #564ece;
        }
      }

      .agent-button {
        width: 271px;
        height: 36px;

        font-weight: 500;
        font-size: 16px;
        line-height: 44px;
        /* or 275% */

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration-line: underline;

        color: #564ece;
        cursor: pointer;
      }
    }
  }
}

.access-steps-container {
  position: relative;
  font-weight: 500;
  color: white;
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
  background-image: linear-gradient(
    to left,
    rgba(86, 78, 206, 0.11),
    rgba(199, 173, 255, 0.11)
  );
  border: 1px dashed rgba(199, 173, 255, 1);
  @media screen and (max-width: 767px) {
    padding: 12px;
    p {
      font-size: 14px;
    }
  }
  .edge-boxes {
    position: absolute;
    width: 8px;
    height: 8px;
  }
  .one {
    top: -4px;
    left: -4px;
    background-image: linear-gradient(
      to bottom,
      rgba(86, 78, 206, 1),
      rgba(199, 173, 255, 1)
    );
  }
  .two {
    top: -4px;
    right: -4px;
    background-image: linear-gradient(
      to bottom,
      rgba(86, 78, 206, 1),
      rgba(199, 173, 255, 1)
    );
  }
  .three {
    left: -4px;
    bottom: -4px;
    background-image: linear-gradient(
      to left,
      rgba(86, 78, 206, 1),
      rgba(199, 173, 255, 1)
    );
  }
  .four {
    right: -4px;
    bottom: -4px;
    background-image: linear-gradient(
      to left,
      rgba(86, 78, 206, 1),
      rgba(199, 173, 255, 1)
    );
  }
}
