.access-rewards {
  background: $cinder-black-color;
  box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
  .heading-access {
    background: rgba(86, 78, 206, 0.22);
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    border-radius: 25px 25px 0px 0px;
    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .list-texts {
    div {
      width: 50%;
      label {
        font-weight: 500;
        font-size: 18px;
        line-height: 44px;
        color: $starstack-blue-main;
      }
      h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 42px;
        letter-spacing: -0.05em;
      }
    }
  }
}

.box-access-tokens {
  border: 1px solid $starstack-blue-main;
  border-radius: 11.3164px;
  width: 114.52px;
  height: 196px;
  text-align: center;
  padding: 4px;
  .icon-to {
    span {
      position: absolute;
      width: 38px;
      height: 38px;
      background: $starstack-blue-main;
      border-radius: 26.8622px;
      left: 0;
      right: 0;
      margin: auto;
      justify-content: center;
      display: flex;
      align-items: center;
      top: -20px;
    }
  }
  h5 {
    font-weight: normal;
    font-size: 8.69082px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 6px 0 0;
    height: 20px;
  }
  a {
    font-weight: normal;
    font-size: 11px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
  }
}

.finish-setups {
  h4 {
    font-weight: normal;
    font-size: 30px;
    span {
      color: $starstack-blue-main;
    }
  }
  .loader-box {
    p {
      color: $starstack-blue-main;
    }
  }
}

.monitor-manage {
  h2 {
    font-weight: normal;
    font-size: 43px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    color: $gray4-color !important;
    letter-spacing: 0px !important;
  }
  ul {
    li {
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $starstack-blue-main;
      margin-bottom: 22px;
    }
  }
}
.creation-success .other-stars {
  background-image: url('../../../img/PreviewCompleteINFTDetails/bg-bnn.webp');
}
.creation-success .create-cards {
  background-image: url($aws-base-url + '/common/shape-bg.webp') !important;
}
.mb-shapes .shape-bg-section {
  margin-top: -144px;
}
.bg-agreements {
  background: $cinder-black-color;
  border-radius: 24px;
  label.form-check-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    span {
      color: $starstack-blue-main;
    }
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    color: $starstack-blue-main;
  }
  h2 {
    font-weight: normal;
    font-size: 30px;
    line-height: 44px;
  }
  h3 {
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.66);
  }
  .card-clim.card-clims-pd {
    min-width: 252.8px;
    padding: 14px !important;
  }
  &.doc-uploads {
    h2 {
      span {
        color: $starstack-blue-main;
      }
    }
    .download-do {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.05em;
        color: $starstack-blue-main;
        opacity: 0.7;
      }
      button {
        border: 2px solid $starstack-blue-main;
        box-sizing: border-box;
        border-radius: 9px;
        background: transparent;
        color: $starstack-blue-main;
        font-size: 14px;
        padding: 6px 14px;
      }
    }

    .Upload-Agreement-Signed {
      background: $dark-color;
      opacity: 0.61;
      border-radius: 34px;
      padding: 35px;
      position: relative;
      button.btn.btn-primary {
        padding: 12px 21px;
        font-size: 16px;
        color: $white-color !important;
      }
      .agreement-signnow-hand {
        position: absolute;
        bottom: -54px;
        left: -24px;
        width: 14%;
      }
    }
  }
  input.form-check-input {
    border: 2px solid #3b37a5;
    border-radius: 0 !important;
    background-color: transparent;
    width: 18px;
    height: 18px;
  }
}

.edit-action {
  color: $starstack-blue-main;
  //   text-decoration: underline;
}

.overflow {
  overflow-wrap: break-word;
}
