//-----------BuckDetail  css ---------------//
.secondary-col {
  color: $starstack-blue-main;
}

.text-action-img {
  p {
    font-size: 16px !important;
    color: #343342;
  }

  .add-cart-btn {
    width: 122px;
    height: 36px;
    background: $gray9-color;
    border-radius: 35px;
    font-weight: 500;
    font-size: 16px;
    color: $gray10-color;
    border: none;
  }

  .custom-control {
    margin: 0 auto;
  }
}
.active-button-class {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
