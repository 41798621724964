.pagination > li > a {
  background-color: white;
  color: $starstack-blue-main;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: $starstack-blue-main;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .page-item.active > a {
  color: white;
  background-color: $starstack-blue-main;
  border: solid 1px $starstack-blue-main;
}

.pagination > .page-item.active > a:hover {
  background-color: $starstack-blue-main;
  border: solid 1px $starstack-blue-main;
}

.list-video-pl {
  // background-image: linear-gradient(
  //     130deg, transparent 27%, rgb(37 255 176 / 3%) 66%, rgb(255 63 86 / 16%) 167%);
  ul {
    height: 450px;
    overflow-y: auto;
    li {
      list-style: none;
      margin-bottom: 5px;
      background: transparent;
      border-left: 2px solid transparent;
      padding: 14px 18px;
      cursor: pointer;
      &:hover {
        background: linear-gradient(
          269deg,
          rgba(86, 78, 206, 0) 85%,
          #262349 100%
        );
        border-left: 2px solid $starstack-blue-main;
      }
      &.active {
        background: linear-gradient(
          269deg,
          rgba(86, 78, 206, 0) 85%,
          #262349 100%
        );
        border-left: 2px solid $starstack-blue-main;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        color: #efe9ff;
      }
      .time-to {
        font-weight: normal;
        font-size: 14px;
        color: #efe9ff;
      }
    }
  }
}
.list-video-container {
  background-color: rgba(239, 233, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
}
.play-action-v {
  span {
    width: 70px;
    height: 70px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 70px;
      height: 70px;
      background: $starstack-blue-main;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }
    svg {
      position: relative;
      z-index: 1;
    }
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
      opacity: 0;
    }
  }
}
.box-size-card {
  flex: 20%;
  padding: 10px;
  .box-car h6 {
    font-size: 16.6391px;
    height: 47px;
    line-height: 20px;
  }
}
.banner-size-card {
  flex: 40%;
  padding: 10px;
}

.stardex-bg {
  background-image: url($aws-base-url + '/Trainingbanner.png');
  background-size: cover;
  h1 {
    font-weight: bold;
    font-size: 36px;
    letter-spacing: -0.05em;
    color: rgba(255, 255, 255, 0.42);
  }
  p {
    font-weight: normal;
    font-size: 15.6391px;
    color: rgba(255, 255, 255, 0.42);
    width: 315px;
    span {
      color: $starstack-blue-main;
      background: none;
      width: auto;
      height: auto;
    }
  }
}

.table-reso {
  background: #0b0c11;
  border-radius: 12px;
  overflow: hidden;
  .table-head {
    background: rgba(255, 255, 255, 0.02);
  }
  .table-reso-list-wrapper {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  .time-to {
    color: $aluminium-gray-color;
    a {
      color: $starstack-blue-main;
      text-decoration: none;
    }
  }
}

.choosing-inft {
  background: rgba(239, 233, 255, 0.03);
  backdrop-filter: blur(93px);
  border-radius: 12px;
  div {
    font-weight: 600;
    font-size: 14px;
    color: #efe9ff;
    .form-check-input {
      width: 20px;
      height: 20px;
      background-color: rgb(86 78 206 / 0%);
      border: 2px solid $starstack-blue-main;
      border-radius: 0 !important;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .compl-check {
    background: rgba(239, 233, 255, 0.03);
    backdrop-filter: blur(93px);
    border-radius: 12px;
    padding: 6px 13px;
    input {
      margin-top: 0;
      cursor: pointer;
    }
  }
}
.secret-text {
  font-weight: normal;
  font-size: 16px;
  color: rgba(239, 233, 255, 0.8);
}

.video-js {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 20px;
  video {
    width: 100%;
  }
  .vjs-poster {
    background-size: cover;
    background-position: bottom;
  }
  .vjs-big-play-button {
    height: 70px;
    width: 70px;
    top: 0;
    left: 0;
    border-radius: 100%;
    right: 0;
    margin: auto;
    bottom: 0;
    line-height: 67px;
    font-size: 36px;
  }
}

@media screen and (max-width: 1600px) {
  .video-js {
    height: 430px;
  }
}
@media screen and (max-width: 1360px) {
  .video-js {
    height: 360px;
  }
}

@media screen and (max-width: 991px) {
  .video-js {
    height: 282px;
  }
  .box-size-card {
    flex: 50% 1;
  }
}
@media screen and (max-width: 500px) {
  .video-js {
    height: 220px;
  }
  .box-size-card {
    flex: 100% 1;
  }
}
.course-shape {
  overflow: hidden;
  &::after {
    background-image: url($aws-base-url + '/bg-shapes-couress.png');
    content: '';
    position: absolute;
    top: -270px;
    left: -50%;
    right: -50%;
    bottom: 0;
    background-size: 100% 100%;
  }
}
