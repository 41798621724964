/*##########################################################
# Template Name    = Startstack
# File Description = This files Includes all Style CSS

############### IMPORTANT READ CAREFULLY  ########
* Only use or set color variables here (.Style/sass/core/colors)

* Only use or set font variables here (.style/sass/core/typography)

* Only use or set all the resets code here (.Style/sass/core/reset) [example: if you want to reset react-owl-carousel npm package default styles then set that reset.scss file.]

* Only use or set all the global Utility classes here (.Style/sass/utils/utils)[example: if you create a class which will be used by more than 1 component than put that class in utils.scss if not than create it into the .sass/components folder]

* Only use or set all the responsive code here (.Style/sass/responsive/) [you will find different screen size @media queries in this sass folder.]

* If your new component its dependent on other component. make sure put it below of that component.

* Keep all the files in alphabetical order. So that we can find any files easily

############################################################*/
@import 'react-phone-number-input/style.css';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'node_modules/react-image-crop/src/ReactCrop.scss';
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'owl.carousel/dist/assets/owl.theme.default.css';
// core
@import './core/colors';
@import './core/typography';
@import './core/reset';

// abstracts
@import './abstracts/mixins';
@import './abstracts/variables';

//utils
@import './utils/utils';

@import './components/buttons';

//responsive
@import './responsive/400px';
@import './responsive/500px';
@import './responsive/576px';
@import './responsive/768px';
@import './responsive/991px';
@import './responsive/1024px';
@import './responsive/1100px';
@import './responsive/1280px';
@import './responsive/1380px';
@import './responsive/1550px';
@import './responsive/2560px';

// layout
@import './layout/accessRewardTokensSection';
@import './layout/controlCryptoSection';
@import './layout/faqSection';
@import './layout/filterSection';
@import './layout/footer';
@import './layout/hassleSection';
@import './layout/header';
@import './layout/nftbSelector';
@import './layout/pickStakeEarnSection';
@import './layout/processSection';
@import './layout/sideNav';
@import './layout/watchlistSection';

// components
@import './components/AboutUs/banner.scss';
@import './components/AboutUs/beyond.scss';
@import './components/AboutUs/ourValues.scss';
@import './components/accordion.scss';
@import './components/AboutUs/starStakeMission.scss';
@import './components/AboutUs/whoAreWe.scss';
@import './components/accessNFTCard.scss';
@import './components/AccessNFT';
@import './components/accessToken';
@import './components/accessTokens';
@import './components/accessNftDetails';
@import './components/accessTokensDetails';
@import './components/agentsManager';
@import './components/addManager';
@import './components/agentProfileBanner';
@import './components/assetModal';
@import './components/banner';
@import './components/bannerCollections';
@import './components/buckDetails';
@import './components/bucksCompleteTransaction';
@import './components/bucksSlider';
@import './components/bundleBucks';
@import './components/Cards/cards.scss';
@import './components/cartSlide';
@import './components/catMarketplace';
@import './components/charts';
@import './components/claimedAccessTokens';
@import './components/claimeForm';
@import './components/collectionCreation';
@import './components/collectionList';
@import './components/comments';
@import './components/convertBuckSuccess';
@import './components/creationSuccess';
@import './components/creatorHubDashboard';
@import './components/creatorHubInvitation';
@import './components/CreatorHubInvitation/banner';
@import './components/CreatorHubInvitation/section1';
@import './components/CreatorHubInvitation/section2';
@import './components/CreatorHubInvitation/creatorHubSections.scss';
@import './components/AboutUs/contactusSection';
@import './components/details';
@import './components/exploreStarstakeDifferenceVideo';
@import './components/featureCollection';
@import './components/form';
@import './components/foundersCollection';
@import './components/getBucks';
@import './components/gettingStarted';
@import './components/hubModal';
@import './components/hubOverviewCards';
@import './components/imageBanner';
@import './components/individualDetail';
@import './components/individualTokenDetails';
@import './components/infoCard';
@import './components/infoSection';
@import './components/letMeKnowSingle';
@import './components/mintGuidelines';
@import './components/mintAccessTokens';
@import './components/modal';
@import './components/NFTGuideline';
@import './components/navTab';
@import './components/nftBucksGuideline';
@import './components/notifications';
@import './components/orderHistory';
@import './components/overView';
@import './components/AboutUs/ourTeamSection';
@import './components/paymentSteps';
@import './components/progressBar';
@import './components/reactDatePicker';
@import './components/reviewApproval';
@import './components/rewards';
@import './components/setupTab';
@import './components/sharePopup';
@import './components/shippingAddress';
@import './components/similarArtist';
@import './components/singleDetails';
@import './components/staked';
@import './components/stakingTable';
@import './components/stardomAwaitsYou';
@import './components/starterBundle';
@import './components/starterBundleGetbucks';
@import './components/AboutUs/stagesOfStarStakeSection';
@import './components/updates';
@import './components/videoBanners';
@import './components/walletCart';
@import './components/walletSetup';
@import './components/myStars';
@import './components/mySales';
@import './components/myAgents';
@import './components/myManager';
@import './components/editManagers';
@import './components/settingsPanel';
// pages
@import './pages/categoryMarketplace';
@import './pages/comingSoon';
@import './pages/contactUs';
@import './pages/creatorHub';
@import './pages/detailsPage';
@import './pages/errorPages';
@import './pages/getbucksMarketplace';
@import './pages/home';
@import './pages/howStarstakeWorks';
@import './pages/hubWatchlist';
@import './pages/iNFTMain';
@import './pages/iNFTMinting';
@import './pages/menuNav';
@import './pages/mintGuidelines';
@import './pages/myProfile';
@import './pages/proceedToCheckout';
@import './pages/profile';
@import './pages/stakeHub';
@import './pages/starProfile';
@import './pages/tandcAndPrivacyPolicy';
@import './pages/trainingCenter';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
