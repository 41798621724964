.star-profile-section {
  margin-top: 65px;
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  .bottom-band {
    background: #0f1013;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    .bottom-band-wrapper {
      display: flex;
      justify-content: space-around;
      .logo-music-profile {
        display: flex;
        align-items: center;
        gap: 5px;
        font-style: italic;
        font-weight: 300;
        svg {
          margin-top: -4px;
        }
      }
      .icons-share-mint {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .action-svg-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #564ece;
            }
          }
          // &:hover {
          //   color: #564ece;
          //   svg {
          //     opacity: 0.6;
          //   }
          // }
        }
      }
    }
  }
  .star-profile-banner-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    .star-banner-img {
      width: 50%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      .star-banner-img-wrapper {
        position: relative;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          min-height: 100%;
        }
        .img-gradient-wrapper {
          background: linear-gradient(
            90deg,
            rgba(4, 5, 12, 0) 0%,
            #04050a 100%
          );
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  .start-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .start-pro {
      width: 90%;
      .pro-pic {
        overflow: hidden;
        img {
          border-radius: 50%;
          border: 2px solid $starstack-blue-main;
          width: 65px;
          height: 65px;
          object-fit: cover;
        }
      }
      .pro-name {
        font-size: 30px;
        color: $white-color;
        .wallet-addr {
          display: flex;
          font-weight: 500;
          font-size: 16px;
          color: #505050;
          cursor: pointer;
          gap: 10px;
        }
      }
    }

    .pro-bio {
      font-size: 16px;
      line-height: 32px;
      font-weight: normal;
      letter-spacing: 1px;
      color: rgba(239, 233, 255, 0.6);
      min-height: 200px;
    }

    .nft-status {
      color: $white-color;

      .text {
        color: #505050;
        font-size: 14px;
      }

      .count {
        font-size: 32px;
      }

      .nft-minted {
        .text {
          color: rgba(239, 233, 255, 0.6);
          font-size: 14px;
        }

        .count {
          font-size: 32px;
        }
      }

      .staked-fans-count {
        .text {
          color: rgba(239, 233, 255, 0.6);
          font-size: 14px;
        }

        .count {
          font-size: 32px;
        }
      }
    }

    .info {
      color: #505050;
      font-size: 18px;
      position: relative;

      .svg-q {
        top: -8px;
        position: relative;
      }
    }

    .stake-my-iNFTs-link {
      button {
        text-decoration: none;
        color: $starstack-blue-main;
        font-size: 20px;

        &:hover {
          color: $white-color;
        }

        &:focus {
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.about-bg {
  background-image: url($aws-base-url + '/backdrop.png');
  background-size: inherit;
  background-position: right;
  background-repeat: no-repeat;
}

.about-pro {
  margin-top: 150px;
  padding-right: 50px;
  width: 100%;
  height: auto;
  .about-img {
    top: -3rem;
    right: -100px;
    flex: 1;
    position: relative;
    img {
      border-radius: 16px;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .about-text {
    width: 788px;
    height: auto;
    flex: 1;
    padding: 50px 50px 50px 100px;
    padding-left: 150px;
    background: $cinder-black-color;
    border-radius: 24px;

    h3 {
      font-size: 30px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: $gray4-color;
    }

    .social {
      p {
        padding: 1rem;
        display: inline;
      }

      a {
        padding: 1rem;
      }

      .social-icon {
        &:hover {
          path {
            fill: $starstack-blue-main;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    padding-right: 0px;

    .about-img {
      width: auto;
      top: 0rem;
      right: -50px;
      margin: 0px !important;
      position: inherit;
    }

    .about-text {
      width: auto;
      padding: 25px;
      padding-left: 50px;
    }
  }

  @media screen and (max-width: $sm) {
    margin-top: 0px;
    padding-right: 0px;

    .about-img {
      top: 3rem;
      right: 0px;
      margin: 1rem !important;
      width: auto;
    }

    .about-text {
      width: auto;
      margin: 2rem !important;
      margin-top: 0rem !important;
      padding: 1rem;
      padding-left: 1rem;
      z-index: 1;

      .social {
        p {
          padding: 0.5rem;
          display: inline;
        }

        a {
          padding: 0.5rem;
        }
      }
    }
  }
}

.my-nft-section {
  .my-nft-buks-cards {
    .my-nft-buks-card {
      max-width: 200px;
      font-size: 16px;

      .img {
        position: relative;
        cursor: pointer;

        .hover {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            162.24deg,
            rgba(157, 153, 208, 0.82) 3.31%,
            rgba(64, 51, 255, 0.82) 97.08%
          );
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }

        img {
          max-width: 100%;
        }
      }

      .rare {
        color: $starstack-blue-main;
      }

      .text {
        color: $white-color;
      }

      .amount-cta {
        border-top: 1.2px solid rgba(76, 74, 106, 0.58);

        .amount {
          color: $starstack-blue-main;
        }

        .cta {
          cursor: pointer;
          padding: 0.4rem 1rem;
          background: $gray9-color;
          border-radius: 18px;
          color: $gray10-color;

          &:hover {
            color: $white-color;
            background: $starstack-blue-main;
          }
        }
      }
    }
  }

  @media screen and (max-width: $md) {
    .my-nft-buks-cards {
      .my-nft-buks-card {
        margin: 4px;

        .amount-cta {
          .amount {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    .my-nft-buks-cards {
      .my-nft-buks-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 100vw;

        .my-nft-buks-details {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-around;
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.why-shoud-purchase {
  .card {
    background-color: transparent;

    span {
      font-weight: normal;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: $starstack-blue-main;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: $white-color;
    }

    .cta {
      .btn {
        margin-right: 2rem !important;
      }
    }
  }
}

.png-devider {
  width: 100%;
  position: relative;
  bottom: -1px;
  overflow: hidden;

  img {
    width: 125%;
  }

  .devider-logo {
    position: absolute;
    background: linear-gradient(
      136.24deg,
      $starstack-blue-main 1.34%,
      #ada7ff 111.12%,
      #756cff 111.13%
    ) !important;
    border-radius: 8px;
    top: 50%;
    left: 75%;
  }

  @media screen and (max-width: $sm) {
    img {
      width: 125%;
    }

    .devider-logo {
      margin-top: -10px;
    }
  }
}

.bg-devider {
  position: relative;
  top: 102px;

  &::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 2px;
    width: 200%;
    background: #564ecd;
    top: 134px;
    left: 50%;
    margin-top: -1px;
    z-index: 99;
  }
}

.stardom-section {
  overflow: hidden;
  background: rgb(11, 13, 25);
  background: -moz-linear-gradient(
    90deg,
    rgba(11, 13, 25, 1) 0%,
    rgba(28, 25, 69, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(11, 13, 25, 1) 0%,
    rgba(28, 25, 69, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(11, 13, 25, 1) 0%,
    rgba(28, 25, 69, 1) 100%
  );

  > div {
    overflow: hidden;
  }

  .startdom-text {
    padding: 3rem;
    padding-left: 0rem;
    z-index: 11;
    position: relative;

    .cta {
      .link-white {
        padding-left: 1rem;
        color: $white-color !important;
      }
    }
  }

  h4 {
    font-size: 30px;
    font-weight: normal;
    color: $white-color;
    margin-bottom: 1rem;

    span {
      color: $starstack-blue-main;
    }
  }

  p {
    font-size: 16px;
    color: $gray4-color;

    b {
      color: $white-color;
      font-weight: normal;
    }
  }

  .stars-img {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
    }
  }

  @media screen and (max-width: 2560px) {
    .stars-img {
      width: 50%;
      height: 100%;

      img {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: $md) {
    .startdom-text {
      padding-left: 2rem;
    }
  }

  @media screen and (max-width: $sm) {
    .stars-img {
      width: 100%;
      height: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .beyond-blockchain {
    background: #080812;
    backdrop-filter: blur(54px);

    .stars-img {
      position: relative;
      width: 50%;

      img {
        width: 100%;
      }

      @media screen and (max-width: $md) {
        width: 100%;
      }
    }

    .main-content-wrapper {
      width: 50%;
      padding: 3rem;
      padding-left: 6rem;

      @media screen and (max-width: $md) {
        width: 100%;
        padding: 5rem 0 3rem;
      }
    }

    .main-content-wrapper {
      .startdom-text {
        h4 {
          font-size: 43px;
          font-weight: 400;
          line-height: 52px;
        }

        p {
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0.015em;
          color: $white-color;
          font-weight: 300;
        }
      }
    }
  }
}

.faq-staking-payments {
  .faq {
    h1 {
      margin-bottom: 3rem;
      font-weight: 600;
      font-size: 43px;
      color: $black-color;
    }
  }

  .stak-payment {
    h1 {
      margin-bottom: 3rem;
      font-weight: 600;
      font-size: 43px;
      color: $black-color;
    }
  }

  .accordion {
    .accordion-item {
      margin-bottom: 2rem;

      .accordion-header {
        button {
          font-size: 20px;
          color: $carbon-gray-color;
        }

        .accordion-button:not(.collapsed) {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: $black-color;
          background-color: $white-color;
          box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
        }
      }

      .accordion-collapse {
        .accordion-body {
          font-weight: 400;
          font-size: 16px;
          line-height: 33px;
          color: $medium-gray-color;

          a {
            color: $starstack-blue-main;
          }
        }
      }
    }
  }
}

.star-footer {
  .subscribe {
    form {
      input {
        background: $gray9-color;
        border-radius: 28.5px;
        border: 0px solid transparent;
        padding: 1rem 2rem;
        max-width: 350px;
      }
    }
  }

  .social-links {
    .social {
      a {
        padding: 8px;
        background: #564ecd;
        border-radius: 4px;
        margin: 4px;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .f-menus {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    color: $white-color;

    li {
      list-style: none;

      a {
        color: rgba(255, 255, 255, 0.63);
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        display: block;
        padding: 0.75rem 0.5rem;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .tnc {
    border-top: 1.2px solid #939393;
    font-size: 18px;

    a {
      padding-right: 1rem;
      text-decoration: none;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.63) !important;

      &:hover {
        color: $white-color !important;
      }
    }
  }
}

.star-profile {
  .full-wrapper {
    background: #000;
  }
  .modal {
    .modal-dialog {
      .modal-content {
        background: #16122a !important;
      }
    }
  }
}

.shareModal {
  .modal-dialog {
    .modal-content {
      background: #16122a !important;
    }
  }

  .closebtn {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  .share-modal-text {
    font-weight: normal;
    font-size: 26px;
    color: $white-color;
  }

  .social-links {
    .social {
      a {
        padding: 8px;
        background: #564ecd;
        border-radius: 4px;
        margin: 4px;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: rgba(255, 255, 255, 0.06);
    opacity: 1;
  }

  input {
    background: rgba(255, 255, 255, 0.06);
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 300;
    line-height: 39px;
    color: $white-color;
  }

  .input-group-text {
    border-radius: 0px;
    font-weight: 300;
    border: 0px;
    background: $starstack-blue-main;
    font-size: 20px;
    text-align: center;
    color: $white-color;
  }

  .input-group-text {
    cursor: pointer;
  }

  .form-text {
    a {
      color: $starstack-blue-main !important;
      text-decoration: underline !important;
    }
  }
}
