.footer {
  overflow: hidden;
  background-image: url($aws-base-url + '/Footer1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  position: relative;

  .Browse-box {
    background: linear-gradient(
      91.45deg,
      $starstack-blue-main 25.74%,
      $light-slate-blue-color 69.14%
    );
    border-radius: 16px;
    padding: 35px 60px;

    h2 {
      font-weight: normal;
      font-size: clamp(1.75rem, calc(0.62rem + 3.13vw), 2.56rem);
      line-height: 52px;
      color: $white-color;
    }

    .btn {
      padding: 18px 58px;
    }
  }

  h6 {
    font-weight: 500;
    font-size: 18px;
    color: $hit-gray-color;
    @media screen and (max-width: 991px) {
      font-size: 14px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .left-foot {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      color: $white-color;
    }

    .form-control {
      width: 379px;
      height: 57px;
      background: $gray9-color;
      border-radius: 28.5px;
      border: none;
      padding: 0px 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: $natural-gray-color;

      &:focus {
        box-shadow: 0px 0px 0px 1px $marguerite-blue-color;
      }
    }
  }

  .right-foot {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 33px;
      color: $white-color;
    }

    ul {
      li {
        list-style: none;
        margin-bottom: 6px;

        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 33px;
          color: rgba(255, 255, 255, 0.63);
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            color: $marguerite-blue-color;
            transition: 0.3s;
          }
        }
      }
    }
  }

  .follow-link {
    margin-top: 60px;
    ul {
      li {
        list-style: none;
        padding-right: 10px;

        a {
          width: 42px;
          height: 43px;
          background: $starstack-blue-main;
          border-radius: 8px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;

      li {
        list-style: none;
        .first-a {
          margin-right: 60px;
          @media screen and (max-width: 991px) {
            margin-right: 0px;
            margin-left: -34px;
          }
        }
        a {
          font-weight: 500;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.63);
          text-decoration: none;
          &:hover {
            color: $marguerite-blue-color;
            transition: 0.3s;
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      margin-top: 20px;
      justify-content: flex-start;
      flex-direction: column-reverse;
      ul {
        width: 100%;
        justify-content: space-between;

        li {
          list-style: none;
          a {
            font-weight: 600;
            font-size: 15px;
          }
        }
      }
    }
  }
}
