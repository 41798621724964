//-----------NFTbucks  css ---------------//

.bucks-bg {
  background-image: url($aws-base-url + '/getbucks/bucks-marketplace.webp');
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.Essentials-banner {
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  position: relative;

  &.banner-image {
    background-image: url($aws-base-url + '/getbucks/get-bucks-bg-new.webp');
    background-size: cover;
    height: 735px;
  }

  .bg-filter {
    background: linear-gradient(
      90deg,
      $dark-color 28.73%,
      rgba(4, 5, 10, 0) 100.56%
    );
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .banner-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
  }

  position: relative;

  .banner-text {
    // position: absolute;
    // width: 100vw;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    z-index: 11;
    // padding-left: 5rem;
    padding-left: 3rem;

    //  padding-right: 10rem;
    //  padding-top: 14rem !important;
    p {
      font-weight: normal;
      //  font-size: 20px;
      font-size: 24px;
      line-height: 32px;
      color: $white-color;
    }

    .banner-text-desc {
      font-size: 20px;
    }

    h1 {
      font-weight: bold;
      font-size: 65px;
      line-height: 72px;
      letter-spacing: -1.5px;
      color: $white-color;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
    }

    .p-text {
      font-size: 20px;
    }
  }

  .bg {
    top: 0px;
    right: 0px;
    filter: opacity(0.5);
  }

  h3 {
    font-weight: 600 !important;
    font-size: 54px !important;
    color: $white-color;
  }

  p.text {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }

  .btn {
    color: $marguerite-blue-color;
    font-weight: normal;
    font-size: 16px;
  }

  .text-content {
    width: 60% !important;
  }
}

.get-bucks {
  .BrowseNfts {
    background: $dark-color;
    padding-bottom: 1.5rem !important;

    .heading {
      color: $white-color;
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 30px;
    }

    .search-result {
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 38px;
      color: $white-color;
      margin-top: 2rem;
    }

    form {
      margin-bottom: 1rem;

      .input-group,
      .dropdown-toggle {
        background-color: rgba(196, 196, 196, 0.37);
        background-color: $gray9-color;
        border-radius: 26px;
        border: solid 1px transparent;
        height: 52px;
      }
    }
  }

  .brows-nft-sec {
    .Essentials-list {
      .parent-row {
        margin-left: -15px;
        margin-right: -15px;
        // .child-row {

        // }
      }

      h3 {
        font-weight: 500;
        font-size: 21px !important;
        color: $white-color;
      }

      .collection-list-box {
        width: 216px;
        margin-left: 15px;
        margin-right: 15px;

        .img-collection {
          border-radius: 0px;

          .img-hover-click {
            border-radius: 0px;
          }
        }

        .card-name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          color: $white-color;
          border-bottom: 1px solid rgba(76, 74, 106, 0.58);
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }

        .add-cart-btn {
          background-color: $starstack-blue-main !important;
          color: $white-color !important;
        }
      }
    }

    .view-Collection-btn {
      background-color: $starstack-blue-main !important;
      color: $white-color !important;
      &:hover {
        background: $gray9-color !important;
        color: $gray10-color !important;
        border: 1.5px solid #5a52ff !important;
      }
    }
  }

  .Essentials-banner .main-content-wrapper {
    max-width: 1000px;
    margin-left: 1px;
    padding-left: 50px;
    /* margin: 0px auto; */
    // padding-left: 100px;
  }

  // .main-content-wrapper .position-relative {
  //   position: inherit !important;
  // }

  //    .Essentials-list.main-content-wrapper22
  //    {
  .pagination {
    margin-left: 40% !important;
    font-size: 20px !important;
    float: right !important;
    padding-right: 6% !important;
  }

  .page-item.active .page-link {
    background-color: $starstack-blue-light !important;
    border-color: $starstack-blue-light !important;
    font-size: 20px !important;
  }

  // }
  .brows-nft-sec {
    min-height: 500px;
    .pagination {
      margin-left: 40% !important;
      font-size: 20px !important;
      float: right !important;
      padding-right: 6% !important;
    }

    .page-item.active .page-link {
      background-color: $starstack-blue-light !important;
      border-color: $starstack-blue-light !important;
      font-size: 20px !important;
    }
  }

  .slick-list {
    margin-left: 10%;
  }

  .slick-slider .slick-prev {
    left: -20px;
  }

  .slick-slider .slick-prev {
    // display: none !important;
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    font-size: 0;
    padding: 0;

    background-repeat: no-repeat;
    background-position: center;

    &::after {
      //   content: "";
      position: absolute;
      top: 0;
      background-color: $white-color;
      width: 60px;
      height: 60px;
      color: $starstack-blue-main;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      background-image: url('../../../img/svg/Arrows/FrontArrowBlue.svg');
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover::after {
      background-color: rgb(72 79 195 / 28%);
      color: $white-color;
    }
  }

  .slick-slider .slick-disabled {
    cursor: none;
    color: gray;
    background-color: gray;
  }

  .parent {
    display: flex;
    flex-wrap: wrap;
    min-height: 1100px;
    //  overflow-y:auto;
  }

  .bundles-list .mb4 {
    flex: 1 0 21% !important;
  }

  .child {
    // flex: 2 0 21%; /* explanation below */
    flex: 0 0 15%;
    // flex: 0 1 calc(20% - 8px);
  }

  @media screen and (max-width: 960px) {
    .child {
      flex: 0 0 35%;
    }
  }

  @media screen and (max-width: 500px) {
    .child {
      flex: 1 0 50%;
      min-width: 214px;
    }
  }
}

.Essentials-list {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    color: $white-color;

    svg,
    path {
      fill: $starstack-blue-main;
      stroke: $starstack-blue-main;
    }
  }

  .collection-list-box {
    width: 216px;
    margin-left: 15px;
    margin-right: 15px;

    .img-collection {
      img {
        width: 100%;
        height: 430px;
      }

      overflow: hidden;

      // border-radius: 10px;
      svg {
        transform: scale(1);
        transition: 0.5s;
      }

      &:hover {
        svg {
          transform: scale(1.1);
          transition: 0.5s;
        }
      }

      .img-hover-click {
        background: linear-gradient(
          162.24deg,
          rgba(157, 153, 208, 0.82) 3.31%,
          rgba(64, 51, 255, 0.82) 97.08%
        );
        opacity: 0;
        transition: 0.5s;

        // border-radius: 10px;
        a {
          text-decoration: none;

          p {
            font-weight: 500;
            font-size: 14px;
            color: $white-color;
          }
        }

        svg {
          width: 53px;
        }
      }

      &:hover {
        .img-hover-click {
          opacity: 1;
          transition: 0.5s;

          svg {
            transform: scale(1);
          }
          &-rejected {
            color: #ffffff;
            background: linear-gradient(
              175.86deg,
              rgba(255, 112, 25, 0.82) 3.12%,
              rgba(255, 0, 14, 0.82) 96.41%
            );

            .reject-text {
              font-weight: 600;
              font-size: 18px;
            }

            .rejection-details {
              font-weight: 400;
              padding-top: 0.5em;

              u {
                cursor: pointer;
                padding: 0 1em;
                display: block;
              }
            }
          }
        }
      }
    }

    .collection-text {
      border-top: 1.51124px solid rgba(76, 74, 106, 0.58);
    }
  }

  // margin-top: -150px;
  .price.d-flex.align-items-center.justify-content-center {
    font-weight: 500;
    font-size: 20px;
    color: $starstack-blue-main;
  }

  .add-cart-btn {
    width: 85px;
    height: 25px;
    background: $gray9-color;
    border-radius: 18px;
    font-weight: 500;
    font-size: 11px;
    color: $gray10-color;
    border: none;
  }

  .qty .feature-cart-btn {
    background: $starstack-blue-main;
    color: $white-color;
    font-size: 11px;
    font-weight: 500;
    border-radius: 18px;
    padding: 1em;
    width: 85px;
    height: 25px;

    &:hover {
      background: $gray9-color;
      color: $gray10-color;
      cursor: pointer;
    }
  }
}

.collections-list {
  h3 {
    font-weight: 600 !important;
    font-size: 30px;
    color: $white-color;
  }

  .view-collection-btn {
    background: $starstack-blue-light !important;
    border-radius: 21px;
    font-weight: 500;
    font-size: 16px;
    color: $white-color !important;
    padding: 0 !important;
    text-decoration: none;
    cursor: pointer;
    height: 42px;
    width: 161px;
    border: none;

    &:hover {
      background: $gray9-color !important;
      color: $gray10-color !important;
      border: 1.5px solid #5a52ff !important;
    }
  }
}

.group-list {
  a {
    color: #ababab !important;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #8781e0 !important;
    }
  }

  .active {
    background: rgba(196, 196, 196, 0.1) !important;
    border-radius: 26px !important;
    color: #8781e0 !important;
  }
}

.bundles-list {
  h3 {
    font-weight: 600 !important;
    font-size: 30px;
    color: $white-color;
  }

  .heading {
    font-weight: 400;
    font-size: 21px;
    line-height: 38px;
    color: $white-color;
    width: fit-content;

    // display: contents;
    p {
      display: inline;
      color: $starstack-blue-main;
    }
  }

  .collection-list-box {
    width: 214px;
    margin-left: 15px;
    margin-right: 15px;

    .child-wrap-row {
      margin-left: 0px;
      margin-right: 0px;
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $white-color;
    }

    .img-collection {
      .main-img {
        width: 214px;
        height: 364px;
        border-radius: 7px;
      }
    }

    .collection-text {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      overflow: hidden;

      h6 {
        height: 40px;
        margin: 0 !important;
      }
    }

    .price {
      background: $gray9-color;
      border-radius: 21px;
      font-weight: 500;
      font-size: 15px;
      color: $moody-blue-color;
      border: none;
      padding: 5px 15px;
    }
  }

  .slick-slider {
    .slick-track {
      float: left;
    }
  }

  .view-bundle-btn {
    background: $starstack-blue-light !important;
    border-radius: 21px;
    font-weight: 500;
    font-size: 16px;
    color: $white-color !important;
    padding: 0 !important;
    text-decoration: none;
    cursor: pointer;
    height: 42px;
    width: 161px;
    border: none;

    &:hover {
      background: $gray9-color !important;
      color: $gray10-color !important;
      border: 1.5px solid #5a52ff !important;
    }
  }

  .view-buck-btn {
    background: #564ece !important;
    border-radius: 21px;
    font-weight: 500;
    font-size: 13px;
    color: $white-color !important;
    padding: 0 !important;
    text-decoration: none;
    cursor: pointer;
    height: 27px;
    width: 91px;
    border: none;

    &:hover {
      background: $gray9-color !important;
      color: $gray10-color !important;
      border: 1.5px solid #5a52ff !important;
    }
  }
}

.bundle-list-box {
  width: 258px;

  .img-collection {
    width: 214px;
    height: 424px;

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .img-hover-click {
        opacity: 1;
        transition: 0.5s;

        svg {
          transform: scale(1);
        }
      }
    }
  }

  .img-hover-click {
    background: linear-gradient(
      162.24deg,
      rgba(157, 153, 208, 0.82) 3.31%,
      rgba(64, 51, 255, 0.82) 97.08%
    );
    opacity: 0;
    height: 424px;
    transition: 0.5s;

    // border-radius: 10px;
    div {
      text-decoration: none;

      p {
        font-weight: 500;
        font-size: 14px;
        color: $white-color;
      }

      h5 {
        color: $gray10-color;
        font-weight: 500;
      }

      h6 {
        color: $gray10-color;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    svg {
      width: 53px;
    }

    &-rejected {
      background: linear-gradient(
        175.86deg,
        rgba(255, 112, 25, 0.82) 3.12%,
        rgba(255, 0, 14, 0.82) 96.41%
      );

      .reject-text {
        font-weight: 600;
        font-size: 18px;
      }

      .rejection-details {
        font-weight: 400;
        padding-top: 0.5em;

        u {
          cursor: pointer;
          padding: 0 1em;
          display: block;
        }
      }
    }
  }

  h6 {
    color: $white-color;
  }
}

.custom-arrow-slider {
  position: relative;

  .arrow-slider-main {
    width: calc(100% - 100px);
    margin: auto;
    position: relative;
  }

  .owl-carousel {
    .owl-nav {
      position: absolute;
      top: 36%;
      left: 0;
      width: 100%;

      button {
        &:hover {
          background: transparent;
          transition: all ease 0.4s;

          span {
            margin-left: 2px;
            transition: all ease 0.4s;
          }
        }

        &.disabled {
          &.owl-prev,
          &.owl-next {
            display: none !important;
          }
        }

        &.owl-next {
          position: absolute;
          right: -60px;
          @media screen and (max-width: 767px) {
            right: -40px;
          }

          span {
            background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
          }
        }

        &.owl-prev {
          position: absolute;
          left: -60px;
          display: unset !important;
          @media screen and (max-width: 767px) {
            left: -40px;
          }

          span {
            background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
