.nft-bucks-guideline-section {
  background-image: url($aws-base-url + '/MintAccessTokens/bg_1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  padding-bottom: 40px;
  .container {
    background: linear-gradient(
      71.45deg,
      rgba(13, 11, 33, 0.54) 14.26%,
      rgba(86, 78, 206, 0.1242) 113.99%
    );
    border: 1px solid #eee;
    border-radius: 35px;
    box-sizing: border-box;
    backdrop-filter: blur(5.21937px);
    border-radius: 21.7765px;
    padding: 40px 15px;
    color: white;
    height: 1316px;
    width: 75%;
  }

  h2 {
    font-size: 1.8rem !important;
    font-weight: 600;
  }

  .buck-img {
    border-radius: 3px;
    padding-top: 80px;
    img {
      height: 530px !important;
    }
  }

  //Requirement sections css

  .specification-details {
    padding: 10px;
    .details-ul {
      list-style: none;
      font-size: 14px;
      text-align: left;
      font-weight: 400;
    }

    .details {
      font-weight: 300;
    }
    .required-seals {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: $white-color;
      .badges {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
      }
      .summary {
        padding-top: 40px;
        width: 60%;
        p {
          font-size: 16px;
          font-weight: 400;
        }
        .summaryDescription {
          font-size: 13px;
          font-weight: 200;
        }
      }
    }

    .nft-buck-rarities {
      //   margin-top: 50px;
      // margin-bottom: 100px;
      margin-top: 20px;
      margin-bottom: 47px;
      .rarities-container {
        display: flex;
        .comp1 {
          flex: 111;
          flex-direction: row;
        }
      }
      .collectable {
        padding-top: 10px;
      }

      .rarities-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 37px;
        color: $starstack-blue-main;
      }
      .icon {
        // margin-right: 5px;
        margin-right: 10px;
      }

      .ml {
        margin-left: -205px;
      }
    }

    .restrictions {
      font-size: 14px;
      ul {
        li {
          padding: 10px;
        }
      }
      svg {
        margin-right: 10px;
      }
    }
  }

  .nft-buck-table {
    padding-top: 80px;
    .table-heading {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .cta {
        button {
          border: 0px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          background: transparent;
          border-radius: 26px;
          padding: 0.5rem 1.5rem;
          margin: 1rem;
          box-shadow: 0px 3px 23px rgba(86, 78, 206, 0.11);
          background: rgba(239, 233, 255, 0.03);
          border-radius: 52px;
          &:nth-child(1) {
            margin-left: 0px;
          }
          &.active {
            background: linear-gradient(
              89.27deg,
              #fc1885 0.23%,
              $starstack-blue-main 0.24%,
              #561eef 99.74%
            );
          }
        }
      }
      .iNFT-purchase-value {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $white-color;
        span {
          color: $starstack-blue-main;
        }
      }
    }
    .MuiPaper-root {
      background: transparent;
    }
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          background-color: rgba(255, 255, 255, 0.02);
          .MuiTableCell-root {
            border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          &:nth-child(even) {
            background-color: rgba(104, 112, 255, 0.02);
          }
          .MuiTableCell-root {
            border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
          }
        }
      }
    }
    .col-name {
      min-width: 300px;
    }
    svg {
      padding-left: 5px;
      width: 40px;
    }
    .box {
      overflow: hidden;
      background: #0b0c11;
      box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
      //   border-radius: 12px;
      border-radius: 0px;
    }

    @media screen and (max-width: 600px) {
      .table-heading {
        flex-direction: column;
        .cta {
          button {
            margin: 0px;
            margin-bottom: 10px;
            padding: 0.5rem;
            font-size: 14px;
          }
        }
      }
    }
  }
}
