$rhs-width: calc(100% - 269px);

.BB-mainwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #080812;

  .BB-imgwrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img-wrapper {
      width: 100%;
      .img {
        width: 100%;
        height: 800px;
        object-fit: cover;
      }
    }
  }
  .BB-textwrapper {
    width: 50%;
    padding: 20px 50px;
    margin-top: -5%;

    .h1 {
      font-size: 43px;
      line-height: 52px;
      font-weight: 600;
      color: #ffffff;
      font-style: normal;
      width: 65%;
    }
    .h2 {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      color: rgba(248, 248, 248, 0.72);
      font-style: normal;
      width: 70%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .icons-social-media {
      a {
        svg {
          margin-right: 20px;
          width: 20px;
          height: 20px;
        }
        &:hover {
          svg {
            path {
              fill: #564ece;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .BB-mainwrapper {
    flex-direction: column;
    .BB-imgwrapper {
      width: 100%;
      .img-wrapper {
        width: 100%;
        .img {
          width: 100%;
          height: 500px;
          object-fit: cover;
        }
      }
    }
    .BB-textwrapper {
      width: 100%;
      padding: 50px 10px;
      margin-top: -3%;
      .h1 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        margin-top: 20px;
        width: 60%;
      }
      .h2 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        width: 70%;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .BB-mainwrapper {
    flex-direction: column;
    .BB-imgwrapper {
      width: 100%;
      .img-wrapper {
        width: 100%;
        .img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }
    }
    .BB-textwrapper {
      width: 100%;
      padding: 50px 10px;
      margin-top: -3%;
      .h1 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        margin-top: 20px;
        width: 90%;
      }
      .h2 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        width: 95%;
        margin-top: 10px;
      }
    }
  }
}
