//-----------Cart  css ---------------//
.cart-nav-item {
  background: none !important;
  border: none !important;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  .total-cart {
    position: absolute;
    top: -3px;
    right: -5px;
    width: 18px;
    height: 18px;
    background: $white-color;
    right: -7px;
    border-radius: 100%;
    font-weight: bold;
    font-size: 14px;
    color: $starstack-blue-main;
  }

  &:hover {
    svg {
      path {
        fill: $starstack-blue-main;
      }
    }

    .hover-open-dropdown {
      opacity: 1;
      width: 349px;
      transition: 0.5s;
      height: 48px;

      a {
        font-size: 16px;
        transition: 0.5s;

        svg {
          path {
            fill: $white-color;
          }
        }
      }
    }
  }

  .hover-open-dropdown {
    right: -14px;
    background: $starstack-blue-main;
    box-shadow: 0px 4px 45px -1px rgb(86 78 206 / 36%);
    border-radius: 9px;
    width: 0;
    height: 0;
    top: 50px;
    opacity: 0;
    transition: 0.5s;

    a {
      color: $white-color;
      text-decoration: none;
      font-size: 0;
      transition: 0.5s;
    }

    &::after {
      content: '';
      border-left: 12px solid rgba(255, 255, 255, 0);
      border-top: 20px solid rgba(255, 255, 255, 0);
      border-bottom: 20px solid $marguerite-blue-color;
      border-right: 12px solid rgba(255, 255, 255, 0);
      position: absolute;
      right: 13px;
      top: -39px;
    }
  }
}

.cart-slide-right {
  right: 0px;
  transition: 0.8s;
  width: 100%;
  background: rgba(0, 0, 0, 0.71);
  position: fixed;
  top: 60px;
  bottom: 0;
  z-index: 999999999999;

  .bg-cart-close {
    background: none !important;
    border: none !important;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .cart-bg {
    width: 542px;
    height: 100vh;
    background: $cinder-black-color;
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    margin-right: 0;
    margin-left: auto;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 100%;
    }
    .cart-heading {
      padding-bottom: 1em;
      border-bottom: 2.2px solid #1c1a38;
      h3 {
        font-weight: normal;
        font-size: 21px;
      }
    }

    .cart-balance {
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: flex-end;
      }
      span {
        color: $starstack-blue-main;
        font-size: 16px;
        margin-right: 0.5em;
      }
    }

    .balance-eth {
      font-weight: 500;
      font-size: 18px;

      span {
        font-weight: 500;
        font-size: 16px;
        color: $starstack-blue-main;
      }
    }

    .text-end.need-text {
      font-weight: 500;
      font-size: 18px;

      span {
        color: $starstack-blue-main;
      }
    }

    .main-heading {
      h1 {
        font-weight: 500;
        font-size: 25px;
      }
    }

    .liat-cart-slide {
      height: calc(100vh - 480px);
      overflow-x: hidden;
      overflow-y: auto;

      .text-cart-list {
        h6 {
          font-weight: normal;
          font-size: 19px;
          line-height: 32px;
          @media screen and (max-width: 600px) {
            font-size: 16px;
            line-height: 16px;
          }
        }

        .cart-price {
          font-weight: normal;
          font-size: 21px;
          color: $starstack-blue-main;
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
        }

        .quantity {
          font-weight: normal;
          font-size: 19px;
          display: flex;
          @media screen and (max-width: 600px) {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 12px;
          }
          .delete {
            cursor: pointer;
            svg {
              width: 22px;
              height: 25px;
            }
          }
          .form-select {
            width: 81px;
            height: 34px;
            background-color: rgba(229, 229, 229, 0.13);
            border-radius: 22px;
            color: $white-color;
            border: none;
            background-position: right 1rem center;
            background-size: 14px 17px;
            padding: 0px 25px;

            &:focus {
              box-shadow: 0px 0px 0px 1px $moody-blue-color;
            }

            option {
              background: #18181d;
              color: $white-color;
              font-size: 14px;
            }
          }
        }

        .eth-text {
          font-weight: normal;
          font-size: 21px;
          color: rgba(255, 255, 255, 0.23);
        }
      }

      .img-carrt {
        .buck-main-card-wrapper,
        .Bundles-card-wrapper {
          width: 164px;

          .onhover-bundle-card-wrapper {
            display: none;
          }
          .buck-image-wrapper {
            cursor: default;
            &:hover {
              .buck-main-image {
                transform: none;
              }
            }
          }
          .top-section {
            &:hover {
              .Bundles-main-img {
                transform: none;
              }
            }
          }
        }
      }
    }

    .summary {
      background: rgba(86, 78, 206, 0.06);

      h5 {
        font-weight: 500;
        font-size: 16px;
        color: #646464;
      }

      .sumary-text {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .checkout-action {
      .btn {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  &.cart-none {
    right: -400px;
    transition: 0.8s;
    width: 0;
  }

  .need-crypto-text {
    font-size: 22px;
    font-weight: 400px;
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
  }

  .quantity-input {
    width: 150px;
    margin-right: 10px;
    @media screen and (max-width: 600px) {
      margin-right: 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: button;
      margin: unset;
    }
  }

  .quantity-input[type='number'] {
    -moz-appearance: unset;
    appearance: unset;
  }

  .update-btn {
    padding: 6px 12px;
    font-size: 16px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}
