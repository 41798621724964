$rhs-width: calc(100% - 269px);

.our-team-section {
  padding: 3rem 5rem;
  background-repeat: no-repeat;
  background-color: #0b0d19;

  .our-team-title {
    margin-bottom: 4rem;

    h1 {
      font-size: 43px;
      font-weight: 600;
    }

    p {
      max-width: 602px;
      font-size: 16px;
      line-height: 26.5px;
    }
  }
}

.team-details-section {
  display: flex;
  flex-direction: column;
  .team-wrapper {
    padding: 2rem 5rem;
    background-color: #0b0d19;
    display: flex;
    justify-content: space-between;

    &:hover {
      background: rgba(255, 255, 255, 0.03);
    }
    .team-img {
      width: 30%;
    }

    img {
      mix-blend-mode: luminosity;
    }

    .team-details {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px 30px 30px 10px;
      p {
        margin-top: 3em;
        font-size: 16px;
        line-height: 26.5px;
      }
    }
  }
}

@media screen and (min-width: 701px) and (max-width: 1300px) {
  .our-team-section {
    padding: 1rem;
  }

  .team-details-section {
    display: flex;
    flex-direction: column;
    .team-wrapper {
      padding: 1rem;
      .team-img {
        width: 100%;
      }
      .team-details {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .our-team-section {
    padding: 1rem;
  }

  .team-details-section {
    display: flex;
    flex-direction: column;
    .team-wrapper {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      .team-img {
        width: 100%;
      }
      .team-details {
        width: 100%;
        margin-top: 5%;
        p {
          margin-top: 1.5em;
        }
      }
    }
  }
}
