.SSM-MainWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  background: url($aws-base-url + '/aboutus/Comet.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  justify-content: center;
  align-items: center;

  .SSM-MainDiv {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    padding: 50px;

    .SSM-Text {
      width: 30%;

      .h1 {
        font-size: 43px;
        line-height: 52px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
      }

      .h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
      }
    }
  }
}

.SSBM-MainWrapper {
  display: flex;
  justify-content: center;
  background-color: #000000;
  position: relative;
  height: 600px;
  justify-content: center;
  align-items: center;

  .SSBM-MainDiv {
    width: 90%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .SSBM-Text {
      width: 50%;

      .h1 {
        font-size: 43px;
        line-height: 52px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
      }

      .h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
        width: 60%;
      }
    }

    .SSBM-img {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .SSBM-img2 {
      display: none;
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .SSM-MainWrapper {
    height: 400px;

    .SSM-MainDiv {
      width: 90%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      .SSM-Text {
        width: 100%;

        .h1 {
          font-size: 35px;
          line-height: 40px;
        }

        .h2 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .SSBM-MainWrapper {
    flex-wrap: wrap-reverse;

    .SSBM-MainDiv {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 10px;

      .SSBM-Text {
        width: 100%;

        .h1 {
          font-size: 35px;
          line-height: 40px;
          font-weight: 400;
        }

        .h2 {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          width: 100%;
        }
      }

      .SSBM-img {
        display: none;
      }

      .SSBM-img2 {
        display: flex;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .SSM-MainWrapper {
    height: 400px;

    .SSM-MainDiv {
      width: 90%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      .SSM-Text {
        width: 100%;

        .h1 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 600;
        }

        .h2 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .SSBM-MainWrapper {
    flex-wrap: wrap-reverse;

    .SSBM-MainDiv {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 10px;

      .SSBM-Text {
        width: 100%;

        .h1 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 600;
        }

        .h2 {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          width: 100%;
        }
      }

      .SSBM-img {
        display: none;
      }

      .SSBM-img2 {
        display: flex;
        width: 100%;
      }
    }
  }
}
