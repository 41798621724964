.training-banner {
  background-image: url(../../../img/Trainingcenter/training-center-img.webp);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 18px;
  background-position: center;

  h1 {
    font-weight: 600;
    font-size: 38px;
    letter-spacing: -0.05em;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(239, 233, 255, 0.8);
  }
}
.get-start {
  h2 {
    font-weight: bold;
    font-size: 39px;
    letter-spacing: 0.01em;
  }
}

.get-tarining-st a {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $white-color;
  text-decoration: none;
}


.training-box {
  background: $cinder-black-color;
  backdrop-filter: blur(90px);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .training-lock {
    position: absolute;
    display: grid;
    place-items: center;
    z-index: 10;
    background: rgb(204, 202, 202);
    height: 12rem;
    @media screen and (max-width: 992px) {
      height: 22rem;
    }
  }
  .training-img {
    height: 12rem;
    @media screen and (max-width: 992px) {
      height: 22rem;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .training-text {
    height: 62.95px;
    margin-top: -10px;
    position: relative;
    background: $cinder-black-color;
    padding: 0px 20px;
    h2 {
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      margin: 0;
    }
    .form-check-input {
      width: 22px;
      height: 22px;
      background-color: rgb(86 78 206 / 0%);
      border: 2px solid $starstack-blue-main;
      border-radius: 0 !important;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
