@media screen and (max-width: $lg) {
  //Home page banner
  .home {
    .banner-home {
      .text-slider {
        .text {
          width: 40% !important;

          h1 {
            font-size: 45px !important;
            line-height: 50px !important;
          }

          div {
            width: 100% !important;
          }

          .cta {
            .btn {
              padding: 10px 30px !important;
              font-size: 15px !important;
            }

            .play-btn {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }

  //Notifications
  .side-drawer {
    width: 100% !important;
  }

  .Essentials-list {
    margin-top: 30px;

    .mob-wrap {
      justify-content: space-around !important;
    }
  }

  .Essentials-banner {
    .w-50 {
      width: 100% !important;
    }

    .banner-text {
      h1 {
        width: 90%;
      }

      .banner-text-desc {
        width: 90%;
      }
    }
  }

  .collections-list {
    .slick-slider {
      width: 91% !important;
    }
  }

  .single-detail-bundle .text-detail {
    padding: 30px 20px 30px 20px;
  }

  // cart slide
  .mob-none {
    display: none;
  }

  .cart-nav-item {
    &:hover {
      .hover-open-dropdown {
        width: 248px;
        height: 44px;

        a {
          font-size: 11px;
        }
      }
    }
  }

  .mob-100 {
    width: 100% !important;
  }

  .mob-banner-a {
    flex-wrap: wrap;
    justify-content: flex-start !important;

    .mob-banner-b {
      position: unset !important;
    }
  }

  .bg-shadow-video svg {
    top: 18px;
  }

  .bg-shadow-video {
    right: 0;
  }

  .navbar {
    .navbar-collapse {
      position: absolute;
      left: 0;
      right: 0;
      background: $woodsmoke-black-color;
      top: 56px;
      padding: 10px 20px 30px;
      max-height: calc(100vh - 56px);
      overflow-y: auto;
      .navbar-nav {
        .nav-link {
          padding-left: 4px;
          width: 100%;
          padding-bottom: 12px;
          padding-top: 12px;
        }

        &.right-nav .nav-link {
          margin-left: 0px;
          text-align: center;
        }
      }
    }

    .mob-none {
      display: none;
    }

    .right-nav .cart-nav-item {
      display: none;
    }

    .mob-block {
      display: inline-flex;
      margin-left: auto;
      align-items: center;

      .cart-nav-item {
        margin-left: 3px;

        svg {
          width: 20px !important;
        }

        .total-cart {
          width: 14px;
          height: 14px;
          font-size: 9px;
        }
      }

      .nav-btn2 {
        background: $aluminium-gray-color;
        border-radius: 25px;
        padding: 3px 7px !important;
        font-size: 11px;
        color: $white-color;

        svg {
          width: 8px !important;
        }
      }

      .nav-link {
        padding: 0 0px 0px 10px;

        svg {
          width: 24px;
        }
      }
    }
  }

  //----------- Faq section ---------------//
  .faq-dashboard-img {
    position: unset !important;
  }

  .faq-section {
    h2 {
      font-size: 32px;
      line-height: 46px;
    }

    .accordion-button {
      font-size: 16px;
    }

    .accordion-body {
      font-size: 14px;
    }
  }

  .mob-br {
    display: none;
  }

  //----------- Footer section ---------------//
  .footer {
    .Browse-box {
      margin-top: 1.2em;
      flex-wrap: wrap;
      border-radius: 12px;
      padding: 20px 20px;
      background: #000;

      .btn {
        padding: 12px 44px;
      }
    }

    h6 {
      font-size: 11px;
      line-height: 30px;
    }

    .left-foot {
      .form-control {
        width: 100%;
        height: 46px;
      }
    }

    .right-foot ul li {
      display: inline-block;
      width: 50%;
    }
  }

  .new_user_Account .setup-bg-box a {
    font-size: 14px !important;
  }

  .height-heading {
    height: auto;

    h2 {
      position: unset !important;
      font-size: 20px !important;
    }
  }

  .connect-user-new {
    h3 {
      font-size: 20px !important;
    }

    p {
      font-size: 13px !important;
    }
  }

  .video-boxs-Tutorial {
    svg {
      width: 100%;
      height: auto;
    }
  }

  .mob-wrap {
    flex-wrap: wrap;
  }

  .collection-list {
    .collection-list-box {
      width: 48%;
    }
  }

  // let me know component css
  .earn-sharing {
    span {
      font-weight: normal;
      font-size: 13px;

      label {
        font-size: 12px;
      }
    }

    .earn-sharing-btn {
      font-size: 12px;

      span {
        width: 38px;
        height: 38px;
      }
    }
  }

  // minting guidelines card
  .mg-main {
    flex-direction: column !important;

    .mg-card {
      .mg-h1 {
        font-size: 25px !important;
        line-height: 28px !important;
      }

      .mg-h2 {
        font-size: 20px !important;
        line-height: 25px !important;
      }
    }
  }

  // mintguideline

  .ng-bg {
    .ng-main {
      display: flex !important;
      flex-wrap: wrap !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;

      .ng-b1 {
        margin: 10px !important;
        padding: 10px !important;
        width: 100% !important;

        .ng-b1-h1 {
          font-size: 20px !important;
          font-weight: 400 !important;
          line-height: 50px !important;
        }
      }

      .ng-b2 {
        margin: 10px !important;
        padding: 10px !important;
        width: 100% !important;

        .ng-b2-h1 {
          font-size: 20px !important;
          font-weight: 400 !important;
          line-height: 50px !important;
        }

        .ng-b3 {
          display: flex;
          width: 100%;

          .ng-b2-h2 {
            font-size: 16px !important;
            line-height: 21px !important;
          }
        }

        .ng-b2-h3 {
          font-size: 16px !important;
          line-height: 21px !important;
        }

        .ng-b2-h4 {
          font-size: 16px !important;
          line-height: 21px !important;
          width: 100% !important;
        }

        .ng-b2-h5 {
          font-size: 12px;
          font-weight: 400;
          font-family: Poppins;
          line-height: 21px;
          color: #564ece;
          width: 100% !important;
        }
      }

      .ng-b4 {
        display: flex;
        gap: 20px;
      }

      .ng-b5 {
        display: flex;
        gap: 5px;
        flex-direction: row;
      }
    }
  }

  // getbucks page
  .gb-Essentials-banner {
    .gb-banner-container {
      margin-left: 20px !important;

      .gb-buck-h1 {
        font-size: 50px !important;
        font-weight: 600 !important;
        line-height: 60px !important;
        width: 80% !important;
        letter-spacing: 0px !important;
      }

      .gb-p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        width: 80% !important;
      }
    }
  }

  // starter bundle page

  .sb-main {
    width: 100% !important;
    padding: 50px 0 !important;

    .sb-main2 {
      display: flex !important;
      flex-wrap: wrap-reverse !important;
      min-height: 650px !important;
      padding: 20px !important;

      .sb-b1 {
        width: 100% !important;
        margin-left: 10px !important;

        .sb-h1 {
          font-size: 40px !important;
          line-height: 50px !important;
          font-weight: 500 !important;
        }

        .sb-h2 {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 400 !important;
        }

        .btn-white {
          width: 200px !important;
        }
      }

      .sb-b2 {
        width: 100% !important;
        margin-left: 10px !important;

        .sb-img {
          width: 400px !important;
          height: 400px !important;
          margin-top: 100px !important;
          position: relative !important;
        }
      }
    }
  }

  // Creatorhub invitation page

  .ci-main {
    flex-direction: column !important;

    .ci-main-bg {
      padding: auto 50px !important;
      width: 100% !important;

      .ci-b1 {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: -50px !important;
        width: 100% !important;

        .ci-h1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 25px;
          color: #ffffffa8;
        }

        .ci-sb1 {
          display: flex;
          justify-content: center;
          flex-direction: row;
          gap: 10px;
          margin: 20px 10px;

          .sb-h2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #ffffffa8;
          }
        }
      }
    }
  }

  .ci-s1 {
    .ci-s1-b1 {
      justify-content: flex-start !important;
      align-items: flex-start !important;
      margin-left: 0 !important;

      .ci-s1-b3 {
        flex-direction: column !important;
        width: 100% !important;

        .ci-s1-b2 {
          width: 100% !important;

          .h1 {
            width: 90% !important;
          }
        }
      }
    }
  }

  .ci-s2 {
    .ci-sb1 {
      .ci-s2-b1 {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .h2 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 37px;
          line-height: 45.5px;
          color: #ffffff;
        }
      }
    }

    .main-bg1 {
      .ci-s31 {
        flex-direction: column !important;

        .s3-b11 {
          width: 100% !important;
          align-items: flex-start !important;
          justify-content: flex-start !important;

          .sub-b1 {
            width: 100% !important;
          }
        }

        .s3-b21 {
          width: 100% !important;
          align-items: flex-start !important;
        }
      }
    }

    .main-bg {
      .ci-s3 {
        flex-direction: column !important;

        .s3-b1 {
          align-items: flex-start !important;
          justify-content: flex-start !important;
          width: 100% !important;

          .sub-b2 {
            width: 100% !important;
          }
        }

        .s3-b2 {
          width: 100% !important;
        }
      }
    }

    .h1 {
      font-size: 30px !important;
      line-height: 35px !important;
    }

    .h2 {
      width: 100% !important;
    }

    .h3 {
      line-height: 20px !important;
      width: 100% !important;
    }
  }

  .needhelp-section {
    .needhelp-content {
      overflow: hidden;
      text-align: center;
      padding: 20px !important;

      .h1 {
        font-size: 40px !important;
        line-height: 45px !important;
      }

      .h2 {
        padding: 30px;
        width: 100% !important;
      }
    }
  }

  //Privacy policy and Terms & Conditions page
  .scroll_screen {
    padding: 1rem 1rem !important;
    br {
      display: block !important;
    }
  }
}
