.new-user-setup {
  .profile-wallet-address {
    h6 {
      span {
        background: -webkit-linear-gradient(45deg, #85ffc4, #5cc6ff, #bc85ff);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.profile-text {
  padding-top: 40px;
}

.profile-text {
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.66);
  }

  .form-control {
    width: 492px;
    height: 65px;
    border: 2px solid $starstack-blue-main;
    box-sizing: border-box;
    border-radius: 36.5px;
    background: none;
    padding: 0px 40px;
    font-weight: 500;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    background: none;
    border: none;
    font-weight: 500;
    font-size: 18px;
    color: $starstack-blue-main;

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
  .noti-text {
    width: 335px;

    h5 {
      font-weight: 500;
      font-size: 18px;
      color: $white-color;
    }
  }
}
