.ExploreStarstakeDifferenceVideo {
  background-image: url($aws-base-url + '/bg-shape-pattren.webp');
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 20px;
  .headings {
    h1 {
      font-weight: 400;
      font-size: 43px;
      color: #fff;
      span {
        color: #564ece;
      }
    }
  }

  .accordion-item {
    background-color: rgb(255 255 255 / 0%);
    border: 0px;
    .accordion-button {
      font-weight: 400;
      font-size: 30px;
      color: #fff;
      background: none;
      padding: 1.7rem 2rem;
      box-shadow: none;
      span {
        color: #564ece;
        margin-right: 20px;
      }
      &::after {
        display: none;
      }
      &:not(.collapsed) {
        background: rgba(60, 59, 74, 0.24);
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .accordion-body {
      color: #fff;
      background: rgba(60, 59, 74, 0.24);
      padding: 0rem 2rem 2rem;
      font-weight: 300;
    }
  }
  .shape-right {
    bottom: -240px;
  }
}

@media screen and (max-width: 768px) {
  .ExploreStarstakeDifferenceVideo .accordion-item .accordion-button {
    font-size: 22px;
  }
}

.container-width {
  max-width: 1440px;
  margin: auto;
}

.img-heading {
  position: absolute;
  width: 100%;
  top: 0px;
  background: rgba(48, 48, 48, 0.25);
  backdrop-filter: blur(16.5px);
  .img-heading-text {
    text-align: left;
    padding: 2em 2em;
    h2 {
      font-size: 22px;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
    }
  }
  @media screen and (max-width: 520px) {
    .img-heading-text {
      text-align: left;
      padding: 1.5em 0.5em;
      h2 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
      p {
        font-size: 12px;
        font-weight: 300;
        margin: 0;
      }
    }
  }
}

.video-icon-s {
  span {
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: #564ece;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }
  }
}

@media screen and (max-width: 991px) {
  .image-wrapper-how-it-work {
    margin-top: 50px;
  }
}
