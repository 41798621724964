.process-section {
  .shape-right {
    top: 180px;
  }

  h2 {
    font-weight: normal;
    font-size: 43px;
    line-height: 52px;
    color: $black-color;
  }

  .process-list {
    ul {
      &::after {
        width: 1px;
        content: '';
        background-color: $black6-color;
        position: absolute;
        left: 32px;
        top: 0;
        bottom: 80px;
      }

      li {
        &:hover span {
          background: $marguerite-blue-color !important;
          color: $white-color;
          border-color: $marguerite-blue-color;
        }

        span {
          min-width: 65px;
          min-height: 65px;
          border: 2px solid $black-color;
          box-sizing: border-box;
          border-radius: 100%;
        }

        h6 {
          font-weight: 500;
          font-size: 20px;
          line-height: 56px;
          color: $black-color;
        }

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
          color: $black2-color;
        }

        a {
          color: $lavender-blue-color;
          text-decoration: none;
        }
      }
    }
  }
}
