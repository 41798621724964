.sb-main {
  width: 100%;
  background: #04050A;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;

  .sb-main2 {
    background: linear-gradient(102.14deg, #6F4ECE 29.17%, #5B00CF 84.6%);
    border-radius: 23px;
    color: white;
    width: 90%;
    display: flex;
    height: 400px;
    padding: 50px 20px;
    flex-direction: row;

    .sb-b1 {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 50px;
      padding: 20px;

      .sb-h1 {
        font-size: 54px;
        line-height: 65px;
        font-weight: 600;
      }

      .sb-h2 {
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }

      .btn-white {
        width: 250px;
        height: 53px;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        background: white;

        &:hover {
          background: gray;
          color: white;
        }
      }
    }

    .sb-b2 {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .sb-img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}