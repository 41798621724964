@import '../core/colors';
.banner-bg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 3rem 0;
}

.design-ex {
  background-image: url('../../../img/mintGuidelines/bg.png'),
    url($aws-base-url + '/AboutBucks/Circle.png');
  background-size: 100% 360px, cover;
  background-repeat: no-repeat;
  background-position: center 450px, center;
}

.designExample {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 3%;
  .item {
    flex: '1  1 1';
    padding: 20px;
    .item-heading {
      font-weight: 500;
      font-size: 38px !important;
      text-align: center;
      color: $white-color !important;
    }
    .item-heading-nonactive {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 48px !important;
      text-align: center;
      line-height: 110%;
      color: rgba(255, 255, 255, 0.09);
    }
    .buck-img {
      border-radius: 3px;
    }
    .text-slider {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      margin-top: 56px;
      .text {
        flex: 1;
        width: 50%;
        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 54px;
          color: $white-color;
          span {
            color: $starstack-blue-main;
          }
        }
        p {
          font-weight: 300;
          font-size: 16px;
          color: rgba(248, 248, 248, 0.72);
        }
        .cta {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          align-content: center;
          .play-btn {
            cursor: pointer;
            font-weight: 300;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $white-color;
            img {
              margin: 0px 1rem;
            }
          }
        }
        .required-seals {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: $white-color;
          .badges {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            span {
              margin-right: 2rem;
            }
          }
        }
      }
      .slider {
        flex: 1;
        width: 50%;
      }
    }
    .let-meknow-card {
      width: 378px;
      transform: scaleY(0.9);
      margin-top: -1.6em;
      background-color: $cinder-black-color;
      .card {
        background: linear-gradient(
          71.45deg,
          rgba(13, 11, 33, 0.54) 14.26%,
          rgba(86, 78, 206, 0.1242) 113.99%
        );
        border: 2px solid $starstack-blue-main;
        box-sizing: border-box;
        backdrop-filter: blur(5.21937px);
        border-radius: 21.7765px;
        img {
          &.logoImg {
            width: 125px;
          }
          &.stampImg {
            width: 40px;
          }
        }
        .card-img-top {
          border-radius: 1.5rem;
        }
      }
      h4.card-name {
        font-weight: normal;
        font-size: 15.6581px;
        line-height: 22px;
        color: $white-color;
      }
      h5 {
        font-weight: 300;
        font-size: 13.9183px;
        color: rgba(255, 255, 255, 0.28);
      }
      .card-addr {
        &.card-text {
          color: #7e7e7e;
          font-size: 10px;
          letter-spacing: 1px;
        }
      }
      .categoryType {
        margin-top: 1rem;
        span {
          font-weight: 300;
          font-size: 15px;
          color: $white-color;
        }
        .text-start {
          label {
            display: block;
            font-weight: 300;
            font-size: 10px;
            line-height: 16px;
            color: $gray10-color;
          }
        }
        .text-end {
          text-align: right !important;
          label {
            display: block;
            font-weight: 300;
            font-size: 10px;
            color: $gray10-color;
          }
        }
      }
    }
  }
}

//----------- hassle section  css ---------------//
.min-guidelines {
  .faq-section .faq-dashboard-img {
    width: 45%;
    top: 0;
    left: unset;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .hassle-img-layout {
    height: auto !important;
    width: 80% !important;
    margin: 0 auto;
    left: unset;

    img {
      width: 70%;
    }
  }
  .mint-hassle-section {
    background-image: url($aws-base-url + '/MintAccessTokens/banner_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    .mint-heading {
      width: 100%;
      text-align: center;
      color: $white-color !important;
      font-weight: 600;
      font-size: 54px;
      padding: 30px;
      margin: 0 auto;
    }
    .heading {
      padding: 10px 0px 10px 0px;
      margin-top: 8rem;
      h1 {
        color: $white2-color;
        font-weight: 600px;
        font-size: 54px;
      }

      p {
        color: $white2-color;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        line-height: 25px;
        letter-spacing: 0.015em;
        width: 50%;
        margin: 0 auto;
      }
    }
    ul li {
      margin: 0px 30px;
      color: $starstack-blue-main;
      font-size: 20px;
      font-weight: 500;
    }
    .shadow-hassle-img {
      top: -130px;
    }
  }
  .overview-stats {
    .card {
      &.available-royalties {
        background: linear-gradient(
          126.44deg,
          $starstack-blue-main 3.16%,
          #8367ee 77.34%
        ) !important;
        border-radius: 21px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 900px) {
  .min-guidelines {
    .mint-hassle-section {
      .mint-heading {
        width: 100%;
        padding: 10px;
        font-weight: 600;
        font-size: 30px;
        margin: 0 auto;
      }
      .heading {
        padding: 80px 0px 10px 0px;
        margin-top: 8rem;

        p {
          color: $white2-color;
          font-size: 16px;
          text-align: center;
          line-height: 30px;
          letter-spacing: 0px;
          width: 95%;
        }
      }
    }
  }
  .designExample {
    justify-content: center !important;
  }
}
