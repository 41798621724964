.StardomAwaitsYou {
  background: linear-gradient(87.37deg, $black-color 5.75%, #2b2768 66.47%);

  h3 {
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: $gray4-color;
  }

  .text-StardomAwaitsYou {
    a {
      font-weight: 500;
      font-size: 20px;
      text-decoration-line: underline;
      color: $white-color;
      margin-left: 40px;
    }
  }
  .img-aw {
    width: 50%;
    img {
      width: 100%;
    }
  }
}

.StardomAwaitsYou {
  background: linear-gradient(87.37deg, $black-color 5.75%, #2b2768 66.47%);
  display: flex;
  justify-content: center;
  .mainwrapper {
    background-image: url($aws-base-url + '/Starbg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 5% 0;
    flex-direction: row;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: center;
      h3 {
        font-weight: normal;
        font-size: 30px;
        line-height: 42px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: $gray4-color;
      }
      a {
        font-weight: 500;
        font-size: 20px;
        text-decoration-line: underline;
        color: $white-color;
        margin-left: 40px;
      }

      .button-wrapper {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .img-aw {
    width: 50%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
  .StardomAwaitsYou {
    background: linear-gradient(87.37deg, $black-color 5.75%, #2b2768 66.47%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mainwrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      .text-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 40px;
        justify-content: center;

        .button-wrapper {
          margin-top: 30px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .img-aw {
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
