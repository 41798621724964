// Backdrop
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
  backdrop-filter: blur(5px);
}

// SlideDrawer

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

.side-drawer {
  overflow: hidden;
  height: 85vh;
  background: #0b0d19;
  position: fixed;
  top: 65px;
  right: 0;
  width: 30%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.4s ease-out;
  border-radius: 15px;
  @media screen and (max-width: 991px) {
    bottom: 20px;
  }

  .notifications-heading {
    padding: 25px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1a183e;
    span {
      color: #9e99e4;
      font-size: 14px;
      font-weight: 500;
      text-decoration-line: underline;
      cursor: pointer;
      transition: all 0.3s ease-out;
      svg {
        margin-right: 6px;
        path {
          transition: all 0.3s ease-out;
          fill: #9e99e4;
        }
      }
      &:hover {
        color: #ffffff;
        svg path {
          fill: #fff;
        }
      }
    }
  }

  .notifications-list-container {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 81px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(
        260.29deg,
        rgba(86, 78, 206, 1),
        rgba(199, 173, 255, 1)
      );
      border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
      overflow: visible;
      background-color: #1a183e !important;
      height: 1px;
    }

    .notification-tab {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 25px 30px;
      background-color: #100e26;

      &.odd {
        background-color: #1a183e;
      }
      .left {
        svg {
          width: 45px;
          height: 45px;
        }
      }
      .right {
        .title {
          color: white;
          font-weight: 600;
          line-height: 24px;
        }
        .description {
          color: white;
          opacity: 0.7;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .low-opacity {
        opacity: 0.4;
      }

      &:hover {
        background-color: #403a9a;
      }
    }
    .no-notifications-text {
      margin: 50px 10px;
      text-align: center;
      color: whitesmoke;
    }
  }

  h5 {
    margin: 20px 10px 5px 10px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .notifications-heading {
      padding: 16px 20px;
      h4 {
        font-size: 18px;
      }
      span {
        font-size: 12px;
      }
    }
    .notifications-list-container {
      .notification-tab {
        gap: 12px;
        padding: 16px 20px;
        .left svg {
          width: 30px;
          height: 30px;
        }
        .right {
          .title {
            font-size: 16px;
            line-height: 140%;
          }
          .description {
            font-size: 12px;
            line-height: 140%;
          }
        }
      }
      .no-notifications-text {
        margin: 30px 10px;
      }
    }
    h5 {
      margin: 12px 6px 2px;
    }
  }
}

.side-drawer.open {
  transform: translateX(0);
  color: whitesmoke;
  overflow: hidden;
}

.notification-icon {
  position: relative;
  cursor: pointer;
  padding: 0;
  .notification-count {
    position: absolute;
    top: -3px;
    right: -10px;
    width: 18px;
    height: 18px;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    border-radius: 50%;
    background-color: #ffffff;
    color: #564ece;
  }
}

.noti-wrapper {
  padding: 8px 10px !important;
  gap: 8px;
  div[role='status'] {
    margin: 0 !important;
  }
  .noti-blue-icon {
    display: block;
    height: 24px;
    width: 24px;
    path {
      fill: #564ece;
    }
  }
  .noti-box {
    display: flex;
    .noti-close-btn {
      flex: 0 0 22px;
      cursor: pointer;
      height: 22px;
      svg {
        display: block;
        height: auto;
        width: 100%;
        path {
          fill: #ff3f56;
        }
      }
      &:hover svg {
        scale: 1.1;
      }
    }
  }
}
