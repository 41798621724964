.charts {
  .cta {
    button {
      border: 0px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      background: transparent;
      border-radius: 26px;
      padding: 0.5rem 1.5rem;
      margin: 1rem;
      &:nth-child(1) {
        margin-left: 0px;
      }
      &.active {
        // background: rgba(86, 78, 206, 0.15);
        background: linear-gradient(
          89.27deg,
          #fc1885 0.23%,
          $starstack-blue-main 0.24%,
          #561eef 99.74%
        );
      }
    }
  }
  .box {
    min-height: 200px;
    background: #0b0c11;
    box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
    border-radius: 32px;
    position: relative;
    .info-modal {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .info {
      color: $white-color;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
      .left-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        div {
          margin-right: 1.5rem;
        }
        .ip-roy-revenue {
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          /* or 16px */
          letter-spacing: -0.03em;
          color: $casper-blue-color;
          span {
            padding: 4px;
            font-weight: 500;
            font-size: 21px;
            color: #f1f3fa;
          }
        }
        .per {
          background: rgba(140, 249, 190, 0.12);
          border-radius: 6px;
          font-weight: 900;
          font-size: 12px;
          color: #8cf9be;
          letter-spacing: 1px;
          padding: 2px 4px;
        }
        .plus-val {
          font-weight: 500;
          font-size: 16px;
          color: $starstack-blue-main;
        }
        .growth {
          font-weight: normal;
          font-size: 14px;
          color: rgba(183, 189, 212, 0.5);
        }
      }
      .total-infts-staked {
        margin-right: 40px;
        font-weight: 500;
        font-size: 16px;
        color: #969bb2;
        span {
          color: $starstack-blue-main;
        }
      }
    }
    .chart-container {
      height: 350px;
      margin-bottom: 80px;
    }
    .bottom-sec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: space-around;
      ul {
        margin: 0;
        padding: 0;
        background: #0d0e15;
        border: 1px solid #262836;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: stretch;
        li {
          cursor: pointer;
          display: block;
          list-style: none;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: rgba(183, 189, 212, 0.5);
          padding: 6px 12px;
          margin: 2px;
          &.active,
          &:hover {
            border-radius: 6px;
            background: #32364d;
            font-size: 12px;
            text-align: center;
            color: $casper-blue-color;
          }
        }
        &.legend {
          border: 0px;
          li {
            font-weight: 500;
            font-size: 16px;
            span {
              margin: 0px 5px;
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 5px;
            }
            &.nrgy {
              color: $starstack-blue-main;
              span {
                background-color: $starstack-blue-main;
              }
            }
            &.usdc {
              color: $white-color;
              span {
                background-color: $white-color;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .cta {
      text-align: center;
      button {
        margin: 0px 5px 10px 5px;
      }
    }
    .box {
      .info {
        flex-direction: column;
        .left-info {
          flex-direction: column;
          div {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
        .total-infts-staked {
          margin-right: 0px;
        }
      }
      .info-modal {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
      .chart-container {
        height: 200px;
        margin-bottom: 200px;
      }
      .bottom-sec {
        flex-direction: column;
      }
    }
  }
}

.cash-out-royalties {
  h2 {
    font-weight: 500;
    font-size: 18px;
    color: $white-color;
    span {
      color: $starstack-blue-main;
    }
  }
  input {
    background: rgba(196, 196, 196, 0.07);
    border-radius: 32.7648px;
    color: $white-color;
    padding: 1rem 1.5rem;
    border: 2px solid transparent;
    &:hover,
    &:focus {
      border: 2px solid $starstack-blue-main;
      background: rgba(196, 196, 196, 0.07);
    }
  }
  .submit-btn {
    display: flex;
    align-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    button {
      min-width: 350px;
    }
  }
  .help-text {
    font-size: 12px;
    color: $chateau-gray-color;
  }
  @media screen and (max-width: 600px) {
    .submit-btn {
      flex-direction: column;
      button {
        min-width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.monthly-royalties-chart {
  position: relative;
  flex: 2;
  // width: 60%;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: $white-color;
  }
  .info {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.pie-chart {
  position: relative;
  // width: 40%;
  flex: 1;
  .chart-heading {
    .info {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: $casper-blue-color;
    }
  }
  .pie-chart-holder {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .recharts-wrapper {
      &::after {
        content: '';
        width: 100%;
        height: 30%;
        background: #0b0c11;
        display: block;
        position: absolute;
        bottom: 0px;
        background: linear-gradient(
          0deg,
          rgba(11, 12, 17, 1) 50%,
          rgba(11, 12, 17, 0) 100%
        );
      }
    }
    .legend-circle {
      position: absolute;
      background: $starstack-blue-main;
      width: 80%;
      height: 80%;
      border-radius: 100%;
    }
    .bottom {
      position: absolute;
      bottom: 0px;
      // width: 100%;
      // text-align: center;
      .info {
        text-align: center;
        .bal {
          font-weight: 900;
          font-size: 20px !important;
          line-height: 100%;
          /* or 20px */
          text-align: center;
          letter-spacing: -0.03em;
          color: $starstack-blue-main;
        }
        .per {
          display: inline;
          padding: 0.5rem 1rem;
          background: rgba(140, 249, 190, 0.12);
          border-radius: 8px;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          letter-spacing: -0.05em;
          color: $starstack-blue-main;
        }
      }
      .legend {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        div {
          margin-right: 1rem;
          // padding: 0px 1rem;
          // width: 100px;
          // display: inline-block;
        }
        .dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          background: $starstack-blue-main;
          margin-right: 4px;
        }
        .asset {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          color: $casper-blue-color;
          margin-right: 4px;
          position: relative;
        }
        .percent {
          font-weight: 300;
          margin-right: 4px;
          font-size: 11px;
          color: rgba(183, 189, 212, 0.65);
        }
      }
    }
  }
}
