.hassle-section {
  background-image: url('../../../img/svg/Background/YellowLight.svg');
  background-size: cover;
  background-repeat: no-repeat;

  .heading {
    h1 {
      color: $white2-color;
      font-weight: 400px;
      font-size: 43px;
    }

    p {
      color: $gray4-color;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
    }
  }

  ul li {
    margin: 0px 30px;
    color: $starstack-blue-main;
    font-size: 20px;
    font-weight: 500;
  }

  .shadow-hassle-img {
    top: -130px;
  }

  @media screen and (max-width: 992px) {
    br {
      display: none;
    }
  }
}

.mint-heading {
  color: $white-color !important;
  font-weight: 600;
  font-size: 54px;
  width: 611px;
  // height: 60px;
  line-height: 60px;
  margin: 0 auto;
}

.heading {
  width: 100vw;
}

.hassle-sub-heading {
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px;
  margin: 0 auto;
  // width: 80% !important;
  line-height: 25px;
  height: max-content !important;

  color: $white-color;
  font-weight: 300;
  padding-top: 1%;
}

.creativity-decoration {
  color: $starstack-blue-main !important;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.hassle-img-layout {
  width: 761px !important;
  height: 625px !important;
  left: 15%;
}

.box-tables {
  background: rgba(11, 12, 17, 0.25) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.mt-top-mob {
  margin-top: -50px;
}

.min-guidelines .faq-section.mt-top-mob .faq-dashboard-img {
  width: 48%;
  bottom: 0;
  left: unset;
  top: -109px;
  height: auto;
}

.designExample .item .let-meknow-card {
  min-width: 390px !important;
  background-color: transparent !important;

  p {
    color: hsla(0, 0%, 100%, 0.322);
  }
}

.designExample .item.item-3s .let-meknow-card {
  min-width: 348px !important;
}

@media screen and (max-width: 1200px) {
  .designExample {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 520px) {
  .min-guidelines .faq-section.mt-top-mob .faq-dashboard-img {
    width: 100%;
    padding: 1em;
  }
}
