.header {
  position: relative;

  .FilterBtn {
    max-width: 151px;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    .dropdown-toggle {
      text-align: center;
      color: $white-color;
      border: 1px solid $silver-gray-color;
      height: 52px;
      border-radius: 100px;
      padding: 8px 10px;
      width: 100%;

      img {
        margin-right: 12px;
      }

      &:hover,
      &:active,
      &::after {
        display: none;
      }
    }
  }

  .input-group {
    position: relative;

    .dropdown {
      flex: 1;
    }

    .dropdown-toggle {
      padding-left: 0;
    }
  }

  .dropdown-toggle {
    padding: 10px 50px 10px 30px;
    flex: 1;
    text-align: left;
    width: 100%;
    color: $white-color;
    font-size: 16px;
    box-shadow: none;

    &::after {
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      border: solid $gray11-color;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 5px;
      margin-left: 8px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      font-size: 10px;
    }
  }

  .show > .btn-success.dropdown-toggle {
    background-color: $starstack-blue-main;
    border-color: $starstack-blue-main;

    &::after {
      border-color: $white-color;
    }
  }

  .dropdown-menu {
    background: rgba(56, 48, 73, 0.6);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 17px;
    left: 0 !important;
    right: auto !important;
    padding: 10px 15px;
    width: 330px;
    margin-top: -4px;

    .dropdown-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #97a3b7;
      padding: 20px 28px 5px;
    }

    a {
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      color: $white-color;
      padding: 20px 28px !important;
      border-radius: 16px;
      position: relative;
      background: transparent;

      &::after {
        border-radius: 16px;
        z-index: -1;
        transition: opacity 0.25s ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        opacity: 0;
        background: linear-gradient(
          83.55deg,
          #fc1885 -12.13%,
          #9b41f6 -12.12%,
          #561eef 111.34%
        );
      }

      path {
        transition: stroke 0.1s ease-in-out, fill 0.1s ease-in-out;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover,
      &:active,
      &.active {
        &::after {
          opacity: 1;
        }

        svg {
          path {
            fill: #ffffff;
            stroke: #ffffff;
            stroke-width: 0.3;
          }
        }
      }
    }
  }

  .input-group-text {
    padding-left: 18px;
  }

  .dropdown-toggle,
  input,
  .input-group-text,
  button,
  .form-control:focus {
    outline: none;
    box-shadow: none;
  }

  .input-group,
  .dropdown-toggle {
    .btn:focus {
      box-shadow: none !important;
      outline: none !important;
      outline-offset: none !important;
    }

    &:hover,
    &:focus {
      box-shadow: none !important;
      outline: none !important;
      outline-offset: none !important;
    }

    .dropdown-toggle,
    input,
    .input-group-text {
      background-color: transparent;
      border: none;
    }
  }

  .header-ribbon {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    background-color: $casper-blue-color;
    text-align: center;
    z-index: 9;
    font-size: 13px;
  }
}

.watch-tut {
  display: flex;
  justify-content: flex-start;
  border: 1px solid $starstack-blue-main;
  box-sizing: border-box;
  border-radius: 36.5px;
  overflow: hidden;
  font-size: 20px;
  color: $white-color;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;

  > div {
    span {
      display: inline-block;
      padding: 0.5rem 1rem;
      background: rgba(86, 78, 206, 0.1);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $starstack-blue-main;

    > div {
      span {
        display: inline-block;
        padding: 0.5rem 1rem;
        background: $starstack-blue-main;

        svg {
          path {
            fill: $white-color;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .sub-header-for-nft {
    flex-direction: column;
  }
}

.sub-header {
  padding: 1rem;
  margin-top: -1.6rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: #070811;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  .r-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1200px) {
      width: 100%;
      .btn {
        padding: 10px;
      }
      .mob-ac-at {
        justify-content: space-between;
      }
    }
  }

  .watch-tut {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    border: 1px solid $starstack-blue-main;
    box-sizing: border-box;
    border-radius: 36.5px;
    overflow: hidden;
    font-size: 20px;
    color: $white-color;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;

    > div {
      span {
        display: inline-block;
        padding: 0.5rem 1rem;
        background: rgba(86, 78, 206, 0.1);
      }
    }

    &:hover {
      background-color: $starstack-blue-main;

      > div {
        span {
          display: inline-block;
          padding: 0.5rem 1rem;
          background: $starstack-blue-main;

          svg {
            path {
              fill: $white-color;
            }
          }
        }
      }
    }
  }
}

.r-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1330px) {
  .header {
    .dropdown-menu {
      margin-top: -7px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .dropdown-toggle {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 500px) {
  .sub-header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .r-div {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .watch-tut {
      flex-direction: column;
      display: flex;
      justify-content: flex-start;

      > div {
        span {
          display: inline-block;
        }
      }
    }
  }
}

.iconContainer {
  background-color: #2f2a3c;
  border-radius: 100px;
  padding-bottom: 0 !important;

  a {
    text-decoration: none !important;
  }

  .icon-items {
    background-color: $mirage-black-color;
    margin-left: 0px !important;
    font-weight: bold !important;
  }
}

.dropdown-text {
  font-family: $starstack-font-header;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.65);
}

.dropcontainer {
  #dropdown-basic {
    border: none !important;
    outline: none !important;
    &:hover {
      color: $starstack-blue-main !important;
    }
    @media screen and (max-width: 1100px) {
      text-align: center;
    }
  }
}

.dropdown-item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $white-color;
}

.copy-nav {
  margin-top: 0px;
}

@media screen and (max-width: 991px) {
  .copy-nav {
    margin-top: 12px;
  }
  .header {
    .dropdown-toggle {
      &::after {
        padding: 4px;
      }
    }
  }
}

.main-logo {
  svg {
    width: 180px;
  }
}

@media screen and (max-width: 400px) {
  .main-logo {
    svg {
      width: 70px;
    }
  }
}
// wallet connect modal
.modal-nav {
  &.modal {
    .modal-dialog {
      max-width: 600px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    color: #564ece;
    font-weight: 500;
    font-size: 26px;
    line-height: 42px;
    background: rgba(217, 217, 217, 0.07);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;
    .close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

  .title {
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    @media screen and (max-width: 600px) {
      padding: 20px;
    }
    .modal-get-started {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border-radius: 20px;
      height: 70px;
      background: rgba(217, 217, 217, 0.07);
      cursor: pointer;
      @media screen and (max-width: 600px) {
        height: 50px;
      }

      .recommended {
        background: linear-gradient(91.27deg, #564ece 12.81%, #3f36ca 94.89%);
        border-radius: 11px;
        color: #ffffff;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 16px;
        @media screen and (max-width: 600px) {
          padding: 5px;
          font-size: 12px;
        }
      }
      .title-and-svg {
        font-weight: 400;
        font-size: 21px;
        color: #ffffff;
        .metamask-svg {
          margin-right: 5px;
          height: 30px;
          width: 30px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
          .metamask-svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .modal-bottom-section {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 40px 5px 0px;
    }
  }
}

// create account modal

.modal-nav {
  &.modal {
    .modal-dialog {
      max-width: 600px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    color: #564ece;
    font-weight: 500;
    font-size: 26px;
    line-height: 42px;
    background: rgba(217, 217, 217, 0.07);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;

    .close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

  .title {
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
  }
  .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.66);
  }
  .form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    .form-input {
      background: rgba(86, 78, 206, 0.11);
      border-radius: 18px;
      border: none;
      padding: 20px;
      margin-top: 30px;
      color: #ffffff;
    }
    .form-input:focus {
      outline: none;
      box-shadow: none;
    }
    .continue-button {
      width: 300px;
      margin-top: 30px;
    }
  }
}

// get connected

.modal-nav {
  &.modal {
    .modal-dialog {
      max-width: 600px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    color: #564ece;
    font-weight: 500;
    font-size: 26px;
    line-height: 42px;
    background: rgba(217, 217, 217, 0.07);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;
    @media screen and (max-width: 600px) {
      padding: 20px;
    }
    .close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

  .title {
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
  }
  .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.66);
  }
  .welcome-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    .wallet-id-wrapper {
      margin-top: 30px;
      .svg-text {
        display: flex;
        align-items: center;
        padding: 20px;
        font-weight: 400;
        font-size: 21px;
        line-height: 40px;
        color: #ffffff;
        width: 100%;
        background: #151723;
        height: 66px;
        border-radius: 13px 13px 0 0;
        svg {
          margin-right: 20px;
          width: 30px;
          height: 30px;
        }
      }
      .wallet-text {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
        background: #1f212c;
        border-radius: 0 0 13px 13px;
        height: 66px;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        color: rgba(255, 255, 255, 0.37);
        @media screen and (max-width: 600px) {
          font-size: 20px;
          letter-spacing: 2px;
        }
      }
    }
    .sign-in-button {
      width: 300px;
      margin-top: 30px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

// web3 wallet not connected modal
.modal-nav {
  &.modal {
    .modal-dialog {
      max-width: 600px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    color: #564ece;
    font-weight: 500;
    font-size: 26px;
    line-height: 42px;
    background: rgba(217, 217, 217, 0.07);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;
    .close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

  .title {
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
  }
  .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.66);
  }
  .body-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    .bottom-wnc-section {
      display: flex;
      justify-content: space-between;
      margin: 30px 30px 0 0;
      .need-help {
        color: #564ece;
        text-decoration: underline;
        cursor: pointer;
      }
      .dismiss {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
