.details {
  h4 {
    font-weight: 600;
    font-size: 30px;
    color: $black-color;
  }

  .detail-table {
    background: linear-gradient(93.61deg, $white-color 0.27%, rgba(239, 240, 247, 0.31) 99.73%);
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 25px;

    .row-table-de {
      background: linear-gradient(93.61deg, $white-color 0.27%, rgba(239, 240, 247, 0.31) 99.73%);
      box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
      border-radius: 22px 22px 0px 0px;

      ul {
        li {
          margin-top: 0px;
        }
      }
    }

    ul {
      justify-content: space-between;

      li {
        list-style: none;
        width: 215px;
        padding: 25px;
        border-right: 1px solid rgb(86 78 206 / 25%);
        margin-top: -15px;

        h4 {
          font-weight: normal;
          font-size: 30px;
          color: $starstack-blue-main;
        }

        span {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.5);
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .detailSection {
    .accordionBlk.accordion {
      .accordion-item {
        background: linear-gradient(93.61deg, $white-color 0.27%, rgba(239, 240, 247, 0.31) 99.73%);
        box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);

        .accordion-button {
          font-weight: 600;
          font-size: 21px;
          color: #262626;

          &::after {
            filter: invert(100%) sepia(9%) saturate(2%) hue-rotate(195deg) brightness(34%) contrast(101%);
          }
        }

        ul {
          li {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $black-color;
          }
        }
      }
    }
  }
}