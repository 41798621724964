.main {
  .tabs-bars {
    .btn {
      border: 0;
      font-weight: normal;
      font-size: 16px;
      border-radius: 26px;
      padding: 0.5rem 1.5rem;
      background: rgba(239, 233, 255, 0.03);

      &.active {
        background: $starstack-blue-main;

        .active-details {
          display: inline-block;
        }
      }

      &:hover,
      &:focus {
        background: $starstack-blue-main;
        border: 0;
        outline: none;
        box-shadow: none;
      }

      &.disabled {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
        &:focus {
          background: rgba(239, 233, 255, 0.03);
        }
      }

      .active-details {
        display: none;

        path {
          fill: $white-color;
        }
      }
    }
  }
  &.sidenav-collapsed {
    overflow-x: visible;
    .stakehub-sidenav-wrapper {
      width: 100px;
      .MuiPaper-root {
        overflow: visible;
      }
      .link-list {
        overflow: visible;
        .sub-dropdown-wrapper {
          position: absolute;
          top: -60px;
          left: 90px;
          z-index: 999999;
          width: 0;
          overflow: visible;
          a {
            background-color: #1e1f24;
            width: 200px;
          }
        }
      }
    }
  }
}

.detail-Qu {
  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .modal-body {
    padding: 30px;
  }

  .modal-content {
    background: #16122a;
    border: 0;
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 31px;
    color: $white-color;

    h2 {
      font-weight: 400;
      font-size: 21px;
      color: $white-color;

      .info-icon {
        width: 25px;
        height: 25px;
        path {
          fill: #564ece;
        }
      }
    }

    .question {
      color: $starstack-blue-main;
      font-size: 17px;
    }

    p {
      font-weight: 300;
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

.overview-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  .card {
    position: relative;
    padding: 24px 16px;
    margin: 0.5rem;
    border-radius: 12px;
    background: rgba(239, 233, 255, 0.1);
    backdrop-filter: blur(46.5px);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.cashed-out-royalties,
    &.total-royalties-received {
      flex: 3 1;
    }

    .info {
      position: absolute;
      right: 8px;
      top: 8px;
      svg {
        width: 23.63px;
        height: 23.63px;
        path {
          fill: $white-color;
        }
      }
    }

    .amount {
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      color: $white-color;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: $white-color;
    }

    &.available-royalties {
      border: none;
      background: linear-gradient(270deg, #564ece 0%, #383752 92.57%);
      backdrop-filter: blur(46.5px);

      svg {
        path {
          fill: $white-color;
        }
      }
    }

    &.token-growth {
      flex: 2 1;

      background: #564ece;
      backdrop-filter: blur(46.5px);

      svg {
        path {
          fill: $white-color;
        }
      }
    }
  }
}

.p-width {
  width: 50%;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}
.heading-over-vi h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.05em;
}
.Earnings-chart-section {
  min-height: 200px;
  background: #0b0c11;
  box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
  border-radius: 32px;
  position: relative;
  .info-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .heading-section {
    padding: 0 4rem 0 1.5rem;
    color: $white-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .left-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      .head {
        font-weight: 500;
        font-size: 18px;
        line-height: 42px;
        color: white;
        span {
          padding: 4px;
          font-weight: 500;
          font-size: 21px;
          color: #564ece;
        }
      }
      .sub {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #a7a7a7;
        opacity: 0.75;
        span {
          cursor: pointer;
          text-decoration: underline;
          color: $starstack-blue-main;
        }
      }
    }
    .total-infts-staked {
      margin-right: 40px;
      font-weight: 500;
      font-size: 16px;
      color: #969bb2;
      span {
        color: $starstack-blue-main;
      }
    }
  }
  .chart-container {
    height: 350px;
    margin-bottom: 80px;
  }
  .bottom-sec {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 600px) {
      padding-top: 8rem;
    }
    ul {
      margin: 0;
      padding: 0;
      background: #0d0e15;
      border: 1px solid #262836;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: stretch;
      li {
        cursor: pointer;
        display: block;
        list-style: none;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: rgba(183, 189, 212, 0.5);
        padding: 6px 12px;
        margin: 2px;
        &.active,
        &:hover {
          border-radius: 6px;
          background: #32364d;
          font-size: 12px;
          text-align: center;
          color: $casper-blue-color;
        }
      }
      &.legend {
        border: 0px;
        li {
          font-weight: 500;
          font-size: 16px;
          margin-right: 20px;
          &.value {
            color: $starstack-blue-main;
            .tilde {
              path {
                stroke: #564ece;
              }
            }
          }
          &.added {
            color: $white-color;
          }
        }
      }
    }
  }
  .cash-out-royalties {
    padding: 4rem 3rem 1rem 3rem;
    h2 {
      font-weight: 500;
      font-size: 18px;
      color: $white-color;
      span {
        color: $starstack-blue-main;
      }
    }
    input {
      background: rgba(196, 196, 196, 0.07);
      border-radius: 32.7648px;
      color: $white-color;
      padding: 1rem 1.5rem;
      border: 2px solid transparent;
      &:hover,
      &:focus {
        border: 2px solid $starstack-blue-main;
        background: rgba(196, 196, 196, 0.07);
      }
    }
    .submit-btn {
      display: flex;
      align-content: center;
      align-items: flex-end;
      flex-wrap: nowrap;
      flex-direction: row;
      button {
        min-width: 350px;
      }
    }
    .help-text {
      font-size: 12px;
      color: $chateau-gray-color;
    }
    @media screen and (max-width: 600px) {
      .submit-btn {
        flex-direction: column;
        button {
          min-width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.total-Nfts {
  h5 {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.03em;
    color: #969bb2;

    span {
      color: $starstack-blue-main;
    }
  }

  ul {
    background: #0d0e15;
    border: 1px solid #262836;
    border-radius: 10px;

    li {
      cursor: pointer;
      display: inline-flex;
      list-style: none;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: rgba(183, 189, 212, 0.5);
      margin: 2px;
      width: 48.38px;
      height: 28px;
      justify-content: center;
      align-items: center;

      &.active,
      &:hover {
        border-radius: 6px;
        background: #32364d;
        color: $casper-blue-color;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .img-height {
    img {
      height: 500px !important;
      object-fit: cover;
      margin-bottom: 10px;
      border-radius: 20px;
    }
  }
}

.card-video-graf {
  height: 100%;
  overflow: hidden;
  box-shadow: 14px 99px 296px rgb(37 255 176 / 6%);
  border-radius: 12px;

  h6 {
    font-weight: bold;
    font-size: 22.2578px;
    line-height: 29px;
    letter-spacing: 0.01em;
  }

  img {
    height: 100%;
  }

  .icon-play-v {
    width: 70px;
    height: 70px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 54%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 70px;
      height: 70px;
      background: $starstack-blue-main;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }

    svg {
      width: 70px;
      height: 70px;
      position: relative;
      z-index: 1;
    }
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
      opacity: 0;
    }
  }
}

.card-overview {
  .card {
    background: none;
    height: 100%;

    .card-img {
      margin-bottom: -18px;
      height: 55%;
      position: relative;

      &::after {
        content: '';
        background: rgba(86, 78, 206, 0.37);
        border-radius: 12px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }

    .card-body {
      background: linear-gradient(
        66.25deg,
        #8c39f4 15.25%,
        $starstack-blue-main 88.84%
      );
      border-radius: 12px;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 991px) {
        flex: 0.9;
      }
      .not-shown {
        opacity: 0;
        pointer-events: none;
      }

      h5 {
        font-weight: normal;
        font-size: 17px;
        line-height: 26px;
        height: auto;
      }

      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: rgba(239, 233, 255, 0.6);
        height: 50px;
        @media screen and (max-width: 991px) {
          height: 20px;
        }
      }

      a {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $white-color;
        text-decoration: none;
      }
    }
  }
}

// overview page verified creator accordian
.overview-verified-creator-accordian {
  .Accordian-solo {
    background: rgba(217, 217, 217, 0.07);
    border-radius: 19px !important;
    padding: 10px;
    .Summary {
      color: $white-color;
      h2 {
        line-height: 42px;
        font-weight: 400;
        font-size: 30px;
        span {
          color: #564ece;
          font-weight: 700px;
        }
      }
      p {
        font-size: 18px;
        width: 70%;
        @media screen and (max-width: 1330px) {
          width: 100%;
        }
        span {
          color: #564ece;
          font-weight: 700px;
        }
      }
      .progress-bar {
        z-index: 1;
        overflow: hidden;
        position: absolute;
        left: 16px;
        right: 28px;
        bottom: 16px;
        height: 19px;
        background: rgba(255, 255, 255, 0.09);
        border-radius: 9.5px;
        .progress {
          height: 19px;
          background: linear-gradient(
            90.2deg,
            #564ece 22.33%,
            #3a32ad 100.76%,
            #3d34c9 100.76%
          );
          border-radius: 9.5px;
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
        }
      }
    }
    .Desc {
      display: flex;
      flex-direction: column;
      .verified-card {
        background: url($aws-base-url + '/Verified-creator-card-bg.webp')
          no-repeat;
        background-size: cover;
        padding: 3rem 4rem;
        display: flex;
        border-radius: 21.053px;
        width: 100%;
        .left {
          margin: auto;
          width: 50%;
          color: rgba(255, 255, 255, 0.42);
          h2 {
            font-weight: 700;
            font-size: 34.5185px;
            line-height: 45px;
            letter-spacing: -0.05em;
          }
          p {
            font-weight: 400;
            font-size: 15.9543px;
            line-height: 20px;
            a {
              text-decoration: underline;
              color: #564ece;
              font-weight: 600;
            }
          }
        }
        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%;
          img {
            width: 30%;
          }
        }
      }
    }
    .MuiButtonBase-root {
      .MuiIconButton-root {
        .MuiIconButton-label {
          svg {
            height: 50px;
            width: 50px;
            @media screen and (max-width: 700px) {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}
.z-index-4 {
  z-index: 4;
}

.box-car {
  background: rgba(239, 233, 255, 0.08);
  border-radius: 9.8182px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.done {
    position: relative;
    .opacity {
      background: #7220f8;
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.active {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100px;
      border-left: 50px solid #7220f8 !important;
      border: 75px solid transparent;
      opacity: 0.2;
    }

    &::before {
      content: '';
      opacity: 0.2;
      position: absolute;
      background: #7220f8;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100px;
    }

    .shape-bgs {
      &::after {
        position: absolute;
        bottom: -31px;
        content: '';
        border-left: 20px solid #24252b !important;
        z-index: 3;
        width: 22px;
        height: 83%;
        border: 11px solid #24252b;
        transform: rotate(213deg);
        left: 62px;
      }

      &::before {
        position: absolute;
        top: -31px;
        content: '';
        border-left: 20px solid #24252b !important;
        z-index: 3;
        width: 22px;
        height: 83%;
        border: 10px solid #24252b;
        transform: rotate(146deg);
        left: 62px;
      }
    }
  }

  h1 {
    font-weight: bold;
    font-size: 50.6452px;
    line-height: 47px;
    letter-spacing: -0.05em;
    color: $white-color;
  }

  span {
    width: 47.12px;
    height: 47.12px;
    background: rgba(239, 233, 255, 0.03);
    border-radius: 10.4706px;
  }

  h6 {
    font-weight: normal;
    font-size: 15.6391px;
    line-height: 23px;
    color: $white-color;
  }
}

.card.royalties-pai {
  background: linear-gradient(
    83.55deg,
    #fc1885 -12.13%,
    #9b41f6 -12.12%,
    #561eef 111.34%
  );

  svg {
    path {
      fill: $white-color;
    }
  }
}

.chart-bar {
  background: #0b0c11;
  backdrop-filter: blur(93px);
  border-radius: 12px;

  h2 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.05em;
    color: $white-color;
  }
}

.progress-bars {
  background: #0b0c11;
  box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
  border-radius: 12px;

  p {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.03em;
    color: $casper-blue-color;
  }
}

@media screen and (max-width: 1970px) {
  .card-video-graf {
    height: 100%;
  }
}

@media screen and (max-width: 1460px) {
  .box-car h6 {
    font-size: 13.6391px;
  }
}

@media screen and (max-width: 991px) {
  .overview-stats {
    flex-wrap: wrap;

    .card {
      &.cashed-out-royalties,
      &.total-royalties-received {
        flex: auto;
      }

      &.available-royalties {
        flex: auto;
      }

      &.token-growth {
        flex: auto;
      }
    }
  }
}

.box.stakehub-bals {
  border-radius: 16px;
}

.ranking-de {
  .details-tab-ra {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -120px;
      bottom: -148px;
      left: 0;
      right: 0;
      background-image: url($aws-base-url + '/common/blur-bg.webp');
      background-size: 100% 100%;
      z-index: -4;
    }

    .MuiPaper-root {
      background: transparent;
    }
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          background-color: rgba(255, 255, 255, 0.02);

          .MuiTableCell-root {
            border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
          }
          .MuitableHeader-header-6 {
            background-color: rgba(255, 255, 255, 0.02);
          }
          .MuiTableCell-body {
            color: white;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          &:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.02);
          }

          .MuiTableCell-root {
            border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
          }

          .MuiTableCell-body {
            color: white;
          }
        }
      }
    }
    .table {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      background: #0b0c11;
      z-index: 1;

      thead {
        tr {
          border: none;

          th {
            border: none;
            font-weight: 500;
            font-size: 14px;
            color: $white-color;
            background: rgba(255, 255, 255, 0.02);
            padding: 16px 20px;
          }
        }
      }

      tbody {
        tr {
          border: none;
          background: rgba(255, 255, 255, 0.02);

          td {
            border: none;
            background: rgba(255, 255, 255, 0.02);
            font-weight: 500;
            font-size: 14px;
            color: rgba(239, 233, 255, 0.6);
            padding: 16px 20px;

            &.name-avatar {
              color: $white-color;
            }

            &.text-primary {
              color: hsla(244, 57%, 56%, 1) !important;
            }

            span {
              border: 6px solid transparent;
              border-bottom: 11px solid #1adbb8;
              align-items: center;
              display: inline-flex;
              width: 6px;
              border-top: 0px;
            }

            &.blue-li {
              color: #1adbb8;
            }

            &.red-li {
              color: #fe3f24;

              span {
                border-top: 11px solid #fe3f24;
                border-bottom: 0px;
              }
            }
          }

          &:nth-of-type(odd) {
            background: #0b0c11;

            td {
              background: #0b0c11;
            }
          }
        }
      }
    }
  }
}

.box-de {
  background: #0b0c11;
  backdrop-filter: blur(93px);
  border-radius: 20px;

  p {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.66);
  }

  h3 {
    font-weight: 500;
    font-size: 30px;
    letter-spacing: -0.05em;
  }

  .detail-boxss {
    background: rgba(239, 233, 255, 0.03);
    backdrop-filter: blur(93px);
    border-radius: 12px;

    span {
      font-weight: 500;
      font-size: 14px;
      color: rgba(239, 233, 255, 0.6);
    }

    h2 {
      font-size: 32px;
      letter-spacing: -0.01em;
    }
  }
}

.de-ch-top {
  background-image: url($aws-base-url + '/bag-char.png');
  background-size: 100% 100%;
  border-radius: 35px;

  &::after {
    background: linear-gradient(
      180deg,
      rgba(11, 12, 17, 0.61) 0%,
      #0b0c11 100%
    );
    border-radius: 33px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
  }

  .img-cha {
    .img-bo-ch {
      width: 271.42px;
      height: 274.14px;
      background: linear-gradient(
        180deg,
        $starstack-blue-main 0%,
        #0056ff 100%
      );
      border-radius: 217px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding: 6px;

      img {
        border-radius: 100%;
        object-fit: cover;
      }
    }

    h4 {
      font-weight: 500;
      font-size: 30px;
      letter-spacing: -0.05em;
    }

    p {
      font-weight: 500;
      font-size: 13.375px;
      color: rgba(255, 255, 255, 0.66);
    }
  }
}

.text-cha {
  .select-drop {
    p {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      color: $starstack-blue-main;
    }

    select.form-select {
      width: 103px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.37);
      border-radius: 7px;
      color: $white-color;
      border: none;
    }
  }

  .line-toolip {
    span {
      background: linear-gradient(
        180deg,
        $starstack-blue-main 0%,
        #6a60ff 100%
      );
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.05em;
      color: $white-color;
      right: 6%;
      top: 20%;
      padding: 3px 10px;
      cursor: pointer;
    }
  }

  .char-bottom {
    span {
      font-weight: 500;
      font-size: 14px;
      color: rgba(239, 233, 255, 0.6);
    }
  }
}

@media screen and (max-width: 1100px) {
  .flex-wa-mo {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .text-cha {
    .select-drop {
      p {
        font-weight: 500;
        font-size: 14px;
      }

      select.form-select {
        width: 80px;
        font-size: 13px;
      }
    }
  }
}

.chart-main-inft {
  .map-chart-Inft {
    background: #0b0c11;
    box-shadow: 0px 14px 34px rgb(21 17 28 / 35%);
    border-radius: 32px;

    .chart-bottom {
      .value-text {
        li {
          color: $white-color;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          margin-left: 40px;
          list-style: none;

          &.actives {
            font-weight: 500;
            font-size: 16px;
            color: $starstack-blue-main;
          }

          span {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -128px;
    left: 0;
    right: 0;
    background-image: url($aws-base-url + '/common/blur-bg.webp');
    background-size: 100% 100%;
  }

  .details {
    right: 2%;
    top: 8%;
  }
}

a.nav-btn1.nav-link.value-bal {
  background: #2f2a3c !important;
  margin-right: -44px;

  span {
    padding-right: 20px;
    -webkit-text-fill-color: white !important;
  }
}

.nav-btn2.mob-none.nav-link.active-no {
  background: $starstack-blue-main !important;
  box-shadow: 0px 0px 16px #564ece75;
}

@media screen and (max-width: 991px) {
  a.nav-btn1.nav-link.value-bal {
    margin-right: 0;
    margin-bottom: 10px;

    span {
      padding-right: 0;
    }
  }
}

.quickLinksText {
  color: $white-color;
  margin-top: 5%;
  margin-bottom: 5%;
}

.quickLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  color: $white-color;
  height: max-content;

  .card2 {
    background: url('../../../img/CreatorHub/black-box2-bg.png') !important;
  }

  .card {
    position: relative;
    padding: 35px 30px;
    margin: 0.5rem;
    border-radius: 12px;
    background: rgba(239, 233, 255, 0.03);

    .card1Text {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 5%;
    }

    .card1Button {
      margin-top: 5%;
      font-size: 20px;
      width: 88%;
      height: 70%;
      color: $starstack-blue-main;
      border-radius: 9px;
      background-color: rgba(86, 78, 206, 0.11);
    }

    .card2Text {
      font-weight: 800;
      font-size: 38px;
      line-height: 50px;
      letter-spacing: 0.035em;
      margin-bottom: 5%;
    }

    .card2ButtonText {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .card2Button {
      margin-top: 5%;
      padding-top: 2%;
      width: 63%;
      height: 55%;
      background-color: $starstack-blue-main;
      color: $white-color;
    }

    .info {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .amount {
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      color: $white-color;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: $white-color;
    }
  }
}

.creator-hub {
  max-width: none !important;

  .right-w-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    h2 {
      font-weight: normal;
      font-size: 30px;
      color: $white-color;
    }

    .rs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;

      .tut-btn {
        display: flex;
        font-weight: normal;
        font-size: 20px;
        color: $white-color;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $starstack-blue-main;
        border-radius: 36.5px;
        overflow: hidden;
        margin-right: 1rem;

        span {
          display: block;
          padding: 1rem 1.5rem;

          &:nth-last-child(1) {
            background: rgba(86, 78, 206, 0.1);
          }
        }
      }
    }
  }

  .pagination {
    margin-left: 40% !important;
    font-size: 20px !important;
    float: right !important;
    padding-right: 6% !important;
  }

  .page-item.active .page-link {
    background-color: $starstack-blue-light !important;
    border-color: $starstack-blue-light !important;
    font-size: 20px !important;
  }

  .text {
    margin: 0;
    font-family: $starstack-font-main !important;
    text-align: center;
    color: $white-color;
    font-size: 24px;
    list-style: 32px;
  }

  .clickIconMargin {
    margin-left: 12%;
  }

  .btn-primary {
    text-decoration: none;
  }

  .newCollectionBtn {
    padding: 15px 30px;
    box-sizing: border-box;
    border-radius: 9px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    text-decoration: none !important;
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .collections-list {
    // padding-top: 3rem;
    padding-bottom: 3rem;

    // padding-left: 1rem;
    .heading {
      font-weight: 400;
      font-size: 21px;
      line-height: 38px;
      color: $white-color;
      width: fit-content;

      //   display: contents;
      p {
        display: inline;
        color: $starstack-blue-main;
      }
    }
  }

  .timeIconMargin {
    margin-left: 25%;
  }
}

.blue-li {
  color: #1adbb8;
  span {
    border: 6px solid transparent;
    border-bottom: 11px solid #1adbb8;
    border-top: 0px;
    align-items: center;
    display: inline-flex;
    width: 6px;
  }
}

.red-li {
  color: red;
  span {
    border: 6px solid transparent;
    border-top: 11px solid red;
    border-bottom: 0px;
    align-items: center;
    display: inline-flex;
    width: 6px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .nft-buck-font {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1270px) {
  .access-nft-font {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 1150px) {
  .access-nft-font {
    font-size: 16px !important;
  }
}
