.video-banner {
  background: url($aws-base-url + '/video-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: auto;
  margin-top: 56px;
}

.video-text-left {
  min-height: 322px;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(53px);
  border-radius: 12px;
  text-align: center;

  &.video-banner-box {
    max-height: 70vh;
    width: 70%;
  }

  h5 {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    color: rgba(239, 233, 255, 0.6);
  }

  .bg-light-box {
    background: rgba(239, 233, 255, 0.03);
    backdrop-filter: blur(93px);
    border-radius: 12px;

    h4 {
      font-weight: 800;
      font-size: 32px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: rgba(239, 233, 255, 0.6);
    }
  }

  a {
    font-weight: 500;
    font-size: 20px;
    color: $starstack-blue-main;
    text-decoration: none;
  }
}

.video-footers {
  background: rgba(32, 32, 32, 0.42);
  backdrop-filter: blur(45px);
  margin-top: -56px;

  .logo-nft {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
  }

  .Available-nft {
    font-size: 18px;
    color: $gray10-color;
  }

  .link-nft.text-white {
    a {
      font-weight: normal;
      font-size: 18px;
      color: $white-color;
    }
  }
}

.snft-details-profile-verify {
  position: absolute;
  bottom: -8px;
  right: 8px;
  background-color: #000;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;
  }
}

.video-boxs-inft {
  a.nav-link {
    img {
      height: 3vw;
      width: 3vw;
    }
    &:before {
      width: 8vw;
      height: 8vw;
    }
  }
}
