.Access-Reward-Tokens {
  &.detailSection {
    .rgtTopSec {
      h2 {
        color: $black-color;
        font-weight: 600;
        font-size: 30px;

        a {
          font-weight: normal;
          font-size: 21px;
          text-decoration-line: underline;
          color: $starstack-blue-main;
        }
      }

      .form-control {
        color: #151414;
      }

      p {
        color: rgba(0, 0, 0, 0.66);
        font-weight: 500;
        font-size: 16px;
      }

      form {
        label {
          color: rgba(0, 0, 0, 0.66);
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .custom-radios input[type="checkbox"] + label span {
      background: linear-gradient(134.22deg, rgba(86, 78, 206, 0.25) 9.27%, rgba(0, 148, 255, 0.25) 142.83%);
    }

    .accordionBlkRight.accordion .accordion-item .accordion-button {
      h3 {
        line-height: 24px;
        margin: 0;
      }

      h2 {
        line-height: 30px;
        margin: 0;
      }

      ul {
        margin-top: 0px;
      }
    }

    .accordionBlkRight.accordion .accordion-item .accordion-body {
      ul {
        margin-bottom: 30px;
        padding-left: 10px;
      }

      .rewardsImg {
        margin-bottom: 40px;
      }
    }
  }
}