.filter-section {
  display: flex;
  flex-grow: 1 !important;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  .react-select-container.creation .react-select__control {
    padding: 10px 5px 10px 0px !important;
    background: $gray9-color !important;
    height: 100%;
  }

  .box-dropdown {
    padding-left: 20px;
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
      padding-left: 0;
      margin-top: 10px;
    }

    .form-select {
      height: 52px;
      background-color: $gray9-color;
      border-radius: 26px;
      border: none;
      color: $gray11-color;
      padding: 0px 24px;
      background-position: right 1rem center;
      background-size: 22px 16px;

      &:focus {
        box-shadow: 0px 0px 0px 1px $moody-blue-color;
      }

      option {
        background: #18181d;
        color: $white-color;
        font-size: 16px;
      }
    }
  }

  .filter-box {
    width: 25%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }

    button {
      border: 1px solid $silver-gray-color;
      box-sizing: border-box;
      border-radius: 26px;
      background: none;
      color: $gray11-color;
      padding: 0px 30px;
      height: 52px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      &:focus {
        box-shadow: 0px 0px 0px 1px $moody-blue-color;
      }
    }
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  width: 90%;
  flex-direction: row;
  @media screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }

  .form-control {
    height: 52px;
    background-color: rgba(196, 196, 196, 0.1);
    border-radius: 26px;
    border: none;
    padding: 0px 10px 0px 50px;
    color: $white-color;

    &:focus {
      box-shadow: 0px 0px 0px 1px $moody-blue-color;
    }

    &::placeholder {
      color: $gray11-color;
    }
  }

  span {
    padding: 14px 20px;
  }
}
