@media screen and (min-width: $xxs) and (max-width: $xs) {
  .invitation-modal {
    width: 420px !important;
  }
  .confirmation-container {
    width: 420px !important;
    padding: 1rem !important;
  }
  .form-modal-box {
    width: 420px !important;
    .button-container {
      flex-wrap: wrap !important;
    }
  }
}

@media screen and (max-width: $xs) {
  .profile-text p {
    font-size: 12px;
  }

  .img-profile {
    svg {
      width: 100%;
    }
  }

  .noti-text h5 {
    font-size: 14px;
  }

  // mintguidelines card

  .mg-main {
    flex-direction: column !important;

    .mg-card {
      width: 300px !important;
      height: 200px !important;
      margin: 20px !important;
      .mg-h1 {
        font-size: 20px !important;
        line-height: 25px !important;
      }
      .mg-h2 {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }

  // about us page all reponsive css
  .stage-of-starstake-section {
    margin: 0 auto;
    .stages-details {
      margin: 1em 0;
    }
    .row-secound {
      margin-left: 0rem !important;
    }
  }

  .team-details {
    padding-top: 2em;
  }
}
