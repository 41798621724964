$rhs-width: calc(100% - 269px);

.AU-MainWrapper {
  background-image: url($aws-base-url + '/aboutus/BannerBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  height: 100vh;

  .AU-MainDiv1 {
    width: 50%;
    padding: 50px;
    display: flex;
    justify-content: center;

    .AU-TextSection {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .h1 {
        font-size: 54px;
        line-height: 60px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        font-style: normal;
      }

      .h2 {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        color: rgba(248, 248, 248, 0.72);
        font-style: normal;
        margin-top: 40px;
      }

      .h3 {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        font-style: normal;
      }

      .AU-ButtonWrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
        .AU-button-browse {
          position: relative;
          z-index: 1;
          width: 244px;
        }

        .AU-Play {
          display: flex;
          cursor: pointer;
          justify-content: space-between;
          align-items: center;
          margin-left: 60px;
          width: 170px;

          .h2 {
            font-size: 20px;
            line-height: 44px;
            font-weight: 500;
            color: #ffffff;
            font-style: normal;
          }

          .svg {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .AU-Seals {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      align-content: center;

      span {
        margin-right: 2rem;

        .img {
          width: 70px;
          height: 70px;

          svg {
            stroke: 3px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .AU-MainWrapper {
    .AU-MainDiv1 {
      width: 100%;

      .AU-TextSection {
        width: 100%;

        .AU-ButtonWrapper {
          align-items: flex-start;
          flex-direction: column;

          .AU-Play {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .AU-MainWrapper {
    height: 100vh;

    .AU-MainDiv1 {
      width: 100%;
      padding: 10px;
      display: flex;

      .AU-TextSection {
        width: 100%;
        margin-top: 30px;

        .h1 {
          font-size: 50px;
          line-height: 50px;
          font-weight: 700;
          z-index: 1;
        }

        .h2 {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          margin-top: 20px;
          z-index: 1;
        }

        .h3 {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }

        .AU-ButtonWrapper {
          align-items: flex-start;
          flex-direction: column;
          margin-top: 20px;

          .AU-Play {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }

      .AU-Seals {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        align-content: center;

        span {
          margin-right: 0.5rem;
          .img {
            width: 40px;
            height: 40px;

            svg {
              stroke: 3px;
            }
          }
        }
      }
    }
  }
}
