@import '../../../Style/sass/abstracts/variables';
@import '../../../Style/sass/core/typography';

.accessNFT-stakehub-wrapper {
  .right-wrapper {
    min-height: 100vh;
    position: relative;
    .bg-img-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      img {
        width: 100%;
      }
    }
  }

  // nav section
  .topbar-section {
    display: flex;
    .top-section-item {
      display: flex;
      overflow-x: auto;
      width: 100%;
      @media only screen and (max-width: 500px) {
        margin-bottom: 20px;
      }
      &::-webkit-scrollbar {
        height: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(
          270.29deg,
          #564ece 1.02%,
          #3a32ad 102.93%,
          #3d34c9 102.94%
        );
        border-radius: 6px;
      }
      &::-webkit-scrollbar-track {
        overflow: visible;
        background-color: transparent;
        height: 1px;
        border-radius: 10px;
      }
      .active {
        background: #564ece;
        color: white;
      }
      button {
        min-width: max-content;
        border: 0px;
        color: rgba(239, 233, 255, 0.6);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        background: rgba(239, 233, 255, 0.03);
        border-radius: 26px;
        padding: 0.5rem 1.5rem;
        margin: 1rem 0.5rem;
      }
    }
    h2 {
      color: white;
      font-weight: 400;
      font-size: 30px;
      line-height: 42px;
      p {
        display: inline;
        color: #564ece;
      }
    }
    .accessNFT-dashboard-tab {
      margin-bottom: 1rem;
      button {
        border: 0px;
        font-style: normal;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        padding: 0.5rem 1.5rem;
        border-radius: 26px;
        color: rgba(239, 233, 255, 0.6);
        background-color: rgba(239, 233, 255, 0.03);
        &:hover {
          border: none;
          background-color: rgba(239, 233, 255, 0.03);
        }
        &:focus {
          box-shadow: none;
        }
      }
      &-active {
        button {
          color: white;
          background: linear-gradient(
            90.2deg,
            #564ece 22.33%,
            #3a32ad 100.76%,
            #3d34c9 100.76%
          );
          &:hover {
            border: none;
            background: linear-gradient(
              90.2deg,
              #564ece 22.33%,
              #3a32ad 100.76%,
              #3d34c9 100.76%
            );
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
  }
  .select-rarity {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 26px;
    min-height: 50px;
    .react-select__control {
      background: transparent;
    }
    .react-select__menu {
      z-index: 2;
    }
  }

  .my-select-nfts-wrapper {
    width: 100%;
    height: 100%;
    .select-top-nav {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
      .select-rarity {
        width: 25%;
        @media screen and (max-width: 991px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .reset-text {
        font-weight: 400;
        font-size: 16px;
        text-align: right;
        color: rgba(255, 255, 255, 0.41);
        cursor: pointer;
      }
    }
  }

  .orders-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    .blue-gradient {
      position: absolute;
      left: 10%;
      height: 700px;
      max-height: 100%;
      width: 700px;
      max-width: 100%;
      background: rgba(86, 78, 206, 0.38);
      filter: blur(200px);
      z-index: -1;
    }
    .open-title {
      font-weight: 400;
      font-size: 21px;
      line-height: 38px;
      color: #ffffff;
    }
    .order-filter-wrapper {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
      }
      .order-nft {
        display: flex;
        justify-content: flex-start;
        width: 75%;
        flex-direction: column;
        padding-right: 20px;
        @media screen and (max-width: 991px) {
          width: 100%;
          margin-top: 30px;
        }
        .nft-details-stakehub {
          display: flex;
          align-items: center;
          margin: 30px 0;
          @media screen and (max-width: 991px) {
            flex-direction: column;
          }
          .onhover-pending-wrapper {
            visibility: visible;
            opacity: 1;
            z-index: 1;
          }
          .blue-info-container {
            background: #0b0d19;
            border-radius: 0 24px 24px 0;
            width: 80%;
            padding: 60px 40px;
            @media screen and (max-width: 991px) {
              padding: 20px 20px 40px;
              border-radius: 24px;
              margin-top: -20px;
            }
            .title-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
              }
              .info-wrapper {
                display: flex;
                flex-direction: column;
                .creator-title {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 38px;
                  color: rgba(255, 255, 255, 0.28);
                }
                .nft-title {
                  font-weight: 400;
                  font-size: 21px;
                  line-height: 25px;
                  color: #ffffff;
                }
                .rarity {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 37px;
                  color: rgba(37, 255, 176, 0.88);
                  text-transform: capitalize;
                  svg {
                    path {
                      fill: rgba(37, 255, 176, 0.88);
                      stroke: rgba(37, 255, 176, 0.88);
                    }
                  }
                }
                .statusnft {
                  font-style: italic;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 38px;
                  color: #ffffff;
                }
              }
              .info-div {
                .typenft {
                  background: #363276;
                  border-radius: 16.5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: max-content;
                  padding: 4px 24px;
                  height: 40px;
                  text-transform: capitalize;
                  font-weight: 400;
                  font-size: 15px;
                  svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                  }
                }
                .view-details-button {
                  background-color: transparent;
                  border: 1px solid #564ece;
                  padding: 12px 42px;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 12px;
                  border-radius: 22px;
                  text-align: center;
                  color: #ffffff;
                  text-decoration: none;
                  &:hover {
                    background-color: #564ece;
                  }
                }
              }
            }
          }
        }
      }
      .filter-wrapper {
        display: flex;
        flex-direction: column;
        width: 25%;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        .filter-text {
          font-weight: 400;
          font-size: 21px;
          line-height: 38px;
          color: #ffffff;
        }
        .reset-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 38px;
          text-align: right;
          color: rgba(255, 255, 255, 0.41);
        }
      }
    }
  }

  .stakehub-accessNft-overview {
    .accessNft-training-banner {
      width: 100%;
      aspect-ratio: 3.5;
      background-image: url($aws-base-url + '/AccessNFTImages/overview-banner.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0% 33%;
      overflow: hidden;
      border-radius: 30px;
      position: relative;
      @media screen and (max-width: 991px) {
        border-radius: 8px;
        aspect-ratio: unset;
        background-position: bottom right;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      .details-content {
        padding: 20px 6%;
        width: 48%;
        min-width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 20px;
        @media screen and (max-width: 1201px) {
          padding-left: 3%;
          gap: 16px;
        }
        @media screen and (max-width: 991px) {
          min-width: 95%;
          z-index: 1;
          position: relative;
          gap: 12px;
        }
        h2 {
          font-weight: 500;
          font-size: 32px;
          line-height: 120%;
          color: #ffffff;
          margin-bottom: 0;
          @media screen and (max-width: 1500px) {
            font-size: 24px;
          }
          @media screen and (max-width: 991px) {
            font-size: 20px;
          }
        }
        p {
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
          line-height: 145.5%;
          @media screen and (max-width: 1201px) {
            margin-bottom: 0;
          }
          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }
          @media screen and (max-width: 991px) {
            font-size: 10px;
          }
        }
        .btn {
          background: linear-gradient(
            89.93deg,
            #ffb89a 12.96%,
            #ff8585 54.64%,
            #8745c9 99.91%,
            #a94fb0 99.92%
          );
          border: 1px solid rgba(255, 255, 255, 0.31);
          border-radius: 40px;
          width: max-content;
          &:hover {
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.31);
          }
          @media screen and (max-width: 1500px) {
            padding: 15px 32px;
          }
          @media screen and (max-width: 1201px) {
            padding: 12px 32px;
          }
          @media screen and (max-width: 1500px) {
            font-size: 16px;
          }
          @media screen and (max-width: 991px) {
            font-size: 14px;
            padding: 8px 18px;
          }
        }
      }
      .close-icon-btn {
        z-index: 3;
        position: absolute;
        right: 0;
        top: 0;
        svg {
          width: 50px;
          height: 50px;

          @media screen and (max-width: 991px) {
            width: 30px;
            height: 30px;
          }
        }
        margin: 20px;
        cursor: pointer;
        transform: rotate(180deg);
      }
    }
    .accessNft-training-banner-header {
      padding: 30px 50px;
      width: 100%;
      background-image: url($aws-base-url + '/AccessNFTImages/overview-banner.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0% 33%;
      overflow: hidden;
      border-radius: 20px;
      position: relative;
      @media screen and (max-width: 991px) {
        border-radius: 8px;
        aspect-ratio: unset;
        background-position: bottom right;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      h2 {
        position: relative;
        z-index: 2;
        font-weight: 500;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 0;
        @media screen and (max-width: 1500px) {
          font-size: 24px;
        }
        @media screen and (max-width: 991px) {
          font-size: 20px;
        }
      }
      .close-icon-btn {
        z-index: 3;
        position: absolute;
        right: 0;
        top: 0;
        svg {
          width: 50px;
          height: 50px;

          @media screen and (max-width: 991px) {
            width: 30px;
            height: 30px;
          }
        }
        margin: 20px;
        cursor: pointer;
      }
    }
    .watch-tut {
      max-width: max-content;
    }
    .creation-boxes {
      display: flex;
      gap: 30px;
      @media screen and (max-width: 1440px) {
        gap: 20px;
      }
      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media screen and (max-width: 600px) {
        gap: 14px;
      }
      .creation-box {
        aspect-ratio: 0.81;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        padding: 0 2%;
        border-radius: 16px;
        width: calc(25% - 15px);
        background-image: url($aws-base-url + '/AccessNFTImages/purchase-creation-box.webp');
        &.creation-box-exclusives {
          background-image: url($aws-base-url + '/AccessNFTImages/exclusive-creation-box.webp');
        }
        &.creation-box-rewards {
          background-image: url($aws-base-url + '/AccessNFTImages/rewards-creation-box.webp');
        }
        &.creation-box-certificates {
          background-image: url($aws-base-url + '/AccessNFTImages/certificate-creation-box.webp');
        }
        @media screen and (max-width: 1440px) {
          width: calc(25% - 10px);
        }
        @media screen and (max-width: 1200px) {
          width: calc(50% - 10px);
        }
        @media screen and (max-width: 600px) {
          width: calc(50% - 7px);
        }
        &:hover {
          box-shadow: 0px 4px 33px rgba(81, 141, 255, 0.23);
        }
        .top-section {
          flex: 1.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h2 {
            color: #ffffff;
            font-weight: 700;
            font-size: 44px;
            line-height: 42px;
            font-family: $starstack-font-gemsbuck;
            @media screen and (max-width: 991px) {
              font-size: 28px;
              line-height: 28px;
            }
          }
          h4 {
            font-family: $starstack-font-gemsbuck;
            color: #ffffff;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            @media screen and (max-width: 991px) {
              font-size: 15px;
            }
          }
        }
        .bottom-section {
          flex: 1;
          display: flex;
          flex-direction: column;
          p {
            margin-top: 3%;
            margin-bottom: 10px;
            color: #ffffff;
            opacity: 0.55;
            letter-spacing: -0.005em;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            min-height: 74px;
            @media screen and (max-width: 991px) {
              font-size: 12px;
              min-height: 64px;
            }
            @media screen and (max-width: 600px) {
              font-size: 10px;
              min-height: 54px;
            }
          }
          h5 {
            font-weight: 600;
            font-size: 15.5888px;
            line-height: 23px;
            color: #ffffff;
            margin-bottom: 3%;
            cursor: pointer;
            width: max-content;
            svg {
              margin-left: 8px;
              height: 23px;
            }
            &:hover svg {
              scale: 1.1;
            }
            @media screen and (max-width: 1550px) {
              font-size: 14px;
              line-height: 20px;
              svg {
                height: 20px;
              }
            }
            @media screen and (max-width: 1440px) {
              font-size: 14px;
              line-height: 18px;
              svg {
                margin-left: 6px;
                height: 18px;
              }
            }
            @media screen and (max-width: 991px) {
              font-size: 13px;
              line-height: 16px;
              svg {
                height: 16px;
              }
            }
            @media screen and (max-width: 600px) {
              font-size: 11px;
              line-height: 14px;
              svg {
                height: 14px;
              }
            }
          }
        }
      }
    }
    .main-gradient-bg {
      position: relative;
      opacity: 0.39;
      filter: blur(200px);
      width: 100vw;
      z-index: -2;
      &::before,
      &::after {
        content: '';
        position: fixed;
        top: 20vh;
        height: 100vh;
        z-index: -1;
      }
      &::before {
        left: 0;
        width: 50%;
        background: #564ece;
      }
      &::after {
        right: 20%;
        width: 40%;
        background: #ae31a2;
      }
    }
  }
}

.claim-certificate-modal {
  &.modal {
    .modal-dialog {
      max-width: 600px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    background: rgba(86, 78, 206, 0.08);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;
    h4 {
      color: #564ece;
      font-weight: 500;
      font-size: 26px;
      line-height: 42px;
      margin-bottom: 0;
    }
    .modal-close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 20px 30px;
    .modal-title {
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
    .otp-input-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 4%;
      .otp-input {
        flex: 1;
        width: 100%;
        background: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #564ece;
        text-align: center;
        font-size: 24px;
      }
    }
    .modal-btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      .modal-submit-button {
        background-color: #564ece;
        border-color: #564ece;
        width: 36%;
        padding: 12px;
        font-size: 18px;
        &:hover {
          background-color: transparent;
        }
      }
      .link-btn {
        width: max-content;
        font-size: 18px;
        padding: 10px;
      }
    }
  }
}
