.btn {
  padding: 20px 50px;
  box-sizing: border-box;
  border-radius: 9px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  box-shadow: none;

  &.btn-semi-dark {
    background: rgba(86, 78, 206, 0.11);
    color: $starstack-blue-main;
  }

  &.btn-white {
    background: $white-color !important;
    color: $starstack-blue-main !important;
  }

  &.btn-md {
    padding: 14px 40px;
    font-size: 14px;
    color: $starstack-blue-main !important;
  }

  &.btn-sm {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    // color: $starstack-blue-main !important;
    color: $white-color;
  }

  &.btn-outline-primary {
    border: 1.5px solid $starstack-blue-light;
    color: $white-color;

    &.btn-md {
      &:hover {
        color: $white-color !important;
      }
    }

    &.btn-sm {
      &:hover {
        color: $white-color !important;
      }
    }

    &:hover {
      background-color: $starstack-blue-light;
    }
  }

  &.btn-primary {
    background-color: $starstack-blue-light;
    border: 1.5px solid $starstack-blue-light;
    color: $white-color;

    &:hover {
      border: 1.5px solid $starstack-blue-light;
      background-color: rgb(91 82 255 / 0%);
    }
  }

  &.btn-light:hover {
    color: $white-color;
    background-color: rgb(4 5 10 / 37%);
    border-color: rgb(4 5 10 / 0%);
  }
}

.btn-link {
  color: $starstack-blue-main;
  &:hover {
    color: $starstack-blue-main;
  }
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.45;
}

.add-cart-btn {
  width: 122px;
  height: 36px;
  background: $starstack-blue-light !important;
  border-radius: 18px;
  font-weight: 500;
  font-size: 16px;
  color: white !important;
  border: none !important;
}

.btn-hover-blue:hover {
  color: $starstack-blue-light !important;
}

.add-to-cart {
  padding: 0.75rem 1.25rem;
  background: $starstack-blue-light;
  border-radius: 25.5px;
  font-weight: 500;
  font-size: 16px;
  outline: 0px;
  border: 0px;
  color: $white-color;
  border: 1px solid $starstack-blue-light;
  svg {
    path {
      fill: white !important;
    }
  }
  &.sold-out {
    background: #ff3f56;
    border-color: #ff3f56;
    h4 {
      font-size: 16px;
    }
    &:hover {
      color: #ff3f56;
    }
  }
  &:hover {
    transition-duration: 0.3s;
    background: transparent;
    svg {
      path {
        stroke: #0b0d19 !important;
      }
    }
  }
}
