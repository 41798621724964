.gb-Essentials-banner {
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url($aws-base-url + '/getbucks/get-bucks-bg-new.webp');
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .gb-banner-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    margin-left: 50px;
    margin-top: 20px;

    .gb-buck-h1 {
      font-size: 65px;
      font-weight: 700;
      line-height: 72px;
      letter-spacing: 2px;
      color: white;
    }
    .gb-p {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: white;
    }
  }
}
