.featureStarButtonContainer {
  display: flex;

  .featureStarButtonDiv {
    display: flex;
    align-items: center;

    .featureStarButton {
      background: none;
      color: inherit;
      border: none;
      padding: 0px !important;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      font: inherit;

      span {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAYCAMAAADat72NAAAAPFBMVEVHcExVTc1QQM9WTs1YSM9QUMdWTs5VT89WTs5VUMpVTs5XTc9YUM9TTMxWTs9WTs9WTc1VTc1XTc1WTs6T3nwgAAAAE3RSTlMAnxCAICDvn98wz58gUI9/r2Bwymb4BwAAAFFJREFUKM/FyTkOgDAMBMDldrgC+P9/xS6QKOKVKCKmHeCjJdNV3ePd1DTxdz//5X+04Z/+s/9UJM8rYc96TOBb1kvVFb5DhUUStsAqbO3fewNHdgzYfkIYXgAAAABJRU5ErkJggg==)
          no-repeat;
        width: 27px;
        height: 29px;
        background-position: center center;
        background-size: contain;
        color: transparent;
        display: inline-block;
      }

      &:hover {
        background: white;
      }
    }
  }
}
