$rhs-width: calc(100% - 269px);

.contact-us-wrapper {
  min-height: 100vh;
  background-image: url('../../../img/common/CCbg1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  position: relative;

  .gradient-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      267.82deg,
      #000000 3.02%,
      rgba(0, 0, 0, 0) 86.13%
    );
    opacity: 0.86;
    transform: rotate(-180deg);
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 100vh;
      padding: 50px;
      align-items: center;
      justify-content: center;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-color;
        width: 40%;

        .quote {
          font-weight: 600;
          font-size: 48px;
          line-height: 53px;
          color: $white-color;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-color;
        width: 45%;
        margin: 4rem;
        border-radius: 29px;
        background-color: rgba(0, 0, 0, 0.71);
        backdrop-filter: blur(27px);

        Form {
          width: 100%;
          margin: 2rem;
          padding: 1rem;

          .head {
            font-family: $starstack-font-secondary;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -0.02em;
            color: $white-color;
          }

          .tag {
            font-family: $starstack-font-secondary;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.71);
          }
          .first-last {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            .inputfirst {
              width: 49%;
              display: flex;
              flex-direction: column;
            }
          }

          .form-label {
            font-family: $starstack-font-secondary;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $white-color;
          }

          .form-control {
            background: rgba(255, 255, 255, 0.2);
            // border: 1px solid #d0d5dd;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 10px 14px;
            gap: 8px;
          }

          .submit-button {
            font-family: $starstack-font-secondary;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            background: $starstack-blue-main;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            width: 100%;
            &:hover {
              background-color: transparent;
            }
          }

          ::placeholder {
            color: $white-color;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .contact-us-wrapper {
    .box {
      .content {
        flex-direction: column;
        width: 100%;
        margin-top: 10%;
        .left {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          .quote {
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin: 10px;
          border-radius: 20px;

          Form {
            margin: 10px;
            padding: 10px;
            width: 100%;
            .head {
              font-weight: 600;
              font-size: 30px;
              line-height: 44px;
            }

            .tag {
              font-size: 16px;
              line-height: 25px;
            }
            .first-last {
              display: flex;
              justify-content: center;
              flex-direction: column;
              .inputfirst {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-right: 0px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .contact-us-wrapper {
    .box {
      .content {
        flex-direction: column;
        width: 100%;
        margin-top: 20%;
        padding: 10px;
        .left {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          .quote {
            font-weight: 600;
            font-size: 35px;
            line-height: 40px;
            margin-left: 10px;
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin: 10px;
          border-radius: 10px;
          Form {
            margin: 10px;
            padding: 10px;
            width: 100%;
            .head {
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
            }
            .tag {
              font-size: 12px;
              line-height: 20px;
            }
            .first-last {
              display: flex;
              justify-content: center;
              flex-direction: column;
              .inputfirst {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-right: 0px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
