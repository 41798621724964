.buck-bundle-details {
  .back-link {
    margin: 0;
    margin-top: 4.3em;
    a {
      padding-top: 0.5em;
    }
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
    margin-left: 1em;
  }
  .single-detail-bundle {
    .single-detail-img {
      z-index: 1;
      position: relative;
      right: -43px;
      top: 19px;

      @media screen and (max-width: 991px) {
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        margin-bottom: 30px;
      }

      .buck-main-card-wrapper {
        width: 264px;
        height: 530px;
        position: relative;

        .buck-video,
        .adbuck-overlay {
          cursor: auto;
        }
      }
    }

    .ownership-fancreds-wrapper {
      margin-top: 20px;
      .icon-fancreds {
        margin-bottom: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        width: max-content;
        background: rgba(255, 255, 255, 0.13);
        border-radius: 20.9776px;
        padding: 6px 15px 6px 8px;
        svg {
          width: 30px;
          height: 30px;
        }
      }
      a {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .text-detail {
      background: $cinder-black-color;
      border-radius: 24px;
      padding: 40px 50px 40px 125px;
      margin-top: 7.3rem;
      @media screen and (max-width: 991px) {
        padding: 20px;
        margin-top: 20px;
        width: 100%;
      }
      h6 {
        font-weight: 500;
        font-size: 16px;
        color: $aluminium-gray-color;
        margin-bottom: 2rem;
        padding-top: 0.96rem;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $starstack-blue-main;
        text-transform: capitalize;
      }

      ul li {
        list-style: none;
      }

      h5 {
        font-weight: normal;
        font-size: 21px;
        color: $aluminium-gray-color;
        margin-top: 0.8em;
      }

      .price-detail {
        font-weight: normal;
        font-size: 21px;
        color: $starstack-blue-main;
      }

      .auth-detail {
        font-weight: 500;
        font-size: 16px;
        color: $aluminium-gray-color;
      }

      h1 {
        font-weight: normal;
        font-size: 30px;
        color: $white-color;
      }

      .view-bundle-btn {
        background: $gray9-color;
        border-radius: 45px;
        font-weight: 500;
        font-size: 16px;
        color: $gray10-color;
        padding: 13px 30px;
        text-decoration: none;

        &:hover {
          background: $starstack-blue-light;
          color: $white-color;
        }
      }

      p {
        min-height: 100px;
        font-weight: normal;
        font-size: 16px;
        line-height: 38px;
        color: $aluminium-gray-color;
        white-space: pre-wrap;
      }
    }

    .standard-bucks {
      background: $cinder-black-color;
      border-radius: 24px;

      h2 {
        font-weight: normal;
        font-size: 21px;
        color: $white-color;

        span {
          color: $starstack-blue-main;
        }
      }

      .slick-slider {
        .slick-track {
          margin-left: 0;
          float: left;
        }

        .slick-dots {
          right: -40px;
          top: -60px;
        }

        .collections-box {
          width: 192.91px;
          overflow: hidden;

          .img-hover-click {
            background: linear-gradient(
              162.24deg,
              rgba(157, 153, 208, 0.82) 3.31%,
              rgba(64, 51, 255, 0.82) 97.08%
            );
            opacity: 0;
            transition: 0.2s;

            a {
              text-decoration: none;

              p {
                font-weight: 500;
                font-size: 16px;
                color: $white-color;
              }
            }
          }

          &:hover {
            .img-hover-click {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
