.AccessTokenStatscard {
  flex: 2 1;
}
.total-sals {
  flex: 3 1;
  background: linear-gradient(
    83.55deg,
    #fc1885 -12.13%,
    #9b41f6 -12.12%,
    #561eef 111.34%
  ) !important;
  svg {
    path {
      fill: $white-color;
    }
  }
}

.card-clim {
  background: linear-gradient(
    71.45deg,
    rgba(13, 11, 33, 0.54) 14.26%,
    rgba(86, 78, 206, 0.1242) 113.99%
  );
  border: 1.19297px solid $starstack-blue-main;
  backdrop-filter: blur(2.8593px);
  border-radius: 16px;
  padding: 13px;

  .img-claimed {
    aspect-ratio: 0.9866;
    width: 100%;
  }
  .text-Claimed {
    padding-top: 14px;
    .title {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
    }
    .subtitle {
      font-size: 10px;
      font-weight: 400;
      line-height: 11px;
      color: rgba(255, 255, 255, 0.28);
      text-transform: capitalize;
    }
    .text1 {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #ffffff;
      text-transform: capitalize;
      svg {
        height: 15px;
        width: 15px;
      }
    }
    h5 {
      font-weight: normal;
      font-size: 12.57791px;
    }
    p {
      font-weight: normal;
      font-size: 10.62481px;
      color: rgba(255, 255, 255, 0.28);
    }
    .seals {
      svg {
        height: 50px;
        width: 50px;
      }
    }
    label {
      font-size: 9px;
      font-weight: 500;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
    h4 {
      font-weight: normal;
      font-size: 10.3508px;
      line-height: 9px;
      color: $white-color;
      svg {
        width: 20px;
        height: 20px;
        margin-top: -2px;
      }
    }
  }
}

.detail-texts-token {
  .font1 {
    font-size: 38px;
    font-weight: 400;
    line-height: 44px;
    color: #ffffff;
  }
  .font2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
  }
  .font3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.66);
  }
  .font4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
  }
  h2 {
    font-weight: normal;
    font-size: 30px;
  }
  h6 {
    font-weight: 500;
    font-size: 16px;
    color: $starstack-blue-main;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.66);
  }
  .list-de-to {
    font-weight: 500;
    font-size: 16px;
    span {
      color: $aluminium-gray-color;
      &.blue-lg {
        color: $starstack-blue-main;
      }
    }
  }
}
.dactive-tokens {
  .modal-dialog {
    display: flex;
    align-items: center;
    max-width: 751px;
  }
  .modal-content {
    background: $cinder-black-color;
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
    border-radius: 31.7949px;
    padding: 20px;
    .modal-header {
      border: none;
      .modal-title.h4 {
        font-weight: normal;
        font-size: 21px;
        line-height: 42px;
        color: $starstack-blue-main;
      }
      .btn-close {
        filter: contrast(0);
        border: 3px solid;
        border-radius: 100%;
        top: -4px;
        position: relative;
      }
    }
    .modal-body {
      h2 {
        font-weight: normal;
        font-size: 30px;
        span {
          color: $starstack-blue-main;
        }
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.03em;
        color: $aluminium-gray-color;
      }
      .btn {
        width: 50%;
        &.btn-transparent {
          color: $starstack-blue-main;
        }
        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

.cash-out {
  .form-label {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.01em;
    color: $white-color;
    span {
      color: #655bf3;
      font-weight: 400;
    }
  }
  .form-control {
    background: rgba(196, 196, 196, 0.07);
    border-radius: 32.7648px;
    padding: 18px 30px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: normal;
    color: $white-color;
    &::placeholder {
      color: rgba(255, 255, 255, 0.18);
    }
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $chateau-gray-color;
    opacity: 0.75;
    a {
      color: #655bf3;
    }
  }
  .re {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.05em;
    text-decoration-line: underline;
    color: $starstack-blue-main;
    cursor: pointer;
  }
}

.chart-main-inft .details {
  right: 2%;
  top: 5%;
}
.post-update {
  .modal-title.h4 {
    font-weight: normal;
    font-size: 30px !important;
    line-height: 42px;
    letter-spacing: -0.05em !important;
    color: $white-color !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.card-clim .text-Claimed h4 {
  font-size: 13.3508px;
  line-height: 18px;
}
.overview-stats.Royalties-Paids {
  .card {
    width: 100% !important;
  }
}
