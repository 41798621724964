.token-form {
  padding: 2rem 4rem;
  background-color: #0b0d19;
  .event-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 21px;
    color: $aluminium-gray-color;
    span {
      color: $starstack-blue-main;
    }
  }
  .form-holder {
    width: 70%;
    .cta {
      button {
        margin-right: 1.5rem;
        // padding: 0.5rem 1.5rem;
        font-weight: normal;
        font-size: 20px !important;
      }
    }
    .download-links {
      a {
        display: block;
        color: $starstack-blue-main;
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 21px;
      }
    }
  }
  .claime-form {
    position: relative;
    .info-modal {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .claime-form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: normal;
        font-size: 30px;
        color: $white-color;
      }
      .status {
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        text-align: right;
        color: #7affff;
      }
    }
    .claime-form-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detail {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        color: $starstack-blue-main;
      }
      .remaining {
        font-weight: 500;
        font-size: 21px;
        text-align: right;
        color: $starstack-blue-main;
      }
    }
  }
  .form-control {
    border: 2px solid $starstack-blue-main;
    box-sizing: border-box;
    border-radius: 36.5px;
    background: transparent;
    padding: 0.5rem 1rem;
    color: $white-color;
  }
  &.restrict {
    position: relative;
    text-align: center;
    input,
    button {
      pointer-events: none;
    }

    .restricted-access {
      background: rgba(86, 78, 206, 0.58);
      opacity: 0.95;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(13px);
      border-radius: 31px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      img {
        border-radius: 31px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        h2 {
          font-weight: 500;
          font-size: 48px;
          line-height: 44px;
          /* or 92% */
          text-align: center;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: $white-color;
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */
          text-align: center;
          color: $white-color;
        }
      }
    }
  }
  @media screen and (max-width: 2560px) {
  }
  @media screen and (max-width: 1563px) {
  }
  @media screen and (max-width: 1440px) {
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
    .form-holder {
      width: 75%;
      .cta {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        button {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          font-size: 16px !important;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 561px) {
    padding: 1rem 1.5rem;
    .form-holder {
      width: 100%;
      .cta {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        button {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          font-size: 16px !important;
        }
      }
      .download-links {
        a {
          display: block;
          color: $starstack-blue-main;
          margin-bottom: 1rem;
          font-weight: 600;
          font-size: 21px;
        }
      }
    }
    &.restrict {
      padding: 0px;
      text-align: center;
      .restricted-access {
        img {
          height: 200px;
        }
        .info {
          .lock-icon {
            svg {
              width: 50px;
              height: 50px;
            }
          }
          h2 {
            font-size: 24px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
          p {
          }
        }
      }
    }
  }
}
