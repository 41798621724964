.overview {
  .top-section {
    .top-section-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .agent-tut-button {
        cursor: pointer;
        border: 1px solid #564ece;
        border-radius: 36.5px;
        font-size: 20px;
        color: #fff;
        padding: 1rem;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          padding: 0.5rem 1rem;
        }
      }
      .add-star-button-agent {
        padding: 10px 40px;
        height: 53px;
        width: 209px;
        @media screen and (max-width: 767px) {
          padding: 10px 20px;
        }
      }
      .top-section-item {
        display: flex;
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: linear-gradient(
            270.29deg,
            #564ece 1.02%,
            #3a32ad 102.93%,
            #3d34c9 102.94%
          );
          border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
          overflow: visible;
          background-color: transparent;
          height: 1px;
          border-radius: 10px;
        }
        button {
          min-width: max-content;
        }
        .active {
          background: $starstack-blue-main;
          color: white;
        }
        button {
          border: 0px;
          color: rgba(239, 233, 255, 0.6);
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          background: rgba(239, 233, 255, 0.03);
          border-radius: 26px;
          padding: 0.5rem 1.5rem;
          margin: 1rem 0.5rem;
        }
      }
      h2 {
        color: white;
        font-weight: 400;
        font-size: 30px;
        line-height: 42px;
        p {
          display: inline;
          color: $starstack-blue-main;
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}
.box-bl-link {
  background: #0b0c11;
  backdrop-filter: blur(93px);
  border-radius: 12px;
  p {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.05em;
  }
  .btn-access-tokens {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 9px;
    font-weight: 500;
    font-size: 20px;
    color: #564ece;
  }
}
.IntroductionNFTs-bg {
  background-image: url($aws-base-url + '/bg-nfts-in2.webp') !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background-size: 100% 100%;
  h3 {
    font-weight: 800 !important;
    font-size: 38px !important;
    letter-spacing: 0.035em;
    color: #ffffff;
  }
}
.agent-manager-earning-balance-container {
  .chart-bar {
    @media screen and (max-width: 767px) {
      padding: 1rem 0 !important;
    }
  }
}
.agent-manager-quicklinks-container {
  .row {
    .col-lg-6 {
      .quick-links {
        .btn-access-tokens {
          @media screen and (max-width: 767px) {
            font-size: 15px;
          }
        }
        .box-bl-link {
          p {
            @media screen and (max-width: 767px) {
              margin-bottom: 10px !important;
            }
          }
          @media screen and (max-width: 767px) {
            padding: 2rem !important;
          }
        }
      }
      .IntroductionNFTs-bg {
        h3 {
          @media screen and (max-width: 767px) {
            font-size: 25px !important;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 2rem !important;
        }
      }
    }
    @media screen and (max-width: 991px) {
      gap: 10px;
    }
  }
}
