.checkoutPage {
  padding: 30px;
  .back-link {
    margin: 0;
    padding-top: 20px;
    a {
      width: 30px;
      display: inherit;
      padding-bottom: 20px;
      svg {
        transition: all ease 0.4s;
        &:hover {
          margin-left: -2px;
          transition: all ease 0.4s;
        }
      }
    }
  }
  .liat-cart-slide {
    .list-cart-box {
      display: flex;
      gap: 50px;
      margin-bottom: 30px;
      .img-carrt {
        margin-right: 30px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
      .buck-main-card-wrapper {
        .buck-image-wrapper {
          cursor: default;
          .buck-main-image {
            transform: none;
          }
        }
      }
      .Bundles-card-wrapper {
        .Bundles-main-img {
          transform: none;
        }
        .onhover-bundle-card-wrapper {
          display: none;
        }
      }
    }
    .text-cart-list {
      flex-grow: 1;
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2;
      }
      h6 {
        font-weight: normal;
        line-height: 38px;
      }
      .cart-price {
        font-weight: normal;
        font-size: 36px;
        color: $starstack-blue-main;
        margin-left: 20px;
        @media screen and (max-width: 1230px) {
          font-size: 22px;
        }
        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }
      .quantity {
        font-weight: normal;
        font-size: 21px;
        align-items: center;
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
        .form-select {
          width: 81px;
          height: 34px;
          background-color: rgba(229, 229, 229, 0.13);
          border-radius: 22px;
          color: $white-color;
          border: none;
          background-position: right 1rem center;
          background-size: 14px 17px;
          padding: 0px 25px;
          &:focus {
            box-shadow: 0px 0px 0px 1px $black-color;
          }
          option {
            background: #18181d;
            color: $white-color;
            font-size: 14px;
          }
        }
      }
      .eth-text {
        font-weight: normal;
        font-size: 21px;
        color: rgba(255, 255, 255, 0.23);
      }
    }
  }
  .delete-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    @media screen and (max-width: 600px) {
      justify-content: center;
      margin-top: 20px;
    }
    .btn {
      border: 2px solid $starstack-blue-main;
      color: $starstack-blue-main;
      border-radius: 9px;
      &:hover {
        color: $white-color;
      }
    }
  }
  .summary-checkout-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .summary-checkout {
      width: 70%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      h4 {
        font-weight: 500;
        color: #646464;
      }
      .sumary-text {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 12px;
        @media screen and (max-width: 1230px) {
          font-size: 15px;
        }
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
      .need-eth {
        text-align: right;
        margin-top: 12px;
        font-size: 21px;
        @media screen and (max-width: 1230px) {
          font-size: 16px;
        }
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
        a {
          color: $starstack-blue-main;
        }
        .grab-link {
          cursor: pointer;
        }
        .complete-transaction {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          .btn {
            margin-left: auto;
            width: max-content;
            background: $starstack-blue-main;
            border-radius: 9px;
            &:hover {
              background: transparent;
              color: $starstack-blue-main;
            }
            @media screen and (max-width: 600px) {
              margin-right: auto;
              margin-left: 0;
            }
          }
          .noted-text {
            margin-top: 10px;
            margin-left: auto;
            text-align: left;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.29);
            width: 314px;

            @media screen and (max-width: 600px) {
              margin-right: auto;
              margin-left: 0;
            }
          }
        }
        .react-select-container {
          .react-select__control {
            min-width: 250px;
            .react-select__single-value {
              color: white;
            }
          }
        }
      }
    }
    .text-left {
      text-align: left;
    }
  }
}

.tab-active-top {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      270.29deg,
      #564ece 1.02%,
      #3a32ad 102.93%,
      #3d34c9 102.94%
    );
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    overflow: visible;
    background-color: transparent;
    height: 1px;
    border-radius: 10px;
  }
  ul {
    display: inline-flex;
    margin: auto;
    li {
      &.op-low {
        opacity: 0.2;
        span {
          background: $dark-color;
        }
      }
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
      margin-left: -100px;
    }
  }
}

.tab-active-top {
  ul {
    li {
      list-style: none;
      width: 142px;

      &::after {
        content: '';
        position: absolute;
        background-image: linear-gradient(
          to right,
          $vampire-gray-color,
          $black5-color
        );
        height: 3px;
        width: 108%;
        right: 0;
        left: 64px;
        top: 8px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      span {
        width: 20px;
        height: 20px;
        background: $white-color;
        display: inline-block;
        border-radius: 100%;
        border: 4px solid rgba(172, 170, 170, 0.56);
        position: relative;
        z-index: 1;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: $white-color;
      }
    }
  }
}

.credit-card-payment-modal {
  .modal-content {
    background: #fff !important;
    .d-flex svg path {
      fill: #18181d;
    }
  }

  #credit-card-payment {
    background: #fff;
    min-height: 80vh;
    height: max-content;
    width: 100%;
  }
}
