.overviews {
  .title-main {
    font-weight: 600;
    font-size: 30px;
    color: $black-color;
    line-height: 38px;
  }

  .Reasons-to-Purchase {
    background: $white-color;
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 25px;
  }

  img {
    max-width: 100%;
    border-radius: 6px;
  }

  iframe {
    width: 100%;
    min-height: 300px;
    border-radius: 6px;
    margin: 0;
  }
}
