.inft-b {
  background-image: url(../../../img/sNFT/bg-banner-graf.webp);

  .text-cha {
    .select-drop {
      select.form-select {
        width: 118px;
      }
    }

    h5 {
      font-size: 30px;
    }

    .line-toolip h5 {
      right: 6%;
      top: 48%;
      font-weight: 500;
      font-size: 30px;
    }
  }

  &.de-ch-top {
    .img-cha .img-bo-ch {
      background: linear-gradient(
        325.98deg,
        #e12aff 0.5%,
        $starstack-blue-main 95.25%
      );
    }
    &::after {
      background: none;
    }
  }
}

.box-bl-link {
  background: #0b0c11;
  backdrop-filter: blur(93px);
  border-radius: 12px;

  p {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.05em;
  }

  .btn-access-tokens {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 9px;
    font-weight: 500;
    font-size: 20px;
    color: $starstack-blue-main;
  }
}

.IntroductionNFTs-bg {
  background-image: url(../../../img/sNFT/bg-nfts-in.png);
  background-size: 100% 100%;

  h3 {
    font-weight: 800 !important;
    font-size: 38px !important;
    letter-spacing: 0.035em;
    color: $white-color;
  }
}

.WalletNFTs-bg {
  background-image: url(../../../img/Lightweightimages/item.webp);
  border-radius: 12px;
  background-size: cover;

  h3 {
    font-weight: 800 !important;
    font-size: 38px !important;
    letter-spacing: 0.035em;
    color: $white-color;
  }
}

.notFound-bg {
  background-image: url(../../../img/Lightweightimages/item.webp);
  border-radius: 12px;
  background-size: cover;

  h3 {
    font-weight: 600 !important;
    font-size: 38px !important;
    letter-spacing: 0.035em;
    color: $warning-1;
    text-align: center;
  }
}

.table-reso {
  font-weight: 500;
  font-size: 14px;

  .padd-clas {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.view-all {
  font-size: 18px;
  text-decoration-line: underline;
  color: $starstack-blue-main;
}

.box-mynft {
  background: #0b0c11;
  box-shadow: 0px 4px 70px rgb(14 13 30 / 86%);
  border-radius: 18px;
  width: max-content;

  .mynft-tex {
    h5 {
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      min-height: 50px;
    }

    .btn {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.26496px;
      text-decoration-line: underline;
      color: $white-color;
    }
  }

  .img-nft-my {
    aspect-ratio: 0.9866;
    img {
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

.box-bl-type {
  background: $starstack-blue-main;
  border-radius: 11px;
  padding: 2px 10px;
  margin-bottom: -12px;
  font-weight: normal;
  font-size: 18px;
}

.st-blance {
  padding: 0 15px 15px;
  font-weight: normal;
  font-size: 18px;
  line-height: 38px;
  color: $starstack-blue-main;
}

.INFTsInProgress-box {
  background: linear-gradient(
    114.88deg,
    #0b0227 8.44%,
    $starstack-blue-main 98.62%
  );
  border-radius: 12px;

  h3 {
    font-weight: 500;
    font-size: 20px;
  }

  h4 {
    font-weight: 500;
    font-size: 27px;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.26496px;
    opacity: 0.6;
  }

  .inft-inprogress-continue {
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    text-decoration-line: underline;
    color: $white-color;
    display: block;
    margin-top: -40px;
    cursor: pointer;
  }

  h6 {
    font-weight: 500;
    font-size: 20px;
    width: 100%;
    text-align: center;

    span {
      opacity: 0.6;
      font-weight: 400;
    }
  }

  .snft-inprogress-card {
    border: 4px solid rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    position: relative;
    .card-clim {
      background: linear-gradient(
        160deg,
        rgba(13, 11, 33, 0.54) 14.26%,
        rgba(86, 78, 206, 0.1242) 113.99%
      );
      border: 1px solid #564ece;
      backdrop-filter: blur(8px);
      border-radius: 10px;
      .img-Claimed {
        aspect-ratio: 0.9866;
      }
    }
    &::after {
      position: absolute;
      content: '';
      border-radius: 18px;
      top: -8px;
      bottom: -8px;
      left: -8px;
      right: -8px;
      border: 6px solid rgba(255, 255, 255, 0.02);
    }
  }
}

.empty-text {
  margin-top: 2rem;
  font-family: $starstack-font-main !important;
  text-align: center;
  color: $white-color;
  font-size: 24px;
  list-style: 32px;
  min-height: 280px;
}

.checkout-dark-card {
  background-color: #242037;
  box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.25);
}

.checkout-snft-title {
  font-weight: 500;
  color: #564ece;
  font-size: 12px;
  line-height: 12px;
}

.snft-checkout-steps {
  ul {
    li {
      list-style: none;
      width: 142px;

      &.op-low {
        span {
          background: $dark-color;
          border: 2px solid #564ece;
        }

        h4 {
          opacity: 0.2;
          color: $white-color;
        }

        &::after {
          opacity: 0.2;
          background-image: linear-gradient(
            90.31deg,
            #ffffff 6.36%,
            rgba(255, 255, 255, 0) 100.14%
          );
        }
      }

      &.active {
        span {
          border: 2px solid $starstack-blue-main;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fff;
          }
        }

        h4 {
          color: $white-color;
        }

        &::after {
          background-image: linear-gradient(
            90.31deg,
            #564ece 6.36%,
            rgba(86, 78, 206, 0.2) 100.14%
          );
        }
      }

      &::after {
        content: '';
        position: absolute;
        background-image: linear-gradient(90deg, #564ece 0%, #564ece 100%);
        height: 3px;
        width: 100%;
        right: 0;
        left: 64px;
        top: 8px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      span {
        width: 20px;
        height: 20px;
        background: $starstack-blue-main;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        z-index: 1;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: $starstack-blue-main;
      }
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    overflow-y: hidden;
    ul {
      padding: 0 12px;
    }
  }
}
