@media screen and (max-width: $xl) {
  .collections-list {
    .slick-slider {
      width: 96%;

      .collections-box {
        width: 190px;

        img {
          width: 121%;
        }
      }
    }
    .collection-list-box {
      width: 194px;

      .img-collection {
        svg {
          width: 100%;
          height: auto;
        }
      }

      .collection-text {
        h5 {
          font-size: 14px;
        }
      }
    }
  }

  .bundles-list {
    .collection-list-box {
      .img-svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .navbar .navbar-collapse .navbar-nav .nav-link {
    font-size: 12px;
    padding-left: 20px;
  }

  // mintguideline
  .ng-bg {
    .ng-main {
      display: flex;
      flex-direction: row !important;
      width: 90% !important;

      .ng-b1 {
        margin: 20px !important;
        padding: 20px !important;
        .ng-b1-h1 {
          font-size: 20px !important;
          font-weight: 400 !important;
          line-height: 50px !important;
        }
      }
      .ng-b2 {
        margin: 20px !important;
        padding: 20px !important;
        .ng-b2-h1 {
          font-size: 20px !important;
          font-weight: 400 !important;
          line-height: 50px !important;
        }

        .ng-b3 {
          display: flex;
          width: 100%;

          .ng-b2-h2 {
            font-size: 16px !important;
            line-height: 21px !important;
          }
        }

        .ng-b2-h3 {
          font-size: 16px !important;
          line-height: 21px !important;
        }

        .ng-b2-h4 {
          font-size: 16px !important;
          line-height: 21px !important;
        }
        .ng-b2-h5 {
          font-size: 16px;
          font-weight: 400;
          font-family: Poppins;
          line-height: 21px;
          color: #564ece;
          width: 80%;
        }
      }
      .ng-b4 {
        display: flex;
        gap: 20px;
      }
      .ng-b5 {
        display: flex;
        gap: 20px;
        flex-direction: row;
      }
    }
  }
}
