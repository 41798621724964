//MODAL
.modal-btn {
  cursor: pointer;
}

.modal-backdrop {
  &.show {
    opacity: 0.8;
  }
}

.text-w {
  color: $white-color;
}

// .detail-img {
//     position: relative;
//     svg {
//         width: 115%;
//         height: 100%;
//     }
// }
.modal {
  .modal-dialog {
    .modal-content {
      background: $cinder-black-color;
      box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
      border-radius: 31px;
      // border: 1px solid $bluelight-color;
      .modal-header {
        border-bottom: 0px solid #dee2e6;
        .modal-title {
          width: 100%;
          font-size: 14px;
          color: $white-color;
          button {
            display: inline-block;
          }
          h4 {
            margin: 0px;
          }
        }
      }
      .modal-body {
        p {
          &.text-w {
            color: $white-color;
          }
          color: $aluminium-gray-color;
          a {
            color: $starstack-blue-light;
          }
        }
        a {
          color: $white-color;
          text-decoration: none;
        }
        .address-box {
          border-radius: 31px;
          border: 2px solid $starstack-blue-light;
        }
      }
      .modal-footer {
      }
    }
  }
}

.tran {
  border-radius: 1rem;
  overflow: hidden;
  // border: 1px solid $starstack-blue-light;
  .address {
    background-color: $gray9-color;
    // border-bottom: 1px solid $starstack-blue-light;
    p {
      color: $white-color !important;
    }
  }
  .asset {
    background-color: $ship-gray-color;
    p {
      color: $white-color !important;
    }
  }
}

.moonpay-getcrypto-modal {
  .powered-by-section {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;

    svg {
      #MP_LOGO {
        path {
          fill: white;
        }
      }
    }
  }
}

.select-crypto-for-deposit-modal {
  .crypto-buttons {
    min-width: 90%;
    padding: 10px 40px;
  }
}

.credit-card-modal-phone-input {
  position: relative;
  .PhoneInputCountry {
    margin-left: 16px;
  }
  .PhoneInputInput {
    height: 57px;
    background: rgba(86, 78, 206, 0.2);
    border-radius: 28.5px;
    border: none;
    padding-right: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: white;
    margin-left: -52px;
    padding-left: 60px;

    &:focus {
      color: white;
      box-shadow: 0px 0px 0px 1px #585fcf;
    }

    &::placeholder {
      color: #454163;
    }

    &:hover,
    &:active,
    &:focus {
      background: rgba(86, 78, 206, 0.2);
      border: none;
      color: white;
    }
  }
}

.agentConfirmationModal {
  padding: 10px;
  color: white;

  .modal-content {
    border-radius: 10px !important;

    .modal-header {
      svg {
        path {
          fill: $aluminium-gray-color;
        }
      }
    }
  }

  &-title {
    font-size: 16px;
    color: #585fcf;
  }

  .body {
    display: flex;
    flex-direction: column;

    p {
      font-size: 14px;
    }

    .agent-icon {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
    }

    .buttons {
      width: 100%;
      margin-top: 30px;
      justify-content: space-evenly;
      display: flex;

      div {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
