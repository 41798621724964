.inft-builder-heading {
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    @media screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 33px;
      padding: 10px;
      sup {
        font-size: 12px;
        line-height: 17px;
        margin-top: -30px;
      }
    }
    sup {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }

  p {
    color: white;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.box-inftmintin {
  background: $cinder-black-color;
  box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
  border-radius: 25px;
  position: relative;
  padding: 22px;
  cursor: pointer;

  .img-inftss {
    background: $cinder-black-color;
    border-radius: 12px;
  }

  h3 {
    font-weight: 500;
    font-size: 21px;
    line-height: 38px;
  }

  a {
    color: $marguerite-blue-color;
    text-decoration: none;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.21);
    height: 66px;
  }

  .action-check {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $starstack-blue-main;

    .form-check-input {
      background-color: rgba(86, 78, 206, 0.15);
      border-radius: 100%;
      width: 28px;
      height: 28px;
      margin: 0;
      border: none;

      &:checked {
        background-color: rgb(255, 255, 255);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

        & + .after-bg {
          color: $white-color;

          &::after {
            content: '';
            background: linear-gradient(
              65.56deg,
              #893af2 12.91%,
              #393394 50.41%
            );
            border-radius: 25px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      }
    }

    button {
      background-color: #564ece;
      outline: none;

      &:hover {
        background-color: #fff;
        color: #564eca;
      }
    }
  }

  .inft-builder-card-button {
    background-color: $starstack-blue-main !important;

    p {
      color: $white-color !important;
      padding: 0px;
      margin: 0px;
      height: auto !important;
    }
  }

  .inftss-side-text {
    p {
      height: auto;
      color: $starstack-blue-main;
      margin: 0px;
    }
  }

  .inftss-text-box {
    p {
      height: auto;
    }
  }

  .inft-mint-step-text {
    color: #564ece;
  }

  &:hover {
    background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
    box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
    border-radius: 25px;

    p {
      color: rgba(255, 255, 255, 0.59);
    }

    .inftss-side-text {
      p {
        color: white;
      }
    }

    .inft-builder-card-button {
      background-color: $white-color !important;

      p {
        color: $starstack-blue-main !important;
      }
    }

    .action-check {
      color: $white-color;

      .form-check-input {
        background-color: rgb(255, 255, 255);

        &:checked {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }

      button {
        background-color: #fff;
        color: #564eca;
      }
    }

    a {
      color: $white-color;
      text-decoration: none;
    }

    .hover-img-open-none {
      display: none;
    }

    .hover-img-open {
      display: block;
    }

    .inft-mint-step-text {
      color: #fff;
    }
  }

  .hover-img-open {
    display: none;
  }
}

.selected-page-layout {
  background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
  box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
  border-radius: 25px;

  p {
    color: rgba(255, 255, 255, 0.59);
  }

  .inftss-side-text {
    p {
      color: white;
    }
  }

  .inft-builder-card-button {
    background-color: $white-color !important;

    p {
      color: $starstack-blue-main !important;
    }
  }

  .action-check {
    color: $white-color;

    .form-check-input {
      background-color: rgb(255, 255, 255);

      &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }
    }

    button {
      background-color: #fff;
      color: #564eca;
    }
  }

  a {
    color: $white-color;
    text-decoration: none;
  }

  .hover-img-open-none {
    display: none;
  }

  .hover-img-open {
    display: block;
  }

  .edit-current-layout,
  .inft-mint-step-text {
    color: #fff;
  }
}

.box-right-open {
  background: $cinder-black-color;
  border-radius: 20px 0px 0px 20px;
  width: 443px;
  position: absolute;
  right: 0;
  top: 45px;

  h3 {
    font-weight: 400 !important;
    font-size: 20px !important;
  }

  p {
    font-weight: 200;
    font-size: 13px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.29);
  }

  a {
    font-weight: 500;
    font-size: 20px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
  }

  .img-opens {
    min-width: 90px;
  }
}

.shadowbar {
  height: 100%;
  overflow-y: auto;

  &::after {
    background-image: url($aws-base-url + '/common/Shadow-shape-video.svg');
    content: '';
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &.left-sh {
    &::after {
      background-image: url($aws-base-url + '/common/Shadow-shape-video.svg');
      opacity: 0.5;
    }
  }
}

.form-general {
  background: $cinder-black-color;
  box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
  border-radius: 31.7949px;
  padding-right: 100px;

  .form-control {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
    border: none;
    padding: 14px 26px;
    font-weight: 500;
    font-size: 16px;
    color: $white-color;

    ::placeholder {
      color: rgba(255, 255, 255, 0.18);
    }

    &:focus {
      color: $white-color;
      box-shadow: 0px 0px 0px 1px $marguerite-blue-color;
    }
  }

  .form-label {
    font-weight: 500;
    font-size: 18px;
    color: $white-color;
    margin-bottom: 13px;

    span {
      font-weight: 300;
      color: rgba(255, 255, 255, 0.18);
    }

    b {
      color: #564eca;
    }
  }

  .form-select {
    background-color: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
    border: none;
    padding: 14px 26px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.18);
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.29);
  }

  .btn.btn-primary {
    background: rgba(86, 78, 206, 0.25);
    border-radius: 9px;
    font-weight: 500;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.3);
    padding: 16px 40px;
    border-color: rgba(86, 78, 206, 0.25);
  }

  .short-statement {
    textarea.form-control {
      height: 104px;
    }
  }

  textarea.form-control {
    height: 215px;
    border-radius: 24px;
  }

  .characters-total {
    position: absolute;
    bottom: 8px;
    right: 22px;
    font-weight: 500;
    font-size: 18px;
    line-height: 42px;
    letter-spacing: -0.05em;
    color: $white-color;

    span {
      font-size: 20px;
    }
  }

  button.btn.btn-primary.donesave {
    background-color: $starstack-blue-light;
    border: 1.5px solid $starstack-blue-light;
    color: $white-color;

    &:hover {
      background-color: transparent;
    }
  }

  .react-select-container .react-select__control {
    background: rgba(86, 78, 206, 0.11) !important;
  }

  .input-min-height {
    min-height: 57px;
  }

  .snft-marketplace-upload-img {
    width: 120px;
    aspect-ratio: 1.23;
    height: auto;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
  }
}

.card-mit {
  .card-clim {
    padding: 22px;
    .text-Claimed {
      h5 {
        font-weight: normal;
        font-size: 20px;
        line-height: 21px;
        color: #ffffff;
      }
      p {
        font-size: 15px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.28);
      }
      h4 {
        font-size: 24.3508px;
        line-height: 35px;
      }
      label {
        font-size: 12.5px;
        line-height: 22px;
      }
      h4.fs-5 {
        line-height: 22px;
      }
      .d-flex {
        flex-wrap: nowrap;
      }
    }
  }
}

.snft-marketplace-thumbnail {
  .sNFT-card {
    width: 100%;
    height: auto;
    cursor: unset;

    .card-image {
      width: 100%;
      aspect-ratio: 1.23;
      height: auto;
    }

    .sNFT-buy-now {
      display: none;
    }

    &:hover {
      box-shadow: none;

      .card-image {
        transform: none;
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .shadowbar {
    height: auto;
  }
}

.bottom-b-tabs {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-top: 1px solid hsl(0deg 0% 100% / 5%);

  ul {
    list-style: none;
  }
}

.list-tab-mint {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: $white-color;
  text-decoration: none;

  &:hover {
    color: $starstack-blue-main;
  }

  .form-check-input {
    background-color: #161734;
    width: 26px;
    height: 26px;
    border-radius: 100% !important;
    margin-top: 0;
    border: none;

    &:focus {
      box-shadow: none;
    }

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}

@media screen and (max-width: 700px) {
  .list-tab-mint {
    margin-bottom: 10px;
  }

  .box-right-open {
    display: none;
  }
}

.done-ch {
  color: $starstack-blue-light;
}

.fan-royalties {
  .upload-form-box {
    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 42px;
    }

    .value-prog {
      font-weight: normal;
      font-size: 16px;

      span {
        font-weight: normal;
        font-size: 16px;
        letter-spacing: -0.05em;
        color: $starstack-blue-main;
        width: 66px;
        height: 31px;
        background: rgb(255 255 255 / 6%);
        border-radius: 12px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    ul {
      li {
        list-style: none;

        label {
          font-weight: 500;
          font-size: 21px;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: rgba(255, 255, 255, 0.78);
          opacity: 0.28;
          width: 100%;
        }

        span {
          font-weight: normal;
          font-size: 18px;
          font-feature-settings: 'pnum' on, 'lnum' on;
        }
      }
    }

    .prog-bars {
      .progress {
        background: rgba(86, 78, 206, 0.11);
        border-radius: 32.7648px;
        position: relative;
        overflow: unset;

        .progress-bar {
          background: linear-gradient(
            90.27deg,
            $starstack-blue-main 30.45%,
            #0056ff 93.18%
          );
          border-radius: 32.7648px;

          &::after {
            content: '';
            position: absolute;
            width: 31px;
            height: 31px;
            background: linear-gradient(
              180deg,
              $starstack-blue-main 0%,
              #0056ff 99.99%,
              rgba(196, 196, 196, 0) 100%
            );
            border: 4px solid $white-color;
            box-sizing: border-box;
            border-radius: 100%;
            left: 53%;
            top: -7px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .left-side-sections {
    height: calc(100vh - 146px);
  }

  .box-reight-section {
    height: calc(100vh - 146px);
    background-color: rgba(0, 0, 0, 0.62);
    position: relative;

    &.bg-eff {
      &::after {
        background-image: url($aws-base-url + '/StarProfile/reight-sha.webp');
        content: '';
        position: absolute;
        bottom: 0;
        left: -65px;
        right: 0;
        height: 662px;
        opacity: 0.4;
      }
    }

    p {
      line-height: 27px;

      span {
        color: $starstack-blue-main;
      }
    }

    .btn-primary-tu {
      background: $starstack-blue-main;
      border-radius: 36.5px;
      color: $white-color;
      font-weight: normal;
      font-size: 20px;
      display: inline-flex;
      align-items: center;

      span {
        width: 64px;
        height: 61px;
        background: rgba(255, 255, 255, 0.09);
        border-radius: 0px 36.5px 36.5px 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background: #2d287e40;
        border: 1px solid $starstack-blue-main;
      }
    }
  }
}

.box-reight-section {
  p {
    line-height: 27px;

    span {
      color: $starstack-blue-main;
    }
  }
}

.INFTDetailsSummary {
  background: $cinder-black-color;
  box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
  border-radius: 31.7949px;
  margin-left: -172px;
  margin-top: 75px;
  padding-left: 180px;

  .list-box-det {
    background: rgba(4, 5, 10, 0.58);
    border-radius: 34px;
    padding: 30px 40px;
    width: 84%;
    margin-bottom: 24px;

    label {
      font-weight: 500;
      font-size: 18px;
      color: $starstack-blue-main;
      width: 100%;
    }

    span {
      font-weight: 500;
      font-size: 21px;
      width: 100%;
    }

    .text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 20px;
    }
  }

  .edit-action {
    font-weight: 500;
    font-size: 18px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
    cursor: pointer;
    width: 88px;
  }

  .btn.btn-transparent {
    color: $starstack-blue-main;
  }
}

.card-clims-pd {
  padding: 22px !important;
}

.ip-royalties textarea.form-control {
  background: rgba(86, 78, 206, 0.11);
  border-radius: 32.7648px;
  border: none;
  height: 275px;
  width: 100%;
}

.date-forments {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        background-color: #13152d;
        border: none;
        color: $white-color;
        border-radius: 45px;
        padding: 16px 34px 16px 20px;
        outline: none;
        // background-image: url(../../../img/svg/Assets/Calender.svg);
        background-position-y: center;
        background-repeat: no-repeat;
        background-position-x: 90%;
      }
    }
  }

  .react-datepicker {
    background-color: #131420;
    color: $white-color;
    border: 1px solid #131420;
    font-size: 12px;
    border-radius: 7px;
    padding: 6px;
  }

  .react-datepicker__header {
    background-color: #131420e8;
    border: 1px solid #131420e8;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $white-color;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $white-color;
    width: 1.8rem;
    line-height: 1.6rem;
    margin: 0;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0;
    background-color: #564ece;
    color: $white-color;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0;
    background-color: rgb(145 145 145 / 20%);
    color: $white-color;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: #564ece;
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: #564ece;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    border: 1px solid #dddddd3d;
    border-radius: 5px;
    height: 28px;
    width: 28px;
    top: 10px;
  }

  .react-datepicker__navigation-icon {
    top: 1px;
    font-size: 15px;
  }

  .react-datepicker__navigation-icon--previous {
    right: 0px;
  }

  .react-datepicker__navigation-icon--next {
    left: 0px;
  }

  .react-datepicker__navigation--previous {
    left: 16px;
  }

  .react-datepicker__navigation--next {
    right: 16px;
  }

  .start-d {
    width: 219px;
    margin-right: 60px;

    input {
      width: 100%;
    }
  }

  .end-d {
    width: 275px;

    input {
      width: 100%;
    }
  }
}

.availability-Information {
  p {
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.29);
  }
}

.choose-heading {
  h1 {
    font-weight: normal;
    font-size: 30px;

    small {
      font-size: 10px;
      letter-spacing: 2px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    width: 530px;
    margin: auto;
  }

  .btn.btn-primary-tu {
    background: $starstack-blue-main;
    border-radius: 36.5px;
    color: $white-color;
    font-weight: normal;
    font-size: 20px;
    display: inline-flex;
    align-items: center;

    span {
      width: 64px;
      height: 61px;
      background: rgba(255, 255, 255, 0.09);
      border-radius: 0px 36.5px 36.5px 0px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  a {
    color: $starstack-blue-main;
    text-decoration: none;
    font-size: 18px;
  }
}

.img-inftssss {
  border-radius: 18px;
  overflow: hidden;
}

.layout-se {
  .box-inftmintin p {
    height: auto;
  }
}

.card-col {
  padding: 8px;
  width: 20%;
}

.save-btn {
  color: rgb(86 78 206 / 36%);
  font-size: 17px;
}

.action-acc {
  a {
    color: $starstack-blue-main;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
  }

  .btn.btn-outline-primary {
    border: 1.5px solid $starstack-blue-light;
    color: $starstack-blue-light;
    font-size: 17px;
    width: 240px;

    &:hover {
      color: $white-color;
    }
  }
}

.Create-token-text {
  a {
    font-weight: normal;
    font-size: 25px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
  }

  h5 {
    font-weight: normal;
    font-size: 27px;
    line-height: 38px;
  }
}

.op-6 {
  opacity: 0.6;
}

.acc-tokens-edit {
  .heading-over-vi {
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.66);
    }
  }

  .headeing-acc {
    background: rgba(86, 78, 206, 0.08);
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
    border-radius: 25px 25px 0px 0px;
    color: $white-color;

    h4 {
      font-weight: 500;
      font-size: 18px;
    }

    .delete-d {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.05em;
      color: $purply-blue-color;
    }
  }

  .upload-form-box {
    textarea.form-control {
      height: 116px;
      width: 100%;
    }
  }

  .action-in {
    input {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      text-decoration-line: underline;
      color: $starstack-blue-main;
    }
  }

  .acc-token-lis {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 18px;

    h5 {
      font-weight: normal;
      font-size: 18px;
      color: $white-color;
    }

    h6 {
      font-weight: 500 !important;
      font-size: 12px !important;
      color: rgba(255, 255, 255, 0.88) !important;
    }

    .img-fluid {
      aspect-ratio: 0.75;
      object-fit: cover;
      max-width: 90px;
    }
  }

  .rare-icon {
    background: $starstack-blue-main;
    border-radius: 14.5778px;
    font-size: 9px;
    color: $white-color;
    width: 53px;
    text-align: center;
    font-weight: 300;
    padding: 3px 0px;
    margin-top: -10px !important;
    position: relative;
    margin: auto;

    svg {
      width: 14px;
      height: auto;
    }
  }

  .add-remove {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: $starstack-blue-main;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .inft-offer-stake-input input,
  .offer-detail-btn {
    pointer-events: none;
  }

  .save-changes-popup {
    position: relative;
    margin-left: auto;
    z-index: 544;
    background: $starstack-blue-main;
    box-shadow: 0px 4px 45px -1px rgb(86 78 206 / 36%);
    border-radius: 9px;
    width: max-content;
    height: max-content;
    color: white;
    padding: 5px 15px;
    &::after {
      transform: rotate(180deg);
      z-index: 5;
      content: '';
      border-left: 12px solid rgba(255, 255, 255, 0);
      border-top: 20px solid rgba(255, 255, 255, 0);
      border-bottom: 20px solid $starstack-blue-main;
      border-right: 12px solid rgba(255, 255, 255, 0);
      position: absolute;
      left: 10px;
      top: 32px;
    }
  }
}

.box-text-over {
  box-shadow: 0px 4px 26px rgb(0 0 0 / 7%);
  border-radius: 21.1353px;
  background: $white-color;

  &.update-blog-texteditor-wrapper {
    border-radius: 26px;
  }

  h3 {
    font-weight: 600 !important;
    font-size: 30px !important;
    color: $black-color !important;
  }

  ul {
    li {
      font-weight: normal;
      font-size: 21px;
      line-height: 34px;
    }
  }

  h1,
  h2 {
    color: rgba(0, 0, 0, 0.88);
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.58);
  }

  .ls-im {
    list-style: none;
  }
  .ql-toolbar {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .ql-formats.d-none {
    display: inline-block !important;
  }
}

.right-shape-im {
  .raunds {
    border-radius: 42px;
  }
}

.currently-img {
  background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
  box-shadow: 3.44286px 10.9024px 35.5762px -4.59048px rgb(0 0 0 / 13%);
  border-radius: 12px;
  padding: 18px;
  width: 260px;

  h5 {
    font-weight: 500;
    font-size: 16.29px;
    color: $white-color;
  }
}

.change-la {
  font-size: 12px;
  padding-right: 20px;
}

/**/
.fixed-topbar {
  background: rgb(8 11 19) !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  top: 0;
}

.m-top {
  margin-top: 146px;
}

button.btn.btn-add-social:hover {
  color: $white-color;
}

.fix-height-600 {
  height: 630px;
}

.fix-height-400 {
  height: 430px;
}

.access-to-select {
  .view-claim {
    flex-direction: row-reverse;
  }
  .form-check-input {
    background-color: rgba(86, 78, 206, 0.15);
    border-radius: 100%;
    width: 28px;
    height: 28px;
    margin: 0;
    border: none;

    &:checked {
      background-color: rgb(255, 255, 255);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}

.inft-header-section-img {
  background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
  box-shadow: 3.44286px 10.9024px 35.5762px -4.59048px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  padding: 0.8rem;
}

.inft-custom-control {
  position: relative;
  width: 76px;
  height: 26px;

  .inft-custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;

    &:checked ~ .inft-custom-control-label::before {
      color: $white-color;
      background-color: $starstack-blue-main;
    }
  }

  .inft-custom-control-label {
    cursor: pointer;

    &::before {
      position: absolute;
      top: 8px;
      left: 5px;
      width: 46px;
      height: 16px;
      content: '';
      background-color: rgba(86, 78, 206, 0.2);
      border-radius: 40px;
    }

    &::after {
      position: absolute;
      top: 4px;
      left: 0;
      width: 23px;
      height: 23px;
      content: '';
      background: no-repeat 50% / 50% 50%;
      background-color: $starstack-blue-main;
      border-radius: 100%;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }
}

.inft-custom-switch
  .inft-custom-control-input
  ~ .inft-custom-control-label::after {
  background-color: $white-color;
}

.inft-custom-switch
  .inft-custom-control-input:checked
  ~ .inft-custom-control-label::after {
  background-color: $starstack-blue-main;
  border: 1.5px solid $white-color;
  transform: translateX(2.1rem);
}

.available-info-borderline {
  border: 1px solid rgba(86, 78, 206, 0.33);
}

.inft-offer-stake-card {
  box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}

.inft-offer-stake-input input {
  border-radius: 28px;
  background: rgba(86, 78, 206, 0.2);
}

.inft-offer-card {
  background: #16122b;
  box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  overflow: hidden;

  .offer-img {
    width: 100%;
    object-fit: cover;
  }

  .offer-img-bg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    background: linear-gradient(
      180deg,
      rgba(54, 54, 54, 0) 0%,
      rgba(0, 0, 0, 0.65) 42%
    );
  }

  .offer-price {
    position: absolute;
    left: 30px;
    bottom: 25px;
    padding: 10px 16px;
    color: #000;
    border-radius: 12px;
    font-weight: 700;
    font-size: 18px;
    z-index: 1;
    background: linear-gradient(
      255.96deg,
      rgba(255, 187, 0, 0.6) 0%,
      #ffbb00 100%
    );
  }

  .offer-arrow-up {
    position: absolute;
    top: -5px;
    right: 5px;
    transform: rotate(180deg);
    cursor: pointer;

    svg {
      height: 38px;
      width: 38px;
    }
  }

  .offer-detail-card {
    background: rgba(86, 78, 206, 0.2);
    border: 1.15517px solid rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    text-align: center;
    padding: 12px;
  }

  .custom-tab-container {
    min-height: 30px !important;
    max-height: max-content !important;
    width: max-content !important;
  }

  .custom-tab-indicator {
    background-color: #522896;
    background: linear-gradient(
      90deg,
      #522896 0%,
      #4b05be 48.96%,
      #8c31ff 100%
    );
    border-radius: 6px;
  }

  .custom-tab-wrapper {
    min-height: 30px !important;
    max-height: max-content !important;
    min-width: 60px !important;
    max-width: max-content !important;
    text-transform: capitalize !important;
    margin-right: 20px !important;
    padding: 4px;
  }

  .offer-arrow-down {
    position: absolute;
    bottom: -2px;
    left: 0;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 12px;
    width: 100%;
    opacity: 0.9;
    background: radial-gradient(50% 50% at 50% 50%, #2f294e 0%, #16122a 100%);
  }

  .offer-detail-btn {
    width: 100%;
    border-color: rgba(86, 78, 206, 0.53);
    background: rgba(86, 78, 206, 0.53);
    backdrop-filter: blur(5.28px);
    -webkit-backdrop-filter: blur(5.28px);
    border-radius: 22px;
    margin-bottom: 20px;
  }

  .offer-access-nft-img {
    aspect-ratio: 0.75;
    object-fit: cover;
    max-width: 80px;
  }

  a {
    color: #564ece;
  }

  .offer-price-collapsed {
    display: inline;
    padding: 6px 16px;
    border-radius: 12px;
    margin-right: 12px;
    background: linear-gradient(
      255.96deg,
      rgba(255, 187, 0, 0.6) 0%,
      #ffbb00 100%
    );
  }

  .offer-arrow-collapsed {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;

    svg {
      height: 38px;
      width: 38px;
    }
  }

  .offer-checkout-btn {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .offer-sold-out {
    color: $black-color;
    background: rgba(252, 24, 133, 0.5);
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 18px;
    font-weight: 700;
    z-index: 1;
    position: relative;
  }

  .offer-soldout-chip {
    display: inline;
    padding: 6px 16px;
    border-radius: 12px;
    margin-right: 12px;
    color: $black-color;
    font-weight: 500;
    background: linear-gradient(
      255.96deg,
      rgba(252, 24, 133, 0.6) 0%,
      #fc1885 100%
    );
  }

  .offer-soldout-text {
    color: #fc1885;
    font-weight: 500;
  }

  .offer-soldout-btn {
    width: 100%;
    color: #fc1885;
    border: 1px solid #fc1885;
    border-radius: 22px;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px 0;
    font-size: 22px;
    font-weight: 600;
    cursor: not-allowed;
  }
}

.offer-access-nft-type {
  display: flex;
  align-items: center;
  font-size: 1rem;
  opacity: 0.5;

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.8rem;
  }
}

.inft-detail-view {
  width: 100%;

  label {
    width: 100%;
    color: $starstack-blue-main;
    font-weight: 500;
    font-size: 0.9rem;
  }

  span {
    color: #fff;
    text-transform: capitalize;
  }
}

.offer-access-nft-rarity {
  color: #fc1885;
  display: flex;
  align-items: center;
  font-size: 1rem;

  svg {
    height: 0.8rem;
    width: 0.8rem;
    margin-right: 0.8rem;
  }

  svg,
  path {
    fill: #fc1885;
    stroke: #fc1885;
  }
}

.snft-checkbox-round {
  .form-check-input {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: linear-gradient(
      134.22deg,
      rgba(86, 78, 206, 0.36) 9.27%,
      rgba(0, 148, 255, 0.36) 142.83%
    );

    &:checked {
      background: linear-gradient(
        134.22deg,
        rgba(86, 78, 206, 0.91) 9.27%,
        rgba(0, 148, 255, 0.91) 142.83%
      );

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -3px;
        bottom: 0;
        transform: rotate(45deg);
        height: 16px;
        width: 8px;
        border-bottom: 3px solid $white-color;
        border-right: 3px solid $white-color;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .mob-res {
    padding: 0px 30px;
  }
}

@media screen and (max-width: 991px) {
  .card-col {
    padding: 8px;
    width: 50%;
  }

  .m-top {
    margin-top: 250px;
  }

  .main-margin {
    padding: 0px 15px;
  }
}
