.ci-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .ci-main-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    margin-top: 100px;
    width: 50%;

    .ci-b1 {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 70%;

      .ci-h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        color: #ffffffa8;
      }
      .ci-sb1 {
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
        margin: 20px 10px;

        &:hover {
          cursor: pointer;
        }

        .sb-h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }

  .ci-b2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 50%;
  }
}
