.collection-creation {
  .blurr-bg2 {
    background-position: left center;

    &::before {
      content: '';
      position: absolute;
      opacity: 0.8;
      filter: blur(200px);
      width: 200px;
      height: 600px;
      top: 30%;
      left: 15%;
      position: absolute;
      z-index: -1;
      background-color: #564ece;
      @media screen and (max-width: 991px) {
        left: 10%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      opacity: 0.8;
      filter: blur(200px);
      width: 200px;
      height: 600px;
      top: 30%;
      right: 50%;
      position: absolute;
      z-index: -1;
      background-color: #ae31a2;
      @media screen and (max-width: 991px) {
        right: 5%;
      }
    }
  }
}

.form-group {
  .thumbImg {
    display: flex;
    align-content: center;

    .isImg {
      display: flex;
      align-content: center;
      align-items: center;
      color: $white-color;

      div {
        margin: 1rem;
      }

      img {
        width: 60px;
        border-radius: 4px;
        &.buck-image {
          aspect-ratio: 0.5;
        }
        &.collection-image {
          aspect-ratio: 0.48;
        }
        &.bundle-image {
          aspect-ratio: 0.6;
        }
      }
    }
  }
  .rarity-selection {
    .react-select__single-value {
      svg {
        height: 22px;
        width: auto !important;
      }
      .buck-rarity-option,
      .buck-rarity-disabled-option {
        display: none;
      }
    }
    .react-select__option {
      position: relative;
      &:not(.react-select__option--is-disabled) {
        color: $white-color !important;
        svg {
          path {
            fill: $white-color !important;
            stroke: $white-color !important;
          }
        }
        &.react-select__option--is-selected,
        &.react-select__option--is-focused,
        &:hover {
          background: rgba(86, 78, 206, 0.1) !important;
        }
      }
      .buck-rarity-disabled-option {
        display: none;
        background: #131313;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        position: absolute;
        left: 5%;
        top: -28px;
        right: 5%;
        padding: 4px 8px;
        opacity: 1 !important;
      }
      &.react-select__option--is-disabled {
        cursor: not-allowed;
        opacity: 0.4;
        color: $starstack-blue-main;
        .buck-rarity-option.hide-details {
          display: none;
        }
        &:hover {
          .buck-rarity-disabled-option {
            display: block;
          }
        }
        svg {
          path {
            fill: $starstack-blue-main;
            stroke: $starstack-blue-main;
          }
        }
      }
      svg {
        height: 22px;
        width: auto !important;
      }
    }
  }
}

.collection-uploaded-img {
  img {
    border-radius: 15px;
  }
}

.buck-collecton-placehlder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: 100%;
  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }

  > div {
    position: relative;
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    img {
      width: 317px;
      height: 636px;
    }

    &.isImg {
      &::after {
        background-image: url('../../../img/Lightweightimages/open-card-bg.png');
      }
    }

    &::after {
      content: '';
      width: 50px;
      height: 100%;
      background-image: url('../../../img/placeholder-bg.png');
      background-position: right bottom;
      background-repeat: no-repeat;
      /* margin-right: 0rem; */
      z-index: inherit;
      // background-color: azure;
      display: block;
      right: -49px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 3;
      background-size: 100% 100%;
    }
  }

  &.bucks {
    > div {
      &.isImg {
        position: relative;
        &::after {
          background-image: none;
        }

        .buck-video {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          aspect-ratio: 0.5;
          max-height: 100%;
          width: 100%;
          video {
            object-fit: cover;
          }
        }
        .audio-icon {
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
          z-index: 2;
          border-radius: 36px;
          background: #564ece;
          min-width: 148px;
          padding: 4px 10px;
          color: #fff;
          font-size: 13px;
          font-weight: 700;
          user-select: none;
          svg {
            margin-right: 4px;
            width: 20px;
            height: 20px;
            @media screen and (max-width: 765px) {
              height: 14px;
              width: 14px;
            }
          }
          &:hover {
            scale: 1.05;
          }
        }
      }

      &::after {
        content: '';
        width: 0px;
        height: 100%;
        background-image: none;
        background-position: right bottom;
        background-repeat: no-repeat;
        /* margin-right: 0rem; */
        z-index: inherit;
        // background-color: azure;
        display: block;
        right: -50px;
        top: 0;
        bottom: 0;
        position: absolute;
        z-index: 3;
        background-size: 100% 100%;
      }
    }
  }

  &.bunlde {
    div {
      max-height: 767px;
      display: flex;
      margin: auto;
    }
    img {
      width: 350px;
      max-height: 100%;
      aspect-ratio: 0.6;
      border-radius: 7px;
      object-fit: cover;
    }
  }

  .buck-img {
    &::after {
      background-image: url('../../../img/Lightweightimages/open-card-bg.png');
    }
  }
}

.edit-field-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;

  .field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    background: $dark-color;
    border-radius: 34px;
    justify-content: space-between;

    div {
      flex: 1;
    }

    .lable {
      font-weight: 500;
      font-size: 18px;
      color: $starstack-blue-main;
    }

    .value {
      font-weight: 500;
      font-size: 21px;
      color: $white-color;
      white-space: pre-wrap;
    }
  }

  .edit-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: $starstack-blue-main;

    a {
      margin: 0px 5px;
    }
  }
}

//-----------NFTbucks  css ---------------//
.review-collection {
  .detail-img {
    margin-left: 50px;
    margin-right: -50px;
    z-index: 1;
    @media screen and (max-width: 991px) {
      width: 100%;
      display: flex;
      gap: 45px;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      .buck-collecton-placehlder {
        > div {
          height: 100%;
        }
      }
    }

    img {
      border-radius: 12px;
      overflow: hidden;
      width: 350px;
      height: 100%;
    }

    svg {
      width: 30px;
    }

    &.bucks {
      .buck-collecton-placehlder {
        img {
          width: 300px;
          border-radius: 17px;
          height: auto;
          aspect-ratio: 0.5;
        }
      }
    }
  }

  .text-detail {
    background: $cinder-black-color;
    border-radius: 24px;
    padding: 40px 50px 40px 140px;
    margin-top: 100px;
    @media screen and (max-width: 991px) {
      padding: 50px;
      margin-top: 40px;
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      color: $aluminium-gray-color;
    }

    ul li {
      list-style: none;
    }

    h5 {
      font-weight: normal;
      font-size: 21px;
      color: $aluminium-gray-color;
    }

    .price-detail {
      font-weight: normal;
      font-size: 21px;
      color: $starstack-blue-main;
    }

    .auth-detail {
      font-weight: 500;
      font-size: 16px;
      color: $aluminium-gray-color;
    }

    h1 {
      font-weight: normal;
      font-size: 32px;
      color: $white-color;
    }

    .view-bundle-btn {
      background: $gray9-color;
      border-radius: 45px;
      font-weight: 500;
      font-size: 16px;
      color: $gray10-color;
      border: none;
      padding: 13px 30px;
      text-decoration: none;

      &:hover {
        border: none;
        background: $starstack-blue-light;
        color: $white-color;
      }
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: $aluminium-gray-color;
    }

    .cta {
      .buck-typen-date {
        display: flex;

        .type {
          font-size: 16px;
          color: $starstack-blue-main;
        }

        .date {
          font-weight: 500;
          font-size: 16px;
          color: $gray10-color;
        }
      }
    }
  }

  .standard-bucks {
    background: $cinder-black-color;
    border-radius: 24px;

    h2 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;

      span {
        color: $starstack-blue-main;
      }
    }

    .slick-slider {
      .slick-track {
        margin-left: 0;
        float: left;
      }

      .slick-dots {
        right: -40px;
        top: -60px;
      }

      .collections-box {
        width: 192.91px;
        overflow: hidden;

        .img-hover-click {
          background: linear-gradient(
            162.24deg,
            rgba(157, 153, 208, 0.82) 3.31%,
            rgba(64, 51, 255, 0.82) 97.08%
          );
          opacity: 0;
          transition: 0.2s;

          a {
            text-decoration: none;

            p {
              font-weight: 500;
              font-size: 16px;
              color: $white-color;
            }
          }
        }

        &:hover {
          .img-hover-click {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 2560px) {
}

@media screen and (max-width: 1563px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1024px) {
  .buck-collecton-placehlder {
    > div {
      img {
        min-width: 250px;
        border-radius: 17px;
      }
    }
  }

  .review-collection {
    .detail-img {
      img {
        // width: 250px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .buck-collecton-placehlder {
    > div {
      img {
        min-width: 80%;
      }
    }
  }

  .review-collection {
    .detail-img {
      img {
        width: 100%;
      }
    }

    .text-detail {
      padding: 2rem;
      margin-top: 25px;

      .edit-field-box {
        position: relative;

        .field {
          padding: 2.5rem !important;
          flex-direction: column;

          > div {
            margin-bottom: 2rem;
          }
        }

        .edit-btn {
          padding-top: 2.5rem !important;
          padding-right: 2.5rem !important;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 561px) {
  .buck-collecton-placehlder {
    > div {
      width: 80%;
      margin-top: 3rem;

      img {
        // width: 80%;
        min-width: auto;
      }
    }

    &.bucks {
      > div {
        width: 80%;
        margin-top: 3rem;

        img {
          // width: 80%;
          min-width: auto;
        }
      }
    }
  }

  .review-collection {
    .text-detail {
      padding: 1.5rem;
      margin-top: 25px;

      .edit-field-box {
        position: relative;

        .field {
          padding: 2rem !important;
          flex-direction: column;
        }

        .edit-btn {
          padding-top: 2rem !important;
          padding-right: 2rem !important;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
.how-rarities-work-modal {
  .modal-dialog {
    max-width: 900px;
    .modal-content {
      overflow: hidden;
      .modal-header {
        background: rgba(255, 255, 255, 0.04);
        color: white;
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
        padding: 1.5rem 4rem;
      }
      .modal-body {
        padding: 1.5rem 4rem;
        div {
          .heading-title {
            color: #564ece;
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 4px;
          }
          li,
          p {
            color: $white-color;
            font-size: 15px;
          }
          .bottom-note {
            font-style: italic;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: rgba(86, 78, 206, 0.92);
          }
        }
      }
    }
  }
}
.buck-collection-rarity {
  float: right;
  svg {
    height: 24px;
    width: auto !important;
    margin-left: 0.8rem;
  }
  .common path {
    fill: $starstack-blue-main;
    stroke: $starstack-blue-main;
  }
  &.rare .rare path {
    fill: $starstack-blue-main;
    stroke: $starstack-blue-main;
  }
  &.collectable .collectable path {
    fill: $starstack-blue-main;
    stroke: $starstack-blue-main;
  }
  &.ultrarare .ultrarare path {
    fill: $starstack-blue-main;
    stroke: $starstack-blue-main;
  }
  &.legendary .legendary path {
    fill: $starstack-blue-main;
    stroke: $starstack-blue-main;
  }
}
.adbuck-selection {
  span.fw-bold {
    color: $white-color;
    font-weight: 500 !important;
  }
  p {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.29);
  }
}
.form-check-input-adbuck {
  input {
    height: 22px;
    width: 22px;
    border: 2px solid #564ece;
    background-color: transparent !important;
    border-radius: 0;
    &:checked {
      border: 2px solid #564ece;
    }
  }
}
