.reward-boxs {
  .update-na {
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: $starstack-blue-main;
  }

  &.details .detailSection .accordionBlk.accordion .accordion-item {
    .accordion-button {
      font-weight: 500;
      font-size: 48px;
      line-height: 38px;
      color: $black-color;
    }

    ul {
      li {
        &.d-revert {
          display: revert;
          margin-left: 18px;
        }
      }
    }
  }

  h4 {
    font-weight: 600;
    font-size: 30px;
    color: $black-color;
  }

  h6 {
    font-size: 20px;
    font-weight: 600;
    color: $black-color;
  }
}
