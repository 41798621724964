.hub-modal {
    &.modal {
        .modal-dialog {
            .modal-content {
                background: #16122A;
                border: 0px;
                .modal-body {
                    padding: 2rem;
                    h2 {
                        svg {
                            margin-right: 10px;
                        }
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 22px;
                        /* identical to box height, or 105% */
                        color: $white-color;
                    }
                    .question {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        /* identical to box height, or 122% */
                        color: $starstack-blue-main;
                    }
                    .text {
                        p {
                            font-style: normal;
                            font-weight: 300;
                            letter-spacing: 1px;
                            font-size: 15px;
                            color: $white-color;
                        }
                    }
                    ul {
                        padding: 0;
                        li {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 15px;
                            letter-spacing: 1px;
                            margin-bottom: 4px;
                            list-style: none;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.confirm {
        .confirm-modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            min-height: 400px;
            height: 100%;
            .processing-icon {
                text-align: center;
                svg {
                    width: 100px;
                    height: 100px;
                }
                &.rotate {
                    -webkit-animation: spin 0.5s linear infinite;
                    -moz-animation: spin 0.5s linear infinite;
                    animation: spin 0.5s linear infinite;
                }
            }
        }
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}