.faq-section {
  .shape-left {
    top: -100px;
  }

  h2 {
    // font-weight: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 52px;
    color: $black-color;
  }

  .accordion-button {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 80px;
    color: $carbon-gray-color;
    // padding: 8px 0px;
    padding: 4px 0px;
    background: rgb(0 0 0 / 0%);

    &:hover {
      color: $black7-color;
    }
  }

  .accordion-button:not(.collapsed) {
    color: $black7-color;
    background: none;
    box-shadow: none;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-item {
    border: none;
    //  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 10px;
    background: rgb(0 0 0 / 0%);

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-body {
    font-weight: 500;
    font-size: 16px;
    // line-height: 33px;
    color: $medium-gray-color;
    padding: 0 0 20px;
  }

  .faq-dashboard-img {
    // bottom: -68px;
    width: 400px;
    top: 25%;
    left: 58%;
  }
}
