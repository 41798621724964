.home {
  .banner-bg {
    background-image: url($aws-base-url + '/home-banner-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }

  .banner-home {
    display: flex;
    height: 90vh;
    justify-content: center;
    align-items: center;

    .text-slider {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      // height: 100vh;
      // max-height: 800px;
      margin-top: 56px;

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50%;

        div {
          width: max-content;
        }

        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 54px;
          line-height: 60px;
          color: $white-color;

          span {
            color: $starstack-blue-main;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: rgba(248, 248, 248, 0.72);
          margin-top: 1rem;
        }

        .cta {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          align-content: center;

          .play-btn {
            cursor: pointer;
            font-weight: 300;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 1rem;
            color: $white-color;

            img {
              margin: 0px 1rem;
            }
          }
        }

        .powered-by {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: $white-color;

          .badges {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            margin-top: 2rem;

            span {
              margin-right: 2rem;

              .img {
                width: 70px;
                height: 70px;

                svg {
                  stroke: 3px;
                }
              }
            }
          }
        }
      }

      .slider {
        flex: 1;
        width: 50%;
      }
    }

    .banner-slider {
      .owl-theme {
        z-index: 2;

        .owl-item {
          .img {
            img {
              width: 100%;
            }
          }
        }

        .owl-dots {
          display: block;

          .owl-dot {
            span {
              width: 6px;
              height: 6px;
              margin: 3px 4px;
              background: rgba(86, 78, 206, 0.39);
            }

            &.active {
              span {
                width: 12px;
                height: 12px;
                background: $starstack-blue-main;
                margin: 0px 4px;
              }
            }
          }
        }
      }
    }
  }

  .about-access-sec {
    h1 {
      font-weight: normal;
      font-size: 43px;
      text-align: center;
      color: $white-color;
    }

    .bg {
      background-image: url('../../../img/svg/Logos/BlackS.svg');
      background-size: 25%;
      background-position: center top;
      background-repeat: no-repeat;
    }

    .about-access {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;
      // width: 100px;
      // height: 100vh;
    }

    .cols {
      flex: 1;

      &:nth-child(1) {
        text-align: right;
      }

      &:nth-child(2) {
        text-align: center;
      }

      h2 {
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        color: $white-color;
        padding: 2rem;
      }

      .img {
        text-align: center;
        padding: 2rem;

        img {
          margin: 0 auto;
        }
      }

      &.hide-tex {
        visibility: hidden;
      }

      span {
        color: $starstack-blue-main;
        font-size: 21px;
        font-weight: bold;
      }

      p {
        font-style: normal;
        font-size: 16px;
        color: $white-color;
      }
    }
  }

  //SECTION
  .left-right-bg {
    background-image: url($aws-base-url + '/lr-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    // height: 100vh;
  }

  .left-right-img-text {
    padding: 3rem 2rem;

    // margin-bottom: 2rem;
    .text {
      width: 40%;
    }

    .left-right-img {
      width: 60%;

      img {
        width: 100%;
      }
    }

    h1 {
      font-weight: normal;
      font-size: 43px;
      color: $white-color;
      margin-bottom: 2rem;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      color: $gray4-color;
    }

    ul {
      margin-bottom: 2rem;

      li {
        font-weight: normal;
        font-size: 16px;
        color: $starstack-blue-main;
        margin-bottom: 1rem;
      }
    }
  }

  //section
  .whos-on {
    .bg {
      background-image: url($aws-base-url + '/who-ison-bg.webp');
      background-size: cover;
      background-repeat: no-repeat;
      margin-left: -2rem;
      margin-right: -2rem;

      // width: 100%;
      h1 {
        text-align: center;
        font-weight: normal;
        font-size: 43px;
        color: $white-color;
      }

      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        text-align: center;
        color: rgba(222, 217, 217, 0.58);
      }

      ul {
        li {
          list-style: none;
          font-weight: normal;
          text-align: center;
          font-size: 16px;
          color: $white-color;
        }
      }
    }
  }

  .category {
    position: relative;

    &::after {
      position: absolute;
      top: 0px;
      z-index: 2;
      content: '';
      height: 100px;
      width: 100%;
      background: -moz-linear-gradient(
        180deg,
        rgba(4, 5, 10, 1) 0%,
        rgba(4, 5, 10, 0.75) 49%,
        rgba(4, 5, 10, 0) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(4, 5, 10, 1) 0%,
        rgba(4, 5, 10, 0.75) 49%,
        rgba(4, 5, 10, 0) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(4, 5, 10, 1) 0%,
        rgba(4, 5, 10, 0.75) 49%,
        rgba(4, 5, 10, 0) 100%
      );
    }

    .heading {
      // width: 40vw;
      position: relative;
      display: flex;
      flex: 1 1;
      height: 100%;
      align-content: center;
      align-items: center;

      h1 {
        position: absolute;
        width: 50vw;
        z-index: 2;
      }

      // flex: 1;
    }

    .categoryBanner {
      h1 {
        font-size: 38px;
      }

      .card-bs-list {
        ul {
          padding-left: 1rem;
        }
      }
    }

    .categoryBanner.main-marketplace .card-bs-list ul {
      padding-left: 3rem;
    }
  }

  .testimonial {
    background-image: url('../../../img/home/profiles.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .owl-theme {
      z-index: 2;

      .owl-item {
        width: 60%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .item {
          text-align: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 765px) {
            width: 60%;
          }
        }

        .msg {
          font-weight: normal;
          font-size: 51px;
          text-align: center;
          color: $white-color !important;
        }

        .profile {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;

          .img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin: 1rem;

            img {
              width: 100%;
            }
          }

          .details {
            display: flex;
            flex-direction: column;
            text-align: left;

            .name {
              font-weight: normal;
              font-size: 21px;
              color: $white-color;
            }

            .type {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              color: rgba(255, 255, 255, 0.17);
            }
          }
        }
      }

      .owl-dots {
        .owl-dot {
          span {
            width: 6px;
            height: 6px;
            margin: 3px 6px;
            background: rgba(86, 78, 206, 0.39);
          }

          &.active {
            span {
              width: 12px;
              height: 12px;
              background: $starstack-blue-main;
              margin: 0px 6px;
            }
          }
        }
      }
    }
  }

  .devider-holder {
    // margin-top: -200px;
    position: relative;
    z-index: 21;
  }

  // FOOTER
  .home-stardom {
    .stardom-section {
      margin-top: 0px !important;
      margin-bottom: 0px !important;

      .startdom-text {
        h4 {
          margin: 1rem 0 !important;
        }
      }
    }
  }

  @media screen and (max-width: 2560px) {
  }

  @media screen and (max-width: 1563px) {
  }

  @media screen and (max-width: 1440px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    height: auto;

    //ABOUT
    .about-access-sec {
      .about-access {
        flex-direction: column;
        align-items: stretch;
        // background-image: url('../../../img/home/s-logo-bg.png');
        background-size: 25%;
        background-position: center top;
        background-repeat: no-repeat;
        // width: 100px;
        // height: 100vh;
      }

      .cols {
        flex: 1;

        .img {
          text-align: center;
          padding: 2rem;

          img {
            margin: 0 auto;
          }
        }

        &.hide-tex {
          display: none;
        }
      }
    }

    //SECTION
    .left-right-bg {
      background-image: url($aws-base-url + '/lr-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      // height: 100vh;
    }

    .left-right-img-text {
      padding: 1rem;
      flex-direction: column !important;

      .text {
        width: 100%;
        margin-bottom: 1rem;
      }

      .left-right-img {
        width: 100%;
        margin-bottom: 2rem;

        img {
          width: 100%;
        }
      }

      ul {
        margin-bottom: 1rem;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    // WHO IS ON section
    .whos-on {
      .bg {
        background-image: url($aws-base-url + '/who-ison-bg.webp');
        background-size: cover;
        background-repeat: repeat;
        margin-left: -2rem;
        margin-right: -2rem;

        ul {
          padding-left: 0;

          li {
            list-style: none;
            font-weight: normal;
            text-align: center;
            font-size: 16px;
            color: $white-color;
          }
        }
      }

      .left-right-img-text {
        flex-direction: row !important;
      }
    }

    //CATEGORY
    .category {
      position: relative;

      .heading {
        // width: 40vw;
        position: relative;
        display: flex;
        // flex: 1 1;
        flex-direction: column;
        height: 100%;
        align-content: center;
        align-items: center;

        h1 {
          position: static;
          width: auto;
          text-align: center;
        }

        // flex: 1;
      }

      .categoryBanner {
        h1 {
          font-size: 38px;
        }

        .card-bs-list {
          // flex: 2;
          width: 100%;

          ul {
            padding-left: 0rem !important;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
          }
        }
      }

      .categoryBanner.main-marketplace .card-bs-list ul {
        padding-left: 0rem;
      }
    }

    //TESTIMONIAL
    .testimonial {
      .owl-theme {
        z-index: 2;

        .owl-item {
          width: 60%;

          .item {
            text-align: center;
          }

          .msg {
            font-weight: normal;
            font-size: 30px;
            text-align: center;
            color: $white-color !important;
          }

          .profile {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            .details {
              text-align: center;

              .name {
                font-weight: normal;
                font-size: 21px;
                color: $white-color;
              }

              .type {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: rgba(255, 255, 255, 0.17);
              }
            }
          }
        }

        .owl-dots {
          .owl-dot {
            span {
              width: 6px;
              height: 6px;
              margin: 3px 6px;
              background: rgba(86, 78, 206, 0.39);
            }

            &.active {
              span {
                width: 12px;
                height: 12px;
                background: $starstack-blue-main;
                margin: 0px 6px;
              }
            }
          }
        }
      }
    }

    .devider-holder {
      position: relative;
      z-index: 21;
    }
  }

  @media screen and (max-width: 600px) {
  }

  @media screen and (max-width: 561px) {
    height: auto;

    .banner-bg {
      height: auto;
      max-height: unset;
    }

    .banner-home {
      overflow: auto;
      height: auto;
      max-height: unset;

      .text-slider {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        height: auto;

        .text {
          flex: 1;
          width: 100% !important;

          h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 54px;
            color: $white-color;

            span {
              color: $starstack-blue-main;
            }
          }

          p {
            font-weight: 300;
            font-size: 16px;
            color: rgba(248, 248, 248, 0.72);
          }

          .cta {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            align-content: center;

            .play-btn {
              cursor: pointer;
              font-weight: 300;
              font-size: 20px;
              display: flex;
              align-items: center;
              text-align: center;
              color: $white-color;

              img {
                margin: 0px 1rem;
              }
            }
          }

          .powered-by {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            color: $white-color;

            .badges {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              align-content: center;

              span {
                margin-right: 2rem;
              }
            }
          }
        }

        .slider {
          margin: 5rem 0 0 0;
          height: auto;
          flex: auto;
          width: 100%;
        }
      }

      .owl-carousel.owl-drag .owl-item {
        width: 350px;
      }
    }

    //ABOUT
    .about-access-sec {
      .about-access {
        flex-direction: column;
        align-items: stretch;
        background-image: url('../../../img/svg/Logos/BlackS.svg');
        background-size: 25%;
        background-position: center top;
        background-repeat: no-repeat;
        // width: 100px;
        // height: 100vh;
      }

      .cols {
        flex: 1;

        .img {
          text-align: center;
          padding: 2rem;

          img {
            margin: 0 auto;
          }
        }

        &.hide-tex {
          display: none;
        }
      }
    }

    //SECTION
    .left-right-bg {
      background-image: url($aws-base-url + '/lr-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      // height: 100vh;
    }

    .left-right-img-text {
      padding: 1rem;
      flex-direction: column !important;

      .text {
        width: 100%;
        margin-bottom: 1rem;
      }

      .left-right-img {
        width: 100%;
        margin-bottom: 2rem;

        img {
          width: 100%;
        }
      }

      ul {
        margin-bottom: 1rem;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    // WHO IS ON section
    .whos-on {
      .bg {
        background-image: url($aws-base-url + '/who-ison-bg.webp');
        background-size: cover;
        background-repeat: repeat;
        margin-left: -2rem;
        margin-right: -2rem;

        ul {
          padding-left: 0;

          li {
            list-style: none;
            font-weight: normal;
            text-align: center;
            font-size: 16px;
            color: $white-color;
          }
        }
      }
    }

    //CATEGORY
    .category {
      position: relative;

      .heading {
        // width: 40vw;
        position: relative;
        display: flex;
        // flex: 1 1;
        flex-direction: column;
        height: 100%;
        align-content: center;
        align-items: center;

        h1 {
          position: static;
          width: auto;
          text-align: center;
        }

        // flex: 1;
      }

      .categoryBanner {
        h1 {
          font-size: 38px;
        }

        .card-bs-list {
          // flex: 2;
          width: 100%;

          ul {
            padding-left: 0rem !important;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
          }
        }
      }

      .categoryBanner.main-marketplace .card-bs-list ul {
        padding-left: 0rem;
      }
    }

    //TESTIMONIAL
    .testimonial {
      .owl-theme {
        z-index: 2;

        .owl-item {
          width: 60%;

          .item {
            text-align: center;
          }

          .msg {
            font-weight: normal;
            font-size: 30px;
            text-align: center;
            color: $white-color !important;
          }

          .profile {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            .details {
              text-align: center;

              .name {
                font-weight: normal;
                font-size: 21px;
                color: $white-color;
              }

              .type {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: rgba(255, 255, 255, 0.17);
              }
            }
          }
        }

        .owl-dots {
          .owl-dot {
            span {
              width: 6px;
              height: 6px;
              margin: 3px 6px;
              background: rgba(86, 78, 206, 0.39);
            }

            &.active {
              span {
                width: 12px;
                height: 12px;
                background: $starstack-blue-main;
                margin: 0px 6px;
              }
            }
          }
        }
      }
    }

    .devider-holder {
      position: relative;
      z-index: 5;
    }
  }
}

.prob-img {
  img {
    width: 100%;
  }
}

.our-solution {
  img {
    position: relative;
    // top: -150px;
  }
}

// HASSEL IMAGE
.home-hassle {
  .mint-heading {
    color: $white-color !important;
    font-weight: 600;
    font-size: 54px;
    width: 611px;
    height: 60px;
    margin: 0 auto;
  }
  .hassle-section {
    background-image: url($aws-base-url + '/common/Hassel.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .hassle-sub-heading {
    font-weight: 300;

    span {
      color: $white-color;
    }
  }
  .button-get-bucks {
    background-color: #564ece;
    width: 316px;
    padding: 10px 30px;
    text-decoration: none;
    color: #ffffff;
    border-radius: 9px;
    border: 1px solid#564ece;
    &:hover {
      background-color: transparent;
    }
  }
  .hassle-img-box {
    .cards-img {
      margin: 0 0 5rem 0;
      width: 60%;
    }

    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .home-hassle {
    .mint-heading {
      color: $white-color !important;
      font-weight: 600;
      font-size: 54px;
      width: 611px;
      height: 60px;
      margin: 0 auto;
    }
    .hassle-section {
      background-image: url($aws-base-url + '/common/Hassel.webp');
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }

    .hassle-sub-heading {
      font-weight: 300;

      span {
        color: $white-color;
      }
    }

    .hassle-img-box {
      .cards-img {
        margin: 0 0 5rem 0;
        width: 90%;
      }

      img {
        width: 100%;
      }
    }
  }
}
