.acces-nft-details-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 90vh;
    background-image: url('../../../img/CreatorHub/details-page-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .gradient-blur-nft {
    position: absolute;
    right: 0;
    bottom: -20%;
    z-index: -1;
  }
  .yellow-img {
    position: absolute;
    left: 0;
    bottom: -20%;
    z-index: -1;
  }
  .imggradient {
    position: absolute;
    top: -15%;
    z-index: -2;
    width: 70%;
    img {
      width: 100%;
    }
  }
  .image-gradient {
    position: absolute;
    top: 75%;
    left: -13%;
    z-index: -2;
  }
  .acces-nft-main-container-for-details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    @media screen and (max-width: 991px) {
      width: 95%;
      margin-top: 10px;
    }

    .blue-box-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      position: relative;
      margin-top: 50px;
      @media screen and (max-width: 991px) {
        justify-content: center;
        margin-top: 30%;
      }

      .blue-box-container {
        flex-direction: column;
        width: 95%;
        background: #0b0d19;
        border-radius: 24px;
        margin-top: 40px;
        display: flex;
        z-index: 1;
        @media screen and (max-width: 991px) {
          margin-top: 312px;
        }
        .vertical-text-nft {
          position: absolute;
          z-index: 2;
          top: 60%;
          left: 7%;
          writing-mode: vertical-rl;
          text-orientation: mixed;
          transform: rotate(180deg);
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          line-height: 38px;
          letter-spacing: 0.5em;
          color: #ababab;
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
        .text-container {
          padding: 50px;
          margin-left: 200px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          @media screen and (max-width: 991px) {
            margin-left: 0;
            width: 100%;
            padding: 20px;
            margin-left: 0px;
          }
          .topbar-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            @media screen and (max-width: 500px) {
              flex-direction: column;
              align-items: flex-start;
            }

            .collection-name {
              background: rgba(255, 255, 255, 0.17);
              backdrop-filter: blur(2px);
              border-radius: 7px;
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              padding: 5px 10px;
              text-transform: capitalize;

              svg {
                width: 25px;
                height: 25px;
              }
              @media screen and (max-width: 991px) {
                margin-bottom: 20px;
              }
            }

            .rarity-section {
              font-weight: 400;
              font-size: 16px;
              line-height: 38px;
              text-align: right;
              color: #564ece;
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: capitalize;
              svg {
                path {
                  stroke: #564ece;
                  fill: #564ece;
                }
              }
            }
            .certificate-access-code {
              font-weight: 400;
              font-size: 16.1px;
              line-height: 18px;
              span {
                color: #81859f;
              }
              strong {
                font-weight: 400;
                color: #564ece;
              }
            }
          }

          .nft-title {
            font-weight: 400;
            font-size: 36px;
            color: #ffffff;
            margin-top: 20px;
          }
          .creator-details {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              border-radius: 50%;
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
            .creator-name {
              font-size: 16px;
              line-height: 24px;
              color: rgba(239, 233, 255, 0.6);
              margin-left: 15px;
              svg {
                width: 20px;
                height: 25px;
              }
            }
          }
          .descriptionofnft {
            padding: auto 20px;
            min-height: 180px;
          }
          .nftinfo-wrapper {
            width: 100%;
            position: relative;
            img {
              position: relative;
              height: 100%;
              width: 100%;
              @media screen and (max-width: 500px) {
                height: 320px;
              }
            }
            .text-on-image {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              svg {
                width: 178px;
                height: 26px;
                margin-left: 20px;
              }
              .nft-info-text {
                font-weight: 500;
                font-size: 23.2094px;
                line-height: 30px;
                letter-spacing: 0.385em;
                color: #ffffff;
                margin-bottom: 40px;
                margin-left: 20px;
              }
            }
            .play-icon-div {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              .play-icon-btn {
                position: relative;
                z-index: 1;
                background: linear-gradient(
                  102.02deg,
                  #9381f5 36.16%,
                  #4c45b9 78.41%
                );
                height: 3.5em;
                width: 3.5em;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                svg {
                  margin-left: 0.5em;
                  height: 1.6em;
                  width: 1.6em;
                }
                &::before,
                &::after {
                  content: '';
                  position: absolute;
                  border-radius: 100%;
                  background: rgba(86, 78, 206, 0.48);
                  animation: pulse-animate 1500ms ease-out infinite;
                  z-index: -1;
                }
                &::before {
                  height: 4em;
                  width: 4em;
                }
                &::after {
                  height: 5em;
                  width: 5em;
                }
                @keyframes pulse-animate {
                  0% {
                    transform: translateX(0) translateY(0) translateZ(0)
                      scale(1);
                    opacity: 1;
                  }
                  100% {
                    transform: translateX(0) translateY(0) translateZ(0)
                      scale(1.3);
                    opacity: 0;
                  }
                }
              }
            }
          }
          .stakehub-side-section-buy-now {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            .price {
              font-weight: 500;
              font-size: 35px;
              line-height: 20px;
              color: #564ece;
              margin-right: 30px;
            }
            .cart-button {
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 18px;
              line-height: 37px;
              color: #ffffff;
              width: 200px;
              height: 51px;
              background: #564ece;
              border: 1px solid #564ece;
              border-radius: 25.5px;
              margin-left: 30px;
              svg {
                path {
                  fill: #ffffff;
                }
              }
              &:hover {
                background-color: transparent;
              }
            }
          }
          .certificate-redeem-text {
            color: #564ece;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
          }
        }
      }
      .image-container {
        position: absolute;
        left: 25px;
        top: 0;
        @media screen and (max-width: 991px) {
          top: -50px;
          left: 30%;
        }
        .ownership-fancreds-wrapper {
          margin-top: 20px;
          margin-left: 24%;
          margin-right: -24%;
          display: flex;
          align-items: center;
          gap: 8px;
          .icon-fancreds {
            display: flex;
            gap: 4px;
            align-items: center;
            width: max-content;
            background: rgba(255, 255, 255, 0.13);
            border-radius: 20px;
            padding: 6px 12px 6px 8px;
            svg {
              width: 24px;
              height: 24px;
            }
            span {
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: 0.03em;
              color: #ffffff;
            }
          }
          a {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
          }
        }
      }
      .left-img-section {
        width: max-content;
        z-index: 1;
        position: relative;
      }
      .top-buy-now-btn-container {
        position: absolute;
        right: 0;
        top: -50px;
        display: flex;
        align-items: center;
        gap: 30px;
        .price-text {
          font-weight: 500;
          font-size: 35px;
          line-height: 20px;
          color: #ffffff;
        }
      }
      .buy-now-button {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        padding: 16px 32px;
        background: linear-gradient(
          89.93deg,
          #ffb89a 12.96%,
          #ff8585 54.64%,
          #8745c9 99.91%,
          #a94fb0 99.92%
        );
        border: 1px solid rgba(255, 255, 255, 0.31);
        border-radius: 8px;
        svg {
          path {
            fill: #ffffff;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}

.info-section-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  position: relative;
  @media screen and (max-width: 991px) {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .main-container-blockchain-info {
    width: 95%;
    margin-top: 50px;
    background: #0b0d19;
    border-radius: 24px;
    .text-wrapper {
      padding: 50px;
      @media screen and (max-width: 991px) {
        padding: 20px;
      }
      .token-info-text {
        font-weight: 400;
        font-size: 21px;
        line-height: 38px;
        color: #ababab;
      }
      .token-info-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin-top: 20px;
      }
    }
  }
}

.review-stars-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -22px;
  margin-bottom: -6px;
  .review-stars {
    font-weight: 700;
    font-size: 50px;
    color: #564ece;
    margin-right: 15px;
  }
  .reviews-number {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
  }
}

.access-review-wrapper {
  width: 100%;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  padding-bottom: 7%;

  .pageeditor-wrapper {
    margin: auto;
    width: 80%;
    background: #ffffff;
    padding: 50px 0;
    @media screen and (max-width: 991px) {
      width: 90%;
      padding: 20px 0;
    }
  }
  .main-content-for-reviews {
    width: 80%;
    background: #ffffff;
    box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.48);
    border-radius: 33px;
    margin: auto;
    @media screen and (max-width: 991px) {
      width: 90%;
      padding: 20px;
    }
    .text-section {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 50px;
      @media screen and (max-width: 991px) {
        padding: 20px;
      }
      .acces-review-text {
        font-weight: 600;
        font-size: 30px;
        line-height: 44px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #000000;
      }

      .reviews-number {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.66);
        text-decoration: none;
        cursor: unset;
      }
    }
  }
}

.review-card-wrapper {
  margin: 30px 0;

  .review-card {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 58px rgba(0, 0, 0, 0.13);
    border-radius: 16px;
    width: 300px;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &:hover {
      transform: scale(1.05);
    }
    .review-comment {
      min-height: 220px;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
    }
    .image-wrapper-reviewer {
      width: 100%;
      .reviewer-image {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
    }
    .reviewer-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

// Arrow slider Main
.review-arrow-slider {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 50px;
  .arrow-slider-main {
    width: 100%;
    margin: auto;
    position: relative;
    z-index: auto !important;
    padding: 0 100px;

    .arrow-slider-card {
      width: max-content;
      padding: 0 30px;
    }
  }

  .owl-carousel {
    .owl-dots {
      margin-top: 14px;
      position: absolute;
      left: 50%;

      .owl-dot {
        &.active,
        &:hover {
          span {
            background: #564ece !important;
            width: 6px;
            height: 6px;
          }
        }

        span {
          width: 4px;
          height: 4px;
          margin: 3px;
          background: #ababab;
        }
      }
    }
    .owl-nav {
      position: absolute;
      top: 36%;
      width: 100%;
      z-index: 2;

      button {
        position: relative;
        width: 60px;
        height: 60px;
        background: #181c35 !important;
        border-radius: 50%;
        &:hover {
          transition: all ease 0.4s;
          opacity: 0.7;
        }

        &.disabled {
          &.owl-prev,
          &.owl-next {
            display: none !important;
          }
        }

        &.owl-next {
          position: absolute;
          right: 5px;

          span {
            background: url('../../../img/svg/Arrows/WhiteFrontArrow1.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
          }
        }

        &.owl-prev {
          position: absolute;
          left: 5px;

          span {
            background: url('../../../img/svg/Arrows/WhiteFrontArrow1.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
            transform: rotate(180deg);
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .owl-nav {
        button {
          height: 45px;
          width: 45px;
        }
      }
    }
  }
}

.accessnft-collection-details-page-wrapper {
  position: relative;
  overflow: hidden;
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: 80vh;
    background: url('../../../img/CreatorHub/details-page-bg.webp') no-repeat;
    background-position: center;
    background-size: cover;
  }
  .cd-accessnft-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .cd-main-content {
      position: relative;
      margin: auto;
      .anft-collection-settings-box {
        margin-top: 40px;
      }
      .title-cd {
        margin-top: 100px;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
        svg {
          margin-right: 10px;
        }
      }
      .rs-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        position: relative;
        margin-top: 60px;

        @media screen and (max-width: 991px) {
          flex-direction: column;
          justify-content: center;
          margin-bottom: 20px;
        }
        .card-section {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          width: 30%;
          @media screen and (max-width: 991px) {
            width: 100%;
          }
          .collection-main-img {
            position: relative;
            z-index: 1;
            & > img {
              width: 264px;
              height: 530px;
              object-fit: cover;
              border-radius: 15px;
            }
            .crosscard {
              position: absolute;
              top: 0;
              left: 3%;
              z-index: -1;
              img {
                height: 530px;
              }
            }
          }
        }
        .collection-details-section {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          position: relative;
          .dark-blue-box {
            background: #0b0d19;
            box-shadow: 6px 19px 63px -8px rgba(0, 0, 0, 0.13);
            border-radius: 31px;
            width: 100%;
            margin-top: 70px;
            margin-left: -100px;
            display: flex;
            justify-content: flex-start;
            padding: 40px;
            padding-top: 20px;
            @media screen and (max-width: 991px) {
              margin-left: 0;
              margin-top: 30px;
              padding: 20px;
            }
            .details-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              width: 85%;
              padding-top: 20px;
              margin-left: 150px;
              padding-bottom: 50px;
              @media screen and (max-width: 991px) {
                width: 95%;
                padding-top: 0px;
                margin-left: 0px;
                padding-bottom: 20px;
              }
              .collection-title-and-quantity {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .quantity {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 23px;
                  line-height: 37px;
                  text-align: right;
                  color: rgba(255, 255, 255, 0.5);
                  gap: 7px;
                  svg {
                    path {
                      fill: rgba(255, 255, 255, 0.5);
                    }
                  }
                }
                .collection-text {
                  font-weight: 400;
                  font-size: 21px;
                  line-height: 38px;
                  color: #ababab;
                }
              }

              .collection-by {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ababab;
              }
              .collection-title-text {
                font-weight: 400;
                font-size: 32px;
                line-height: 38px;
                color: #ffffff;
              }
              .collection-details-text {
                font-weight: 400;
                font-size: 15px;
                line-height: 35px;
                color: #ababab;
                min-height: 200px;
                width: 80%;
              }
              .button-section {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 30px;
                gap: 20px;
                flex-wrap: wrap;
                @media screen and (max-width: 991px) {
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
      .blur-bg-gradient {
        position: absolute;
        right: 4%;
        z-index: -1;
        top: 0;
        img {
          width: 110%;
          height: 110%;
        }
      }
    }
  }
}

// collection-details-slider-wrapper
.collection-details-slider-wrapper {
  width: 100%;
  justify-content: center;
  margin-top: 50px;
  .collection-details-slider {
    width: 85%;
    margin: auto;
  }
}

.arrow-slider-wrapper-collection {
  margin: 40px auto;
  background-color: #0b0d19;
  border-radius: 30px;
  padding: 50px;
  position: relative;
  .title-of-slider {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 38px;
    text-transform: capitalize;
    color: #ffffff;

    svg {
      width: auto !important;
      path {
        fill: #564ece;
        stroke: #564ece;
      }
    }
  }
  .arrow-slider-container {
    width: 90%;
    margin: auto;
  }
}
