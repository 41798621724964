//-----------Founders Collection  css ---------------//
.headings {
  h1 {
    font-weight: normal;
    font-size: 30px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.58);
  }
}

.main h3 {
  font-weight: normal;
  font-size: 21px;
}

.founderscollectionlist {
  margin: 0 -15px;

  .name-br.mb-3 {
    font-weight: 500;
    font-size: 16px;
    color: $moody-blue-color;
    svg {
      path {
        fill: $starstack-blue-main;
        stroke: $starstack-blue-main;
      }
    }
  }

  .add-cart-btn {
    width: 122px;
    height: 36px;
    background: $gray9-color;
    border-radius: 18px;
    font-weight: 500;
    font-size: 16px;
    color: $gray10-color;
    border: none;
  }

  .price {
    background: none !important;
    width: auto !important;
  }

  &.collection-list .collection-list-box {
    width: 200px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.token-info {
  background: $cinder-black-color;
  border-radius: 24px;

  h5 {
    font-weight: normal;
    font-size: 21px;
    color: $aluminium-gray-color;
  }

  div {
    font-weight: 500;
    font-size: 16px;

    .gray-col {
      color: $aluminium-gray-color;
    }

    .secondary-col {
      color: $starstack-blue-main;
    }
  }
}
