.add-manager-modal {
  .modal-dialog {
    max-width: max-content;
  }
  .add-manager-wrapper {
    width: 886px;
    margin: auto;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0b0d19;
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    border-radius: 31.7949px;

    .box4 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .box5 {
        width: 50%;
        display: flex;
        gap: 20px;
        align-items: center;
      }

      .box6 {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        span {
          font-size: 15px;
        }
      }
    }
    .box7a {
      display: flex;
      gap: 10px;
      width: 100%;
      margin-top: 50px;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
    }

    .box7 {
      display: flex;
      gap: 10px;
      width: 100%;
      margin-top: 50px;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
    }

    .box8 {
      width: 30%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .box9 {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .box10 {
      display: flex;
      flex-direction: column;
      width: 65%;
    }

    .box11 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 1rem;
      width: 30%;
      button {
        width: 202px;
        height: 53px;
        padding: 0 !important;
      }
    }

    .box12 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin-bottom: 0;
      }
      input {
        border: 1px solid #564ece;
        background-color: transparent;
        border-radius: 0px !important;
      }
    }

    .h1 {
      font-family: Poppins;
      font-weight: 400;
      color: #ffffff;
      font-size: 30px;
      line-height: 42px;
    }

    .h2 {
      font-family: Poppins;
      font-weight: 400;
      color: #ffffff;
      font-size: 21px;
      line-height: 23px;
    }

    .h3 {
      font-family: Poppins;
      font-weight: 400;
      color: #ffffff;
      font-size: 18px;
      line-height: 42px;
    }

    .h4 {
      font-family: Poppins;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: #a7a7a7 !important;
    }

    .h5 {
      font-family: Poppins;
      font-weight: 500;
      color: #a7a7a7 !important;
      font-size: 14px;
      line-height: 21px;
    }

    .h6 {
      font-family: Poppins;
      font-weight: 400;
      color: #ffffff;
      font-size: 18px;
      line-height: 21px;
    }

    .h7 {
      font-family: Poppins;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      width: 70%;
      font-size: 21px;
      line-height: 34px;
    }
  }
}

.add-manager-success-modal {
  .modal-dialog {
    max-width: max-content;
  }
  .successCard {
    max-width: 650px;
    max-height: 500px;
    margin: 0;
    background-color: #0b0d19;
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    border-radius: 31.7949px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0;

      .image {
        margin: 1rem;
        width: 174.69px;
        height: 169.15px;
        img {
          width: 100%;
        }
      }
      .text {
        text-align: center;
        width: 80%;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
      }
    }
  }
}
