.pick-stake-earn-section {
  background: linear-gradient(
    95.54deg,
    $starstack-blue-main 20.44%,
    $chetwode-blue-color 121.22%
  ) !important;

  h2 {
    font-size: 43px;
    font-weight: 400;
  }

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: $white-color;
  }
  &.stardom-bundle-sec {
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}

.background-starter {
  background: linear-gradient(
    95.54deg,
    $starstack-blue-main 20.44%,
    $chetwode-blue-color 121.22%
  );
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  .wrapper {
    width: 80%;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 40%;

      h2 {
        font-size: 43px;
        font-weight: 400;
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: $white-color;
      }
      .starter-desc-about-bucks {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.96);
      }
      .view-bundle-button-about-bucks {
        button {
          color: #564ece;
          background: white;
          :hover {
            transition: all 0.3s ease;
            background: transparent;
            border: 1px solid white;
          }
        }
      }
    }

    &.stardom-bundle-one {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }

    .stardom-image {
      position: absolute;
      display: flex;
      right: 0;
      justify-content: center;
      align-items: center;
      z-index: 1;
      height: 100%;
      width: 60%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .background-starter {
    .wrapper {
      width: 100%;
      padding: 20px;
      .stardom-image {
        width: 70%;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 990px) {
  .background-starter {
    padding-top: 10px;
    padding-bottom: 10px;
    .wrapper {
      width: 90%;
      padding: 20px;
      justify-content: center;
      flex-direction: column-reverse;
      .text-wrapper {
        width: 100%;

        h2 {
          font-size: 30px;
          line-height: 30px;
        }

        p {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &.stardom-bundle-one {
        p {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .stardom-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        height: 100%;
        width: 100%;

        img {
          width: 120%;
          height: 100%;
        }
      }
    }
  }
}
