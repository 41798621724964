.needhelp-section {
  margin-top: 2rem;
  background-image: url($aws-base-url + '/CreatorhubInvitationImg/Group 6945.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .needhelp-content {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;

    .h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      color: #ffffff;
    }

    .h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      color: #ffffffa8;
      width: 75%;
      text-align: center;
      padding: 30px;
    }
  }
}
