.editManagers-modal {
  .modal-dialog {
    max-width: max-content;
    .modal-content {
      @media screen and (max-width: 991px) {
        overflow: scroll;
      }
    }
  }

  .editManagers {
    width: 886px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0b0d19;
    box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
    border-radius: 31.7949px;
    .head {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .Name {
        display: flex;
        flex-direction: column;
      }
      .name {
        font-weight: 400;
        font-size: 30px;
        line-height: 42px;
        color: #ffffff;
      }
      .tag {
        font-weight: 400;
        font-size: 18px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.28);
      }
    }

    .body {
      gap: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .form {
        display: flex;
        flex-direction: column;
        .info {
          padding: 1rem;
          // width: 625px;
          height: 150px;
          left: 499px;
          top: 309px;
          background: rgba(86, 78, 206, 0.11);
          border-radius: 11.09px;
          .infoText {
            // font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 23px;
            color: #ffffff;
          }
          .infoHead {
            // font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #564ece;
            opacity: 0.5;
          }
          .infoTail {
            // font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.28);
          }
        }

        .formHead {
          font-weight: 400;
          font-size: 21px;
          line-height: 23px;
          color: #ffffff;
        }
      }
    }

    .form_group {
      margin-top: 1rem;
      margin-bottom: 1rem;
      .form_label {
        font-weight: 600;
        font-size: 18px;
        line-height: 42px;
        color: #ffffff;
      }
    }

    .actions {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      p {
        // font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
      }
      .buttons {
        gap: 15px;
        display: flex;
        flex-direction: row;
        .button {
          width: 202px;
          height: 53px;
          padding: 0 !important;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}
