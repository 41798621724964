.side-nav {
  background: rgb(124 124 124 / 4%);
  width: 269px;
  height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 50px 30px;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: $white-color;
    padding: 0 12px;
    margin-bottom: 10px;
  }

  ul {
    li {
      list-style: none;

      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 37px;
        color: $aluminium-gray-color;
        text-decoration: none;
        padding: 8px 12px;
        display: block;
        transition: all 0.2s ease-in-out;

        span {
          width: 32px;
          display: inline-block;
          text-align: center;
          margin-right: 8px;

          path {
            transition: all 0.2s ease-in-out;
          }

          .white-icon {
            path {
              fill: rgba($color: $aluminium-gray-color, $alpha: 0.7);
            }
          }
        }

        &.active {
          background: rgba(86, 78, 206, 0.15);
          border-radius: 4px;
          color: $starstack-blue-main;

          span {
            .custom-icon {
              path {
                stroke: $starstack-blue-main;
                fill: none;
              }
            }

            svg {
              path {
                fill: $starstack-blue-main;
              }
            }
          }
        }

        &:hover {
          color: $marguerite-blue-color;

          span {
            .custom-icon {
              path {
                stroke: $starstack-blue-main;
                fill: none;
              }
            }

            svg {
              path {
                fill: $starstack-blue-main;
              }
            }

            .custom-rect {
              #logout-rect {
                fill: $starstack-blue-main;
              }

              rect {
                stroke: $starstack-blue-main;
              }
            }
          }
        }
      }
    }
  }
}

.side-bar-nav {
  z-index: 10;
  position: relative;
  @media screen and (max-width: 765px) {
    display: none;
    z-index: 0;
  }
  .mob-btn-side-nav {
    position: absolute;
    top: -48px;
    left: 196px;
    padding: 0px 4px;
    z-index: 999999999;
    display: none;
    border-radius: 38px;

    svg {
      width: 20px;
      height: auto;
    }
  }
}
