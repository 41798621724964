.shipping-section {
  h4 {
    font-weight: normal;
    font-size: 21px;
    line-height: 38px;
    color: $white-color;
  }

  .box-address {
    background: $ebony-black-color;
    border-radius: 11px;

    h4 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: $vampire-gray-color;
    }

    h3 {
      font-weight: normal;
      font-size: 21px;
      line-height: 30px;
      color: $white-color;
    }

    .btn {
      width: 229px;
      height: 53px;

      &:focus,
      &:hover {
        box-shadow: none;
      }

      &.btn-outline-primary {
        color: $starstack-blue-main;

        &:hover {
          color: $white-color;
        }
      }

      &.btn-none {
        background: none;
        border: none;

        &:focus,
        &:hover {
          border: none;
        }
      }
    }
  }

  .add-address {
    .btn {
      width: 316px;
      height: 73px;
    }
  }

  .add-address-modal {
    .modal-dialog {
      max-width: 628px;
      margin-top: 77px;
    }
  
    .modal-content {
      background-color: $cinder-black-color;
      color: $white-color;
      border-radius: 40px;
  
      .modal-header {
        border-bottom: none;
  
        span {
          cursor: pointer;
          padding-left: 40px;
        }
      }
  
      .form-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
      }
  
      .form-control {
        width: 100%;
        height: 65px;
        border: 2px solid $starstack-blue-main;
        box-sizing: border-box;
        border-radius: 36.5px;
        background: none;
        padding: 0px 40px;
        font-weight: 500;
        font-size: 16px;
      }
  
      .btn {
        width: 316px;
        height: 73px;
      }
    }
  }
  
}