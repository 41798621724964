@import '../core/typography';
.profile_banner {
  .main_card {
    background-image: url('../../../img/common/CCbg.webp');
    background-size: cover;
    border-radius: 33px;
    .linear-gradient {
      border-radius: 33px;
      background: linear-gradient(
        180deg,
        rgba(11, 12, 17, 0.61) 50%,
        #0b0c11 100%
      );
    }

    .img-cha {
      height: 284.42px;
      width: 284.42px;
      background: linear-gradient(325.98deg, #e12aff 0.5%, #564ece 95.25%);
      border-radius: 217px;
      img {
        width: 271.42px;
        height: 274.14px;
        border-radius: 217px;
        @media screen and (max-width: 767px) {
          height: 240px;
          width: 240px;
        }
      }
      @media screen and (max-width: 767px) {
        height: 250px;
        width: 250px;
      }
    }
  }

  .card_text {
    width: 100% !important;
    justify-content: space-between !important;
    display: flex !important;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 1rem !important;
    .request-credit-wrapper {
      .twenty {
        font-weight: 600;
        font-size: 45px;
        line-height: 42px;
        letter-spacing: -0.01em;
        color: #ffffff;
        margin-bottom: 0px !important;
        @media screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 25px;
        }
      }
      .credits {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        span {
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
        .two-circle-svg {
          height: 13px;
          width: 16px;
          @media screen and (max-width: 767px) {
            height: 10px;
            width: 12px;
          }
        }
      }
      .request-credit-text {
        color: white !important;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 1rem;
      }
    }

    .starstake-white-logo {
      max-width: 100%;
      width: 233px;
      height: auto;
      @media screen and (max-width: 767px) {
        max-width: 190px;
      }
    }
    .agentText {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      margin-top: 1rem;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 25px;
        margin-top: 0.5rem;
      }
    }

    .creditText {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(239, 233, 255, 0.6);
      margin-bottom: 0px !important;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .starBook {
    display: flex;
    flex-direction: column;
    .starbook_details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem !important;
      margin-right: 2rem !important;
      .nickname {
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: #ffffff;
        .edit_profile {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.66);
          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 25px;
        }
      }
      .starDetails {
        display: flex;
        flex-direction: row;
        p {
          font-family: $starstack-font-gemsbuck;
          font-size: 30px;
          line-height: 40px;
          color: white;
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 30px;
          }
        }
        span {
          font-family: $starstack-font-Montserrat;
          font-style: normal;
          font-weight: 500;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(239, 233, 255, 0.6);
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .starbook_text {
      display: flex;
      justify-content: flex-end;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em;
      color: #ffffff;
      margin-right: 11.8rem !important;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 25px;
        justify-content: flex-start;
        margin-right: 0 !important;
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border: none !important;
    }
  }
  .agent-error-message {
    height: 20px;
    text-align: center;
    animation-name: error;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
  }
  @keyframes error {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
}
