.About-us {
  .heading {
    font-weight: 600;
    font-size: 30px;
    color: $white-color;
    margin-bottom: 40px;
  }

  .row > * {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pos {
    position: relative;

    // left: -140px;
    @media (max-width: 991px) {
      position: relative;
      // left: 30%;
    }
  }
}

.mt-57 {
  margin-top: 57px;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
}

.categoryBanner {
  &.main-marketplace {
    background: url($aws-base-url + '/marketplace-bg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;

    @media (max-width: 1680px) {
      height: 630px;
    }

    @media (max-width: 1366px) {
      height: 630px;
    }

    @media (max-width: 1150px) {
      height: auto;
    }

    .card-bs-list ul li {
      padding: 12px;

      .text-bs {
        font-weight: 500;
        font-size: 30px;
      }

      .box-cards-bs {
        transform: scale(1);
        transition: 0.5s;

        img {
          filter: brightness(0.7);
          transition: 0.5s;
        }

        &:hover {
          transform: scale(1.1);
          transition: 0.5s;
          cursor: pointer;

          img {
            filter: brightness(1);
            transition: 0.5s;
          }
        }
      }
    }

    .header-class {
      font-weight: 600;
      font-size: 41px;
      line-height: 48px;
      width: 100%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      @media screen and (max-width: 700px) {
        font-weight: 600;
        font-size: 25px;
        line-height: 45px;
      }
    }
  }

  &.categoryBannerss {
    aspect-ratio: 1.78;
  }

  background: url($aws-base-url + '/categoryBanner.png');
  background-size: cover;
  background-position: center top;
  width: 100%;
  position: relative;
  margin-top: 65px;
  z-index: auto !important;

  @media (max-width: 991px) {
    margin-top: 56px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .leMeKNow {
    background: rgba(0, 0, 0, 0.13);
    backdrop-filter: blur(27px);
    position: absolute;
    border-top-left-radius: 44px;
    border-bottom-left-radius: 44px;
    top: 33%;
    right: 0;
    padding: 40px;
    color: $white-color;

    @media (max-width: 1366px) {
      top: 28%;
      padding: 30px;
    }

    @media (max-width: 767px) {
      padding: 20px 25px;
    }

    .imgWrap {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      border: solid 2px #c3aeff;
      margin-right: 15px;
      box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
      -webkit-box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
      -moz-box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
      overflow: hidden;

      img {
        max-width: 100%;
        width: auto;
        height: 100%;
      }

      @media (max-width: 991px) {
        width: 45px;
        height: 45px;
      }
    }

    i {
      font-size: 18px;
      margin-left: 8px;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 800;

      @media (max-width: 991px) {
        font-size: 28px;
      }

      @media (max-width: 767px) {
        font-size: 25px;
      }

      p {
        font-size: 16px;
        color: rgba(239, 233, 255, 0.6);
        font-weight: 500;
      }
    }
  }

  .fixed-top {
    top: 56px;

    @media (max-width: 991px) {
      top: 56px;
    }
  }

  .navbar {
    background: rgba(11, 13, 25, 0.66);
    padding: 12px 4%;
    z-index: 9;

    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          padding: 12px 19px;
          height: 100%;
          position: relative;

          &:before {
            content: '';
            width: 0;
            height: 3px;
            background: $starstack-blue-main;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: all ease-in-out 0.1s;
          }

          &.active {
            &:before {
              transition: all ease-in-out 0.1s;
              width: 100%;
              opacity: 1;
            }
          }

          &.dropdown {
            .dropdown-toggle {
              color: $white-color;
              border: none;

              &:hover,
              &:focus {
                outline: none;
                box-shadow: none;
                color: #585fcf;
                transition: 0.3s;
              }

              &::after {
                display: inline-block;
                margin-left: 14px;
                bottom: 14px;
                vertical-align: middle;
                content: '\2039';
                border: none;
                transform: rotate(271deg);
                font-size: 48px;
                font-weight: 200;
                line-height: 0;
              }
            }

            .dropdown-menu {
              width: 212px;
              background: rgb(48 48 48);
              backdrop-filter: blur(20px);
              border-radius: 16px;
              padding: 0;
              overflow: hidden;

              a {
                font-weight: 500;
                font-size: 16px;
                color: $white-color;
                border-bottom: 1px solid rgba(255, 255, 255, 0.07);
                padding: 12px 18px;

                &.dropdown-item:focus,
                &.dropdown-item:hover {
                  color: #6175fc;
                  background-color: #e9ecef00;
                }
              }
            }
          }
        }
      }
    }

    .btn {
      padding: 10px 43px;
      font-size: 16px;

      @media (max-width: 767px) {
        padding: 6px 15px;
        font-size: 13px;
      }

      &.navbar-toggler {
        @media (max-width: 767px) {
          padding: 0px 0px;
          font-size: 20px;
        }
      }
    }

    &.colorChange {
      background: rgb(13 15 34);
    }
  }
}

.customCard {
  background: rgba(14, 13, 30, 0.86);
  box-shadow: 0px 4px 70px rgba(14, 13, 30, 0.86);
  border-radius: 19px;
  transition: all ease-in-out 0.4s;
  border: 2px solid rgba(14, 13, 30, 0.02);
  overflow: hidden;

  &:hover {
    border: 2px solid $starstack-blue-main;
    transition: all ease-in-out 0.4s;
  }

  @media (max-width: 991px) {
    margin-bottom: 15px;
  }

  .card-text {
    .d-flex {
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  }

  .imgWrap {
    width: 100%;
    aspect-ratio: 1.78;
    background-color: #04050a;

    img {
      object-fit: cover;
      object-position: top center;
    }
  }

  .card-body {
    position: relative;
    padding: 46px 19px 30px;
    background: $dark-color;

    .card-title {
      font-size: 17px;
      color: $white-color;
      max-width: 90%;
      line-height: 25px;
    }

    .limit {
      position: absolute;
      right: 0;
      top: 20px;
      right: 10px;
    }

    button.btn {
      background: $dark-color;
      box-shadow: 0px 4px 70px rgba(14, 13, 30, 0.86);
      border-radius: 100px;
      padding: 8px 30px;
      font-size: 16px;
      font-weight: 400;

      &:hover {
        background: $starstack-blue-light;
      }

      @media (max-width: 576px) {
        margin-top: 4px;
      }
    }

    .musicTag {
      background: $starstack-blue-main;
      border-radius: 11px;
      padding: 5px 15px;
      width: max-content;
      position: absolute;
      top: -17px;

      img {
        width: auto;
      }

      span {
        font-size: 18px;
        color: $white-color;
        display: inherit;
        margin-left: 5px;
      }
    }

    .song {
      font-weight: 400;
      font-size: 18px;
      color: $starstack-blue-main;
    }

    .lgtTxt {
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.28);
    }
  }
}

.bannerCarousel {
  position: relative;
  margin-top: -20%;
  z-index: 2;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  &.Featureds-infys {
    margin-top: 0px;

    .owl-carousel {
      width: 125%;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .customCard {
      box-shadow: none;
      background: rgb(14 13 30 / 0%);
      border: 2px solid rgb(14 13 30 / 0%);
      aspect-ratio: 1.78;
      width: 100%;

      &:hover {
        border: 2px solid $starstack-blue-main;
        transition: all ease-in-out 0.4s;
      }
      .imgWrap {
        height: 100%;
        width: 100%;
        border-radius: 19px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-body {
        background: linear-gradient(
          351.89deg,
          rgba(4, 5, 10, 0.95) 5.49%,
          rgba(4, 5, 10, 0) 92.53%
        );
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;

        .text-box-cards {
          padding: 78px 17px 30px;
        }

        button.btn {
          background: #04050a00;
          box-shadow: none;

          &:hover {
            background: $starstack-blue-light;
          }
        }

        .card-title {
          font-size: 26px;
        }

        .lgtTxt {
          font-size: 18px;
        }

        .song {
          font-size: 24px;
        }

        .musicTag {
          width: auto;
        }
      }
    }
  }
}

.featuredStarts {
  .owl-carousel {
    @media (max-width: 767px) {
      padding-right: 0;
    }

    .customCard {
      .imgWrap {
        height: 125px;
      }

      .card-title {
        margin-bottom: 15px;
        max-width: 100%;
      }

      .card-body {
        padding: 78px 17px 30px;
        background: $cinder-black-color;

        .profile {
          width: 126px;
          height: 126px;
          border-radius: 100px;
          border: solid 5px $dark-color;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -63px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .owl-dots {
      position: absolute;
      top: -50px;
      right: 3%;

      .owl-dot {
        &.active,
        &:hover {
          span {
            background: $starstack-blue-main !important;
            width: 6px;
            height: 6px;
          }
        }

        span {
          width: 4px;
          height: 4px;
          margin: 3px;
          background: $aluminium-gray-color;
        }
      }
    }

    .owl-nav {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 991px) {
        right: 0;
      }

      @media (max-width: 767px) {
        position: relative;
        top: inherit;
        transform: none;
        right: inherit;
      }

      button {
        background: $white-color;
        width: 60px;
        height: 60px;
        border-radius: 100px;
        transition: all ease 0.4s;

        @media (max-width: 991px) {
          width: 45px;
          height: 45px;
        }

        &:hover {
          background: $white-color;
          transition: all ease 0.4s;

          span {
            margin-left: 2px;
            transition: all ease 0.4s;
          }
        }

        &.owl-next {
          span {
            background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
          }
        }

        &.owl-prev {
          display: none;
        }
      }
    }
  }
}

.popularSec {
  margin-bottom: 130px;
}

p:empty {
  display: none;
}

.owl-carousel .owl-item img {
  width: auto;
}

.BrowseNfts {
  background: $white-color;
  padding-bottom: 60px !important;

  .heading {
    color: $black-color;
  }

  .filterSec {
    margin-bottom: 5%;
    .row > * {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .FilterBtn {
      // max-width: 151px;
      border: 1px solid $white-color;
      border-radius: 26px;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .dropdown-toggle {
        text-align: center;
        background: transparent;
        color: $natural-gray-color;
        border: 1px solid $silver-gray-color;
        height: 52px;
        border-radius: 100px;
        padding: 8px 10px;
        width: 100%;

        img {
          margin-right: 12px;
        }

        &:hover,
        &:active,
        &:focus {
          background: $starstack-blue-main;
          border-color: $starstack-blue-main;
          color: $white-color;
        }

        &::after {
          display: none;
        }
      }
    }

    .input-group {
      position: relative;

      .dropdown {
        flex: 1;
      }

      .dropdown-toggle {
        padding-left: 0;
      }
    }

    .dropdown-toggle {
      padding: 10px 50px 10px 30px;
      flex: 1;
      text-align: left;
      width: 100%;
      color: $natural-gray-color;
      font-size: 16px;
      box-shadow: none;

      &::after {
        position: absolute;
        right: 28px;
        top: 32%;
        transform: translateY(-50%);
        border: solid $gray11-color;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    .show > .btn-success.dropdown-toggle {
      background-color: $starstack-blue-main;
      border-color: $starstack-blue-main;

      &::after {
        border-color: $white-color;
      }
    }

    .dropdown-menu {
      border: 1px solid rgba(255, 255, 255, 0.07);
      background: #222224;
      backdrop-filter: blur(20px);
      border-radius: 16px;
      left: auto !important;
      right: 0 !important;

      a {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        color: $white-color;
        transition: all ease 0.4s;
        padding: 10px 28px;

        &:last-child {
          border-bottom: 0;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
          transition: all ease 0.4s;
          color: $starstack-blue-main;
          background: transparent;
        }
      }
    }

    .input-group-text {
      padding-left: 18px;
    }

    .dropdown-toggle,
    input,
    .input-group-text,
    button,
    .form-control:focus {
      outline: none;
      box-shadow: none;
    }

    .input-group,
    .dropdown-toggle {
      background-color: rgba(196, 196, 196, 0.37);
      border-radius: 26px;
      border: solid 1px transparent;
      height: 52px;

      &:hover,
      &:focus {
        border-color: $starstack-blue-main;
        box-shadow: none !important;
      }

      .dropdown-toggle,
      input,
      .input-group-text {
        background-color: transparent;
        border: none;
      }
    }
  }

  .customCard {
    margin-bottom: 56px;
    box-shadow: none;
    background: rgba(196, 196, 196, 0.37);

    @media (max-width: 1000px) {
      margin-bottom: 30px;
    }

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
  }

  .text-center {
    button {
      @media (max-width: 991px) {
        margin-top: 30px;
      }
    }
  }

  .btn.btn-outline-primary {
    color: $starstack-blue-main;

    &:hover {
      background-color: $starstack-blue-main;
      color: $white-color;
    }
  }
}

.px-3per {
  padding-left: 3%;
  padding-right: 3%;
}

.categoryFooter {
  .socialLinks {
    list-style: none;

    li {
      margin-right: 13px;

      a {
        background: $starstack-blue-main;
        border-radius: 8px;
        width: 42px;
        height: 43px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          img {
            transition: all ease 0.3s;
            transform: rotateY(380deg);
          }
        }

        img {
          transition: all ease 03s;

          &:hover {
            transition: all ease 0.3s;
            transform: rotateY(380deg);
          }
        }
      }
    }
  }

  .bottomFooter {
    .row {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }

    h6 {
      font-weight: 500;
      font-size: 18px;
      color: $white-color;
      text-align: left;

      @media (max-width: 767px) {
        text-align: center;
        padding-top: 10px;
      }
    }

    ul {
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 767px) {
        justify-content: center;
      }

      li {
        margin: 0 15px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 991px) {
          font-size: 12px;
        }

        a {
          font-weight: 500;
          font-size: 18px;
          cursor: pointer;
          color: rgba(255, 255, 255, 0.63);
          transition: all ease 0.3s;

          &:hover {
            color: $white-color;
            transition: all ease 0.3s;
          }

          @media (max-width: 991px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.mob-fl-bn {
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }

  .card-bs-list {
    ul {
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      li {
        @media (max-width: 370px) {
          width: 50%;
        }

        img {
          @media (max-width: 370px) {
            width: 100%;
          }
        }
      }
    }
  }
}

//NFT process section
.nftprocess-sec {
  font-family: Poppins;
  font-style: normal;

  &.container {
    max-width: 1300px;
  }

  h6 {
    font-weight: 600;
    font-size: 21px;
    line-height: 52px;
    color: $starstack-blue-main;
  }

  h1 {
    font-weight: 600;
    font-size: 43px;
    line-height: 52px;
    color: $white-color;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.58);
  }

  .mb-10 {
    margin-bottom: 8rem !important;
  }

  .glance-sec {
    h6 {
      font-weight: 600;
      font-size: 21px;
      line-height: 52px;
      text-align: center;
    }

    h1 {
      font-weight: 600;
      font-size: 43px;
      line-height: 52px;
      text-align: center;
      color: $white-color;
    }

    .glance-block {
      width: 274px;
      height: 280px;
      padding: 35px;
      background: rgba(232, 232, 232, 0.05);
      border-radius: 11px;

      &:hover {
        width: 274px;
        height: 280px;
        background: linear-gradient(
          136.24deg,
          $starstack-blue-main 1.34%,
          #ada7ff 111.12%,
          #756cff 111.13%
        );
        border-radius: 11px;
      }

      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 44px;
        color: $white-color;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: $gray4-color;
      }
    }
  }
}

.stardom-bundle-sec {
  background: $white-color !important;

  p {
    font-weight: 400;
    font-size: 16px;
  }

  .bg-color {
    max-height: 410px;
    background: linear-gradient(
      95.54deg,
      $starstack-blue-main 20.44%,
      $chetwode-blue-color 121.22%
    );
  }

  .stardom-img {
    top: -195px;
    position: relative;
    z-index: 9999999999;

    img {
      height: 700px;
      width: 660px;
    }
  }

  .btn-outline-light {
    background-color: $white-color;
    color: $starstack-blue-main;
  }
}

@media (max-width: 991px) {
  .categoryBanner.main-marketplace h1 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 510px) {
  .glance-block {
    width: 100% !important;

    &:hover {
      width: 100%;
    }
  }
}
