.update-box {
  border: 2px solid $starstack-blue-main;
  box-sizing: border-box;
  box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
  border-radius: 25px;

  h5 {
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    color: $black-color;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.58);
  }

  .date-tex {
    font-weight: 500;
    font-size: 18px;
    color: $starstack-blue-main;
    margin-bottom: 20px;
  }
}