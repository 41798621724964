.control-crypto-section {
  background: url($aws-base-url + '/Control-crypto-sec-bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .heading {
    h1 {
      color: $white2-color;
      font-weight: 400;
      font-size: 43px;
    }
  }

  .control-crypto-box {
    &:hover {
      .icon-im-A {
        transition: 1s;
        background: $lavender-blue-color;

        svg {
          path {
            fill: $white-color;
          }

          transition: 1s;
          transform: rotate(360deg);
        }
      }
    }

    .icon-im-A {
      width: 56px;
      height: 56px;
      transition: 1s;
      background: rgba(178, 178, 178, 0.23);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transition: 1s;
        transform: rotate(1deg 356deg);
      }
    }
  }

  .text-control-crypto {
    h5 {
      color: $white2-color;
      font-size: 20px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: $gray4-color;
      font-weight: 400;
    }
  }
}
