.feature-collection {
  .view-Collection-btn {
    background: $starstack-blue-light !important;
    border-radius: 21px;
    font-weight: 500;
    font-size: 16px;
    color: $white-color !important;
    padding: 0 !important;
    text-decoration: none;
    cursor: pointer;
    height: 42px;
    width: 161px;

    &:hover {
      background: $gray9-color !important;
      color: $gray10-color !important;
    }
  }

  .collections-img {
    display: flex;
    .main-img {
      width: 214px;
      height: 424px;
      border-radius: 8px;
    }
    .extra-img {
      width: auto;
      height: 424px;
    }
  }
  .collection-text {
    width: 214px;
  }
}
