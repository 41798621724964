.Accesstokenbtn {
  .btn {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    span {
      width: 60px;
      background: rgba(86, 78, 206, 0.13);
      border-radius: 0px 36.5px 36.5px 0px;
      height: 100%;
      padding: 13px 0px 13px 10px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.card-holder {
  .single-card {
    border-radius: 25px;
    border: 1px solid hsla(0, 0%, 67.1%, 0.38);
    background: rgb(0 0 0 / 0%);
    cursor: pointer;
    margin: 20px;
    width: 280px;

    img {
      width: 225px;
      height: 330px;
      border-radius: 10px;
    }
    .token-img.test {
      border-radius: 10px;
      .type-n-rare {
        bottom: -14px;
        .token-type {
          div {
            background: $starstack-blue-main;
            border-radius: 20.5px;
            width: 25px;
            height: 25px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            margin: 0 0.25rem;
            svg {
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
        .token-rarity {
          background: $starstack-blue-main;
          border-radius: 20.5px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 400;
          padding: 0 0.75rem;
          color: hsla(0, 0%, 100%, 0.88);
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .info {
      .title {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
      }
      .by {
        font-weight: normal;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.28);
      }
    }
    .view-claim {
      a {
        font-weight: normal;
        font-size: 16px;
        text-decoration-line: underline;
        color: $starstack-blue-main;
      }
      span {
        font-weight: normal;
        font-size: 15px;
        color: $aluminium-gray-color;
      }
    }
    &:hover {
      background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
      box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
      border: 1px solid rgb(83 54 179);
      transition: 2s;
      .view-claim {
        a {
          color: $white-color;
        }
      }
    }
  }
}

.RecentClaimRequests {
  .heading-over-vi {
    .btn {
      color: $starstack-blue-main;
      font-weight: normal;
      font-size: 18px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .fliter-table {
    h6 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;
    }
    select {
      font-weight: normal;
      font-size: 18px;
      color: rgba(86, 78, 206, 0.58);
      border: none;
      background-color: transparent;
      width: 140px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      option {
        background-color: #0b0c11;
        color: $white-color;
        font-size: 15px;
      }
    }
    .btn {
      font-weight: normal;
      font-size: 18px;
      text-decoration-line: underline;
      color: $starstack-blue-main;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .table {
    tr {
      th {
        .form-check-input {
          width: 22px;
          height: 22px;
          background-color: rgba(86, 78, 206, 0.15);
        }
      }
      td {
        font-weight: normal !important;
        font-size: 16px !important;
        color: $white-color !important;
        .btn {
          border-radius: 9px;
          width: 102px;
          height: 38px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: $white-color;
          &.btn-Requested {
            background: rgba(86, 78, 206, 0.27);
            border-radius: 9px;
          }
          &.btn-Received {
            border-radius: 9px;
            background: rgba(0, 86, 255, 0.14);
          }
          &.btn-Fulfilled {
            background: rgba(247, 143, 114, 0.38);
            border-radius: 9px;
          }
          &.btn-Confirmed {
            background: rgba(37, 255, 176, 0.29);
            border-radius: 9px;
          }
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        .form-check-input {
          width: 22px;
          height: 22px;
          background-color: rgba(86, 78, 206, 0.15);
        }
        &.des-claim {
          line-height: 17px;
        }
      }
    }
  }
}

.access-token {
  z-index: 9999999;
  .modal-dialog {
    max-width: 923px;
    .modal-content {
      background: $cinder-black-color;
      box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
      border-radius: 31.7949px;
      padding: 12px;
      .modal-header {
        border: none;
        .modal-title {
          font-weight: normal;
          font-size: 30px;
          span {
            font-size: 21px;
            color: $aluminium-gray-color;
          }
        }
        button.btn-close {
          filter: contrast(0);
          border: 3px solid;
          border-radius: 100%;
          top: -4px;
          position: relative;
        }
      }
      .card-clim {
        background: linear-gradient(
          71.45deg,
          rgba(13, 11, 33, 0.54) 14.26%,
          rgba(86, 78, 206, 0.1242) 113.99%
        );
        border: 1.19297px solid $starstack-blue-main;
        backdrop-filter: blur(2.8593px);
        border-radius: 11.9297px;
        padding: 16px;
        .text-Claimed {
          padding-top: 14px;
          .h1 {
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.28);
            text-transform: capitalize;
          }
          .h2 {
            font-size: 10px;
            font-weight: 400;
            line-height: 11px;
            color: #ffffff;
            text-transform: capitalize;
          }
          .h3 {
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            color: #ffffff;
            text-transform: capitalize;
          }
          label {
            font-size: 9px;
            font-weight: 500;
            line-height: 12px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
      .action-claim-bottom {
        h5 {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
        .btn.btn-Received {
          background: rgba(86, 78, 206, 0.27);
          border-radius: 9px;
          color: $white-color;
          padding: 6px 15px;
          font-size: 14px;
          font-weight: 300;
        }
        .btn.btn-transparent.token-detail-a {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-decoration-line: underline;
          color: $starstack-blue-main;
          width: 100%;
          margin-top: 20px;
        }
      }
      .textEventClaimRequest {
        .type-tokens {
          span {
            font-weight: 500;
            font-size: 16px;
            color: rgba(37, 255, 176, 0.88);
            svg {
              path {
                fill: rgba(37, 255, 176, 0.88);
              }
            }
          }
        }
        h3 {
          font-weight: normal;
          font-size: 21px;
        }
        h6 {
          font-weight: normal;
          font-size: 21px;
          color: $aluminium-gray-color;
        }
        p {
          font-weight: normal;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.28);
        }
        .Claim-Recipient {
          background: rgba(86, 78, 206, 0.11);
          border-radius: 11.09px;
          padding: 20px;
          h5 {
            font-weight: normal;
            font-size: 16px;
            color: $starstack-blue-main;
            opacity: 0.5;
          }
          h4 {
            font-weight: normal;
            font-size: 21px;
          }
          h6 {
            font-weight: normal;
            font-size: 18px;
          }
          .gmail-id {
            font-weight: normal;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.28);
          }
          .recipient-code {
            width: 402px;
            font-weight: 500;
            font-size: 16px;
            color: $starstack-blue-main;
            overflow: hidden;
          }
        }
        .update-status {
          h2 {
            font-weight: 500;
            font-size: 16px;
          }
          .btn {
            width: 202px;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
