.contactus-section {
  padding: 9.5rem 5rem;
  background: url($aws-base-url + '/aboutus/contactusBG.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .h1 {
    font-size: 43px;
    line-height: 52px;
    font-weight: 600;
    color: #ffffff;
    font-style: normal;
    margin-top: 50px;
  }
  p {
    max-width: 519px;
    margin-bottom: 4em;
    font-size: 16px;
    line-height: 26.5px;
  }
  @media screen and (min-width: 0px) and (max-width: 700px) {
    padding: 6rem 3rem;
    p {
      margin-bottom: 2em;
    }
  }
}
