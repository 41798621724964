@media screen and (min-width: $xs) and (max-width: $sm) {
  .invitation-modal {
    width: 500px !important;
  }
  .confirmation-container {
    width: 500px !important;
    padding: 1rem !important;
  }
  .form-modal-box {
    width: 500px !important;
    .button-container {
      flex-wrap: wrap !important;
    }
  }
}

@media screen and (max-width: $sm) {
  .Essentials-list {
    .collection-list-box {
      // padding: 0px 4px !important;
      // width: 158px;
      margin: 0 auto;
    }

    .collections-list .slick-slider {
      width: 100% !important;

      .slick-next {
        display: none !important;
      }

      ul.slick-dots {
        display: none !important;
      }
    }
    .feature-collection {
      margin: 0 auto;
    }
  }

  .Essentials-banner {
    max-height: 100%;
    .banner-text {
      margin-left: -50px;
      z-index: 11;
      padding: 0 1rem !important;
      p {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: $white-color;
      }
      .banner-text-desc {
        font-size: 20px;
        margin-top: 2rem;
      }
      h1 {
        font-weight: bold;
        font-size: 65px;
        line-height: 72px;
        letter-spacing: -1.5px;
        color: $white-color;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
      }
      .p-text {
        font-size: 20px;
      }
    }

    .bg {
      top: 0px;
      right: 0px;
      filter: opacity(0.5);
    }
    h3 {
      font-weight: 600 !important;
      font-size: 54px !important;
      color: $white-color;
    }

    p.text {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
    }

    .btn {
      color: $marguerite-blue-color;
      font-weight: normal;
      font-size: 16px;
    }

    .text-content {
      width: 100% !important;
    }

    .bg {
      display: none;
    }
  }

  .bundles-list {
    .collection-list-box {
      img {
        width: 100%;
      }
    }
  }

  .founderscollectionlist.collection-list .collection-list-box {
    width: 214px;
  }

  .single-detail-bundle {
    ul.slick-dots {
      display: none !important;
    }

    .slick-slider {
      width: 100%;

      .slick-next {
        display: none !important;
      }
    }

    .standard-bucks h2 {
      font-size: 15px;
    }
  }

  .cart-slide-right {
    .cart-bg {
      width: 100%;

      .cart-heading h3 {
        font-weight: normal;
        font-size: 14px;
      }

      .balance-eth {
        font-size: 10px;
      }

      .text-end.need-text {
        font-size: 14px;
      }

      .main-heading h1 {
        font-size: 20px;
      }

      .liat-cart-slide {
        height: calc(100vh - 433px);

        .text-cart-list {
          h6 {
            font-size: 14px;
          }

          .quantity {
            font-size: 12px;

            .form-select {
              width: 72px;
              padding: 0px 16px;
            }
          }

          .cart-price {
            font-size: 16px;
          }

          .eth-text {
            font-size: 16px;
          }
        }
      }

      .summary .sumary-text {
        font-size: 13px;
      }
    }
  }

  .text-banner-A h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .video-box-A svg {
    width: 100%;
    height: auto;
  }

  .bg-shadow-video svg {
    top: 18px;
    width: 100%;
  }

  .btn {
    padding: 14px 30px;
    font-size: 14px;
  }

  .card-img {
    margin-bottom: -33px !important;
  }

  //----------- hassle section ---------------//

  .hassle-section .heading h1 {
    font-size: 32px !important;
  }

  .hassle-img-box svg {
    width: 100%;
    height: auto;
  }

  .hassle-section ul li {
    margin: 0px 18px !important;
    font-size: 16px !important;
  }

  .process-section h2 {
    font-size: 32px !important;
  }

  .control-crypto-section .heading h1 {
    font-size: 32px;
  }

  .pick-stake-earn-section {
    h2 {
      font-size: 30px;
    }

    .d-flex {
      flex-wrap: wrap;
    }
  }

  //----------- process section ---------------//
  .process-section {
    .process-list {
      ul {
        z-index: 1;

        &::after {
          display: none;
        }

        li {
          span {
            min-width: 40px !important;
            min-height: 40px !important;
          }

          h6 {
            font-size: 14px;
            line-height: 28px;
          }

          p {
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            color: $black2-color;
          }
        }
      }
    }
    h2 {
      font-size: 32px !important;
    }
  }

  // sideTap css
  .new_user_Account .setup-bg-box .btn {
    width: 100% !important;
  }

  .Bucks-To-Stake {
    .setup-bg-box {
      height: auto !important;
    }
  }

  a.nav-link.link-he-nav {
    padding-left: 0px !important;
    font-size: 10px;
    padding-right: 0;
  }

  .wrap-mo {
    flex-wrap: wrap;
  }

  .video-text-left {
    min-width: 100%;
    height: auto;

    h5 {
      font-size: 18px;
      line-height: 22px;
    }

    p {
      font-size: 12px;
    }

    .bg-light-box {
      h4 {
        font-size: 20px;
      }

      span {
        font-size: 11px;
      }
    }

    a {
      font-size: 15px;
    }
  }

  .video-boxs-inft {
    padding: 40px 0px;
  }

  .Available-nft {
    margin-top: 10px;
  }

  .link-nft {
    margin-top: 10px;
  }

  .let-me-know {
    .darkBlk {
      min-width: 100%;
    }

    .let-meknow-text {
      p {
        font-size: 12px;
      }

      .cont-box {
        margin-left: 0px;

        h4 {
          font-size: 20px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }

  .detailSection .darkBlk .card {
    h4 {
      font-size: 19px;
      line-height: 28px;
      width: 262px;
    }

    .card-body .headerRgt {
      img {
        width: 40px;
      }
    }
  }

  .update-box {
    h5 {
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      color: $black-color;
    }

    p {
      font-size: 12px;
    }
  }

  .comment-all-box p {
    font-size: 12px;
  }

  .comment-all-box h3 {
    font-size: 26px;
  }

  .comment-all-box span {
    font-size: 13px;
  }

  .reward-boxs.details
    .detailSection
    .accordionBlk.accordion
    .accordion-item
    .accordion-button {
    font-size: 23px;
  }

  .let-me-know {
    padding-bottom: 90px;

    .w-50 {
      width: 100% !important;
    }
  }

  .let-me-know.Burn-Fat .let-meknow-text .cont-box {
    margin-right: 0;
  }

  // Inft image tem
  .img-banner {
    &.categoryBanner {
      .icon-image-tem {
        width: 100%;

        img {
          width: 34px !important;
        }
      }

      .video-text-left {
        height: auto;
      }

      .image-banner-boxs-inft {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .open-btn-vi {
      img {
        width: auto !important;
      }
    }

    .video-thm-detail {
      img {
        width: auto !important;
      }

      h6 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
