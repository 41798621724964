$rhs-width: calc(100% - 269px);
.access-token-details {
  margin-top: 56px;
  .access-token-details-section {
    .sec-heading {
      margin-bottom: 4rem;
      h4 {
        font-weight: normal;
        font-size: 21px;
        color: $white-color;
        span {
          color: $starstack-blue-main;
        }
      }
    }
    .token-details {
      background-color: #0b0d19;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @media screen and (max-width: 991px) {
        margin-top: 10%;
      }
      .left-sec {
        padding: 1rem;
        position: relative;
        margin-left: -8rem;
        margin-top: -5rem;

        .let-meknow-card {
          flex: 1.25;
          width: 400px;
          height: 500px;
          @media screen and (max-width: 991px) {
            width: 100%;
            height: 100%;
            margin-top: 60px;
          }
          .card {
            background: linear-gradient(
              71.45deg,
              rgba(13, 11, 33, 0.54) 14.26%,
              rgba(86, 78, 206, 0.1242) 113.99%
            );
            border: 2.17765px solid $starstack-blue-main;
            box-sizing: border-box;
            backdrop-filter: blur(5.21937px);
            border-radius: 21.7765px;
          }
          h4.card-name {
            font-weight: normal;
            font-size: 15.6581px;
            line-height: 22px;
            color: $white-color;
          }
          h5 {
            font-weight: 300;
            font-size: 13.9183px;
            color: rgba(255, 255, 255, 0.28);
          }
          .card-addr {
            &.card-text {
              font-size: 10px;
              letter-spacing: 1px;
            }
          }
          .categoryType {
            margin-top: 1rem;
            span {
              font-weight: 300;
              font-size: 15px;
              color: $white-color;
            }
            .text-start {
              label {
                font-weight: 300;
                font-size: 10px;
                line-height: 16px;
                color: $gray10-color;
              }
            }
            .text-end {
              text-align: left !important;
              label {
                font-weight: 300;
                font-size: 10px;
                color: $gray10-color;
              }
            }
          }
        }
        .card-mit {
          .card-clim {
            .text-Claimed {
              .h5 {
                font-weight: normal;
                font-size: 19.57791px;
              }

              p {
                font-size: 14.62481px;
              }

              h4 {
                font-size: 24.3508px;
                line-height: 35px;
              }

              label {
                font-size: 14.36252px;
                line-height: 22px;
              }
            }
          }
        }
      }
      .right-sec {
        width: 100%;
        .v-text {
          position: absolute;
          bottom: 2rem;
          left: 3rem;
          font-weight: normal;
          font-size: 21px;
          line-height: 38px;
          letter-spacing: 0.315em;
          color: $aluminium-gray-color;
          transform: rotate(-90deg);
          transform-origin: bottom left;
        }
        .row-one {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          font-weight: normal;
          font-size: 21px;
          .hash-no {
            font-size: inherit;
            color: $aluminium-gray-color;
          }
          .out-of {
            font-size: inherit;
            color: $aluminium-gray-color;
            margin-left: 2rem;
          }
          .rairty {
            color: #25ffb0e0;
            svg {
              margin: 0px 0.5rem;
              path {
                fill: #25ffb0e0;
              }
            }
          }
        }
        .title {
          font-weight: bold;
          font-size: 30px;
          color: $white-color;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          .claimed {
            font-weight: normal !important;
            font-size: 15px;
            line-height: 38px;
            color: $aluminium-gray-color;
          }
        }
        .creator {
          font-weight: 500;
          font-size: 16px;
          color: $aluminium-gray-color;
          span {
            color: $starstack-blue-main;
          }
        }
        .token-type {
          > div {
            display: inline-block;
            margin-right: 1rem;
            padding: 0.5rem 1.25rem;
            background: $starstack-blue-main;
            border-radius: 20.5px;
            color: $white-color;
            svg {
              width: 18px;
            }
          }
        }
        .text {
          font-weight: normal;
          font-size: 16px;
          line-height: 27px;
          /* or 172% */
          color: $aluminium-gray-color;
          h4 {
            font-size: 21px;
            font-weight: 600;
            color: $starstack-blue-main;
          }
        }
        .unlock-access {
          position: relative;
          .video-sec {
            .video-boxs-Tutorial {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .watch-btn {
            display: inline-block;
            color: $white-color;
            position: relative;
            z-index: 5;
            top: -1rem;
            margin: 0 auto;
            text-align: center;
            width: 100%;
            button {
              position: relative;
              padding: 0.5rem 1rem;
              background: linear-gradient(
                158.07deg,
                $starstack-blue-main 6.22%,
                $lavender-blue-light-color 92.27%
              );
              border-radius: 11px;
            }
          }
        }
      }
    }
    .token-info {
      font-weight: 500;
      font-size: 16px;
      color: $white-color;
      padding: 4rem;
      .info-title {
        font-weight: normal;
        font-size: 21px;
        line-height: 38px;
        color: $aluminium-gray-color;
        margin-bottom: 1rem;
      }
      .info-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
    }
    .video-images {
      .gal-images {
        display: flex;
        justify-content: space-between;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.details {
          > div {
            &:nth-child(1) {
              flex: 1;
            }
            &:nth-last-child(1) {
              flex: 2;
            }
          }
        }
      }
      .video-sec {
        .video-boxs-Tutorial {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .faq {
      .faq-staking-payments {
        .accordion {
          .accordion-item {
            .accordion-header {
              .accordion-button {
                background-color: #f2f2f2;
                font-weight: 500;
                &:not(.collapsed) {
                  background-color: #f2f2f2;
                }
              }
            }
            .accordion-collapse .accordion-body {
              background-color: #f2f2f2;
            }
          }
        }
      }
    }
    @media screen and (max-width: 2560px) {
    }
    @media screen and (max-width: 1563px) {
    }
    @media screen and (max-width: 1440px) {
    }
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 1200px) {
      .token-details {
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: stretch;
        .left-sec {
          padding: 0px 2rem;
          position: relative;
          margin-left: 0px;
          margin-top: -2rem;
        }
        .right-sec {
          .row-one {
            flex-direction: column;
          }
          .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .claimed {
              font-weight: normal !important;
              font-size: 15px;
              line-height: 38px;
              color: $aluminium-gray-color;
            }
          }
          .token-type {
            > div {
              margin-bottom: 0.5rem;
            }
          }
          .unlock-access {
            position: relative;
            .video-sec {
              .video-boxs-Tutorial {
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .watch-btn {
              display: inline-block;
              color: $white-color;
              position: unset;
              z-index: 5;
              top: 1rem;
              margin: 1rem auto;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
      .token-info {
        font-weight: 500;
        font-size: 16px;
        color: $white-color;
        padding: 2rem;
        .info-title {
          font-weight: normal;
          font-size: 21px;
          line-height: 38px;
          color: $aluminium-gray-color;
          margin-bottom: 1rem;
        }
        .info-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
      }
      .video-images {
        .gal-images {
          flex-direction: row;
          > div {
            flex: 1;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }
        .video-sec {
          .video-boxs-Tutorial {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      .token-details {
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: stretch;
        .left-sec {
          padding: 0px 2rem;
          position: relative;
          margin-left: 0px;
          margin-top: -2rem;
        }
        .right-sec {
          .v-text {
            display: none;
          }
          .row-one {
            flex-direction: column;
          }
          .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .claimed {
              font-weight: normal !important;
              font-size: 15px;
              line-height: 38px;
              color: $aluminium-gray-color;
            }
          }
          .creator {
          }
          .token-type {
            > div {
              margin-bottom: 0.5rem;
            }
          }
          .text {
          }
          .unlock-access {
            position: relative;
            .video-sec {
              .video-boxs-Tutorial {
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .watch-btn {
              display: inline-block;
              color: $white-color;
              position: unset;
              z-index: 5;
              top: 1rem;
              margin: 1rem auto;
              text-align: center;
              width: 100%;
              // button {
              //     position: relative;
              //     padding: 0.5rem 1rem;
              //     background: linear-gradient(158.07deg, $starstack-blue-main 6.22%, $lavender-blue-light-color 92.27%);
              //     border-radius: 11px;
              // }
            }
          }
        }
      }
      .token-info {
        font-weight: 500;
        font-size: 16px;
        color: $white-color;
        padding: 2rem;
        .info-title {
          font-weight: normal;
          font-size: 21px;
          line-height: 38px;
          color: $aluminium-gray-color;
          margin-bottom: 1rem;
        }
        .info-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
      }
      .video-images {
        .gal-images {
          flex-direction: column;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .video-sec {
          .video-boxs-Tutorial {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    @media screen and (max-width: 561px) {
    }
  }
}
