.ci-s2 {
  .ci-sb1 {
    background-image: url('../../../../img/CreatorhubInvitationImg/bgmain.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .ci-s2-b1 {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 37px;
        line-height: 45.5px;
        color: #ffffff;
      }
    }
  }

  .main-bg1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ci-s31 {
      margin: 0 auto;
      padding-top: 10px;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      flex-direction: row-reverse;
      position: relative;

      .s3-b11 {
        display: flex;
        width: 50%;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;

        .sub-b1 {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          width: 70%;

          .h31 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            color: #ffffffa8;
            width: 90%;
            text-align: left;
          }
        }
      }

      .s3-b21 {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        margin-top: 0;
        .image-wrapper {
          width: 70%;
        }
        @media screen and (max-width: 991px) {
          justify-content: center;
        }
      }
    }
  }

  .main-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ci-s3 {
      margin: 0 auto;
      padding-top: 5rem;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      flex-direction: row;
      margin: 10px;
      padding: 10px;

      .s3-b1 {
        display: flex;
        width: 50%;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .sub-b2 {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          width: 70%;
        }
      }

      .s3-b2 {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }
    }
  }

  .h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #ffffff;
  }

  .h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #ffffffa8;
    width: 90%;
  }

  .h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #ffffffa8;
    width: 90%;
    text-align: left;
  }
}
