@media screen and (max-width: $mdlg) {
  .new_user_Account {
    .setup-bg-box {
      width: 100%;
      height: auto;
    }
  }

  .banner-collection {
    .total-blance {
      svg {
        width: 100%;
      }
    }
  }

  // let me know component css
  .let-me-know {
    .darkBlk {
      min-width: 488px;
    }

    .let-meknow-text {
      .cont-box {
        margin-left: 0px;

        h4 {
          font-size: 20px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }

  // menunav
  .menuNav {
    display: flex !important;
  }
}
