.menuNav {
  display: none;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin-top: 64px;
  padding: 0 16px;

  .fontmenu {
    font-size: 20px;
    color: white;
    margin: 10px;
  }

  .menunav1 {
    width: 300px;
    background: #15152c;
    gap: 20px;

    .h2 {
      font-size: 18px;
      color: white;
      margin: 25px 10px 10px;
      padding-left: 15px;
    }
    .dropdown {
      .link {
        font-size: 16px;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 20px;
        padding-left: 15px;
        .icon {
          display: flex;
          justify-content: center;

          svg {
            width: 20px;
            height: 20px;
          }
        }
        .white-icon {
          path {
            fill: rgba($color: $aluminium-gray-color, $alpha: 0.7);
          }
        }
      }
      .active-link {
        color: #564ece !important;
        .icon {
          .custom-icon {
            path {
              stroke: $starstack-blue-main;
              fill: none;
            }
          }

          svg {
            path {
              fill: $starstack-blue-main;
            }
          }
        }
      }
      &.disabled {
        .link {
          color: #adb5bd;
          .icon {
            .custom-icon {
              path {
                stroke: #adb5bd;
                fill: none;
              }
            }
            svg {
              path {
                fill: #adb5bd;
              }
            }
          }
        }
      }
    }
  }
}
