.main-screen {
  background-image: url($aws-base-url + '/common/T&Cbackground.png');
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .head {
      font-family: $starstack-font-secondary;
      font-weight: 600;
      font-size: 38px;
      line-height: 39px;
      text-align: center;
      color: $white-color;
      margin: 1rem;
    }
    .tag {
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $white-color;
      margin: 1rem;
    }
    .body {
      font-family: $starstack-font-secondary;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      margin: 1rem;
      color: rgba(255, 255, 255, 0.69);
      margin-top: 50px;
    }
  }
  .powered-by {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    color: $white-color;
    position: absolute;
    bottom: 0;
    margin-bottom: 20vh;
    p {
      font-weight: 600;
      margin-top: 15px;
    }
    svg {
      width: 60px;
      height: 60px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 10vh;
    }
  }
}
.scroll_screen {
  padding: 5rem 20rem;
  color: rgba(255, 255, 255, 0.66);
  display: flex;
  align-items: center;
  background-color: #0d0d1a;
  line-height: 26px;
  font-weight: 400;
  font-size: 16px;
  p {
    text-align: justify;
  }
  ul {
    li {
      text-align: justify;
    }
  }

  .content {
    overflow: auto;
    .contact-us-link {
      cursor: pointer;
      font-weight: 800;
      color: #564ece;
      &:hover {
        transition: all 0.5s ease;
        text-decoration: underline;
      }
    }
    .intercom-link {
      cursor: pointer;
      font-weight: 800;
      color: #564ece;
      &:hover {
        transition: all 0.5s ease;
        text-decoration: underline;
      }
    }
  }
  ul {
    margin-left: 2rem;
    li {
      margin-bottom: 5px;
      span {
        font-weight: bold;
      }
    }
  }
}
.main_screen::before {
  opacity: 0.35;
}
