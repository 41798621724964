$rhs-width: calc(100% - 269px);
.calimed-tokens {
  .calimed-tokens-section {
    .head-filter {
      display: flex;
      justify-content: space-between;
      h4 {
        font-weight: normal;
        font-size: 20px;
        color: $white-color;
        .sec-heading {
          font-size: 20px;
          color: $white-color;
        }
        span {
          color: $starstack-blue-main;
        }
      }
    }
    .cards-sec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      .cards-container {
        flex: 3;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        .card-box {
          padding: 1rem;
          background: $cinder-black-color;
          border-radius: 24px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          .token-img {
            margin-right: 1rem;
            img {
              height: 200px;
            }
          }
          .details {
            display: flex;
            width: 100%;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            .by {
              font-weight: normal;
              font-size: 16px;
              color: rgba(255, 255, 255, 0.28);
            }
            .title {
              font-weight: normal;
              font-size: 21px;
              color: $white-color;
            }
            .claimed {
              font-weight: normal;
              font-size: 16px;
              color: $aluminium-gray-color;
              .token-rarity {
                font-weight: normal;
                font-size: 16px;
                margin-left: 10px;
                color: rgba(37, 255, 176, 0.88);
                svg {
                  path {
                    fill: rgba(37, 255, 176, 0.88);
                  }
                }
              }
            }
            .token-type {
              display: flex;
              justify-content: space-between;
              div {
                background: $starstack-blue-main;
                border-radius: 20.5px;
                width: 40px;
                height: 40px;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                margin: 0px 0.25rem;
                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }
            .req {
              font-style: italic;
              font-weight: normal;
              font-size: 16px;
              color: $starstack-blue-main;
            }
            button {
              border-radius: 2rem;
              padding: 0.75rem 1.5rem;
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }
      .filter-sec {
        flex: 1;
        // width: 350px;
        // max-width: 350px;
        padding: 1rem;
        .head-filter {
          justify-content: space-between;
          h4 {
            font-weight: normal;
            font-size: 20px;
            color: $white-color;
            .sec-heading {
              font-size: 20px;
              color: $white-color;
            }
            span {
              color: $starstack-blue-main;
            }
          }
        }
      }
    }
    @media screen and (max-width: 2560px) {
    }
    @media screen and (max-width: 1563px) {
    }
    @media screen and (max-width: 1440px) {
    }
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 768px) {
      .cards-sec {
        flex-direction: column-reverse;
        .cards-container {
          .card-box {
            .token-img {
              img {
              }
            }
            .details {
            }
          }
        }
        .filter-sec {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
    @media screen and (max-width: 600px) {
    }
    @media screen and (max-width: 561px) {
      .cards-sec {
        flex-direction: column-reverse;
        .cards-container {
          .card-box {
            flex-direction: column;
            .token-img {
              margin-bottom: 1rem;
              img {
                width: 100%;
                height: auto;
              }
            }
            .details {
            }
          }
        }
        .filter-sec {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
}
