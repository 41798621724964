//--------------- color code ---------------//

// black variants
$black-color: #000000;
$dark-color: #04050a;
$cinder-black-color: #0b0d19;
$woodsmoke-black-color: #0a0c15;
$ebony-black-color: #0b0d1a;
$mirage-black-color: #1c1924;

// blue variants
$starstack-blue-main: #564ece;
$starstack-blue-light: #5a52ff;
$chetwode-blue-color: #8984da;
$light-slate-blue-color: #7d74ff;
$marguerite-blue-color: #585fcf;
$lavender-blue-color: #8379fc;
$lavender-blue-light-color: #837bff;
$moody-blue-color: #8781e0;
$purple-blue-color: #6d36b7;
$casper-blue-color: #b7bdd4;
$purply-blue-color: #696699;

// white variants
$white-color: #fff;

//warning colors
$warning-1: #9c0000;

// gray variants
$hit-gray-color: #acacac;
$medium-gray-color: #818181;
$carbon-gray-color: #616161;
$natural-gray-color: #858585;
$aluminium-gray-color: #ababab;
$silver-gray-color: #c4c4c4;
$ship-gray-color: #3b3b42;
$vampire-gray-color: #494e68;
$chateau-gray-color: #a7a7a7;
$pastel-gray-color: #ccc;

// other colors
$light-mint: #b2ffd6;

//--------------- Rgba color code ---------------//
$white2-color: rgba(255, 255, 255, 1);
$gray4-color: rgba(222, 217, 217, 0.58);
$gray9-color: rgba(196, 196, 196, 0.1);
$gray10-color: rgba(255, 255, 255, 0.5);
$gray11-color: rgba(133, 133, 133, 1);
$black2-color: rgba(0, 0, 0, 0.4);
$black5-color: rgba(255, 255, 255, 0);
$black6-color: rgba(0, 0, 0, 0.12);
$black7-color: rgba(0, 0, 0, 1);
$greenlight-color: rgba(133, 255, 196, 1);
$bluelight-color: rgba(92, 198, 255, 1);
$par-color: rgba(188, 133, 255, 1);
