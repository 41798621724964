.similar-artist-cards {
  .similarSlider {
    &.slick-slider {
      width: 100%;

      .slick-arrow {
        &.slick-next {
          width: 150px;
          text-align: center;

          // background-image: url('../../../img/stardom.png');
          &::after {
            content: '';
            background-image: url('../../../img/svg/Arrows/FrontArrowBlue.svg');
            background-position: center center;
            background-repeat: no-repeat;
            text-align: center;
            margin-left: -30px;
            line-height: 62px;
          }

          &:hover {
            &::after {
              background-color: $white-color;
            }
          }
        }
      }
    }

    .slick-list {
      .slick-track {
        .slick-slide {
          .similar-artist-card-box {
            max-width: 75%;
            background: $cinder-black-color;
            border-radius: 19px;
            overflow: hidden;

            .pro-img-bg {
              width: 100%;

              img {
                max-width: 100%;
              }
            }

            .pro-img-cont {
              position: relative;
              width: 100%;
              margin-bottom: 60px;

              .pro-img {
                border: 5px solid $cinder-black-color;
                overflow: hidden;
                border-radius: 75px;
                position: absolute;
                margin-top: -60px;
                margin-left: -60px;
                left: 50%;
                right: 50%;
                width: 120px;
                height: 120px;

                img {
                  max-width: 100%;
                }
              }
            }

            .album-name {
              font-size: 18px;
              line-height: 38px;
            }

            .cta {
              button {
                font-weight: normal;
                border-radius: 30px;
                font-size: 16px;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    .slick-next::after {
      content: '>';
      // background-image: url('../../img/svg/Logos/SLetter.svg');
      position: absolute;
      top: 0;
      background: $white-color;
      width: 60px;
      height: 60px;
      color: $starstack-blue-main;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      line-height: 54px;
    }
  }

  @media screen and (max-width: $md) {
    .similarSlider {
      .slick-dots {
        position: absolute;
        right: 0px;
        top: -25px;
      }
    }
  }

  @media screen and (max-width: $sm) {
    .similarSlider {
      &.slick-slider {
        .slick-list {
          margin-right: 0px;

          .slick-track {
            .slick-slide {
              .similar-artist-card-box {
                max-width: 95%;
                margin-left: 5%;
              }
            }
          }
        }

        .slick-arrow {
          &.slick-next {
            display: none !important;
          }
        }
      }

      .slick-dots {
        position: absolute;
        right: 0px;
        top: -25px;
      }
    }
  }
}

.featureStarButtonContainer {
  display: flex;

  .featureStarButtonDiv {
    display: flex;
    align-items: center;

    .featureStarLeftButton {
      transform: rotate(180deg);
    }

    .featureStarButton {
      background: none;
      color: inherit;
      border: none;
      padding: 0px !important;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      font: inherit;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAYCAMAAADat72NAAAAPFBMVEVHcExVTc1QQM9WTs1YSM9QUMdWTs5VT89WTs5VUMpVTs5XTc9YUM9TTMxWTs9WTs9WTc1VTc1XTc1WTs6T3nwgAAAAE3RSTlMAnxCAICDvn98wz58gUI9/r2Bwymb4BwAAAFFJREFUKM/FyTkOgDAMBMDldrgC+P9/xS6QKOKVKCKmHeCjJdNV3ePd1DTxdz//5X+04Z/+s/9UJM8rYc96TOBb1kvVFb5DhUUStsAqbO3fewNHdgzYfkIYXgAAAABJRU5ErkJggg==)
          no-repeat;
        width: 27px;
        height: 29px;
        background-position: center center;
        background-size: contain;
        color: transparent;
        display: inline-block;
      }

      &:hover {
        background: white;
      }
    }
  }
}
