.paymentSteps {
  padding-top: 63px;

  .d-flex {
    @media (max-width: 1024px) {
      // flex-direction: column !important;
    }
  }
  .selected-amount {
    background: #141231;
    border-radius: 0px 0px 24px 24px;
    padding: 20px;
    hr {
      background: rgba(255, 255, 255, 0.66);
      height: 2px;
    }
    .error {
      color: #ff2525 !important;
    }
    .amount {
      font-weight: 600;
      font-size: 30px;
      line-height: 100%;
      color: #4ece89;
    }
    .amount-stake {
      font-weight: 600;
      font-size: 21px;
      line-height: 17px;
    }
  }

  .back-link {
    position: absolute;

    @media (max-width: 991px) {
      position: relative;
      margin-bottom: 40px;
    }

    a {
      margin-bottom: 10px;
      display: inherit;
      transition: all ease 0.4s;

      &:hover {
        margin-left: -2px;
        transition: all ease 0.4s;
      }
    }
  }

  .sectionTop {
    position: relative;
    z-index: 1;
    margin-bottom: 225px;

    &::before {
      content: '';
      background: url($aws-base-url + '/backdrop.png') no-repeat;
      position: absolute;
      right: 0%;
      bottom: 0%;
      //   transform: translateY(-50%);
      height: 897px;
      max-width: 1064px;
      width: 100%;
      z-index: 0;
    }

    &::after {
      content: '';
      background: url($aws-base-url + '/AboutBucks/YellowLight.png') no-repeat;
      position: absolute;
      right: 0;
      bottom: -6%;
      height: 876px;
      width: 100%;
      z-index: 0;
      background-size: 100% 100%;
    }
  }

  .darkBlk {
    background: $cinder-black-color;
    border-radius: 24px;
    padding: 30px 38px 30px;
    z-index: 2;
    position: relative;

    @media (max-width: 1170px) {
      padding: 20px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.66);
    }

    h2 {
      font-size: 30px;
      color: $white-color;

      @media (max-width: 1280px) {
        font-size: 26px;
      }

      @media (max-width: 991px) {
        font-size: 22px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .heading30 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: $starstack-blue-main;

    @media (max-width: 1280px) {
      font-size: 26px;
    }

    @media (max-width: 991px) {
      font-size: 22px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .leMeKnow {
    .SubHeadingTop {
      font-weight: 500;
      font-size: 18px;
      color: $starstack-blue-main;
    }

    .bucksSection {
      padding-bottom: 166px;
      flex-wrap: wrap;

      @media (max-width: 1366px) {
        padding-bottom: 7%;
      }

      @media (max-width: 1024px) {
        flex-direction: row !important;
      }

      .imgWrap {
        margin-right: 21px;
        border-radius: 7px;
        max-width: 122px;
        margin-bottom: 25px;
        flex: 0 0 25%;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .quantity {
          width: 21px;
          height: 21px;
          color: white;
          background: $starstack-blue-main;
          border-radius: 100px;
          padding: 2px;
          position: absolute;
          top: -11px;
          left: -11px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .remove {
          width: 21px;
          height: 21px;
          background: $starstack-blue-main;
          border-radius: 100px;
          padding: 2px;
          position: absolute;
          top: -11px;
          right: -11px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            background: url('../../../img/svg/CheckMark/CheckMarkWhite.svg')
              no-repeat;
            width: 9px;
            height: 9px;
            background-position: center center;
            background-size: contain;
          }
        }
      }
    }

    .borderSec {
      display: block;
      width: 100%;
      border-bottom: 1px solid rgba(90, 90, 90, 0.48);
      margin-bottom: 25px;
    }

    .totalSpend {
      span {
        font-size: 18px;
        color: $starstack-blue-main;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .note {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      color: rgba(255, 255, 255, 0.66);

      a {
        color: $starstack-blue-main;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .InsecondStep {
      .totalSpend {
        p {
          color: $white-color;
          font-size: 18px;
          margin-bottom: 0;

          @media (max-width: 1024px) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .YouAreStaking {
    &.pd-0 {
      padding: 0;

      .CardHeadSection,
      .BodyContent {
        padding: 22px 35px 58px;

        @media (max-width: 1170px) {
          padding: 20px;
        }
      }

      .CardHeadSection {
        border-bottom: 2.2px solid rgba(28, 26, 56, 0.58);
      }
    }

    .letmeKnowBlk {
      .imgWrap {
        width: 60px;
        height: 60px;
        border-radius: 100px;
        border: solid 2px #c3aeff;
        margin-right: 15px;
        box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
        -webkit-box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
        -moz-box-shadow: 1px 0px 18px 0px rgba(90, 82, 255, 0.56);
        overflow: hidden;

        @media (max-width: 991px) {
          width: 45px;
          height: 45px;
        }
      }

      h2 {
        text-align: left;

        p {
          margin-bottom: 0;
        }
      }
    }

    .changeAccessToken {
      background: #181b29;
      border: 1px solid $starstack-blue-main;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 30px;
      display: flex;

      @media (max-width: 1170px) {
        padding: 15px;
        flex-direction: column;
      }

      .leftImage {
        margin-right: 17px;
        width: 30px;
        flex: 0 0 30px;

        @media (max-width: 1170px) {
          flex: 1 1 auto;
          width: 100%;
          margin-bottom: 12px;
        }
      }

      .rightText {
        h4 {
          font-weight: 400;
          font-size: 21px;
          line-height: 38px;
          color: $white-color;

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }

        ul {
          display: flex;
          align-items: center;
          list-style: none;
          padding-left: 0;
          margin-top: 5px;
          margin-bottom: 0;

          li {
            margin-right: 20px;
            color: $white-color;

            &:last-child {
              margin-right: 0;
            }

            img {
              margin-right: 10px;
            }
          }
        }

        label {
          font-weight: 400;
          font-size: 18px;
          line-height: 38px;
          color: $starstack-blue-main;
          display: inherit;
        }

        button {
          border-radius: 100px;
          padding: 15px 17px;
          font-size: 16px;
        }
      }
    }
  }

  .selectYourBucks {
    padding: 0;

    .CardHeadSection {
      padding: 0px;
      display: flex;
      cursor: pointer;
      border-radius: 24px 24px 0px 0px;
      .selection-title {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        background: #564ece26;
        .title {
          color: white;
          font-weight: 500;
          font-size: 16px;
          line-height: 38px;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          color: linear-gradient(
            90.2deg,
            #564ece 22.33%,
            #3a32ad 100.76%,
            #3d34c9 100.76%
          );
          background: #ffffff;
        }
      }
      .selected {
        background: linear-gradient(
          90.2deg,
          #564ece 22.33%,
          #3a32ad 100.76%,
          #3d34c9 100.76%
        );
        margin-top: -15px;
      }
      border-bottom: 2.2px solid rgba(28, 26, 56, 0.58);

      @media (max-width: 1170px) {
        padding: 0px;
      }
    }

    .FooterSection {
      &.proceed {
        .CardBottom {
          .redText {
            color: $starstack-blue-main;
          }

          .subHeadTop {
            .baseText {
              color: #4ece89;
            }
          }
        }

        .BucksFooterSec {
          background: #4ece89;
          cursor: pointer;
        }
      }

      .checkout-flow-footer-adbuck {
        background: #0c0e16;
        padding: 15px 25px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.42);

        @media (max-width: 1170px) {
          padding: 20px;
        }
      }
    }

    .subHeadTop {
      h5 {
        font-weight: 500;
        font-size: 24px;
        color: $white-color;

        @media (max-width: 1280px) {
          font-size: 20px;
        }

        @media (max-width: 1024px) {
          margin-bottom: 18px;
        }

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }

      h3 {
        font-weight: 600;
        font-size: 31px;
        line-height: 17px;
        text-align: right;
        color: $starstack-blue-main;

        @media (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }
    }

    .subHeadBottom {
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #4d4e57;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.8;

        &:hover {
          transition: all ease 0.4s;
          opacity: 1;
        }

        img {
          margin-left: 15px;
        }
      }

      span {
        font-weight: normal;
        font-size: 18px;
        line-height: 37px;
        text-align: right;
        color: $starstack-blue-main;
      }
    }

    .denomination {
      padding: 40px 35px;

      @media (max-width: 1170px) {
        padding: 20px;
      }

      h3 {
        position: relative;
        font-weight: 400;
        font-size: 21px;
        line-height: 29px;
        text-align: center;
        padding-left: 39px;
        color: $white-color;

        @media (max-width: 991px) {
          font-size: 16px;
        }

        .backLink {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all ease-in-out 0.4s;
          cursor: pointer;
          opacity: 0.5;

          &:hover {
            opacity: 1;
            left: -2px;
            transition: all ease-in-out 0.4s;
          }
        }
      }

      .denominationBody {
        text-align: center;
        // padding: 5% 0;
        min-height: 540px;
        height: 100%;
        .dontHaveWallet {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: rgba(255, 255, 255, 0.38);
          max-width: 475px;
          margin: 0 auto 15px;
        }

        .getBucksLink {
          font-weight: 500;
          font-size: 20px;
          line-height: 44px;
          text-align: center;
          text-decoration-line: underline;
          color: $starstack-blue-main;
          cursor: pointer;
        }
      }

      .CardBottom {
        background: #141231;
      }
      .checkout-chip {
        background: rgba(86, 78, 206, 0.2);
        color: $white-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        border-radius: 16px;
        cursor: pointer;
        &.selected {
          background: $starstack-blue-main;
        }
        &:hover {
          background: $starstack-blue-main;
        }
      }
    }

    .denominationBody {
      text-align: center;
      // padding: 5% 0;
      min-height: 540px;
      height: 100%;
      .carousel-Section {
        .value {
          font-weight: 500;
          font-size: 31px;
          line-height: 38px;
          text-align: center;
          color: $starstack-blue-main;
          margin-bottom: 30px;
          margin-top: -15px;
        }

        .owl-carousel {
          position: relative;

          .owl-item {
            transform: scale(0.9);
            transition: all 0.2s ease-in-out;
            opacity: 0.6;

            &.active.center {
              transform: scale(1);
              cursor: pointer;
              opacity: 1;

              .Block {
                position: relative;
                transition: all 0.4s ease;

                &::after {
                  content: '';
                  width: 0;
                  height: 0;
                  background: rgba(86, 78, 206, 0.81);
                  border-radius: 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transition: all ease 0.4s;
                  transform: translate(-50%, -50%);
                }

                &::before {
                  position: absolute;
                  content: 'Select this denomination';
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: $white-color;
                  top: 50%;
                  left: 50%;
                  max-width: 107px;
                  transform: translate(-50%, -50%);
                  z-index: 0;
                  opacity: 0;
                  transition: all ease 0.3s;
                }

                &.buck {
                  &::before {
                    content: 'Click to add these buck' !important;
                  }
                }

                &:hover {
                  transition: all ease 0.3s;

                  &::before {
                    opacity: 1;
                    z-index: 1;
                    transition: all ease 0.3s;
                  }

                  &:after {
                    width: 199px;
                    height: 100%;
                    transition: all ease 0.4s;
                  }
                }
              }
            }

            img {
              max-width: 199px;
              width: 100%;
            }
          }

          .owl-nav {
            button {
              width: 20%;
              height: 100%;
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;

              &:before {
                position: absolute;
                width: 100%;
                height: 100%;
              }

              span {
                background: $white-color;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: transparent;
                display: inherit;
                position: relative;

                &:before {
                  width: 18px;
                  height: 14px;
                  background-size: contain;
                  background-position: center center;
                }
              }

              &.owl-prev {
                left: -30px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                &:before {
                  content: '';
                  background: linear-gradient(
                    90deg,
                    $cinder-black-color -5.56%,
                    rgba(11, 13, 25, 0) 93.01%
                  );
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0px;
                }

                span {
                  &:before {
                    background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
                      no-repeat;
                    background-size: contain;
                    transform: rotate(180deg);
                    content: '';
                    background-position: center center;
                    position: absolute;
                    top: 13px;
                    left: 10px;
                  }
                }
              }

              &.owl-next {
                right: -30px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                &:before {
                  content: '';
                  background: linear-gradient(
                    90deg,
                    $cinder-black-color -5.56%,
                    rgba(11, 13, 25, 0) 93.01%
                  );
                  transform: rotate(-180deg);
                  position: absolute;
                  right: 0px;
                  top: 0;
                }

                span {
                  &::before {
                    background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
                      no-repeat;
                    background-size: contain;
                    content: '';
                    background-position: center center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }

          .item {
            .Block {
              overflow: hidden;
              width: 198px;
              height: 397px;
              img {
                margin: 0 auto;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .CardBottom {
      background: #121420;
      padding: 25px;
      border-radius: 0;

      @media (max-width: 1170px) {
        padding: 20px;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: $white-color;

        opacity: 0.36;

        @media (max-width: 1024px) {
          margin-bottom: 10px;
          display: inherit;
        }
      }

      .redText {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #ff2525;
      }

      .subHeadTop {
        .baseText {
          font-weight: 600;
          font-size: 30px;
          line-height: 17px;
          text-align: right;
          color: #ff2525;

          @media (max-width: 1280px) {
            font-size: 26px;
          }

          @media (max-width: 991px) {
            font-size: 22px;
          }

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
      }

      .subHeadBottom {
        h4 {
          font-weight: 600;
          font-size: 21px;
          line-height: 17px;
          text-align: right;
          color: $white-color;

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
      }
    }

    .BucksFooterSec {
      background: #ff2525;
      border-radius: 0;
      padding: 26px 25px;
      font-weight: bold;
      font-size: 20px;
      line-height: 44px;
      text-align: center;
      cursor: not-allowed;
      color: $white-color;

      @media (max-width: 1170px) {
        padding: 20px;
      }

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }

    .checkout-bucks-list {
      background-color: #18172a;
      overflow-y: auto;
      height: 666px;
      .checkout-remove-buck {
        position: absolute;
        padding: 0;
        right: -25px;
        top: -5px;
        svg {
          height: 24px;
          width: 24px;
        }
        svg,
        path {
          fill: $starstack-blue-main;
        }
      }
    }
  }

  .needSomeBucks {
    text-align: center;
    padding: 0 25px 25px;
    position: relative;
    z-index: 2;

    h2 {
      color: $white-color;
    }
  }
  .payment-right-text {
    svg,
    path {
      fill: $starstack-blue-main;
      stroke: $starstack-blue-main;
    }
    .nav-link {
      color: $starstack-blue-main !important;
      &::after {
        border-width: 0.4em;
      }
    }
    .dropdown-menu-dark {
      background-color: #141231;
      text-align: right;
    }
  }

  .checkout-get-bucks-tab,
  .checkout-spend-bucks-tab {
    background: #0b0d19;
    border-radius: 0px 0px 34px 26px;
    overflow: hidden;

    .top-section {
      div.d-flex {
        span {
          color: $white-color;
          font-weight: 500;
          font-size: 18px;
          line-height: 17px;
          &.text-pink {
            color: #ff3f56;
          }
        }
        h5 {
          color: $starstack-blue-main;
          font-weight: 500;
          font-size: 23px;
          line-height: 21px;
          &.text-pink {
            color: #ff3f56;
          }
        }
      }
      p {
        color: rgba(255, 255, 255, 0.66);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
      }
    }
  }

  .checkout-get-bucks-tab {
    .get-nftbuck-container {
      height: 676px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #04050a;
      .nftbuck-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        img {
          height: 280px;
          width: 140px;
        }
        button {
          font-weight: 500;
          font-size: 12px;
          background: #564ece;
          border-radius: 19px;
          padding: 4px 10px;
          width: max-content;
          &:hover {
            background: transparent;
          }
        }
        div.gap-2 {
          span {
            font-weight: 500;
            font-size: 12px;
            color: $white-color;
          }
          .purchase-qty {
            display: flex;
            background: rgba(86, 78, 206, 0.19);
            border-radius: 19px;
            padding: 6px 12px;
            gap: 9px;
            align-items: center;
            span {
              cursor: pointer;
              color: $white-color;
              font-weight: 500;
              font-size: 14px;
              line-height: 10px;
              user-select: none;
            }
            h6 {
              color: $starstack-blue-main;
              font-weight: 500;
              font-size: 14px;
              line-height: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .bottom-section {
      div.d-flex {
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 17px;
          color: $white-color;
        }
      }
      .crypto-dropdown {
        background: #1f2231;
        border-radius: 6px;
        padding: 6px 48px 6px 12px;
        color: $white-color;
        position: relative;
        cursor: pointer;
        &::after {
          position: absolute;
          top: 10px;
          right: 5px;
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-right: 0.8px solid rgba(255, 255, 255, 0.33);
          border-top: 0.8px solid rgba(255, 255, 255, 0.33);
          transform: rotate(135deg);
          margin-right: 0.5em;
          margin-left: 1em;
        }
      }
    }
    .button-section {
      border-top: 2.2px solid #2f2e46;
      display: flex;
      align-items: center;
      gap: 20px;
      button {
        width: 100%;
        &.btn-primary {
          background: #564ece;
          &:hover {
            background: transparent;
          }
        }
        &.btn-text {
          color: $starstack-blue-main;
          text-decoration: underline;
        }
      }
    }
  }

  .checkout-spend-bucks-tab {
    .spend-nftbuck-container {
      background: #04050a;
      .sort-denomination {
        h6 {
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          color: $white-color;
        }
        .sort-by {
          color: $white-color;
          background: rgba(86, 78, 206, 0.2);
          border-radius: 16.5px;
          cursor: pointer;
          padding: 4px 12px;
          &.selected,
          &:hover {
            background: linear-gradient(
              90.2deg,
              #564ece 22.33%,
              #3a32ad 100.76%,
              #3d34c9 100.76%
            );
          }
        }
      }
    }
    .bottom-section {
      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: rgba($color: $white-color, $alpha: 0.33);
        margin-bottom: 0;
      }
      span {
        font-weight: 600;
        font-size: 21px;
        color: $white-color;
        &.text-green {
          font-size: 30px;
          color: #4ece89;
        }
        &.text-pink {
          font-size: 30px;
          color: #ff3f56;
        }
      }
    }
    p.extra-text {
      border-top: 2.2px solid #2f2e46;
      color: rgba(255, 255, 255, 0.66);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    p.text-pink {
      color: #ff3f56;
    }
    .empty-bucks-list {
      min-height: 500px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      padding-top: 24px;
      margin-top: 10px;
      span {
        cursor: pointer;
      }
    }
  }
}
