.agent-card {
  background: #0b0c11 !important;
  backdrop-filter: blur(93px) !important;
  border-radius: 20px !important;
  max-width: 610px;
  width: fit-content;
  .agent-card-content {
    display: flex;
    .left-section {
      img {
        border-radius: 50%;
      }
    }
    .right-section {
      padding: 0 2rem;
      .buttons-wrapper {
        button {
          border-radius: 9px;
          padding: 10px 20px !important;
          width: 178px;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: #ffffff;
          @media screen and (max-width: 600px) {
            font-size: 12px;
            line-height: 20px;
            margin-left: 0 !important;
          }
        }
        @media screen and (max-width: 600px) {
          display: flex;
          gap: 10px;
          flex-direction: column;
        }
      }
      .agent-information {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #564ece;
        opacity: 0.5;
        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 10px;
        }
      }
      .agent-wallet {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #564ece;
        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 10px;
        }
      }
      .agent-email {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        text-decoration-line: underline;
        color: rgba(255, 255, 255, 0.28);
        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 10px;
        }
      }
      .starName {
        font-weight: 400;
        font-size: 30px;
        line-height: 42px;
        color: #ffffff;
        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 0;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}
