.fix-height-700{
    height: 712px;
}
.preview-links{
    a {
        font-weight: 500;
        font-size: 20px;
        text-decoration-line: underline;
        color: $starstack-blue-main;
    }
} 
.INFTDetailsSummary{
    h2 {
        font-weight: normal;
        font-size: 30px;
        span{
            color: $starstack-blue-main;
        }
    }
} 
.toogle-s{
    &[aria-expanded="true"] + p{
          display: none;
    }
    &[aria-expanded="true"]{
        .off-toogle{
            display: none;
        }
        .on-toogle{
            display: block;
        }
    }
    &[aria-expanded="false"]{
        .off-toogle{
            display: block;
        }
        .on-toogle{
            display: none;
        }
    }
}
.ip-royalties {
.collapse  {
    .css-xb97g8 {
        display: none;
    }
    .css-26l3qy-menu{
        background: rgba(36, 33, 76, 0.67) !important;
        .css-4ljt47-MenuList{
            background: transparent !important;
            font-weight: normal;
            font-size: 16px;
            color: $white-color;
            padding: 0 !important;
            .css-1n7v3ny-option {
                background: transparent !important; 
                color: $starstack-blue-main !important;
                font-weight: normal;
                font-size: 16px;
            }
        }
    }
}
}

.custom-fields{
    .btn-save {
        font-weight: 500;
        font-size: 18px;
        color: $starstack-blue-main;
    }
    .btn-add-social[aria-expanded="true"]{
         display: none;
    }
    .btn-add-social[aria-expanded="false"]{
        display: block;
   }
}