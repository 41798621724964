.my-stars-agent-manager {
  .starCard {
    background: #0b0c11;
    backdrop-filter: blur(93px);
    border-radius: 20px;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 10px;
    margin-right: 10px;
    width: 524px;

    .starcard-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .starImage {
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .confirmation-await {
      color: yellow;
      font-weight: bolder;
    }
    .confirmation-reject {
      color: red;
      font-weight: bolder;
    }
    .confirmation-approve {
      color: greenyellow;
      font-weight: bolder;
    }
    .starType {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.66);
      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .starName {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.05em;
      color: #ffffff;
      margin-top: 2px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 25px;
        margin-top: 1px;
      }
    }
    .starInfo {
      position: relative;
      padding: 1rem;
      background: rgba(239, 233, 255, 0.03);
      backdrop-filter: blur(93px);
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 2rem;
      .vertical-line {
        position: absolute;
        border-left: 1px solid rgba(255, 255, 255, 0.12);
        height: 70px;
        left: 50%;
        top: 10px;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .earnings {
        .earningImage {
          width: 16px;
          height: 15.31px;
          left: 0px;
          top: 2.58px;
        }
        .earningCount {
          display: flex;
          gap: 4px;
          align-items: center;
          p {
            font-family: $starstack-font-Montserrat;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(239, 233, 255, 0.6);
          }
        }
        .starEarning {
          display: flex;
          flex-direction: column;
          font-family: $starstack-font-Montserrat;
          font-weight: 800;
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.01em;
          color: #ffffff;
          @media screen and (max-width: 600px) {
            margin-top: 10px;
            font-size: 25px;
            line-height: 35px;
          }
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 1rem;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2rem;

      .visit-creatorhub-button {
        width: 100%;
        font-size: 16px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        svg {
          height: 20px;
          width: 20px;
        }
        &:hover {
          span {
            transition: all 0.3s ease;
            margin-right: 10px;
          }
        }
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 0.5rem;
        gap: 10px;
      }
    }
  }

  .profileButton {
    width: 202px;
    height: 53px;
    background: #564ece;
    border-radius: 9px !important;
    font-family: $starstack-font-main !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 0px !important;
  }

  .salesButton {
    width: 202px;
    height: 53px;
    background: #0b0c11 !important;
    border: 1px solid #564ece !important;
    border-radius: 9px !important;
    font-family: $starstack-font-main !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 0px !important;
    &:hover {
      background: #564ece !important;
    }
  }
}
