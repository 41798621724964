.ng-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ng-main {
    display: flex;
    background: linear-gradient(
      117.24deg,
      rgba(255, 255, 255, 0.076) 5.43%,
      rgba(255, 255, 255, 0.016) 88.67%
    );
    backdrop-filter: blur(90px);
    border-radius: 35px;
    flex-direction: row;
    width: 80%;
    border: 1px solid #ffffff;

    .ng-b1 {
      width: 50%;
      margin: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .ng-b1-h1 {
        font-size: 30px;
        font-weight: 600;
        font-family: Poppins;
        line-height: 74px;
        color: white;
      }
    }
    .ng-b2 {
      width: 50%;
      margin: 20px;
      display: flex;
      flex-direction: column;

      .ng-b2-h1 {
        font-size: 30px;
        font-weight: 600;
        font-family: Poppins;
        line-height: 74px;
        color: white;
      }

      .ng-b3 {
        display: flex;
        width: 100%;

        .ng-b2-h2 {
          font-size: 21px;
          font-weight: 400;
          font-family: Poppins;
          line-height: 21px;
          color: white;
          text-align: left;
          width: 50%;
        }
      }

      .ng-b2-h3 {
        font-size: 21px;
        font-weight: 500;
        font-family: Poppins;
        line-height: 21px;
        color: white;
      }

      .ng-b2-h4 {
        font-size: 14px;
        font-weight: 400;
        font-family: Poppins;
        line-height: 21px;
        color: white;
        width: 80%;
      }
      .ng-b2-h5 {
        font-size: 16px;
        font-weight: 400;
        font-family: Poppins;
        line-height: 21px;
        color: #564ece;
      }
    }
    .ng-b4 {
      display: flex;
      gap: 20px;
    }
    .ng-b5 {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.divider-main-wrapper {
  width: 100%;
  .img-devider {
    position: relative;
    .gradient-wrapper {
      position: absolute;
      top: 27%;
      right: 10%;
      z-index: 1;
      .gradient-div {
        position: relative;
        background: linear-gradient(
          136.24deg,
          #564ece 1.34%,
          #ada7ff 111.12%,
          #756cff 111.13%
        );
        border-radius: 8px;
        padding: 30px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 20px;
        letter-spacing: 2px;
        color: #ffffff;
        svg {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .gradient-div-slogo {
    position: absolute;
    background: linear-gradient(
      136.24deg,
      #564ece 1.34%,
      #ada7ff 111.12%,
      #756cff 111.13%
    );
    border-radius: 8px;
    padding: 30px;
    right: 20%;
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 50px;
      height: 50px;
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .divider-main-wrapper {
    .img-devider {
      .gradient-wrapper {
        position: absolute;
        top: 21%;
        right: 5%;
        .gradient-div {
          border-radius: 8px;
          padding: 25px 20px;
          font-weight: 300;
          font-size: 25px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
@media screen and (min-width: 765px) and (max-width: 990px) {
  .divider-main-wrapper {
    .img-devider {
      .gradient-wrapper {
        top: 20%;
        right: 5%;
        .gradient-div {
          border-radius: 8px;
          padding: 15px;
          font-weight: 300;
          font-size: 20px;
          letter-spacing: 1px;
        }
      }
    }
    .gradient-div-slogo {
      padding: 30px;
      top: 10%;
    }
  }
}
@media screen and (min-width: 561px) and (max-width: 764px) {
  .divider-main-wrapper {
    .img-devider {
      .gradient-wrapper {
        top: 23%;
        right: 5%;
        .gradient-div {
          border-radius: 8px;
          padding: 10px;
          font-weight: 300;
          font-size: 15px;
          letter-spacing: 1px;
          svg {
            margin-right: 5px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .gradient-div-slogo {
      padding: 20px;
      top: 15%;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 560px) {
  .divider-main-wrapper {
    .img-devider {
      .gradient-wrapper {
        top: 22%;
        right: 5%;
        .gradient-div {
          border-radius: 8px;
          padding: 10px;
          font-weight: 300;
          font-size: 10px;
          line-height: 10px;
          letter-spacing: 0px;
          svg {
            margin-right: 5px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .gradient-div-slogo {
      padding: 10px;
      top: 15%;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
