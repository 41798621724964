.new_user_Account {
  .nav-pills {
    &:before {
      content: '';
      position: absolute;
      left: 31px;
      border-left: 1px dashed $white-color;
      top: 12px;
      bottom: 10px;
    }

    .nav-link {
      color: $white-color;
      background: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      display: flex;
      cursor: pointer;

      span {
        min-width: 31px;
        height: 31px;
        background: rgb(69 69 73);
        display: inline-block;
        border-radius: 100%;
        z-index: 1;
      }

      &.active {
        span {
          background: linear-gradient(
            134.22deg,
            rgba(86, 78, 206, 0.91) 9.27%,
            rgba(0, 148, 255, 0.91) 142.83%
          );
          transform: scale(1.2);
        }
      }

      &.active-done {
        span {
          background: linear-gradient(
            134.22deg,
            rgba(86, 78, 206, 0.91) 9.27%,
            rgba(0, 148, 255, 0.91) 142.83%
          );
          transform: scale(1.2);

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: -3px;
            bottom: 0;
            transform: rotate(45deg);
            height: 16px;
            width: 8px;
            border-bottom: 3px solid $white-color;
            border-right: 3px solid $white-color;
            margin: auto;
          }
        }
      }
    }
  }

  .connect-user-new {
    h2,
    h3 {
      color: $white-color;
      font-weight: normal;
      font-size: 30px;
      line-height: 38px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $gray4-color;

      a {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .setup-bg-box {
    width: 628px;
    height: 533px;
    background: $cinder-black-color;
    // box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 31px;

    .btn {
      width: 316px;
      padding-left: 0;
      padding-right: 0;
    }

    a {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: $starstack-blue-main;
      text-decoration: none;
    }

    .form-control {
      height: 63px;
      background: rgba(196, 196, 196, 0);
      border-radius: 31.5px;
      border: none;
      padding: 0px 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: $natural-gray-color;
      box-shadow: 0px 0px 0px 1px $gray9-color;

      &:focus {
        box-shadow: 0px 0px 0px 1px $marguerite-blue-color;
      }
    }
  }

  .Bucks-To-Stake {
    position: relative;

    &::after {
      content: '';
      background-image: url('../../../img/bg-shape-bucks.png');
      position: absolute;
      left: -25px;
      top: 27%;
      width: 210px;
      height: 336px;
    }

    .setup-bg-box {
      height: 631px !important;
    }
  }
}

button.btn {
  .triangle-icon {
    border-top: 6.5px solid transparent;
    border-bottom: 6.5px solid transparent;
    border-left: 12px solid $starstack-blue-main;
    display: inline-block;
    width: 0px;
    height: 0px;
  }
  &:hover {
    .triangle-icon {
      border-left: 12px solid $white-color;
    }
  }
}
