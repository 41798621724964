.bundle-description-section {
    position: relative;
    .abs-bg-row {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        z-index: -1;
        .abs-bg {
            height: 100%;
            background: $cinder-black-color;
            border-radius: 24px;
        }
    }
    h4 {
        font-weight: normal;
        font-size: 21px;
        line-height: 38px;
        color: $white-color;
    }
    .availability {
        color: $ship-gray-color;
        span {
            color: $starstack-blue-main;
        }
    }
    .add-to-cart {
        padding: 0.75rem 1.25rem;
        background: $gray9-color;
        border-radius: 25.5px;
        font-weight: 500;
        font-size: 16px;
        outline: 0px;
        border: 0px;
        color: $white-color; 
    }
    .bundle-detail {
        .path {
            font-weight: normal;
            line-height: 38px;
            color: $aluminium-gray-color;
        }
        .bundle-price {
            line-height: 38px;
            color: $starstack-blue-main;
        }
        .box-address {
            // background: #$ebony-black-color;
            border-radius: 11px;
            .text-container {
                margin-left: 50px;
            }
            .created-by {
                a {
                    color: $starstack-blue-main;
                }
            }
            .collection-edition {
                color: $starstack-blue-main;
            }
            .collection-maintained {
                span {
                    color: $starstack-blue-main;
                }
            }
            h4 {
                font-weight: normal;
                font-size: 21px;
                color: $white-color;
            }
            p {
                color: $aluminium-gray-color;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 38px;
            }
            h3 {
                font-weight: normal;
                font-size: 21px;
                line-height: 30px;
                color: $white-color;
            }
            .token-info-heading {
                color: $vampire-gray-color;
            }
            .token-info {
                .secondary-col {
                    color: $starstack-blue-main;
                }
            }
        }
    }
    .add-address {
        .btn {
            width: 316px;
            height: 73px;
        }
    }
}

.dark-bg {
    background: $cinder-black-color;
    border-radius: 24px;
}

.count {
    color: $starstack-blue-main;
}