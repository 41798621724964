@import '../../Style/sass/abstracts/variables';
@import '../../Style/sass/core/typography';

.credit-card-payment-status-wrapper {
  margin-top: 65px;
  min-height: calc(100vh - 65px);
  background-image: url($aws-base-url + '/Giphy/ConvertBucks.gif');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  div {
    width: 50%;
    min-width: 500px;
    max-width: 85%;
    backdrop-filter: blur(18.5px);
    background: rgba(48, 44, 97, 0.33);
    padding: 40px;
    border-radius: 16px;
    text-align: center;

    h4 {
      font-family: $starstack-font-gemsbuck;
      font-size: 24px;
      color: #fff;
    }

    p {
      font-size: 18px;
    }

    img,
    svg {
      height: 120px;
      width: 120px;
      object-fit: contain;
    }

    h6 {
      margin-top: 20px;
      color: #bbc9ed;

      strong {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    em {
      font-size: 13px;
      color: #bbc9ed;
    }
  }
}
