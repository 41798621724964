.bucks-complete-transaction {
  .page-heading {
    color: $white-color;
    display: flex;
    flex-direction: row;
    margin-top: 120px;
    margin-bottom: 30px;

    .back-link {
      margin: 0;
    }

    h3 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;
      margin-left: 10px;
    }

    p {
      margin-left: 10px;
      font-weight: normal;
      font-size: 16px;
      color: #ababab;
    }
  }

  .banner-collection {
    button {
      &.rounded {
        border-radius: 50px !important;
      }
    }
  }

  // SELECT NFT SECTION
  .select-nft {
    background-image: url('../../../img/svg/Background/YellowLight.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    .shadow-hassle-img {
      z-index: -1;

      svg {
        width: 100%;
      }
    }

    .filter {
      display: flex;
      gap: 30px;
      align-items: center;
      flex-wrap: wrap;

      .input {
        &.search {
          position: relative;

          input {
            padding: 15px 30px 15px 50px;
            background-color: rgba(196, 196, 196, 0.1);
            border-radius: 26px;
            @media screen and (max-width: 1024px) {
              max-width: 200px;
            }
          }

          .icon {
            position: absolute;
            z-index: 1;
            top: 18px;
            left: 18px;
          }
        }
      }
      .react-select-container {
        &.filter {
          .react-select__control {
            min-width: 250px;
            padding: 10px;
            background-color: rgba(196, 196, 196, 0.1);
            border-radius: 26px;
            @media screen and (max-width: 1024px) {
              max-width: 200px;
              min-width: 200px;
            }
          }
        }
      }
    }

    .box {
      margin: 1rem;
      flex: 1;
      background-color: $cinder-black-color;

      h3 {
        font-weight: normal;
        font-size: 21px;
        color: $white-color;
      }

      .sub-heading {
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height, or 131% */
        color: rgba(255, 255, 255, 0.66);
      }

      //CARDS
      .cards-holder {
        height: 550px;
        overflow-y: scroll;

        .card {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          background: #141231;
          border-radius: 24px;
          padding: 1rem;
          margin: 14px 0px;
          margin-right: 0.5rem;

          .buck-img {
            img {
              width: 80px;
            }
          }

          .card-details {
            padding-left: 1rem !important;
            width: 100%;

            .card-title-qty-price {
              position: relative;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: stretch;
              height: 100%;

              .check-mark {
                border-radius: 15px;
                background-color: hsla(0, 0%, 100%, 0.259);
                position: absolute;
                width: 30px;
                height: 30px;
                top: 0;
                right: 0;
                z-index: 10;
              }

              .active {
                background: $white-color;
                background-image: url('../../..//img/svg/CheckMark/CheckMark.svg');
                background-position: center center;
                background-repeat: no-repeat;
              }

              .card-title {
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                color: $white-color;
                margin-bottom: 1rem;
              }

              .card-qty {
                font-weight: normal;
                font-size: 16px;
                color: $gray10-color;

                > span {
                  padding: 0.3rem 1.5rem;
                  background: $gray9-color;
                  border-radius: 26px;
                  font-weight: normal;
                  font-size: 16px;
                  text-align: center;
                  color: $marguerite-blue-color;
                  margin-right: 1rem;
                }
              }

              .select-qty {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                font-weight: normal;
                font-size: 18px;
                color: $white-color;

                > span {
                  margin-left: 1rem;
                  display: inline-block;
                }

                .select-input {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  align-content: center;

                  > span {
                    margin-right: 1rem;
                  }
                }

                .cta {
                  button {
                    &.view-details-btn {
                      padding: 0.5rem 1rem !important;
                      font-weight: normal !important;
                      font-size: 16px;
                      text-align: center;
                      color: $white-color;
                      border-radius: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .react-select-container {
      &.filter {
        min-width: 150px;
        @media screen and (max-width: 991px) {
          width: 300px;
        }
      }

      .react-select__control css-13cymwt-control {
        width: 100%;
      }
      .react-select__control {
        padding: initial;
        .react-select__value-container {
          .react-select__single-value {
            color: $white-color !important;
          }
        }
      }

      &.converter {
        .react-select__control {
          padding: initial;

          .react-select__value-container {
            background: rgba(196, 196, 196, 0) !important;

            .react-select__single-value {
              color: $white-color;
            }
          }
        }
      }

      &.creation {
        .react-select__control {
          .react-select__value-container {
            &.react-select__value-container--has-value {
              color: $white-color;

              .react-select__single-value {
                color: $white-color !important;
              }
            }
          }
        }
      }
    }
  }

  //SELECTED SECTION
  .selected-list {
    .buck-imgs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .buck-img {
        margin-right: 0.5em;

        img {
          width: 80px;
        }
      }
    }

    .selected-buck-details {
      padding-top: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.32);

      .details {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin-bottom: 1rem;

        .card-title {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: $white-color;
          max-width: 50%;
        }

        .card-qty-amount {
          width: 40%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          align-content: center;

          .card-qty {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $gray10-color;
          }

          .amount {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: $starstack-blue-main;
          }
        }
      }
    }

    .total-sec {
      .total {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        font-weight: normal;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.66);

        span {
          color: $starstack-blue-main;
        }
      }

      .converting-to {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
    }

    .check {
      font-size: 16px;
      line-height: 21px;
      color: $white-color;

      .form-check {
        .form-check-input {
          background-color: transparent !important;
          border: 1px solid $starstack-blue-main;
          width: 20px;
          height: 20px;
          border-radius: 0px;
          padding: 4px;
        }

        .form-check-label {
          padding-left: 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .select-nft {
      flex-direction: column;

      .filter {
        .input {
          &.search {
            position: relative;

            input {
              padding-left: rem;
            }

            .icon {
              padding: 0.5rem 1rem;
              position: absolute;
              z-index: 1;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
  }

  @media screen and (max-width: 561px) {
    .select-nft {
      flex-direction: column;

      .filter {
        // flex-direction: column;
        .input {
          &.search {
            position: relative;

            input {
              padding-left: rem;
            }

            .icon {
              padding: 0.5rem 1rem;
              position: absolute;
              z-index: 1;
            }
          }
        }
      }

      .box {
        margin: 1rem;
        flex: 1;
        background-color: $cinder-black-color;

        h3 {
          font-weight: normal;
          font-size: 21px;
          color: $white-color;
        }

        .sub-heading {
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          /* identical to box height, or 131% */
          color: rgba(255, 255, 255, 0.66);
        }

        //CARDS
        .cards-holder {
          height: 550px;
          overflow-y: scroll;

          .card {
            flex-direction: column;

            .buck-img {
              text-align: center;
              margin-bottom: 1rem;

              img {
                width: 100px;
              }
            }

            .card-details {
              padding-left: 1rem !important;
              width: 100%;

              .card-title-qty-price {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: stretch;
                height: 100%;

                .check-mark {
                  border-radius: 15px;
                  background-color: hsla(0, 0%, 100%, 0.259);
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  top: 0;
                  right: 0;
                  z-index: 10;
                }

                .card-title {
                  margin-right: 1.5rem;
                }

                .card-qty {
                  margin-bottom: 1rem;
                  text-align: center;
                }

                .select-qty {
                  flex-direction: column;

                  .select-input {
                    margin-bottom: 1rem;
                  }

                  .cta {
                    button {
                      &.view-details-btn {
                        padding: 0.5rem 1rem !important;
                        font-weight: normal !important;
                        font-size: 16px;
                        text-align: center;
                        color: $white-color;
                        border-radius: 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.react-select-container {
  &.converter {
    .react-select__control {
      padding: initial;

      .react-select__value-container {
        background: rgba(196, 196, 196, 0) !important;

        .react-select__single-value {
          color: $starstack-blue-main !important;
        }
      }

      .react-select__indicator-separator {
        background-color: hsla(0, 0%, 80%, 0) !important;
      }

      .react-select__dropdown-indicator {
        padding-left: 0px !important;
      }
    }
  }
}
