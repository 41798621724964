@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Gemsbuck 01';
  src: url('../../../Fonts/gemsbuck/Bold.otf');
  format: ('otf');
  font-display: swap;
}
@font-face {
  font-family: 'Dustfine';
  src: url('../../../Fonts/dustfine/Dustfine.otf');
  format: ('otf');
  font-display: swap;
}
@font-face {
  font-family: 'ElementalEnd';
  src: url('../../../Fonts/elemental_end/ElementalEnd.ttf');
  format: ('ttf');
  font-display: swap;
}

$starstack-font-main: 'Poppins', sans-serif;
$starstack-font-secondary: 'Inter', sans-serif;
$starstack-font-Montserrat: 'Montserrat', sans-serif;
$starstack-font-header: 'Space Grotesk', sans-serif;
$starstack-font-dustfine: 'Dustfine', sans-serif;
$starstack-font-gemsbuck: 'Gemsbuck 01', sans-serif;
$starstack-font-syne: 'Syne', sans-serif;
$starstack-font-elemental: 'ElementalEnd', sans-serif;
