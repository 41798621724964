.hub-watchlist {
    .head-filter {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        .sec-heading {
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            color: $white-color;
        }
        .FilterBtn {
            max-width: 200px;
            @media (max-width: 767px) {
                max-width: 100%;
            }
            .dropdown-toggle {
                text-align: center;
                background: transparent;
                color: $natural-gray-color;
                border: 1px solid $silver-gray-color;
                height: 52px;
                border-radius: 100px;
                padding: 8px 20px;
                width: 100%;
                img {
                    margin-right: 12px;
                }
                &:hover,
                &:active,
                &:focus {
                    background: $starstack-blue-main;
                    border-color: $starstack-blue-main;
                    color: $white-color;
                }
                &::after {
                    display: none;
                }
            }
        }
        .input-group {
            position: relative;
            .dropdown {
                flex: 1;
            }
            .dropdown-toggle {
                padding-left: 0;
            }
        }
        .dropdown-toggle {
            padding: 10px 50px 10px 30px;
            flex: 1;
            text-align: left;
            width: 100%;
            color: $natural-gray-color;
            font-size: 16px;
            box-shadow: none;
            &::after {
                position: absolute;
                right: 28px;
                top: 32%;
                transform: translateY(-50%);
                border: solid $gray11-color;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 5px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }
        .show>.btn-success.dropdown-toggle {
            background-color: $starstack-blue-main;
            border-color: $starstack-blue-main;
            &::after {
                border-color: $white-color;
            }
        }
        .dropdown-menu {
            border: 1px solid rgba(255, 255, 255, 0.07);
            background: #222224;
            backdrop-filter: blur(20px);
            border-radius: 16px;
            left: auto !important;
            right: 0 !important;
            a {
                border-bottom: 1px solid rgba(255, 255, 255, 0.07);
                color: $white-color;
                transition: all ease 0.4s;
                padding: 10px 28px;
                &:last-child {
                    border-bottom: 0;
                }
                &:hover,
                &:active,
                &:focus,
                &.active {
                    transition: all ease 0.4s;
                    color: $starstack-blue-main;
                    background: transparent;
                }
            }
        }
    }
}