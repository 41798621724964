.nav-button-main-wrapper {
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      270.29deg,
      #564ece 1.02%,
      #3a32ad 102.93%,
      #3d34c9 102.94%
    );
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    overflow: visible;
    background-color: transparent;
    height: 1px;
    border-radius: 10px;
  }
  .nav-tab-wrapper {
    margin: 1rem 0.5rem;
    min-width: max-content;
    button {
      min-width: max-content !important;
      border: 0px;
      font-style: normal;
      font-size: 16px;
      font-weight: normal;
      padding: 0.5rem 1.5rem;
      border-radius: 26px;
      color: white;
      background: rgba(38, 19, 49, 0.55);
      &:hover {
        border: none;
        background: #644ea2;
        box-shadow: 0px 3px 23px 0px rgba(86, 78, 206, 0.11);
      }
      &:focus {
        box-shadow: none;
      }
    }
    &-active {
      button {
        color: white;
        background: #564ece;
        &:hover {
          background: linear-gradient(
            89.27deg,
            #fc1885 0.23%,
            #564ece 0.24%,
            #561eef 99.74%
          );
        }
        &.active {
          background: #564ece;
          box-shadow: 0px 3.18149px 24.3914px rgba(86, 78, 206, 0.11);
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
