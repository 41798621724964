.share-model {
  .modal-dialog {
    max-width: 630px;

    .modal-content {
      min-height: 260px;
      background: #16122a;
      box-shadow: 0px 4px 80px rgb(0 0 0 / 25%);
      border-radius: 15px;
      padding: 40px;

      .follow-link {
        margin-bottom: 25px;

        ul li {
          list-style: none;
          padding-right: 10px;

          a {
            width: 42px;
            height: 43px;
            background: $starstack-blue-main;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .modal-header {
        border: none;
        padding: 0;

        .modal-title {
          color: $white-color;
          width: 480px;
          font-size: 18px;
        }

        button.btn-close {
          filter: invert(100%) sepia(7%) saturate(2%) hue-rotate(195deg)
            brightness(217%) contrast(193%);
          padding: 0;
          opacity: 1;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .btn.btn-primary {
        border-radius: 0;
        padding: 0 40px;
        height: 65px;

        &:hover {
          background: $starstack-blue-main;
        }
      }

      .form-control {
        padding: 0px 192px 0px 20px;
        height: 65px;
        border-radius: 0;
        background: $white-color;
        border: none;
        // color: $white-color;
        font-size: 18px;
      }

      div.mt-4 span {
        color: $starstack-blue-main;
        font-size: 20px;
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}
.ReactCrop__crop-selection {
  border: 2px dashed #fff;
}
.crop-modal {
  .modal-dialog {
    width: 100%;
    .modal-content {
      width: 450px;
      background: #16122a;
      box-shadow: 0px 4px 80px rgb(0 0 0 / 25%);
      border-radius: 15px;
      padding: 20px;
      margin: auto;
      @media screen and (max-width: 700px) {
        width: 300px;
      }
      .modal-body {
        width: 100%;
        align-items: center;
      }
      .follow-link {
        margin-bottom: 25px;

        ul li {
          list-style: none;
          padding-right: 10px;

          a {
            width: 42px;
            height: 43px;
            background: $starstack-blue-main;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .modal-header {
        border: none;
        display: flex;
        align-items: baseline;

        .modal-title {
          color: $white-color;
          width: 450px;
        }

        button.btn-close {
          filter: invert(100%) sepia(7%) saturate(2%) hue-rotate(195deg)
            brightness(217%) contrast(193%);
          padding: 0;
          opacity: 1;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .btn.btn-primary {
        border-radius: 0;
        padding: 0 40px;
        height: 65px;

        &:hover {
          background: $starstack-blue-main;
        }
      }

      .form-control {
        padding: 0px 192px 0px 20px;
        height: 65px;
        border-radius: 0;
        background: $white-color;
        border: none;
        // color: $white-color;
        font-size: 18px;
      }

      a {
        color: $starstack-blue-main;
        font-size: 20px;
      }
    }
  }
}
