@import '../../../../Style/sass/core/typography';

// delivery details page
.delivery-details-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
  .top-section {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .title-delivery {
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      color: #ffffff;
    }
    .btn {
      border-radius: 22px;
    }
  }
  .delivery-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    position: relative;
    .access-card-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      .onhover-pending-wrapper {
        visibility: visible;
        opacity: 1;
        a {
          &.click-for-details {
            display: none;
          }
        }
      }
    }
    .deliver-details-current-status-wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      .currentstatus {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .titlecurrent {
          font-weight: 500;
          font-size: 18px;
          line-height: 44px;
          text-align: center;
          color: #ffffff;
        }
        .status-progress {
          margin-top: 20px;
          display: flex;
          align-items: center;
          width: 70%;
          .tickmark-wrapper-border {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #564ece;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            .tickmark-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #564ece;
              height: 25px;
              width: 25px;
              padding: 6px;
              border-radius: 50%;
            }
          }
          .tickmark-wrapper-border-red {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ff3f56;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            .tickmark-wrapper-red {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ff3f56;
              height: 25px;
              width: 25px;
              padding: 6px;
              border-radius: 50%;
            }
          }
          .line-blue {
            width: 30%;
            height: 2px;
            background-color: #564ece;
          }
          .blue-miniline {
            width: 30%;
            height: 2px;
            background-color: rgba(86, 78, 206, 0.45);
          }
          .line-red {
            width: 30%;
            height: 2px;
            background-color: #ff3f56;
          }
        }
        .status-progress-for-title {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 70%;
          .progress-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            color: #564ece;
          }
        }
      }
      .blue-box-wrapper {
        width: 100%;
        background: #0b0d19;
        border-radius: 24px;
        display: flex;
        justify-content: flex-end;
        .main-details-about-nft {
          display: flex;
          flex-direction: column;
          width: 90%;
          padding: 40px;
          .fan-delivery-details {
            .header-div {
              background: rgba(121, 118, 163, 0.17);
              border-radius: 9px 9px 0px 0px;
              color: #ffffff;
              padding: 20px;
              h5 {
                margin: 0;
              }
            }
            .bottom-section {
              display: flex;
              background: rgba(142, 138, 201, 0.06);
              border-radius: 0 0 9px 9px;
              .address-form {
                padding: 20px;
                color: #ffffff;
                h3 {
                  font-weight: 400;
                  font-size: 30px;
                  line-height: 27px;
                  color: #ff3f56;
                }
              }
              h6 {
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                color: #564ece;
              }
              h4 {
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
              }
              .details {
                padding: 20px;
                display: flex;
                flex-direction: column;
              }
              .border-right {
                border-right: 2px solid rgba(255, 255, 255, 0.06);
              }
              .right-section {
                width: 50%;
                display: flex;
                justify-content: center;
              }
            }
          }
          .title-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info-wrapper {
              display: flex;
              flex-direction: column;
              .creator-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 38px;
                color: rgba(255, 255, 255, 0.28);
              }
              .nft-title {
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                color: #ffffff;
              }
              .rarity {
                font-weight: 500;
                font-size: 16px;
                line-height: 37px;
                color: rgba(37, 255, 176, 0.88);
                text-transform: capitalize;
                svg {
                  path {
                    fill: rgba(37, 255, 176, 0.88);
                    stroke: rgba(37, 255, 176, 0.88);
                  }
                }
              }
            }
            .info-div {
              background: #363276;
              border-radius: 22px;
              padding: 10px 20px;
              font-weight: 400;
              font-size: 16px;
              color: #ffffff;
              svg {
                width: 20px;
                height: 20px;
                margin-right: 6px;
              }
            }
          }
          .button-section {
            display: flex;
            gap: 20px;
            margin-top: 20px;
            z-index: 1;
            position: relative;
            .refund-processing {
              background-color: #cbaf56;
              border-radius: 12px;
              color: #ffffff;
              padding: 10px 20px;
              font-size: 20px;
              &:hover {
                opacity: 0.6;
              }
            }
            .replytoseller {
              cursor: pointer;
              .svg-traingle {
                margin-right: 10px;
                width: 20px;
                height: 20px;
              }
              svg path {
                fill: #ffffff;
                stroke: 1px;
              }
            }
            .markasrecieved {
              .svg-traingle {
                width: 30px;
                height: 30px;
              }
              svg path {
                fill: #fe9800;
                stroke: 1px;
              }
            }
            .review-rating &:hover {
              svg path {
                fill: #ffffff;
              }
            }
          }
          .status-details-wrapper {
            position: relative;
            .status-title-section {
              display: flex;
              justify-content: space-between;
              background: rgba(86, 78, 206, 0.17);
              padding: 20px;
              border-radius: 11px 11px 0px 0px;
            }

            .status-details-section {
              background: rgba(86, 78, 206, 0.11);
              border-radius: 0px 0px 11px 11px;
              padding: 20px;
              .see-full-status {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100%;
                background: linear-gradient(
                  180deg,
                  rgba(19, 20, 45, 0) 0%,
                  #13142d 100%
                );
                padding: 20px;
                border-radius: 0px 0px 9px 9px;
                .reverse-svg {
                  transform: rotate(180deg);
                }
                svg path {
                  fill: #ffffff;
                  stroke: 3px #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
.buyer-information {
  background: rgba(86, 78, 206, 0.11);
  border-radius: 11.09px;
  padding: 30px;
  .content-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .buyer-img {
      margin-right: 30px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }
}
.modal-deliver-details {
  &.modal {
    padding: 20px;
    .modal-dialog {
      min-width: 760px;
      .modal-content {
        border: none !important;
      }
    }
  }
  .attention {
    font-weight: 700;
    font-size: 31px;
    line-height: 42px;
    color: #564ece;
    padding: 10px 30px;
    margin-top: 20px;
  }
  .close-button {
    cursor: pointer;
  }
  .modal-title-section {
    font-weight: 400;
    font-size: 21px;
    line-height: 42px;
    color: #564ece;
    padding: 10px 30px;
    margin-top: 20px;
  }
  .body-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 20px;

    .recieved {
      background-color: red;
      .white-tickmark {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        height: 30px;
        width: 30px;
        padding: 6px;
        border-radius: 50%;
        svg {
          height: 10px;
          width: 10px;
        }
      }
    }
    .reviewbutton {
      background-color: #564ece;
      border: 1px solid #564ece;
      padding: 15px 20px;
      border-radius: 12px;
      text-transform: capitalize;
      &:hover {
        background-color: transparent;
      }
    }
    .report-submitted {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .svg-wrapper-submit {
        background-color: #564ece;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
    .what-wrong {
      font-weight: 400;
      font-size: 30px;
      line-height: 42px;
      color: #ffffff;
    }
    // radio-check
    .form-check-input {
      width: 1em;
      height: 1em;
      margin-top: 0.25em;
      vertical-align: top;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid #564ece;
      appearance: none;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .text-editor-report-other {
      .tox-editor-container .tox-edit-area__iframe {
        background: rgba(86, 78, 206, 0.11);
      }
      .tox-editor-header {
        border-bottom: none !important;
      }
    }
  }
}

.accessNft-status {
  border-radius: 9px;
  padding: 10px;
  text-align: center;
  text-transform: capitalize;
  background: #7d2b36;
  &.status-processing {
    background: rgba(86, 78, 206, 0.27);
  }
  &.status-delivered {
    background: rgba(247, 143, 114, 0.2);
  }
  &.status-received {
    background: rgba(37, 255, 176, 0.29);
  }
}

.overview-details-cards {
  background: rgba(239, 233, 255, 0.07);
  backdrop-filter: blur(46.5px);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;
  position: relative;
  height: 130px;
  h2,
  p {
    &.gemsbuck {
      font-family: $starstack-font-gemsbuck;
    }
  }
  &.bg-gradient-blue {
    background: linear-gradient(270deg, #564ece 0%, #383752 92.57%);
  }
  &.bg-gradient-pink {
    background: linear-gradient(
      255.96deg,
      rgba(255, 105, 240, 0.6) 0%,
      #ff007a 100%
    );
  }
  &.bg-gradient-green {
    background: linear-gradient(
      90deg,
      rgba(119, 206, 78, 0.4) 10%,
      rgba(119, 206, 78, 0.7) 60%,
      rgba(119, 206, 78, 0.9) 100%
    );
  }
  &.bg-gradient-skyblue {
    background: linear-gradient(
      255.96deg,
      rgba(0, 71, 255, 0.6) 0%,
      #00f0ff 100%
    );
  }
  &.bg-plain-blue {
    background: #564ece;
  }
  &.bg-black {
    background: #18181f;
  }
  &.bg-grey {
    background: #221f27;
  }
  &.bg-violet {
    background: #161529;
  }
  &.bg-dark-blue {
    background: rgba(86, 78, 206, 0.21);
    backdrop-filter: blur(46.5px);
  }
  &.blue-gradient-bg-card {
    background: linear-gradient(
      90.2deg,
      #564ece 22.33%,
      #3a32ad 100.76%,
      #3d34c9 100.76%
    );
  }
  &.black-bg-card {
    background: #15151c;
  }
  P {
    margin-bottom: 0;
  }
  .review-stars {
    font-weight: 700;
    font-size: 50px;
    color: #564ece;
    margin-right: 15px;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .q-mark-svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &:hover {
    box-shadow: 0px 0px 10px 2px #23204e;
    .review-stars {
      color: #ffffff;
    }
  }
}
