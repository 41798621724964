@media screen and (max-width: 1380px) {
  .let-me-know {
    .darkBlk {
      min-width: 549px;
    }

    .let-meknow-text h3 {
      font-size: 29px;
    }
  }

  .StardomAwaitsYou {
    h3 {
      font-weight: normal;
      font-size: 26px;
      line-height: 42px;
    }

    p {
      font-size: 13px;
    }

    a {
      font-size: 13px;
    }

    button.btn.btn-primary {
      font-size: 13px;
      padding: 10px 20px;
    }
  }
}
