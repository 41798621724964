@import '../core/colors';

//----------- error 404 section  css ---------------//

.error-banner-bg {
  background-image: url($aws-base-url + '/common/Errorbackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
}
.error-404-section {
  h2 {
    padding-top: 140px;
    font-size: 68px;
    font-weight: bold;
    span {
      color: rgb(86, 78, 206);
    }
  }
  p {
    font-weight: 400;
    font-size: 30px;
    line-height: 25px;
    color: $white-color;
  }

  .text-white {
    color: white;
  }

  .btn {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .error-404-section {
    h2 {
      padding-top: 70px;
      font-size: 30px;
    }
    .d-flex {
      flex-wrap: wrap;
    }
  }
}
