.example-thumb {
  cursor: pointer;
}

.example-thumb::after {
  content: '';
  position: absolute;
  width: 31px;
  height: 31px;
  background: linear-gradient(
    180deg,
    $starstack-blue-main 0%,
    #0056ff 99.99%,
    rgba(196, 196, 196, 0) 100%
  );
  border: 4px solid $white-color;
  box-sizing: border-box;
  border-radius: 100%;
  left: -7px;
  top: -7px;
  cursor: pointer;
}

.example-track {
  background: rgba(86, 78, 206, 0.11);
  border-radius: 0.5rem;
  position: relative;
  overflow: unset;
  height: 1rem;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: $white-color;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.example-track.example-track-0 {
  background: linear-gradient(
    90.27deg,
    $starstack-blue-main 30.45%,
    #0056ff 93.18%
  );
}

.supply-slider {
  .horizontal-slider {
    width: 100%;
    height: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;

    .example-track-0 {
      background: transparent;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }

      &::before {
        left: 0;
        background: linear-gradient(
          91.95deg,
          #296ac5 15.15%,
          #666bcd 51.93%,
          #ff91e3 98.22%
        );
        z-index: 1;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &::after {
        background: #d9d9d9;
        width: 20px;
      }
    }

    .example-track-1 {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #d9d9d9;
    }

    .example-thumb {
      display: none;
    }
  }
}
