.buck-bundle-details {
  .detail-img-bundle {
    margin-right: -70px;
    position: relative;

    .img:hover {
      overflow: hidden;
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        content: '';
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .delete-svg-icon-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      cursor: pointer;
      margin: 10px;
      svg {
        height: 25px;
        width: 25px;
      }
      &:hover {
        scale: 1.2;
      }
    }
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &.no-radius {
      .img {
        border-radius: 0px;
      }
    }

    .img {
      position: relative;
      border-radius: 15px;

      .showOpenCards {
        position: relative;

        &::after {
          content: '';
          width: 50px;
          height: 100%;
          background-image: url('../../../img/Lightweightimages/open-card-bg.png');
          background-position: right bottom;
          background-repeat: no-repeat;
          z-index: inherit;
          display: block;
          right: -49px;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: 3;
          background-size: 100% 100%;
        }
      }

      .detail-img-text {
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;

        &.bundle {
          border-radius: 15px;
          background: linear-gradient(
            0deg,
            $starstack-blue-main -23.58%,
            rgba(0, 0, 0, 0) 95.55%
          );
          bottom: 0;
          top: auto;
          width: 100%;
        }

        h3 {
          font-weight: 600;
          font-size: 25px;
          color: $white-color;
          margin-bottom: 0;
        }

        hr {
          color: $white-color;
        }

        font-size: 14px;
        color: #d6d6d6;
      }
    }
  }
}

.upload-complete {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: $starstack-blue-light;
    display: block;
    z-index: 1;
    top: -10px;
    position: absolute;
  }
}

.collection-heading {
  font-weight: normal;
  font-size: 21px;
  line-height: 38px;
  color: $white-color;

  span {
    color: $starstack-blue-light;
  }

  .icon {
    margin-right: 0.5rem;
  }

  svg {
    path {
      fill: $starstack-blue-light;
    }
  }
}

.list-slider {
  .collections-list {
    &.creator-hub {
      .slick-slider {
        width: 100%;
        padding-right: 60px;

        .collections-box {
          padding: 0px 1rem;
          .collections-img {
            position: relative;

            img {
              width: 100% !important;
            }

            .hover {
              opacity: 0;
              position: absolute;
              z-index: -1;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(86, 78, 206, 0.84);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-content: center;
              color: $white-color;

              a {
                color: $white-color;
              }
            }
          }

          &:hover {
            .collections-img {
              .hover {
                opacity: 1;
                z-index: 0;
              }
            }
          }
        }

        .slick-next::after {
          right: -15px;
          background-color: $black5-color;
        }
      }
    }
  }
}

.buck-counts {
  font-weight: 500;
  font-size: 23px;
  line-height: 37px;
  text-align: right;
  color: $gray10-color;
}

.collection-list {
  h3 {
    font-weight: 600 !important;
    font-size: 30px !important;
    color: $white-color;
  }

  .slick-slider {
    .slick-track {
      margin-left: 0;
      float: left;
    }
  }

  .nav-pills {
    .nav-item {
      .nav-link {
        font-weight: 500;
        font-size: 16px;
        color: $aluminium-gray-color;
        cursor: pointer;

        &.active {
          background: $gray9-color;
          border-radius: 26px;
          color: $moody-blue-color;
        }
      }
    }
  }

  .collection-list-box {
    margin-right: 15px;
    width: 216px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .img-collection {
      overflow: hidden;
      img {
        width: 216px;
        height: 430px;
      }
      svg {
        transform: scale(1);
        transition: 0.5s;
      }

      &:hover {
        svg {
          transform: scale(1.1);
          transition: 0.5s;
        }
      }

      .img-hover-click {
        background: linear-gradient(
          162.24deg,
          rgba(157, 153, 208, 0.82) 3.31%,
          rgba(64, 51, 255, 0.82) 97.08%
        );
        opacity: 0;
        transition: 0.5s;
        a {
          text-decoration: none;
          p {
            font-weight: 500;
            font-size: 14px;
            color: $white-color;
          }
        }
        svg {
          width: 53px;
        }
      }
      &:hover {
        .img-hover-click {
          opacity: 1;
          transition: 0.5s;

          svg {
            transform: scale(1);
          }
        }
      }
    }

    .collection-title {
      height: 40px;
    }

    .collection-text {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .btn {
        border-radius: 30px;
        padding: 5px 10px;
        background-color: #564ece;
        color: #ffffff;
        border: 0;
        font-size: 15px;
        &:hover {
          background-color: transparent;
          color: #564ece;
          border: 1px solid #564ece;
        }
      }

      .add-btn {
        border-radius: 30px;
        padding: 5px 10px;
        background-color: #564ece;
        color: #ffffff;
        border: 0;
      }
    }
  }

  .price.d-flex.align-items-center.justify-content-center {
    font-weight: 500;
    font-size: 20px;
    color: $starstack-blue-main;
  }

  .add-cart-btn {
    width: 85px;
    height: 25px;
    background: $gray9-color;
    border-radius: 18px;
    font-weight: 500;
    font-size: 11px;
    color: $gray10-color;
    border: none;
  }

  .qty .feature-cart-btn {
    background: $starstack-blue-main;
    color: $white-color;
    font-size: 11px;
    font-weight: 500;
    border-radius: 18px;
    padding: 1em;
    width: 85px;
    height: 25px;
    border: none;

    &:hover {
      background: $gray9-color;
      color: $gray10-color;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 561px) {
  .list-slider {
    .collections-list .slick-slider .collections-box {
      width: 350;
    }

    .collections-list {
      &.creator-hub {
        .slick-slider {
          padding-right: 0px;

          .collections-box {
            padding: 0px 0.5rem;
          }
        }
      }
    }
  }
}

.card-main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.collections-card-main {
  display: flex;
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .collections-card-wrapper {
    width: 300px;
    .imgs-wrapper {
      .collection-main-img {
        position: relative;
        z-index: 1;
      }
      .collection-bluebg-img {
        right: 0.5px;
        width: auto;
      }
    }
    .bottom-main {
      display: flex;
      flex-direction: column;
      width: 84%;
      .text-section {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .collection-details-info {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #564ece;
          display: flex;
          align-items: center;
        }
      }
      .collection-main-title {
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        color: #ffffff;
        margin-bottom: 8px;
      }
    }
  }
}
.img-shadow {
  position: absolute;
  top: -20%;
  right: 0;
}

.collection-details-wrapper-box {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .dark-box-details {
    width: 80%;
    margin-left: -100px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      margin-left: 0;
    }
  }
}

.markethub-buckdetails-section {
  .buckdetails-collection {
    padding: 30px;
    background-color: #0b0d19;
    border-radius: 24px;
    margin-top: 50px;
    .your-collection .featuredStarts {
      padding-left: 0;
      padding-right: 0;
    }
    .custom-arrow-slider {
      .owl-carousel {
        .owl-nav {
          button {
            &.owl-next {
              right: -50px;
            }

            &.owl-prev {
              left: -50px;
            }
          }
        }
      }
    }
  }
}
