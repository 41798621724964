.comming-soon-wrapper {
  margin: 0px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .gradient-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(270deg, #000000 3%, rgba(0, 0, 0, 0) 56%);
      opacity: 0.6;
      z-index: 1;
    }
  }

  .content {
    color: white;
    text-align: center;
    position: relative;
    z-index: 2;

    &-greet {
      font-family: $starstack-font-secondary;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $white-color;
    }

    &-head {
      font-family: $starstack-font-secondary;
      font-style: normal;
      font-weight: 700;
      font-size: 51px;
      line-height: 60px;
      text-align: center;
      letter-spacing: 0.34em;
      color: $white-color;
    }

    &-body {
      font-family: $starstack-font-secondary;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: rgba(255, 255, 255, 0.69);
    }
  }
}
