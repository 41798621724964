$rhs-width: calc(100% - 269px);

.WAW-MainWrapper {
  display: flex;
  justify-content: center;
  background-color: #000000;
  position: relative;
  background: url($aws-base-url + '/aboutus/BlueBlur.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  justify-content: center;
  align-items: center;

  .WAW-MainDiv {
    width: 90%;
    display: flex;
    padding: 50px;
    justify-content: space-between;

    .WAW-Text {
      width: 30%;

      .h1 {
        font-size: 43px;
        line-height: 52px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
      }

      .h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
      }
    }

    .WAW-Logos {
      width: 55%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      justify-items: center;
      align-items: center;

      .img {
        width: 280px;
        margin: 30px;
      }
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .WAW-MainWrapper {
    display: flex;
    height: 600px;

    .WAW-MainDiv {
      width: 90%;
      display: flex;
      padding: 20px;
      justify-content: center;

      .WAW-Text {
        width: 50%;

        .h1 {
          font-size: 35px;
          line-height: 40px;
        }

        .h2 {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .WAW-Logos {
        align-items: center;
        justify-content: center;

        .img {
          width: 280px;
          margin: 30px auto;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .WAW-MainWrapper {
    height: 800px;

    .WAW-MainDiv {
      width: 90%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      justify-content: center;

      .WAW-Text {
        width: 100%;

        .h1 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 600;
        }

        .h2 {
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
        }
      }

      .WAW-Logos {
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;

        .img {
          width: 280px;
          margin: 30px auto;
        }
      }
    }
  }
}
