.custom-control {
  position: relative;
  width: 67px;
  height: 32px;

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;

    &:checked ~ .custom-control-label::before {
      color: $white-color;
      background-color: $starstack-blue-main;
    }
  }

  .custom-control-label {
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 67px;
      height: 32px;
      content: '';
      background-color: rgba(86, 78, 206, 0.2);
      border-radius: 40px;
    }

    &::after {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 26px;
      height: 26px;
      content: '';
      background: no-repeat 50% / 50% 50%;
      background-color: $starstack-blue-main;
      border-radius: 100%;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $white-color;
  transform: translateX(2.1rem);
}

.error-credit-card {
  color: #ff3f56 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}