.access-nft-card {
  background: linear-gradient(
    71.45deg,
    rgba(13, 11, 33, 0.54) 14.26%,
    rgba(86, 78, 206, 0.1242) 113.99%
  );
  margin: 20px;
  width: 330px;
  border: 1.19297px solid $starstack-blue-main;
  backdrop-filter: blur(2.8593px);
  border-radius: 16px;
  padding: 13px;
  .access-nft-img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
  .text-Claimed {
    padding-top: 14px;
    .card-title {
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
      color: #ffffff;
    }
    p {
      font-size: 15px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.28);
    }

    h4 {
      font-size: 16px;
      line-height: 35px;
      svg {
        width: 20px;
        height: 20px;
        margin-top: -2px;
      }
    }
    .seals {
      svg {
        height: 50px;
        width: 50px;
      }
    }
    label {
      font-size: 12px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.5);
      font-style: bold;
    }
  }
}

.individual-card-nft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nft-card-background {
    border-radius: 25px;
    border: 1px solid hsla(0, 0%, 67.1%, 0.38);
    background: rgb(0 0 0 / 0%);
    cursor: pointer;
    margin: 20px;
    width: 280px;

    img {
      width: 225px;
      height: 330px;
      border-radius: 10px;
    }
    .nft-details {
      border-radius: 10px;
      .type-n-rare {
        bottom: -14px;
        .token-type {
          div {
            background: $starstack-blue-main;
            border-radius: 20.5px;
            width: 25px;
            height: 25px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            margin: 0 0.25rem;
            svg {
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
        .token-rarity {
          background: $starstack-blue-main;
          border-radius: 20.5px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 400;
          padding: 0 0.75rem;
          color: hsla(0, 0%, 100%, 0.88);
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .info {
      .title {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
      }
      .by {
        font-weight: normal;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.28);
      }
    }
    .view-claim {
      a {
        font-weight: normal;
        font-size: 16px;
        text-decoration-line: underline;
        color: $starstack-blue-main;
      }
      span {
        font-weight: normal;
        font-size: 15px;
        color: $aluminium-gray-color;
      }
    }
    &:hover {
      background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
      box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
      border: 1px solid rgb(83 54 179);
      transition: 2s;
      .view-claim {
        a {
          color: $white-color;
        }
      }
    }
  }
}
