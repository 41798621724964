.Material-ui-accordion {
  margin-top: 20px;
  width: 100%;
  .accord-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    svg {
      path {
        fill: #000000;
      }
    }
  }
}
