@media screen and (max-width: $xl) {
    .box2 {
        margin: 100px !important;
    }
    
    .box3 {
        margin-top: 100px !important;
        width: 100% !important ;
    }

    .box3a {
        padding: 20px !important ;
        width: 100% !important ;
    }

    .box7 {
        display: flex;
        flex-direction: column !important ;
        justify-content: center !important ;
        align-items: center !important ;
    }
    .box8{
        width: 100% !important ;
    }
    .box9{
        width: 100% !important ;
    }

    .box10 {
        width: 100% !important ;
    }

    .box11 {
        width: 100% !important ;
    }

    .textfield {
        padding: 10px;
        margin-top: 20px
    }

    .h1 {
        font-size: 20px;
        line-height: 20px;
    }


    .h2 {
        font-size: 16px;
        line-height: 18px;
    }

    .h5 {
        font-size: 10px;
        line-height: 17px;
    }


    .h6 {
        font-size: 12px;
        line-height: 17px;
    }

    .h7 {
        font-size: 14px;
        line-height: 20px;
    }
}