.profile-left-bo {
  background: #0b0c11;
  border-radius: 10px;

  h3 {
    font-weight: normal;
    font-size: 21px;
  }

  a {
    font-weight: 500;
    font-size: 20px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
  }

  h4 {
    font-weight: 500;
    font-size: 21px;
    letter-spacing: -0.05em;
    color: $starstack-blue-main;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: $gray4-color;
  }

  .box-list-po {
    background: $cinder-black-color;
    border-radius: 0px 0px 10px 10px;

    a {
      text-decoration: none;
      text-decoration-line: none;
    }

    .list-profile-header {
      height: 33%;
      cursor: pointer;

      .list-count {
        background-color: #5e36bf;
        padding: 0.2rem 0.7rem;
        border-radius: 50%;
      }

      &.active-list {
        background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
        box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
        border-radius: 18px;

        h4 {
          color: $white-color;
        }
      }

      .form-check-input {
        width: 28px;
        height: 28px;
        background-color: rgb(57 51 148 / 23%);
        border-radius: 100% !important;
        border: none;
        background-size: 23px;

        &:checked {
          background-color: #5e36bf;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &:hover {
        background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
        box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
        border-radius: 18px;

        h4 {
          color: $white-color;
        }

        p {
          color: rgba(255, 255, 255, 0.59);
        }

        .profile-edit-icon {
          svg {
          }
          path {
            fill: $white-color;
            stroke: $white-color;
          }
        }
      }
    }
  }
}

.star-profiles {
  background-image: url($aws-base-url + '/StarProfile/banner-tari.webp');
  background-size: cover;
  border-radius: 12px;

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
  }

  .btn {
    font-weight: 600;
    font-size: 16px;
    color: $starstack-blue-main;
  }
}

.star-profiles-dashboard {
  background-image: url(../../../img/CreatorHub/traning-center.webp);
  background-size: 100% 100%;
  border-radius: 12px;

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
  }

  .btn {
    font-weight: 600;
    font-size: 16px;
    color: $starstack-blue-main;
  }
}

.top-bar-he {
  background: rgba(11, 13, 25, 0.66);
  margin-top: 60px;

  a {
    font-weight: 500;
    font-size: 20px;
    text-decoration-line: underline;
    color: $starstack-blue-main;
  }

  .Accesstokenbtn {
    .btn {
      span {
        padding: 10px 0px 10px 10px;
      }
    }
  }

  .save-action {
    .btn {
      padding: 13px 34px;
      font-size: 16px;
    }
  }
}

.box-reight-section {
  background: rgba(0, 0, 0, 0.62);
  backdrop-filter: blur(90px);
  max-width: 483px;
  margin-left: auto;
  height: calc(100vh - 150px);
  overflow-y: auto;

  &.bg-eff {
    &::after {
      background-image: url($aws-base-url + '/StarProfile/reight-sha.webp');
      content: '';
      position: absolute;
      bottom: 0;
      left: -65px;
      right: 0;
      height: 662px;
      opacity: 0.4;
    }
  }

  .top-hea {
    background: rgba(86, 78, 206, 0.06);
  }

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.66);
  }
}

.upload-form-box {
  background: $cinder-black-color;
  box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgb(0 0 0 / 13%);
  border-radius: 31.7949px;
  margin-bottom: 10%;

  textarea.form-control {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
    border: none;
    height: 275px;
    width: 90%;
  }

  label.form-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 42px;
    color: $white-color;

    span {
      font-weight: 300;
      color: hsl(0deg 0% 100% / 42%);
    }

    b {
      color: $starstack-blue-main;
    }
  }
}

.left-side-sections {
  height: calc(100vh - 150px);
  overflow-y: auto;

  .bg-sh-prof {
    min-height: 100%;

    &::after {
      content: '';
      background-image: url($aws-base-url + '/common/blur-bg.webp');
      position: absolute;
      top: 0;
      bottom: 0px;
      left: 0;
      right: 0;
      background-size: 100% 100%;
      z-index: -1;
    }
  }
}

.upload-files {
  .form-control {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    width: 229px;
    height: 53px;

    &:hover {
      + .up-action .btn {
        background: $starstack-blue-light;
        color: $white-color !important;
      }
    }
  }

  .btn {
    width: 229px;
    height: 53px;
    border-radius: 9px;
    font-weight: 500;
    font-size: 18px;
    color: $starstack-blue-main !important;

    &:hover {
      background: $starstack-blue-light;
      color: $white-color;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.29);
  }
}

.progres-bars {
  .progress-bar {
    width: 174px;
    height: 9px;
    background: $starstack-blue-main;
    border-radius: 9px;
  }

  // while loading
  .progress-bar-loading {
    width: 66px;
  }

  h6 {
    font-weight: normal;
    font-size: 16px;
    color: $white-color;
  }
}

input.form-control:disabled + .up-action,
input.form-control:disabled {
  display: none !important;
}

.socil-list-form {
  h6 {
    font-weight: 500;
    font-size: 18px;
    color: $white-color;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
  }

  .form-control {
    background: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
    border: none;
    font-weight: normal;
    font-size: 16px;
    color: $white-color;
    padding: 14px 22px;
  }

  .form-select {
    background-color: rgba(86, 78, 206, 0.11);
    border-radius: 32.7648px;
    border: none;
    font-weight: normal;
    font-size: 16px;
    color: $white-color;
    padding: 14px 22px 14px 60px;
  }

  .socail-imgs {
    position: absolute;
    top: 16px;
    left: 24px;
  }
}

.btn-add-social {
  background: rgba(86, 78, 206, 0.37);
  padding: 7px 20px !important;
  color: $white-color;
  border-radius: 40px !important;
  font-weight: 400 !important;
}

.select-social {
  min-width: 280px;

  .react-select-container .react-select__control {
    background: rgb(19 21 45);
    padding: 6px 10px 6px 8px;
    min-height: 52px;
  }

  .react-select__single-value {
    color: hsl(0deg 0% 100%);
    font-weight: 300;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    overflow: hidden;
  }
}

@media screen and (max-height: 800px) {
  .bg-sh-prof {
    height: auto !important;

    &::after {
      bottom: -58px !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .box-reight-section {
    max-width: 100%;
    height: auto;
  }

  .left-side-sections {
    height: auto;
  }

  .upload-form-box textarea.form-control {
    width: 100%;
  }

  .top-bar-he {
    margin-top: 56px;
  }
}

@media screen and (max-width: 991px) {
  .mob-ac-at {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 26px;
  }

  .top-bar-he a {
    font-size: 14px;
  }
}

span.img-edit {
  position: absolute;
  width: 36px;
  height: 36px;
  background: $white-color;
  bottom: 15px;
  right: 27px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg path {
    fill: #644ccc;
  }
}

.border-redi {
  border-radius: 8px;
}

.icon-menu-social {
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
