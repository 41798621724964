@media screen and (max-width: $xxs) {
  .invitation-modal {
    width: 350px !important;
  }

  .form-modal-box {
    width: 350px !important;

    .button-container {
      flex-wrap: wrap !important;
    }
  }

  .confirmation-container {
    width: 350px !important;
    padding: 1rem !important;
  }

  .filter-section {
    .search-box {
      max-width: 100%;
      margin-bottom: 10px;
    }

    .collection-box {
      max-width: 100%;
      margin-bottom: 10px;
    }

    .denomination-box {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .collection-list .collection-list-box .collection-text h5 {
    font-size: 12px;
  }

  .navbar-brand svg {
    width: 130px;
  }

  // getbucks page
  .gb-Essentials-banner {
    .gb-banner-container {
      margin-left: 20px !important;
      margin-right: 20px !important;

      .gb-buck-h1 {
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 22px !important;
        width: 100% !important;
      }

      .gb-p {
        font-weight: 400 !important;
        font-size: 22px !important;
        line-height: 27px !important;
        width: 100% !important;
      }
    }
  }

  // Starter bundle

  .sb-main {
    width: 100% !important;
    padding: 10px 0 !important;
    margin-top: 200px !important;

    .sb-main2 {
      display: flex !important;
      flex-wrap: wrap-reverse !important;
      min-height: 600px !important;

      .sb-b1 {
        width: 100% !important;
        margin-left: 0 !important;

        .sb-h1 {
          font-size: 30px !important;
          line-height: 40px !important;
        }

        .sb-h2 {
          font-size: 14px !important;
          line-height: 20px !important;
        }

        .btn-white {
          width: 100px !important;
        }
      }

      .sb-b2 {
        width: 100% !important;
        margin-left: 0 !important;
        object-fit: cover !important;

        .sb-img {
          width: 100px !important;
          height: 100px !important;
          margin-top: 200px !important;
        }
      }
    }
  }
}
