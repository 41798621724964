$rhs-width: calc(100% - 269px);

.main-div {
  position: relative;

  .cbs-main-div {
    background-image: url($aws-base-url + '/Giphy/ConvertBucks.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    min-height: 100vh;
    justify-content: center;
    align-items: center;

    .cbs-main-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      width: 80%;
      margin-top: 5%;
      min-height: 100vh;

      .h1 {
        font-size: 44px;
        line-height: 22px;
        font-weight: 600;
        color: white;
      }

      .h2 {
        font-size: 21px;
        line-height: 31px;
        font-weight: 400;
        color: white;
        text-align: center;
      }
    }

    .data-table-wrapper {
      width: 70%;

      .data-table {
        margin-top: 50px;
        background: rgba(0, 0, 0, 75%);
        flex-direction: column;
        display: flex;
        padding: 30px;
        width: 100%;
        border-radius: 20px 20px 0 0;

        .data-row {
          flex-direction: row;
          display: flex;
          justify-content: space-between;

          .h3 {
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
            color: white;
            text-align: center;
          }
        }

        .btn-wrapper {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin-left: -50px;

          .btn {
            color: #fafafaa8;
            background: transparent;
            font-size: 18px;
            cursor: pointer;
          }

          .img {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            margin-top: 8px;
          }
        }
      }

      .dotted-img {
        width: 100%;
        margin-top: -35px;
      }

      .data-table-grey {
        background: rgba(217, 217, 217, 14%);
        flex-direction: column;
        display: flex;
        padding: 30px;
        width: 100%;
        margin-top: -30px;
        border-radius: 0 0 20px 20px;

        .data-row-grey {
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .h3-grey {
            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            color: white;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .main-div {
    .cbs-main-div {
      .cbs-main-wrapper {
        width: 100%;
        margin-top: 10%;

        .h1 {
          font-size: 30px;
          line-height: 50px;
          font-weight: 500;
        }

        .h2 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: white;
          text-align: center;
        }
      }

      .data-table-wrapper {
        width: 95%;

        .data-table {
          padding: 20px;

          .data-row {
            .h3 {
              font-size: 12px;
              line-height: 18px;
              font-weight: 300;
            }
          }

          .btn-wrapper {
            margin-left: -30px;

            .btn {
              font-size: 12px;
            }

            .img {
              width: 10px;
              height: 10px;
              margin-left: 5px;
              margin-top: 5px;
            }
          }
        }

        .data-table-grey {
          .data-row-grey {
            .h3-grey {
              font-size: 12px;
              line-height: 18px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
