.banner-collection {
  background: url('../../../img/svg/Banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .total-blance {
    border-right: 2px solid;
    margin-bottom: -20px;
    .font-width-definer {
      width: 70%;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    h4 {
      font-weight: 600;
      font-size: 36px;
      letter-spacing: -0.03em;
      color: $light-mint;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.03em;
      color: $gray4-color;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      min-width:  100px;
      width: max-content;
      height: 40px;
      background: rgba(140, 249, 190, 0.12);
      border-radius: 7px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .border-right {
    border-image: linear-gradient(to bottom, $white-color 0%, $black5-color) 0 1;
  }

  .action-text {
    h4 {
      font-weight: normal;
      font-size: 30px;
      line-height: 38px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.88);
    }

    .btn {
      padding: 10px 40px;
      font-weight: normal;
      font-size: 16px;
      color: $starstack-blue-main;

      &:hover {
        color: $white-color;
      }
    }
  }
}
