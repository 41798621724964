// Main Resets ::start▼
body {
  margin: 0;
  font-family: $starstack-font-main;
  background-color: $dark-color;
  font-size: 16px;
}

input {
  background: rgb(23 24 29);
  border-radius: 26px;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $white-color;
  border: 0px;
  outline: 0px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6c757d !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  border: none !important;
  background-color: #2d2f58 !important;
  border-radius: 4px;
  overflow: hidden;
}

h1 {
  font-size: 48px;
}

// Main Resets ::end▲

// resets for react-owl-carousel
.owlList {
  .owl-carousel {
    padding-right: 10%;

    @media (max-width: 767px) {
      padding-right: 0;
    }

    .customCard {
      .imgWrap {
        height: 125px;
      }

      .card-title {
        margin-bottom: 15px;
        max-width: 100%;
      }

      .card-body {
        padding: 78px 17px 30px;
        background: $cinder-black-color;

        .profile {
          width: 126px;
          height: 126px;
          border-radius: 100px;
          border: solid 5px $dark-color;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -63px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      button.btn {
        background: $starstack-blue-light;
      }
    }

    .owl-dots {
      position: absolute;
      top: -50px;
      right: 3%;

      .owl-dot {
        &.active,
        &:hover {
          span {
            background: $starstack-blue-main !important;
            width: 6px;
            height: 6px;
          }
        }

        span {
          width: 4px;
          height: 4px;
          margin: 3px;
          background: $aluminium-gray-color;
        }
      }
    }

    a.nav-link.link-he-nav {
      color: $marguerite-blue-color;
    }

    .owl-nav {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 991px) {
        right: 0;
      }

      @media (max-width: 767px) {
        position: relative;
        top: inherit;
        transform: none;
        right: inherit;
      }

      button {
        background: $white-color;
        width: 60px;
        height: 60px;
        border-radius: 100px;
        transition: all ease 0.4s;

        @media (max-width: 991px) {
          width: 45px;
          height: 45px;
        }

        &:hover {
          background: $white-color;
          transition: all ease 0.4s;

          span {
            margin-left: 2px;
            transition: all ease 0.4s;
          }
        }

        &.owl-next {
          span {
            background: url('../../../img/svg/Arrows/FrontArrowBlue.svg')
              no-repeat;
            width: 27px;
            height: 29px;
            background-position: center center;
            background-size: contain;
            color: transparent;
            display: inline-block;
          }
        }

        &.owl-prev {
          display: none;
        }
      }
    }
  }
}

// resets for cards
.card-img {
  margin-bottom: -80px;
}

// resets for react pagination
.page-item .page-link {
  background-color: $black-color !important;
  color: white !important;
  border-color: #0a12ca !important;
}

// resets for react slick slider
.slick-slider {
  width: 90%;

  .collections-box {
    width: 213px;

    .collections-img {
      display: flex;

      .main-img {
        border-radius: 8px;
      }
    }

    .view-Collection-btn {
      background: $gray9-color;
      border-radius: 21px;
      font-weight: 500;
      font-size: 16px;
      color: $gray10-color;
      border: none;
      padding: 10px 20px;
      text-decoration: none;

      &:hover {
        border: none;
        background: $starstack-blue-light;
        color: $white-color;
      }
    }

    .total-list {
      font-weight: 500;
      font-size: 16px;
      color: $gray10-color;
      display: flex;
      align-items: center;
    }

    .collections-hover {
      background: linear-gradient(
        180deg,
        $starstack-blue-main 0%,
        rgba(86, 78, 206, 0) 100%
      );
    }
  }

  .slick-prev {
    position: absolute;
    left: -80px;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    font-size: 0;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      background-color: rgb(72 79 195 / 28%);
      width: 60px;
      height: 60px;
      color: $white-color;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      background-image: url('../../../img/svg/Arrows/FrontArrowBlue.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover::after {
      color: $starstack-blue-main;
    }
  }

  .slick-next {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    font-size: 0;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      background-color: $white-color;
      width: 60px;
      height: 60px;
      color: $starstack-blue-main;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      background-image: url('../../../img/svg/Arrows/FrontArrowBlue.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover::after {
      background-color: rgb(72 79 195 / 28%);
      color: $white-color;
    }
  }

  .slick-dots {
    position: absolute;
    right: 80px;
    top: -70px;
    display: flex !important;
    list-style: none;
    padding: 0;

    li {
      width: 5px;
      margin: 0px 3px;
      height: 5px;
      background: $aluminium-gray-color;
      border-radius: 100%;

      &.slick-active {
        background: $starstack-blue-main;
      }

      button {
        opacity: 0;
        display: none;
      }
    }
  }
}

// resets for form
form {
  .form-group {
  }

  .form-label {
    font-weight: 500;
    font-size: 18px;
    color: $white-color;

    span {
      color: rgba(171, 171, 171, 0.54);

      &.mandatory {
        color: $starstack-blue-light;
      }
    }
  }

  .form-control {
    height: 57px;
    background: rgba(86, 78, 206, 0.2);
    border-radius: 28.5px;
    border: none;
    padding: 0px 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: $white-color;

    &:focus {
      color: $white-color;
      box-shadow: 0px 0px 0px 1px $marguerite-blue-color;
    }

    &::placeholder {
      color: #454163;
    }

    &:hover,
    &:active,
    &:focus {
      background: rgba(86, 78, 206, 0.2);
      border: none;
      color: $white-color;
    }

    // &[type="file"] {
    //     background: transparent;
    //     border: none;
    //     border-radius: 0px;
    //     &.custom-file-upload {
    //         border: 0px solid $pastel-gray-color;
    //         display: inline-block;
    //         padding: 6px 12px;
    //         cursor: pointer;
    //     }
    // }
  }

  textarea {
    &.form-control {
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .text-muted {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.29) !important;
  }
  .design-tips {
    height: 80%;
    display: flex;
    align-items: center;
    background: rgba(86, 78, 206, 0.11);
    border: 1.3px dashed #564ece;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #564ece;
  }
  .marketPriceText {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.29) !important;
  }
}

// resets for from react select
.react-select-container {
  min-height: 57px;

  .react-select__control {
    background: $gray9-color;
    border-radius: 26px;
    border: none;
    padding: 6px 5px 6px 0px;
    .react-select__placeholder {
      padding: 0px 12px;
    }
    .react-select__multi-value {
      background: $starstack-blue-main;
      border-radius: 16.5px;
      color: $white-color;
      padding: 3px 5px;
      svg {
        width: 15px;
        height: 15px;
      }
      .react-select__multi-value__label {
        font-weight: normal;
        font-size: 12px;
        color: $white-color;
      }
      .css-xb97g8:hover {
        background: none;
        color: $white-color;
        border-radius: 100%;
      }
    }
    .react-select__input-container {
      color: $white-color;
      padding: 0px 12px;
    }
    .react-select__value-container {
      flex-wrap: nowrap;
    }
    .react-select__indicator-separator {
      width: 0;
    }
  }
  .react-select__menu {
    background: rgba(24, 22, 51, 0.67) !important;
    backdrop-filter: blur(20px);
    border-radius: 16px;
    padding: 0 !important;
    color: $white-color;
    z-index: 2;
    .react-select__menu-list {
      background: transparent !important;
      font-weight: normal;
      font-size: 16px;
      color: $white-color;
      padding: 0 !important;
      .react-select__option {
        background: transparent !important;
        color: $white-color;
        font-weight: normal;
        font-size: 16px;
        overflow-wrap: anywhere;
        &.react-select__option--is-selected,
        &.react-select__option--is-focused,
        &:hover {
          color: $starstack-blue-main;
          svg {
            path {
              fill: $starstack-blue-main;
              stroke: $starstack-blue-main;
            }
          }
        }
      }
      .icon-menusx {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &.creation {
    .react-select__control {
      padding: 10px 5px 10px 0px !important;
      background: rgba(86, 78, 206, 0.2) !important;
      height: 100%;
      .react-select__single-value {
        color: $white-color;
        padding: 0px 12px;
      }
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
      }
      &.react-select__control--is-focused {
        box-shadow: 0px 0px 0px 1px $marguerite-blue-color;
      }
    }
    .react-select__menu {
      overflow: hidden;
      margin-top: 0;
      // background: rgba(48, 48, 48, 0.68) !important;
      .react-select__menu-list {
        width: 100% !important;
        margin-bottom: -1px;
        .react-select__option {
          font-size: 16px;
          padding: 20px !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.07);
          // background: rgba(24, 22, 51, 0.67);
          // backdrop-filter: blur(20px);
        }
      }
    }
  }
}

// react-datepicker
.react-datepicker {
  .react-datepicker__day {
    &.react-datepicker__day--selected {
      border: 0.1px solid white;
      background-color: #564ece;
    }
    &.react-datepicker__day--disabled {
      color: #ababab;
      opacity: 0.5;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: #564ece;
    }
  }
}

.MuiTableCell-root {
  font-family: $starstack-font-main !important;
}
