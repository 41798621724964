.creator-hub-table {
  h2 {
    color: white;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
  }
  a {
    color: $starstack-blue-main;
    text-decoration: none;
  }
}
.overview-stats-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  .card {
    position: relative;
    width: 291px;
    padding: 24px 16px;
    margin: 0.5rem;
    border-radius: 12px;
    background: rgba(239, 233, 255, 0.03);

    .info {
      position: absolute;
      right: 8px;
      top: 8px;
    }
    .amount {
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      color: $white-color;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: $white-color;
      svg {
        margin-right: 0.5rem;
      }
    }
    &.available-royalties {
      // flex: 2 1;
      background: linear-gradient(
        83.55deg,
        #fc1885 -12.13%,
        #9b41f6 -12.12%,
        #561eef 111.34%
      );
      svg {
        path {
          fill: $white-color;
        }
      }
    }
  }
}
.quick-links {
  .box-bl-link-border {
    background: linear-gradient(
      73.6deg,
      #85ffc4 2.11%,
      #5cc6ff 42.39%,
      #783ef4 85.72%
    );
    border-radius: 12px;
    backdrop-filter: blur(93px);
    padding: 2px;
    .box-bl-link {
      background: #0b0c11;
      p {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.05em;
      }
      .btn-access-tokens {
        background: rgba(86, 78, 206, 0.11);
        border-radius: 9px;
        font-weight: 500;
        font-size: 20px;
        color: $starstack-blue-main;
        path {
          transition: all 0.15s ease-in-out;
        }
        &:hover {
          background: $starstack-blue-main;
          color: $white-color;
          svg {
            path {
              fill: $white-color;
            }
          }
        }
      }
    }
  }
}
.cash-out {
  background: $cinder-black-color;
  border-radius: 10px;
  .form-label {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.01em;
    color: $white-color;
    span {
      color: #655bf3;
      font-weight: 400;
    }
  }
  .form-control {
    background: rgba(196, 196, 196, 0.07);
    border-radius: 32.7648px;
    padding: 18px 30px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.05em;
    color: rgba(255, 255, 255, 0.18);
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $chateau-gray-color;
    opacity: 0.75;
    a {
      color: #655bf3;
    }
  }
  .re {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.05em;
    text-decoration-line: underline;
    color: $starstack-blue-main;
    cursor: pointer;
  }
}

.mybucks-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;

  @media screen and (max-width: 1200px) {
    column-gap: 2rem;
  }
  @media screen and (max-width: 760px) {
    column-gap: 1.4rem;
  }
}

.creatorhub-bucks-dashboard-card {
  background: url('../../../img/CreatorHub/traning-center.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  padding: 2rem 5rem;
  h3 {
    width: 50%;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: #ffffff;
    @media screen and (max-width: 1600px) and (min-width: 992px) {
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  button {
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(189, 0, 255, 0.39);
    border-radius: 12px;
    padding: 10px 20px;
    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #564ece;
      text-decoration: none;
    }
    &:hover {
      transition: all 0.3s ease;
      background: transparent;
      a {
        color: white;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

.filter-dropdown {
  position: relative;
  margin-left: auto;
  .dropdown-toggle {
    text-align: center;
    padding: 8px 10px;
    width: 220px;
    height: 52px;
    border: 1px solid rgba(196, 196, 196, 0.1);
    border-radius: 26px;
    background: rgba(196, 196, 196, 0.1) !important;
    color: #ababab;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid #564ece;
      box-shadow: none;
    }
    &::after {
      display: inline-block;
      margin-left: 14px;
      bottom: 14px;
      vertical-align: middle;
      content: '\2039';
      border: none;
      transform: rotate(271deg);
      font-size: 48px;
      font-weight: 200;
      line-height: 0;
    }
  }

  .dropdown-menu {
    margin-top: 5px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: rgba(15, 14, 41, 0.94);
    backdrop-filter: blur(4px);
    border-radius: 16px;
    left: auto !important;
    right: 0 !important;

    a {
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      color: white;
      transition: all ease 0.4s;
      padding: 10px 28px;

      &:last-child {
        border-bottom: 0;
      }

      &:hover,
      &:active,
      &:focus,
      &.active {
        color: #564ece;
        background: transparent !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    h3 {
      width: 100%;
    }
    .filter-dropdown {
      .dropdown-toggle {
        width: 180px;
        height: 40px;
        padding: 4px 5px;
      }
    }
  }
}

.search-box {
  input {
    border: 1px solid rgba(196, 196, 196, 0.1) !important;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid #564ece !important;
      box-shadow: none;
    }
  }
}

.nftbucks-filter-dropdown {
  .dropdown-toggle {
    text-align: left;
  }
}

.collection-details-nav-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .filter-dropdown {
    margin-left: 0;
    .dropdown-toggle {
      width: 280px;
    }
  }
}
