// color utility classes
.bg-gray {
  background: #f2f2f2;
}

.blue-grey-text {
  color: #81859f;
}
.grey-light {
  color: rgba(255, 255, 255, 0.66);
}
.text-blue {
  color: $starstack-blue-main !important;
}
.text-grey {
  color: rgba(171, 171, 171, 0.14);
}
.text-gray-dark {
  color: rgba(171, 171, 171, 0.47);
}
.error {
  color: #ff3f56;
}
.light-green-text {
  color: #25ffb0;
}
.grey-text {
  color: rgba(222, 217, 217, 0.58);
}
.text-right {
  text-align: right;
}
.text-pink {
  color: #ff3f56;
}
.text-yellow {
  color: #b5a06a;
}

.text-pinkish {
  color: #fa077f;
}
.text-green {
  color: #25ffb0;
}
.text-ocean-green {
  color: #1adbb8;
}
.grey-text-font {
  color: rgba(255, 255, 255, 0.35);
}
.text-gray {
  color: $aluminium-gray-color;
}

.svg-blue {
  svg {
    path {
      fill: $starstack-blue-main;
    }
  }
}

.border-purple {
  border: 1px solid #4d176f;
}

// container/wrapper utility classes
.main-content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.st-container {
  max-width: 1090px;
}

.right-wrapper {
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  padding-left: 269px;
  padding-right: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 1319px) {
    padding-left: 100px;
  }
  @media screen and (max-width: 991px) {
    min-height: 125vh;
  }
  @media screen and (max-width: 765px) {
    padding: 0;
    margin-top: 0;
  }
}

.height-heading {
  height: 36px;
}

.z-index-1 {
  z-index: 1;
}

.header-marg {
  margin-top: 58px;
}

.back-link {
  margin: 20px;

  a {
    display: inherit;

    svg {
      transition: all ease 0.4s;

      &:hover {
        margin-left: -2px;
        transition: all ease 0.4s;
      }
    }
  }

  h3 {
    font-weight: normal;
    font-size: 30px;
    color: $white-color;
  }
}

.page-loader {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loader-v2 {
  text-align: center;
  display: flex;
  height: 60vh;
}

.box {
  min-height: 200px;
  background: #0b0d19;
  box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
  border-radius: 32px;
  position: relative;

  .info-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

// layout utility classes
.parent-wrap-row {
  margin-left: -15px;
  margin-right: -15px;

  .child-wrap-row {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.parent-width.parent-wrap-row {
  justify-content: flex-start !important;

  .child-wrap-row {
    min-width: 280px;
    max-width: 290px;
  }

  .collection-text {
    width: 215px;
  }
}

.main-margin {
  max-width: 1440px;
  margin: auto;
}

// All background utility classes
.banner-bg {
  background-image: url($aws-base-url + '/CreatorHub/creation-bg2.webp');
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.Banner {
  z-index: 2;
}

.blurr-bg {
  &::after {
    content: '';
    background-image: url($aws-base-url + '/common/blur-bg.webp');
    opacity: 0.5;
    top: 15%;
    left: 256px;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

// video related utility classes
.video-box-A {
  border-radius: 27px;
  overflow: hidden;
}

.video-icon-A {
  a.nav-link {
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: $starstack-blue-main;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }
  }

  .modal-video-movie-wrap {
    box-shadow: 0px 0px 100px 2px rgb(255 255 255 / 25%);
  }

  .modal-video {
    background-color: rgb(31 31 31 / 0%);
  }

  .modal-video-close-btn:before,
  .modal-video-close-btn:after {
    width: 60%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .modal-video-close-btn {
    top: -15px;
    right: -18px;
    background: $natural-gray-color;
    border-radius: 6px;
    width: 44px;
    height: 44px;
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}

// iframe css
.modalIframe {
  inset: 0px 0px 0px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -161px;
  left: -2px;
  height: calc(100% + 174px);
  width: calc(100% + 4px);

  iframe {
    border-radius: 10px;
    height: 80vh;
  }
}

.text-input {
  padding: 0.3rem 0.5rem;
  background: $gray9-color;
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  color: $white-color;
  margin-right: 1rem;
  max-width: 119px;
}

//----------- Connect Wallet -----------//
.connectwallet-link {
  cursor: pointer;
  color: white !important;
  background: linear-gradient(
    180deg,
    $starstack-blue-main 0%,
    #6036ff 100%
  ) !important;
  border: 0.5px solid $starstack-blue-main;
  &:hover {
    color: $starstack-blue-main !important;
    background: transparent !important;
    transition-duration: 0.5s;
  }
}

//----------- email verification -----------//
.verify-email {
  background-image: url('../../../img/common/CCbg1.webp');
  background-size: 100vw 100vh;
  height: 100vh;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $white-color;
    font-size: 20px;
    font-weight: 500;
  }
}

//--------------- navbar css ---------------//
.navbar {
  background: #000000;

  .navbar-toggler {
    padding-right: 0px;

    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      background: url('../../../img/svg/Menu/Menu.svg');
      background-size: cover;
      width: 1em;
      height: 1em;
    }
  }

  .navbar-collapse {
    .navbar-nav {
      .nav-link {
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: $white-color;
        padding-left: 60px;
        padding-right: 0;
        transition: 0.3s;

        &:hover {
          color: $marguerite-blue-color;
          transition: 0.3s;
        }
      }

      &.right-nav {
        .nav-link {
          padding-left: 0px;
          margin-left: 14px;

          &.nav-btn1 {
            padding: 8px 16px !important;
            background: $mirage-black-color;
            border-radius: 100px;

            span {
              background: -webkit-linear-gradient(
                45deg,
                $greenlight-color,
                $bluelight-color,
                $par-color
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          &.nav-btn2 {
            background: rgb(171 171 171);
            border-radius: 25px;
            padding: 5px 15px !important;
            font-size: 13px;
          }
        }
      }
    }
  }

  &.colorChange {
    background: #000000;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 15%);
  }
}

.mob-block {
  display: none;
}

.tab-content .overview-stats .card {
  width: 100% !important;
}

.form-check-input {
  padding: 0;
}

// Buck Details utility clasess
.rarity-white-icon {
  width: 14px !important;

  svg,
  path {
    fill: #fff;
    stroke: #fff;
    stroke-width: 1px;
  }
}

.freez {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1029;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.shape-bg-section-position img {
  margin-bottom: -14%;
}

.footer-join-astra {
  display: flex;
  margin-top: 64px;
  .line {
    width: 100%;
    height: 1.2px;
    background-color: #939393;
  }
  .link {
    position: relative;

    .blue-logo {
      margin-top: -45px;
      position: relative;
      &:hover {
        .astra-bg {
          background-color: #fff;
        }
      }
      svg {
        width: 300px;
        z-index: 1;
      }
      .astra-bg {
        position: absolute;
        left: 2%;
        top: 35%;
        background-color: #564ece;
        height: 20px;
        width: 270px;
        z-index: -4;
      }
    }
  }
}

.manager-access-noti {
  z-index: 999;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  color: #ff3f56;
  font-size: 18px;
  font-weight: bolder;
  .blink {
    animation: blink-animation 3s steps(5, start) infinite;
    -webkit-animation: blink-animation 3s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  .manager-login-star-text {
    color: gray;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s ease;
    svg {
      margin-right: 0.5rem;
      g {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      color: white;
      svg {
        g {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
}

.inner-html-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
  }
  img {
    height: auto;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
  iframe {
    max-width: 100%;
    // height: auto !important;
  }
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.pointer-none {
  pointer-events: none;
}

.half-opacity {
  opacity: 0.5;
}

.w-max {
  width: max-content;
}

.font-family-gemsbuck {
  font-family: $starstack-font-gemsbuck;
}

.resource-download-icon {
  cursor: pointer;

  &:hover path {
    fill: #fff;
  }
}
