.staking-table {
  position: relative;
  .table-heading {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .cta {
      button {
        border: 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        background: transparent;
        border-radius: 26px;
        padding: 0.5rem 1.5rem;
        margin: 1rem;
        box-shadow: 0px 3px 23px rgba(86, 78, 206, 0.11);
        background: rgba(239, 233, 255, 0.03);
        border-radius: 52px;

        &:nth-child(1) {
          margin-left: 0px;
        }

        &.active {
          background: linear-gradient(
            89.27deg,
            #fc1885 0.23%,
            $starstack-blue-main 0.24%,
            #561eef 99.74%
          );
        }
      }
    }

    .iNFT-purchase-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: $white-color;

      span {
        color: $starstack-blue-main;
      }
    }
  }

  .MuiPaper-root {
    background: transparent;
  }

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        background-color: rgba(255, 255, 255, 0.02);

        .MuiTableCell-root {
          border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
        }

        .MuiTableCell-body {
          color: white;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:nth-child(even) {
          background-color: rgba(255, 255, 255, 0.02);
        }

        .MuiTableCell-root {
          border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
        }

        .MuiTableCell-body {
          color: white;
        }
      }
    }
  }

  .col-name {
    min-width: 300px;
  }

  .box {
    overflow: hidden;
    background: #0b0c11;
    box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
    border-radius: 12px;
  }

  @media screen and (max-width: 600px) {
    .table-heading {
      flex-direction: column;

      .cta {
        button {
          margin: 0px;
          margin-bottom: 10px;
          padding: 0.5rem;
          font-size: 14px;
        }
      }
    }
  }
}

.pagination-common-table {
  background-color: rgba(255, 255, 255, 0.02);
  width: 100%;
  border-radius: 0 0 12px 12px;
  margin-top: -10px;
}
#bluemark {
  &:hover {
    cursor: pointer;
  }
}

.view-all-btn-wrapper {
  display: flex;
  justify-content: center;
  .view-all-button {
    text-align: center;
    cursor: pointer;
    color: #546ece;
    margin: 30px auto;
    line-height: 13.5px;
    border-bottom: 1px solid #546ece;
  }
}

.view-all-only-wrapper {
  display: flex;
  justify-content: flex-end;
  .view-all-only {
    cursor: pointer;
    color: #546ece;
    border-bottom: 1px solid #546ece;
  }
}
