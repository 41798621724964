.OV-MainWrapper {
  background-color: #000000;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .h1 {
    font-size: 43px;
    line-height: 52px;
    font-weight: 600;
    color: #ffffff;
    font-style: normal;
    margin-top: 50px;
  }

  .OV-DivWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-top: -250px;

    .OV-img {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .OV-img2 {
      display: none;
    }

    .OV-Accordian {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .Accordian-solo {
        width: 100%;
        margin-bottom: 30px;
        padding: 30px;
        border-radius: 20px;
        .Header {
          font-size: 32px;
          line-height: 20px;
          font-weight: 400;
          color: #ffffff;
          font-style: normal;
        }

        .Desc {
          font-size: 16px;
          line-height: 27px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.51);
          font-style: normal;
        }
      }
    }
  }
}

.OV-MainWrapper1 {
  display: flex;
  justify-content: center;
  position: relative;
  background: url($aws-base-url + '/aboutus/Vision.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  margin-top: -300px;
  justify-content: center;
  align-items: center;

  .OV-DivWrapper1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;

    .h1 {
      font-size: 43px;
      line-height: 52px;
      font-weight: 600;
      color: #ffffff;
      font-style: normal;
      text-align: center;
    }

    .h2 {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #ffffff;
      font-style: normal;
      text-align: center;
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .OV-MainWrapper {
    .h1 {
      font-size: 43px;
      line-height: 52px;
      font-weight: 600;
      color: #ffffff;
      font-style: normal;
      margin-top: 50px;
    }

    .OV-DivWrapper {
      width: 100%;
      flex-direction: column;
      padding: 10px;
      margin-top: 10px;

      .OV-img {
        display: none;
      }

      .OV-img2 {
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
      }

      .OV-Accordian {
        width: 100%;
        .Accordian-solo {
          width: 100%;
          margin-bottom: 30px;
          padding: 30px;
          border-radius: 20px;

          .Header {
            font-size: 20px;
            line-height: 25px;
          }

          .Desc {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.51);
            font-style: normal;
          }
        }
      }
    }
  }

  .OV-MainWrapper1 {
    height: 60vh;
    margin-top: 0px;

    .OV-DivWrapper1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .h1 {
        font-size: 35px;
        line-height: 40px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
        text-align: center;
      }

      .h2 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .OV-MainWrapper {
    .h1 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 600;
      margin-top: 20px;
    }

    .OV-DivWrapper {
      width: 100%;
      flex-direction: column;
      padding: 10px;
      margin-top: 10px;

      .OV-img {
        display: none;
      }

      .OV-img2 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }

      .OV-Accordian {
        width: 100%;
        .Accordian-solo {
          width: 100%;
          margin-bottom: 10px;
          padding: 10px;
          border-radius: 10px;

          .Header {
            font-size: 20px;
            line-height: 25px;
          }

          .Desc {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .OV-MainWrapper1 {
    height: 60vh;
    margin-top: 0px;

    .OV-DivWrapper1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .h1 {
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        color: #ffffff;
        font-style: normal;
        text-align: center;
      }

      .h2 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #ffffff;
        font-style: normal;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
