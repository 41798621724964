.sb-wrapper {
    position: relative;

    .starter-bundle {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        .text {
            background: linear-gradient(102.14deg, $starstack-blue-main 29.17%, #2b00cf 84.6%);
            border-radius: 20px;
            color: $white-color;
            position: absolute;
            margin-right: 2rem;
            padding: 4em 0;
            .text-text {
                margin: 1.15rem 2rem;
                h1 {
                    font-size: 54px;
                    font-weight: 600;
                }
                p {
                    width: 44%;
                    font-weight: 400;
                    line-height: 28px;
                    font-style: normal;
                }
                .view-bundle-btn {
                    .btn-white {
                        width: 250px;
                        height: 53px;
                        padding: 0;
                        font-size: 16px;
                        font-weight: 400;
                        background: $white-color;
                        color: $starstack-blue-main;
                        &:hover {
                            background: $gray9-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .image {
            position: relative;
            right: 0px;
            width: 50%;
            margin-left: 45%;
            img {
                width: 115%;
            }
        }
        > div {
            flex: 1;
        }
    }
}
